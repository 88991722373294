import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  Tab,
  Tabs,
  Link,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Switch,
  Modal,
  useTheme
} from '@mui/material'
import { HubSpotLogo } from 'src/assets/integrations/HubSpotLogo'
import { ZapierLogo } from 'src/assets/integrations/ZapierLogo'
import WebhookTable from './IntegrationWebhookTable'
import { ApiLogo } from 'src/assets/integrations/apiLogo'
import { useUpdateApiKeyMutation, useGetApiKeyMutation } from 'src/services/integration-service'
import { useGetHubspotIntegrationQuery } from 'src/services/hubspot-service'
import { toast } from 'react-hot-toast'
import { config, planNames } from 'src/config.js'
import HeaderWithPopover from 'src/components/HeaderWithPopover'
import { useSelector } from 'react-redux'
import PremiumFeatureDialog from 'src/utils/premiumFeatureDialog'
import useUserVerification from 'src/hooks/useUserVerification'
import { useAuth } from 'src/auth/auth'
import { useGetMeAndWorkspaceQuery, useGetMeQuery } from 'src/services/user-service'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import axios from 'axios'
// import { getAuthEmail } from "src/services/auth-service.js";
import { Enterprise } from 'src/assets/sidebar/SBEnterprise'
// import { useNavigate } from 'react-router-dom'
import ApiUpgradeButton from 'src/components/ApiUpgradeBtn'
import { useGetCurrentPlanQuery } from 'src/services/billing-service'
import { StarNew } from 'src/icons/starnew'
import PlanUpgradePopup from 'src/components/PlanUpgradePopup'

import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router-dom'

const integrationData = [
  { logo: <ApiLogo />, title: 'Success.ai API' },
  { logo: <ZapierLogo />, title: 'Zapier' },
  { logo: <Enterprise sx={{ fontSize: '100px' }} />, title: 'Warmup Connector' },
  { logo: <HubSpotLogo />, title: 'HubSpot' }
]

const ButtonCommingSoon = () => {
  return (
    <Button
      fullWidth
      variant='contained'
      sx={{
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        py: 1.5
      }}
    >
      Coming Soon
    </Button>
  )
}

const Api = ({
  updateApiKey,
  getApiKey,
  isLoadingUpdateApiKey,
  isFreeUser,
  advancedApiButton = false,
  setOpenModal,
  canEdit,
  tabValue,
  isEarlyBirdAccessUser,
  isAppSumoUser
}) => {
  const [open, setOpen] = useState(false)
  const [clipboard, setClipboard] = useState('')
  const workspace = useSelector((state) => state.workspace)
  const currentPlan = useGetCurrentPlanQuery()
  const PLAN_MONTHLY_GROWTH = 'Monthly Growth'
  const PLAN_YEARLY_GROWTH = 'Yearly Growth'
  const PLAN_MONTHLY_INFINITY = 'Monthly Infinity'
  const PLAN_YEARLY_INFINITY = 'Yearly Infinity'

  const checkPlan = () => {
    const planId =
      currentPlan?.currentData?.subscription?.sendingWarmup?.planId ||
      currentPlan?.currentData?.subscription?.leads?.planId
    const planName = planNames[planId]
    return [
      PLAN_MONTHLY_GROWTH,
      PLAN_YEARLY_GROWTH,
      PLAN_MONTHLY_INFINITY,
      PLAN_YEARLY_INFINITY
    ].includes(planName)
  }

  const handleClickOpen = () => {
    if (isFreeUser) {
      setOpenModal(true)
      return
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleGenerateKey = async () => {
    try {
      const toastId = toast.loading('Generating new API key', { duration: Infinity })
      const { message } = await updateApiKey({ workspace }).unwrap()
      toast.success(message, { id: toastId, duration: 2000 })
      setOpen(false)
    } catch (error) {
      toast.error('Failed to generate API key. Please try again later.')
    }
  }

  const getApiKeyForCopy = async () => {
    const { apiKey } = await getApiKey({ workspace: workspace?._id }).unwrap()
    setClipboard(apiKey)
  }

  const copyToClipBoard = async () => {
    // navigator.clipboard
    //   .writeText(clipboard)
    //   .then(() => {
    //     toast.success('Copied to Clipboard')
    //   })
    //   .catch((err) => {
    //     toast.error('Unable to copy to clipboard')
    //   })

    try {
      await navigator.clipboard.writeText(clipboard)
      toast.success('Copied to Clipboard')
    } catch (err) {
      toast.error('Unable to copy to clipboard')
    }
  }

  useEffect(() => {
    getApiKeyForCopy()
  }, [isLoadingUpdateApiKey, tabValue])

  return (
    <>
      <Box
        sx={{ width: '100%', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}
      >
        {
          (checkPlan() || isEarlyBirdAccessUser || isAppSumoUser)
            ? (
              <Grid container spacing={2} justifyContent='flex-start'>
                <Grid item>
                  <Button variant='outlined' disabled={!canEdit} onClick={handleClickOpen}>
                    Generate new API Key
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!clipboard || isFreeUser || !canEdit}
                    onClick={copyToClipBoard}
                  >
                    Copy API Key
                  </Button>
                </Grid>
              </Grid>
            )
            : (
              <ApiUpgradeButton />
            )
        }
        <Typography variant='body2' sx={{ display: 'block', position: 'absolute', left: '24px', bottom: '2px' }}>
          <Link
            href={`${config.API_BASE_URL}/docs`}
            color='primary'
            underline='none'
            target='_blank'
            rel='noreferrer'
          >
            See Documentation
          </Link>
        </Typography>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: '22px', fontWeight: '700' }}>Are you sure?</DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ letterSpacing: '1px', fontWeight: 400, fontSize: '15px' }}>
            This will generate a new API key and invalidate the old one.
          </Typography>
        </DialogContent>

        <DialogActions sx={{ margin: 2 }}>
          <Button onClick={handleClose} color='primary' variant='outlined'>
            Cancel
          </Button>
          <Button
            disabled={isLoadingUpdateApiKey}
            onClick={handleGenerateKey}
            color='primary'
            variant='contained'
          >
            Generate New API Key
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const IntegrationCard = ({ logo, title, children, zapierSwitch = '', theme }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: '0px 12px 15px 0px #4B71970D',
      width: '100%',
      p: 3,
      flexDirection: 'column',
      height: '100%',
      backgroundColor: '#fff',
      borderRadius: '12px',
      position: 'relative'
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #E4E4E5',
        py: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '120px',
          height: '83px'
        }}
      >
        {logo}
      </Box>
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        mt: 2
      }}
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '28px',
          color: `${theme?.palette.primary.primaryText}`
        }}
      >
        {title}
      </Typography>
      <Box>{zapierSwitch}</Box>
    </Box>

    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        mt: 3
      }}
    >
      {children}
    </Box>
  </Box>
)
export const ChromeExtensionCard = () => {
  const theme = useTheme()
  const [isToggleEnabled, setIsToggleEnabled] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)

  const handleToggle = () => {
    setIsToggleEnabled((prev) => !prev)
  }

  const handleDownload = () => {
    setIsDownloaded()
  }

  return (
    <Tooltip title='Coming Soon' arrow placement='top'>
      <Card
        sx={{
          borderRadius: 2,
          p: 2,
          textAlign: 'center',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Box
          sx={{
            borderRadius: 2,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src='/assets/img.png'
            alt='Chrome Extension'
            style={{
              width: '100%',
              height: '190px',
              objectFit: 'cover',
              borderRadius: '12px'
            }}
          />
        </Box>

        <Box sx={{ textAlign: 'start', mt: 2 }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              color: theme.palette.primary.primaryText
            }}
          >
            Chrome Extension
          </Typography>
          <Typography sx={{ color: '#8181B0', mb: 2, fontSize: '14px', fontWeight: 400 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
          </Typography>
        </Box>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <Typography
            sx={{
              mr: 1,
              color: theme.palette.primary.primaryText,
              fontSize: '14px',
              fontWeight: 700
            }}
          >
            Enable Chrome Extension
          </Typography>
          <Switch checked={isToggleEnabled} onChange={handleToggle} />
        </Box>

        {isToggleEnabled && (
          <Button
            fullWidth
            variant='contained'
            sx={{
              borderRadius: '8px',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              py: 2
            }}
            startIcon={
              <img
                src='/assets/Icon-1.png' // Replace with your actual image path
                alt='Download Icon'
                style={{ width: '20px', height: '20px' }}
              />
            }
            onClick={handleDownload}
            disabled={isDownloaded}
          >
            {isDownloaded ? 'Downloaded' : 'Download Extension'}
          </Button>
        )}
      </Card>
    </Tooltip>
  )
}

const Page = () => {
  const navigate = useNavigate()
  const { data: user } = useGetMeQuery()
  const [tabValue, setTabValue] = useState(0)
  const [isZapierEnabled, setIsZapierEnabled] = useState(false)
  const [zapierKey, setZapierKey] = useState('')
  const [updateApiKey, { isLoading: isLoadingUpdateApiKey }] = useUpdateApiKeyMutation()
  const [getApiKey] = useGetApiKeyMutation()
  const { data: HubSpotIntegrationData } = useGetHubspotIntegrationQuery()
  const [openModal, setOpenModal] = useState(false)
  const { isFreeUser, advancedApiButton, isEarlyBirdAccessUser, isAppSumoUser } = useUserVerification()
  const { canEdit } = useAuth()
  const [openOverlay, setOpenOverlay] = useState(false)
  const currentPlan = useGetCurrentPlanQuery()
  const PLAN_MONTHLY_GROWTH = 'Monthly Growth'
  const PLAN_YEARLY_GROWTH = 'Yearly Growth'
  const PLAN_MONTHLY_INFINITY = 'Monthly Infinity'
  const PLAN_YEARLY_INFINITY = 'Yearly Infinity'

  const checkPlan = () => {
    const planId =
      currentPlan?.currentData?.subscription?.sendingWarmup?.planId ||
      currentPlan?.currentData?.subscription?.leads?.planId
    const planName = planNames[planId]
    return [
      PLAN_MONTHLY_GROWTH,
      PLAN_YEARLY_GROWTH,
      PLAN_MONTHLY_INFINITY,
      PLAN_YEARLY_INFINITY
    ].includes(planName)
  }

  const handleOpenOverlay = () => {
    setOpenOverlay(true)
  }

  const handleCloseOverlay = () => {
    setOpenOverlay(false)
    setTabValue(0)
  }
  const email = user?.email

  const theme = useTheme()

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const [, setWorkspace] = useState(null)

  const handleWorkspaceChange = (newWorkspace) => {
    setWorkspace(newWorkspace)
  }

  const { data } = useGetMeAndWorkspaceQuery()

  const addZapierApiKey = async (checked) => {
    if (email) {
      try {
        const result = await axios.post(`${config.API_BASE_URL}/integrations/store-zapier-key`, {
          email,
          checked
        })

        if (result !== undefined) {
          setZapierKey(result?.data?.zapierApiKey)
          setIsZapierEnabled(checked)
        } else {
          console.log('undefind issue result -> ', result)
        }
      } catch (error) {
        console.error('Error updating isAuto:', error)
      }
    }
  }

  const copy = () => {
    navigator.clipboard.writeText(zapierKey)
    toast.success('Copied to Clipboard')
  }

  const getUserIntegration = async (email) => {
    try {
      const result = await axios.get(
        `${config.API_BASE_URL}/integrations/get-user-integrations/${email}`
      )
      if (result !== undefined) {
        setIsZapierEnabled(result?.data?.[0]?.zapierVerifiedFlag === 'Y')
        setZapierKey(result?.data?.[0]?.zapierApiKey)
      } else {
        console.log('undefind issue result -> ', result)
      }
    } catch (error) {
      console.error('Error updating isAuto:', error)
    }
  }

  getUserIntegration(email)
  // const navigate = useNavigate()

  const handleNavigate = () => {
    navigate('/integrations/hubspot-settings')
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%'
        }}
      >
        <Box
          sx={{
            width: '90%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <HeaderWithPopover
            title='Integrations'
            onWorkspaceChange={handleWorkspaceChange}
            selectedTimeline={data?.workspace}
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column'
            }}
          >
            <Typography
              sx={{
                color: '#8181B0',
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0px',
                width: '100%',
                mt: 1.5
              }}
            >
              Experience seamless integration and elevate your efficiency with Success.ai. Choose
              the method that suits you best and supercharge your productivity today!
            </Typography>
          </Box>
          <Box sx={{ mt: 3, width: '100%' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              onClick={handleOpenOverlay}
              sx={{ borderBottom: '1px solid #E4E4E5' }}
            >
              <Tab label='API & Integrations' />
              <Tab label='Webhook' />
            </Tabs>
            <Box sx={{ mt: 3 }}>
              {!isFreeUser
                ? (
                  tabValue === 0 && (
                    <Grid container columnSpacing={3} rowGap={2}>
                      {integrationData.map((integration, index) =>
                        integration.title === 'Success.ai API'
                          ? (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                              <IntegrationCard logo={integration.logo} title={integration.title} theme={theme}>
                                <Api
                                  updateApiKey={updateApiKey}
                                  getApiKey={getApiKey}
                                  isLoadingUpdateApiKey={isLoadingUpdateApiKey}
                                  isFreeUser={isFreeUser}
                                  advancedApiButton={advancedApiButton}
                                  setOpenModal={setOpenModal}
                                  canEdit={canEdit}
                                  tabValue={tabValue}
                                  isEarlyBirdAccessUser={isEarlyBirdAccessUser}
                                  isAppSumoUser={isAppSumoUser}
                                />
                              </IntegrationCard>
                            </Grid>
                          )
                          : integration.title === 'Zapier'
                            ? (
                              <Grid item xs={12} sm={6} md={4} key={index}>
                                <IntegrationCard
                                  logo={integration.logo}
                                  title={integration.title}
                                  zapierSwitch={
                                    <Switch
                                      checked={isZapierEnabled}
                                      onChange={async ({ target: { checked } }) => {
                                        await addZapierApiKey(checked)
                                      }}
                                      name='enable_disable_zapier'
                                    />
                                  }
                                  theme={theme}
                                >
                                  {
                                    isZapierEnabled && (
                                      <Grid
                                        container
                                        spacing={2}
                                        justifyContent='space-between'
                                        alignItems='center'
                                      >
                                        <Grid item xs={12}>
                                          <Typography variant='subtitle2' color='textSecondary'>
                                            Zapier Api Key
                                          </Typography>

                                          <Button
                                            variant='contained'
                                            color='primary'
                                            display='flex'
                                            justifyContent='space-between'
                                            alignItems='center'
                                            onClick={() => copy()}
                                            sx={{
                                              borderRadius: '10px',
                                              marginTop: '0.5rem',
                                              overflow: 'hidden',
                                              cursor: 'pointer',
                                              width: '100%',
                                              height: '100%'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                padding: '13px 16px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                color: 'white',
                                                fontWeight: '600',
                                                textOverflow: 'ellipsis',
                                                fontSize: { xs: '12px', sm: '14px' },
                                                maxWidth: { xs: '70%', sm: 'calc(100% - 40px)' }
                                              }}
                                            >
                                              Copy API Key       {/* zapierKey?.substring(0, isXs ? 20 : 33) + "..." */}
                                            </Typography>
                                            <ContentCopyIcon style={{ color: '#fff' }} />
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    )
                                  }
                                </IntegrationCard>
                              </Grid>
                            )
                            : integration.title === 'Warmup Connector'
                              ? (
                                <>
                                  {/* <Grid item xs={12} sm={6} md={4} key={index}>
                                  <IntegrationCard logo={integration.logo} title='Warmup Connector' theme={theme}>
                                    <Button
                                      fullWidth
                                      variant='contained'
                                      title={
                                        data?.workspace?.enterprise
                                          ? 'Warmup Connector'
                                          : 'Warmup Connector'
                                      }
                                      onClick={() => data?.workspace?.enterprise && navigate('/enterprise')}
                                      disabled={!data?.workspace?.enterprise || !canEdit}
                                      sx={{
                                        borderRadius: '8px',
                                        fontSize: '14px',
                                        fontWeight: 700,
                                        lineHeight: '18px',
                                        py: 2.5,
                                        backgroundColor: data?.workspace?.enterprise
                                          ? 'primary.main'
                                          : 'gray',
                                        color: data?.workspace?.enterprise ? 'white' : 'black'
                                      }}
                                    >
                                      {data?.workspace?.enterprise
                                        ? 'Warmup Connector'
                                        : 'Warmup Connector'}
                                    </Button>
                                  </IntegrationCard>
                                </Grid> */}
                                </>
                              )
                              : integration.title === 'HubSpot'
                                ? (
                                  <Grid item xs={12} sm={6} md={4} key={index}>
                                    <IntegrationCard logo={integration.logo} title={integration.title}>
                                      {
                                        (checkPlan() || isEarlyBirdAccessUser || isAppSumoUser)
                                          ? (
                                            !HubSpotIntegrationData?.data
                                              ? (
                                                <Button
                                                  fullWidth
                                                  variant='contained'
                                                  sx={{
                                                    borderRadius: '8px',
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    lineHeight: '18px',
                                                    py: 1.5
                                                  }}
                                                  href={config.HUBSPOT_LINK}
                                                  target='_blank'
                                                >
                                                  Start Integration
                                                </Button>
                                              )
                                              : (
                                                <Button
                                                  fullWidth
                                                  variant='contained'
                                                  sx={{
                                                    borderRadius: '8px',
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    lineHeight: '18px',
                                                    py: 1.5
                                                  }}
                                                  onClick={handleNavigate}
                                                >
                                                  HubSpot Settings
                                                </Button>
                                              )
                                          )
                                          : (
                                            <ApiUpgradeButton />
                                          )
                                      }
                                    </IntegrationCard>
                                  </Grid>
                                )
                                : (
                                  <Grid item xs={12} sm={6} md={4} key={index}>
                                    <IntegrationCard logo={integration.logo} title={integration.title}>
                                      <ButtonCommingSoon />
                                    </IntegrationCard>
                                  </Grid>
                                )
                      )}
                    </Grid>
                  )
                )
                : (
                  <Modal
                    open={openOverlay}
                    onClose={handleCloseOverlay}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <PlanUpgradePopup
                      onClose={handleCloseOverlay}
                      icon={<StarNew sx={{ height: '38px', width: '45px' }} />}
                      cardContent='Your|plan|does not support|Webhooks'
                    />
                  </Modal>
                )}
              {/* {tabValue === 1 && <WebhookTable canEdit={canEdit} />} */}
              {/* {tabValue === 1 && <WebhookTable canEdit={canEdit} />} */}
              {
                tabValue === 1
                  ? (
                    (checkPlan() || isEarlyBirdAccessUser || isAppSumoUser)
                      ? (
                        <WebhookTable canEdit={canEdit} />
                      )
                      : (
                        <Modal
                          open={openOverlay}
                          onClose={handleCloseOverlay}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <PlanUpgradePopup onClose={handleCloseOverlay} icon={<StarNew sx={{ height: '38px', width: '45px' }} />} cardContent='Your|plan|does not support|Webhooks' />
                        </Modal>
                      )
                  )
                  : <></>
              }
            </Box>
          </Box>
        </Box>
      </Box>
      <PremiumFeatureDialog open={openModal} onClose={handleCloseModal} />
    </>
  )
}

export default Page
