import React, { useState } from 'react'
import {
  Box,
  Chip,
  Typography,
  useTheme,
  Tooltip,
  Popover,
  Button
  , useMediaQuery,
  Grid
} from '@mui/material'
import { EAMoreIcon } from 'src/assets/emailAccounts/EAMoreIcon'
import { LinkedIn, WhatsApp, Delete, DriveFileRenameOutline } from '@mui/icons-material'
import { EATotalEmailsSent } from 'src/assets/emailAccounts/EATotalEmailsSent'
import LAInvitationReqIcon from 'src/assets/emailAccounts/LAInvitationReqIcon.png'
import { EATotalWarmupSent } from 'src/assets/emailAccounts/EATotalWarmupSent'
import { LABounceIcon } from 'src/assets/emailAccounts/LABounceIcon'
import LAMessageSentIcon from 'src/assets/emailAccounts/LAMessageSentIcon.png'
import { capitalizeFirstLetter } from 'src/utils/helperFunctions'

const LinkedAccountBlock = ({ account, cardName, bulkSettingsOn, canEdit = true, onDelete, deleteLoading, onReconnect, reconnectLoading }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isTab = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const isCardView = cardName === 'Card view' || isMobile || isTab
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const isTableView = cardName === 'Table view' && !isMobile && !isTab
  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (

    <Box
      sx={{
        width: '100%',
        height: '100%',
        p: 3,
        pt: 1.5,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0px 12px 15px 0px #4B71970D',
        backgroundColor: '#fff',
        '&:hover': {
          boxShadow: '0px 2px 14px -1px rgba(0, 0, 0, 0.2)'
        },
        transition: 'all 0.2s ease-in-out',
        flexDirection: 'column'
        // position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          flexDirection: { xs: 'column-reverse', md: 'row' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // width: isCardView ? '100%' : '150vw',
            maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
          }}
        >
          {
            account?.account_type === 'LINKEDIN' && <LinkedIn sx={{ height: '40px', width: '40px', color: '#0077B5' }} />
          }
          {
            account?.account_type === 'WHATSAPP' && <WhatsApp sx={{ height: '40px', width: '40px', color: '#25D366' }} />
          }
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
            // bulkSettingsOn ? "calc(100% - 50px)" : "100%""
          }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                gap: '2px',
                width: isCardView ? '100%' : { md: '39vw', lg: '30vw', xl: '36vw' }
              }}
            >
              {isCardView
                ? (
                  <Typography
                    sx={{
                      ml: 1,
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '18px',
                      color: `${theme.palette.primary.primaryText}`,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%'
                    }}
                  >
                    {account.account_type === 'LINKEDIN' ? `${account.account_obj.first_name} ${account.account_obj.last_name}` : account.account_type === 'WHATSAPP' ? account.account_obj?.name : account.account_id}

                  </Typography>
                  )
                : <></>}
              <Typography
                sx={{
                  display: 'flex',
                  ml: !bulkSettingsOn && 1,
                  fontSize: isCardView ? '13px' : '14px',
                  fontWeight: isCardView ? 400 : 600,
                  lineHeight: '18px',
                  color: isCardView ? '#8181B0' : '#14171f',
                  // whiteSpace: 'wrap',

                  // textOverflow: 'ellipsis',
                  width: '100%'
                }}
              >
                {/* {account.email}{" "} */}
                {account.account_type === 'LINKEDIN'
                  ? (
                    <div>
                      <span>{account?.account_obj?.email}</span><br />
                      <span style={{ fontWeight: 'normal' }}>{'(  ' + account?.account_id + '  )'}</span>
                    </div>
                    )
                  : account?.account_id}{' '}
                {isTableView && (
                  account?.account_status !== 'OK'
                )
                  ? (
                    <Chip
                      label='Disconnected'
                      sx={{
                        fontSize: '10px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: 'red',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        border: ' 1px solid #FAD7DB',
                        px: 0.5,
                        ml: 1,
                        width: '100px'
                      }}
                      size='small'
                    />
                    )
                  : (
                    <></>
                    )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'space-between', md: 'center' },
              alignItems: 'center',
              width: { xs: '100%', md: 'fit-content' },
              borderRadius: 0.75,
              p: 1.3,
              pr: 0
            }}
          >
            {' '}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#f2f2f2'
                },
                borderRadius: 0.75,
                p: 0.5
              }}
              onClick={handleClick}
            >
              {account?.account_status === 'OK'
                ? <Chip
                    label='Connected'
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '16px',
                      color: 'green',
                      backgroundColor: '#fff',
                      borderRadius: '18px',
                      border: '1px solid lime',
                      px: 0.5,
                      py: 2,
                      width: '100%'
                    }}
                    size='small'
                  />
                : account?.account_status === 'CONNECTING'
                  ? <Chip
                      label='Connecting'
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: '#b8860b',
                        backgroundColor: '#fff',
                        borderRadius: '18px',
                        border: '1px solid yellow',
                        px: 0.5,
                        py: 2,
                        width: '100%'
                      }}
                      size='small'
                    />
                  : <Chip
                      label={capitalizeFirstLetter(account?.account_status)}
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: 'red',
                        backgroundColor: '#fff',
                        borderRadius: '18px',
                        border: '1px solid red',
                        px: 0.5,
                        py: 2,
                        width: '100%'
                      }}
                      size='small'
                    />}
              <Tooltip title='' placement='top' sx={{ textAlign: 'center' }} arrow>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <EAMoreIcon />
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* ---------------------MID--------------- */}
      <>
        <Grid container spacing={1} sx={{}} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            py: 2,
            mt: 1,
            borderTop: `1px solid ${theme.palette.grey[200]}`
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <EATotalEmailsSent />
            </Box>
            <Box sx={{ ml: 1 }}>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',

                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '25px',
                  color: theme.palette.primary.primaryText
                }}
              >
                {account?.linkedInRequestSentToday || 0}
              </Typography>
              <Typography sx={{ color: '#8181B0', fontSize: '13px', fontWeight: 400 }}>
                Linkedin Requests Sent Today
              </Typography>
            </Box>
          </Box>

        </Box>
        <Grid container spacing={2} columnSpacing={3}>
          <Grid item xs={6}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                p: 2,
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                backgroundColor: '#F2F4F6',
                flexDirection: 'column',
                gap: 1
              }}
            >
              {' '}
              <Box>
                {' '}
                <EATotalWarmupSent />
              </Box>
              <Typography sx={{ color: '#8181B0', fontSize: '13px', fontWeight: 400 }}>
                LinkedIn Message Sent Today
              </Typography>
              <Typography
                sx={{ fontSize: '20px', fontWeight: 700, lineHeight: '25px', color: theme.palette.primary.primaryText }}
              >
                {' '}
                {account?.linkedInMessageSentToday || 0}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                p: 2,
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                backgroundColor: '#F2F4F6',
                flexDirection: 'column',
                gap: 1
              }}
            >
              <Box>
                <img
                  height={20}
                  width={20}
                  src={LAMessageSentIcon}
                />
              </Box>
              <Typography sx={{ color: '#8181B0', fontSize: '13px', fontWeight: 400 }}>
                Message Sent Past Week
              </Typography>
              <Typography
                sx={{ fontSize: '20px', fontWeight: 700, lineHeight: '25px', color: theme.palette.primary.primaryText }}
              >
                {account?.linkedInMessageSentPastWeek || 0}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                p: 2,
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                backgroundColor: '#F2F4F6',
                flexDirection: 'column',
                gap: 1
              }}
            >
              <Box>
                {' '}
                <LABounceIcon />
              </Box>
              <Typography sx={{ color: '#8181B0', fontSize: '13px', fontWeight: 400 }}>
                Message Bounced Past Week
              </Typography>
              <Typography
                sx={{ fontSize: '20px', fontWeight: 700, lineHeight: '25px', color: theme.palette.primary.primaryText }}
              >
                {' '}
                {account?.bouncedMessagePastWeek || 0}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                p: 2,
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                backgroundColor: '#F2F4F6',
                flexDirection: 'column',
                gap: 1
              }}
            >
              <Box>
                {' '}
                <img
                  height={20}
                  width={20}
                  src={LAInvitationReqIcon}
                />

              </Box>
              <Typography sx={{ color: '#8181B0', fontSize: '13px', fontWeight: 400 }}>
                LinkedIn Request Sent Past Week
              </Typography>
              <Typography
                sx={{ fontSize: '20px', fontWeight: 700, lineHeight: '25px', color: theme.palette.primary.primaryText }}
              >
                {' '}
                {account?.linkedInRequestSentPastWeek || 0}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>

      {/* ---------------------MID--------------- */}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 240
          }}
        >
          {(account?.account_status !== 'OK' && account?.account_status !== 'CONNECTING' && account?.account_status !== 'FAILED') && (
            <Button
              fullWidth
              sx={{
                py: 2,
                px: 2,

                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                color: '#101828'
              }}
                // disabled = {account.status === 'paused'}
              onClick={() => { onReconnect(account.account_id, account.account_obj?.email); handleClose() }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <DriveFileRenameOutline sx={{ mr: 1 }} fontSize='small' />
                Reconnect to Account
              </Box>
            </Button>
          )}
          <Button
            fullWidth
            sx={{
              py: 2,
              px: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              color: '#101828',
              '&:hover': {
                backgroundColor: '#ffd6d6'
              },
              borderRadius: 0
            }}
            // disabled = {account.status === 'paused'}
            onClick={() => {
              // setOpenRemoveAccountDialog(true);
              onDelete(account.account_id)
              setAnchorEl(null)
            }}
            disabled={!canEdit}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Delete sx={{ mr: 1 }} fontSize='small' />
              Delete Account
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

export default LinkedAccountBlock
