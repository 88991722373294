import React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { useTheme, alpha } from '@mui/material/styles'

const PhoneBlueSvg = (props) => {
  const theme = useTheme()
  const fillColor = alpha(theme.palette.primary.main, 0.1)
  const strokeColor = theme.palette.primary.main

  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='22' height='22' rx='3' fill={fillColor} />
      <path
        d='M8.88849 9.16446C9.29449 10.0101 9.84795 10.8026 10.5489 11.5035C11.2498 12.2044 12.0423 12.7579 12.8879 13.1639C12.9607 13.1988 12.9971 13.2163 13.0431 13.2297C13.2066 13.2774 13.4074 13.2431 13.5459 13.144C13.5849 13.1161 13.6182 13.0827 13.6849 13.016C13.8889 12.8121 13.9908 12.7101 14.0934 12.6435C14.4801 12.392 14.9786 12.392 15.3652 12.6435C15.4678 12.7101 15.5698 12.8121 15.7737 13.016L15.8874 13.1297C16.1974 13.4397 16.3524 13.5947 16.4366 13.7612C16.604 14.0923 16.604 14.4833 16.4366 14.8143C16.3524 14.9808 16.1974 15.1358 15.8874 15.4458L15.7954 15.5378C15.4865 15.8467 15.332 16.0012 15.122 16.1192C14.8889 16.2501 14.527 16.3442 14.2597 16.3434C14.0188 16.3427 13.8542 16.296 13.5249 16.2025C11.7554 15.7003 10.0857 14.7527 8.69272 13.3597C7.29973 11.9667 6.35212 10.297 5.84989 8.5275C5.75643 8.19824 5.70971 8.03361 5.70899 7.79273C5.70819 7.52543 5.80232 7.16348 5.93323 6.93043C6.05121 6.72041 6.20568 6.56594 6.51463 6.25699L6.60659 6.16504C6.91659 5.85503 7.0716 5.70003 7.23807 5.61583C7.56915 5.44837 7.96014 5.44837 8.29121 5.61583C8.45768 5.70003 8.61269 5.85503 8.9227 6.16504L9.03637 6.27871C9.24031 6.48265 9.34228 6.58462 9.40894 6.68716C9.66036 7.07384 9.66036 7.57235 9.40894 7.95903C9.34228 8.06157 9.24031 8.16354 9.03637 8.36748C8.96969 8.43416 8.93635 8.4675 8.90844 8.50647C8.80927 8.64497 8.77502 8.84579 8.8227 9.00933C8.83611 9.05535 8.85357 9.09172 8.88849 9.16446Z'
        stroke={strokeColor}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export const PhoneBlueIcon = createSvgIcon(<PhoneBlueSvg />, 'PhoneBlue')