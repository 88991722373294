/* eslint-disable no-prototype-builtins */
import axios from 'axios'
// import moment from "moment";
import { config } from 'src/config'
import { toast } from 'react-hot-toast'
import moment from 'moment-timezone'
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
export function convertToNestedObject (obj) {
  const result = {}

  for (const key in obj) {
    const parts = key.split('.')
    let nestedObj = result

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i]
      if (!nestedObj[part]) {
        nestedObj[part] = i === parts.length - 1 ? obj[key] : {}
      }
      nestedObj = nestedObj[part]
    }
  }

  return result
}

export function countStatus (dataToCheck, key) {
  const filteredData = dataToCheck.filter((data) => data[key] === true)
  return filteredData?.length
}
export function isFreeTrialExpired (freeTrialExpiresAt) {
  // Parse the freeTrialExpiresAt string using Moment.js
  const expiresAt = moment(freeTrialExpiresAt)

  // Get the current date using Moment.js
  const currentDate = moment()

  // Compare the two dates
  if (currentDate.isAfter(expiresAt)) {
    // The current date is after the expiration date
    return false
  } else {
    // The free trial is still valid
    return true
  }
}
export function daysLeftInFreeTrial (freeTrialExpiresAt) {
  // Parse the freeTrialExpiresAt string using Moment.js
  const expiresAt = moment(freeTrialExpiresAt)

  // Get the current date using Moment.js
  const currentDate = moment()

  // Calculate the difference in days
  const daysLeft = expiresAt.diff(currentDate, 'days')

  return daysLeft
}
export function filterObject (object) {
  const result = {}
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key]
      if (!element) continue
      if (Array.isArray(element) && !element.length) continue
      result[key] = element
    }
  }
  return result
}

export function getWeekdays () {
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const currentDay = weekdays[new Date().getDay()]

  while (currentDay !== weekdays[weekdays.length - 1]) {
    weekdays.push(weekdays.shift())
  }

  return weekdays
}

export function getDayName (dateString) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const d = new Date(dateString)
  const dayName = days[d.getDay()]
  return dayName
}

export function getCurrentWeekdays (timezone) {
  const weekdays = moment.weekdays()

  const currentDay = moment.tz(timezone).format('dddd')

  while (currentDay !== weekdays[weekdays.length - 1]) {
    weekdays.push(weekdays.shift())
  }

  return weekdays
}

export function last7dates () {
  const today = new Date()

  const last7Dates = []

  for (let i = 0; i < 7; i++) {
    const date = new Date(today)
    date.setDate(today.getDate() - i)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    last7Dates.push(`${month}/${day}/${year}`)
  }

  return last7Dates
}

export function next7dates () {
  const today = new Date()
  const next7Dates = []

  for (let i = 1; i <= 7; i++) {
    const date = new Date(today)
    date.setDate(today.getDate() + i)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    next7Dates.push(`${month}/${day}/${year}`)
  }

  return next7Dates
}

export const getShortDayName = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase()
}

export function createCsv (data) {
  const header = Object.keys(data[0]).join(',')
  return data.reduce((acc, current) => {
    const row = Object.values(current).map((v) => {
      if (typeof v === 'string' && v.includes(',')) {
        return `"${v}"`
      } else if (v === null || v === undefined || v === '') {
        return '' // Treat null, undefined, and empty values as empty strings
      } else {
        return String(v) // Convert non-string values to strings
      }
    })
    return acc + '\n' + row.join(',')
  }, header)
}

export function downloadCsv (filename, data, url) {
  try {
    const element = document.createElement('a')
    if (url) {
      element.href = url
    } else {
      const file = new Blob([createCsv(data)], { type: 'text/csv;charset=utf-8' })
      element.href = URL.createObjectURL(file)
    }
    element.download = filename + '.csv'
    document.body.appendChild(element)
    element.click()
    element.remove()
  } catch (err) {
    console.log(err)
  }
}

const s3 = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
  }
})

async function generateSignedUrl (objectKey, expiresIn = 3600) {
  try {
    const command = new GetObjectCommand({
      Bucket: process.env.REACT_APP_AWS_CSV_BUCKET_NAME,
      Key: objectKey
    })
    const url = await getSignedUrl(s3, command, { expiresIn })
    return url
  } catch (error) {
    throw new Error('Error generating signed URL')
  }
}

export const downloadCsvWithSignedURL = async (s3FileKey) => {
  try {
    // const urlObj = new URL(s3FileKey);
    // const filename = urlObj.pathname.substring(1);
    const signedUrl = await generateSignedUrl(s3FileKey)
    const element = document.createElement('a')
    element.href = signedUrl
    document.body.appendChild(element)
    element.click()
    element.remove()
  } catch (error) {
    throw new Error('Error downloading CSV:')
  }
}

export async function fetchGoogleSheet (link) {
  const sheetID = link.split('/d/')[1].split('/edit')[0]
  const range = 'A1:ZZ'
  const apiKey = config.GOOGLE_SHEET_KEY
  // update key
  try {
    const sheetInfoEndpoint = `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}?key=${apiKey}`
    const sheetInfoResponse = await axios.get(sheetInfoEndpoint)
    const sheetInfo = sheetInfoResponse.data

    const sheetCount = sheetInfo.sheets.length

    if (sheetCount > 1) {
      return {
        error: true,
        message: 'More than one sheet found. This function supports only single-sheet documents.'
      }
    }

    const valuesEndpoint = `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}/values/${range}?key=${apiKey}`
    const valuesResponse = await axios.get(valuesEndpoint)
    const data = valuesResponse.data

    if (!data.values || data.values.length === 0) {
      return {
        error: true,
        message: 'No Data Found in the sheet.'
      }
    }

    // Extracting columns and rows
    const columns = data.values[0]
    const rows = data.values.slice(1)

    // Identify non-empty columns
    const nonEmptyColumnIndexes = columns.reduce((indexes, column, index) => {
      const isColumnEmpty = rows.every(row => !row[index] || row[index] === '')
      if (!isColumnEmpty) {
        indexes.push(index)
      }
      return indexes
    }, [])

    // Filter columns and rows to remove empty columns
    const filteredColumns = nonEmptyColumnIndexes.map(index => columns[index])
    const filteredRows = rows.map(row => nonEmptyColumnIndexes.map(index => row[index] || 'NA'))

    return { columns: filteredColumns, rows: filteredRows }
  } catch (error) {
    console.error('Error fetching data:', error)
    return {
      error: true,
      message: 'Error fetching data from the sheet.'
    }
  }
}

export const uploadImage = async (url, avatar) => {
  try {
    const response = await axios.put(url, avatar)
    return response
  } catch (error) {
    console.log(error)
  }
  return null
}

export function generateDates (start, end, timezone) {
  const startDate = moment.tz(start, timezone).startOf('day')
  const endDate = moment.tz(end, timezone).startOf('day')

  const labels = []

  while (startDate.isSameOrBefore(endDate, 'day')) {
    const label = startDate.format('YYYY-MM-DD')
    labels.push(label)
    startDate.add(1, 'day')
  }

  return labels
}

export const handleDialogUploadAllClick = async ({
  listType,
  leads,
  domains,
  isUploading,
  checkDuplicates,
  stats,
  link,
  addBlocklist,
  setOpen,
  setSnackbarOpen,
  setSnackbarMsg,
  onLeadsCreate,
  workspaceId
}) => {
  const errorMessage = listType === 'email' ? 'Email parameter is mandatory...' : 'Domain parameter is mandatory...'
  const dataArray = listType === 'email' ? leads : domains
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/
  const emailNotFound = dataArray.every(obj => !obj.hasOwnProperty('email'))
  const domainNotFound = dataArray.every(obj => !obj.hasOwnProperty('domain'))
  if (Object.keys(dataArray[0]).length === 0 || listType === 'email' ? emailNotFound : domainNotFound) {
    toast.error(errorMessage)
    return
  }
  let validEmails = []
  let validDomains = []
  if (listType === 'email') {
    validEmails = dataArray
      .map(obj => ({ ...obj, email: obj.email?.trim() }))
      .filter(obj => emailRegex.test(obj.email))

    if (validEmails.length === 0) {
      toast.error('Invalid data: The array must only contain valid email addresses.')
      return
    }
  } else if (listType === 'domain') {
    validDomains = dataArray
      .map(obj => ({ ...obj, domain: obj.domain?.trim() }))
      .filter(obj => domainRegex.test(obj.domain))

    if (validDomains.length === 0) {
      toast.error('Invalid data: The array must only contain valid domain addresses.')
      return
    }
  }
  setOpen(false)
  if (isUploading) return
  try {
    let totalCreatedLeads = []
    let data = {}
    setSnackbarOpen(true)
    setSnackbarMsg('Uploading leads...')
    const limit = 5000
    const total = listType === 'email'
      ? (checkDuplicates ? stats?.leads?.length : leads.length)
      : (checkDuplicates ? stats?.leads?.length : domains.length)
    for (let i = 0; i < total; i += limit) {
      const start = i
      const end = i + limit > total ? i + (total % limit) : i + limit
      const blockData = listType === 'email'
        ? (checkDuplicates ? stats?.leads.slice(start, end) : validEmails.slice(start, end))
        : (checkDuplicates ? stats?.leads.slice(start, end) : validDomains.slice(start, end))
      if (link) {
        data = {
          blockData,
          type: 'Google',
          link,
          listType,
          workspaceId
        }
      } else {
        data = {
          blockData,
          type: 'CSV',
          listType,
          workspaceId
        }
      }
      const { totalAdded } = await addBlocklist({ data }).unwrap()
      totalCreatedLeads = [...totalCreatedLeads, ...totalAdded]
      setSnackbarMsg(`${end} / ${total} emails uploaded!`)
    }
    onLeadsCreate(true)
    setTimeout(() => {
      setSnackbarOpen(false)
      toast.success(`${totalCreatedLeads.length ?? 0} out of ${total ?? 0} uploaded successfully!`)
      if (totalCreatedLeads.length < total) {
        toast.error(`There were errors with ${total - totalCreatedLeads.length ?? 0} remaining entries.`)
      }
    }, 2500)
  } catch (error) {
    toast.error(error.data.error.message)
  }
}

export const handleUploadAllClick = async (checkDuplicates, listType, leads, domains, duplicateCheck, setStats, setOpen, workspaceId) => {
  try {
    const data = {
      checkDuplicates,
      stats: true,
      listType,
      workspaceId
    }
    const errorMessage = listType === 'email' ? 'Email parameter is mandatory...' : 'Domain parameter is mandatory...'
    const dataArray = listType === 'email' ? leads : domains
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/
    const emailNotFound = dataArray.every(obj => !obj.hasOwnProperty('email'))
    const domainNotFound = dataArray.every(obj => !obj.hasOwnProperty('domain'))
    if (Object.keys(dataArray[0]).length === 0 || listType === 'email' ? emailNotFound : domainNotFound) {
      toast.error(errorMessage)
      return
    }
    if (listType === 'email') {
      const validEmails = dataArray
        .map(obj => ({ ...obj, email: obj.email?.trim() }))
        .filter(obj => emailRegex.test(obj.email))

      if (validEmails.length === 0) {
        toast.error('Invalid data: The array must only contain valid email addresses.')
        return
      }
      data.blockData = dataArray
    } else {
      const validDomains = dataArray
        .map(obj => ({ ...obj, domain: obj.domain?.trim() }))
        .filter(obj => domainRegex.test(obj.domain))

      if (validDomains.length === 0) {
        toast.error('Invalid data: The array must only contain valid domain addresses.')
        return
      }
      data.blockData = dataArray
    }
    const { createdEmails } = await duplicateCheck({ data }).unwrap()
    setStats(createdEmails)
    setOpen(true)
  } catch (error) {
    // toast.error(error.data.error.message);
  }
}

export const updateData = (data, type, setLeads, setDomains) => {
  const updatedData = data.map((row) => {
    const item = {}
    row.forEach((cell) => {
      if (cell.type !== 'DO_NOT_IMPORT') {
        cell.type === type && (item[cell.type] = cell.value)
      }
    })
    return item
  })
  setLeads(updatedData)
  setDomains(updatedData)
}

export const handleSelectTypeChange = (sheetData, column, selection, setSheetData, setLeads, setDomains, setSelectionValue, index, selectionValue) => {
  const newSelectionValue = [...selectionValue]
  newSelectionValue[index] = selection
  setSelectionValue(newSelectionValue)
  const updatedSheetData = sheetData.map((row) =>
    row.map((cell) => {
      if (cell.column === column) return { ...cell, type: selection.value }
      return cell
    })
  )
  setSheetData(updatedSheetData)
  updateData(updatedSheetData, selection.value, setLeads, setDomains)
}

export const emailRegex = /^[A-Za-z0-9](?!.*?\.\.)([A-Za-z0-9._%-]*[A-Za-z0-9])?@[A-Za-z0-9](?!-)[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

export const replacePTags = (html) => {
  return html.replace(/<p(\s[^>]*)?>/gi, '<div$1>').replace(/<\/p>/gi, '</div>')
}

export const formatNumberCommaSeparator = (num) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const maskEmail = (email) => {
  const [username, domain] = email.split('@')
  const maskedUsername = username.slice(0, 2) + '****'
  return `${maskedUsername}@${domain}`
}

window.generateDayLabels = generateDates

const parseScript = (scriptStr) => {
  // eslint-disable-next-line no-undef
  const parser = new DOMParser()
  const doc = parser.parseFromString(scriptStr, 'text/html')
  const script = doc.querySelector('script')

  if (!script) return null

  const result = {
    type: script.type || 'text/javascript',
    innerHTML: script.innerHTML || undefined,
    src: script.src || undefined,
    async: script.async || undefined,
    defer: script.defer || undefined
  }

  // Remove undefined properties
  Object.keys(result).forEach(key =>
    result[key] === undefined && delete result[key]
  )

  return result
}

export const parseScripts = (code) => {
  const scriptRegex = /<script[^>]*>[\s\S]*?<\/script>/g
  const matches = code?.match(scriptRegex) || []
  return matches.map(parseScript).filter(Boolean)
}

export const formatNumber = (num) => {
  if (!num || isNaN(num)) return 0 // Handle NaN, undefined, null, or 0

  if (num < 1000) return num

  const units = ['', 'k', 'M', 'B']
  const order = Math.floor(Math.log10(num) / 3)
  const unitValue = Math.pow(10, order * 3)

  const formatted = (num / unitValue).toFixed(1)
  // Remove .0 if present
  const value = formatted.endsWith('.0')
    ? formatted.slice(0, -2)
    : formatted

  return value + units[order]
}
