import {
  Box,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider
} from '@mui/material'
import { ClockRewind } from 'src/icons/clockRewind'
import { useTheme } from '@emotion/react'

const RecentSearchBlock = ({ searches, isLoading, onSearchClick }) => {
  const theme = useTheme()
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const month = months[date.getMonth()]
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const period = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12

    return `${month} ${day}, ${formattedHours}:${minutes} ${period}`
  }

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        p: { xs: 2, sm: 3 },
        boxShadow: '0px 8px 34px 0px rgba(40, 40, 123, 0.01)',
        width: '100%',
        maxWidth: { xs: '100%', sm: '520px' },
        mb: 2,
        mx: 'auto'
      }}
    >
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
          <CircularProgress size={25} thickness={5} />
        </Box>
      ) : (
        <Box sx={{ width: '100%' }}>
          {/* Header */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: { xs: 2, sm: 1 },
              justifyContent: { xs: 'center', sm: 'flex-start' }
            }}
          >
            <ClockRewind sx={{ fontSize: '16px' }} />
            <Typography
              variant='body1'
              sx={{
                fontWeight: 700,
                fontSize: { xs: '14px', sm: '16px' },
                color: theme.palette.primary.primaryText,
                ml: 1,
                textAlign: { xs: 'center', sm: 'left' }
              }}
            >
              Recent Searches
            </Typography>
          </Box>

          {/* List */}
          <List sx={{ width: '100%', p: 0 }}>
            {!searches?.recent?.length
              ? (
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#667085',
                    textAlign: 'center',
                    py: 2
                  }}
                >
                  No recent searches
                </Typography>
              )
              : (
                searches.recent.map((search, index) => (
                  <div key={search._id}>
                    <ListItem
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: { xs: 'flex-start', sm: 'center' },
                        p: '8px 0',
                        cursor: 'pointer',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: { xs: 1, sm: 0 }
                      }}
                      onClick={() => onSearchClick(search.query)}
                    >
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                            <Typography
                              component='span'
                              sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 600,
                                fontSize: '14px',
                                mr: 1
                              }}
                            >
                              {formatDate(search.createdAt)}:
                            </Typography>
                            {search.name}
                          </Typography>
                        }
                        sx={{ width: '100%' }}
                      />

                      <Button
                        size='small'
                        sx={{
                          width: { xs: '100%', sm: '58px' },
                          textTransform: 'none',
                          fontSize: '14px',
                          fontWeight: 600,
                          px: 2,
                          color: 'black',
                          bgcolor: 'white',
                          border: '1px solid #E4E4E5',
                          '&:hover': {
                            bgcolor: '#F8F9FA'
                          },
                          mt: { xs: 1, sm: 0 }
                        }}
                      >
                        Save
                      </Button>
                    </ListItem>

                    {index < searches.recent.length - 1 && (
                      <Divider sx={{ my: '5px', borderColor: '#E4E4E5' }} />
                    )}
                  </div>
                ))
              )}
          </List>
        </Box>
      )}
    </Box>
  )
}

export default RecentSearchBlock
