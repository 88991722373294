import { Box, Typography, Button } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import BilingPage from './BillingPage.js'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Logo } from '../logo.js'

const Page = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const workspaceID = searchParams.get('workspaceID')
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        p: 8,
        overflowX: 'hidden'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mb: 2 }}>
        <Box>
          <Logo sx={{ color: '#103776' }} logoTextColor='#103776' />
        </Box>
        <Button
          variant='outlined'
          onClick={() => navigate('/settingsUpdated')}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            fontSize: '14px',
            fontWeight: 'bold',
            borderRadius: '12px',
            padding: '8px 16px',
            textTransform: 'none',
            borderColor: 'rgba(0, 0, 0, 0.2)',
            color: 'black',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
              borderColor: 'rgba(0, 0, 0, 0.4)'
            }
          }}
        >
          I will do it later
          <ArrowForwardIcon fontSize='small' sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '75%',
          flexDirection: 'column',
          mb: 3
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '85%',
          flexDirection: 'column'
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.primaryText,
            fontSize: '24px',
            fontWeight: 900,
            lineHeight: '32px',
            letterSpacing: '0px'
          }}
        >
          Welcome on Success.ai
        </Typography>

        <Typography
          sx={{
            color: '#8181B0',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0px',
            mt: 2
          }}
        >
          Please continue with plan selections.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <BilingPage workspaceID={workspaceID} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '100%'
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Page
