import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  CircularProgress,
  IconButton,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme

} from '@mui/material'
import { DownloadOutlined, Close, ArrowDropDown } from '@mui/icons-material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useState, useCallback } from 'react'
import {
  useGetCurrentPlanQuery,
  useUpdatePlanMutation,
  useBillingUsageQuery,
  useGetPaymentMethodQuery,
  useUpdatePaymentMutation,
  useInvoicesQuery,
  useUpdateFreePlanMutation,
  useUpdateFreeTrialPlanMutation
} from 'src/services/billing-service.js'
import { config, planNames } from 'src/config.js'
import { toast } from 'react-hot-toast'
import { Total } from 'src/assets/campaignDetailsLeads/Total'
import { CheckCircleIcon } from 'src/assets/general/CheckCircleIcon'
import {
  useGetLeadsUsageQuery,
  // useGetSavedFilesQuery,
  useGetSaveFilesMutation
} from 'src/services/leads-service'
import { downloadCsv } from 'src/utils/util'
import moment from 'moment-timezone'
import { useGetAccountAnalyticsMutation } from 'src/services/campaign-service'
import PlanCard from './PlanCard'
import PopoverMenu from './PopoverMenu'
import RemoveWorkSpaceDialog from './RemoveWorkspaceDialog'
import {
  setWorkspace,
  useDeleteWorkSpaceMutation,
  useGetWorkSpacesMutation,
  useUpdateWorkSpaceStatusMutation
} from 'src/services/workSpace-service'
import { useDispatch, useSelector } from 'react-redux'
import AddOnsTable from './AddOnsTable'
import PropTypes from 'prop-types'
import Pagination from 'src/components/Pagination.js'
import useUserVerification from 'src/hooks/useUserVerification';
import { useGetAccountsLengthMutation } from 'src/services/account-service'

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import { Logo } from 'src/components/logo'
import CloseIcon from '@mui/icons-material/Close'

const UpdatePlanDialog = (props) => {
  const { open, handleClose, theme } = props
  return (
    <>
      {' '}
      <Dialog open={open} onClose={handleClose} sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}>
        <DialogTitle>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '30px',
              color: `${theme.palette.primary.primaryText}`
            }}
          >
            Great decision!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '28px',
              color: '#8181B0'
            }}
          >
            {' '}
            Are you ready to scale your campaigns and purchase this plan?
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
            {' '}
            <Button onClick={handleClose} variant='contained' fullWidth>
              Yes Purchase!!
            </Button>
            <Button onClick={handleClose} variant='outlined' fullWidth sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
UpdatePlanDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  theme: PropTypes.string
}

// const Features = ({ type, plan, typePlan, theme }) => {
const Features = ({ type, plan, typePlan, theme }) => {
  const generateWarmupFeature = (plan) => {
    let activeLeads, AIcredits, support, qualityLeads, mailBox
    switch (plan) {
      case 'growth':
        activeLeads = '6,000'
        // emails = "6,000";
        AIcredits = '10,000'
        support = 'Live Chat'
        qualityLeads = '1,200'
        mailBox = '5'
        // emailSending = "Unlimited Email Sending";
        break
      case 'skyrocket':
        activeLeads = '200,000'
        // emails = "200,000";
        AIcredits = '20,000'
        support = 'Premium'
        qualityLeads = '5,000'
        mailBox = '100'
        // emailSending = "Buyer Intent Data";
        break
      case 'enterprise':
        activeLeads = '500,000'
        // emails = "500,000";
        AIcredits = '30,000'
        support = 'Premium'
        qualityLeads = 'Unlimited'
        mailBox = '250'
        // emailSending = "Everything in GROWTH+";
        break
      default:
        activeLeads = '50'
        // emails = "100";
        AIcredits = '50'
        support = 'Live Chat'
        qualityLeads = '100'
        // emailSending = "100";
        break
    }

    // Build common features based on the plan
    const commonFeatures = []

    // Features for all plans
    commonFeatures.push(
      {
        icon: <CheckCircleIcon color='#ffffff' />,
        text: 'Unlimited Emails & Phone Number Lookups'
      },
      { icon: <CheckCircleIcon color='#ffffff' />, text: `${qualityLeads} Email Exports` },
      { icon: <CheckCircleIcon color='#ffffff' />, text: `${qualityLeads} Phone Number Exports` }
    )
    if (plan === 'skyrocket') {
      commonFeatures.push({ text: 'Everything in Starter +', isHeading: true })
    } else if (plan === 'enterprise') {
      commonFeatures.push({ text: 'Everything in Growth+', isHeading: true })
    }

    // Conditional features for the growth plan
    if (plan === 'growth') {
      commonFeatures.push(
        { icon: <CheckCircleIcon color='#00AA38' />, text: 'Unlimited Email Sending' },
        { icon: <CheckCircleIcon color='#00AA38' />, text: 'Unlimited Email Warmup' }
      )
    }

    // Continue adding features for all plans
    commonFeatures.push(
      { icon: <CheckCircleIcon color='#00AA38' />, text: `${activeLeads} Active Contacts` },
      { icon: <CheckCircleIcon color='#00AA38' />, text: `Connect up to ${mailBox} Mailboxes` },
      { icon: <CheckCircleIcon color='#00AA38' />, text: `${AIcredits} AI Writer credits` }
    )

    // Additional features for the growth plan
    if (plan === 'growth') {
      commonFeatures.push(
        { icon: <CheckCircleIcon color='#00AA38' />, text: 'AI Auto-Reply *' },
        { icon: <CheckCircleIcon color='#00AA38' />, text: 'Unified Inbox - InboxHub' },
        { icon: <CheckCircleIcon color='#00AA38' />, text: 'CRM - Pipeline Management *' },
        { icon: <CheckCircleIcon color='#00AA38' />, text: 'Zapier Integration *' },
        { icon: <CheckCircleIcon color='#00AA38' />, text: `${support} Support` }
      )
    }

    // Additional features for the skyrocket plan
    if (plan === 'skyrocket') {
      commonFeatures.push(
        { icon: <CheckCircleIcon color='#00AA38' />, text: 'Buyer Intent Data' },
        {
          icon: <CheckCircleIcon color='#00AA38' />,
          text: 'Multichannel Sequences: email, LinkedIn, phone'
        },
        { icon: <CheckCircleIcon color='#00AA38' />, text: 'Workspaces' },
        {
          icon: <CheckCircleIcon color='#00AA38' />,
          text: 'Team Functions (Team Invites & Roles)'
        },
        { icon: <CheckCircleIcon color='#00AA38' />, text: 'Webhooks & API Access' },
        { icon: <CheckCircleIcon color='#00AA38' />, text: `${support} Chat Support` }
      )
    }
    return commonFeatures
  }
  const generateLeadsFeature = (plan) => {
    let qualityLeads, support
    switch (plan) {
      case 'skyrocket':
        qualityLeads = '1,200'
        support = 'Live Chat'
        break
      case 'growth':
        qualityLeads = '5,000'
        support = 'Premium'
        break
      case '10xscale':
        qualityLeads = '10,000'
        support = 'Premium'
        break
      default:
        qualityLeads = '30'
        support = 'Live Chat'
        break
    }
    return [
      {
        icon: <CheckCircleIcon color='#00AA38' />,
        text: `Each Month: ${qualityLeads} Quality Leads`
      },
      { icon: <CheckCircleIcon color='#00AA38' />, text: 'Pay For Verified Leads Only' },
      { icon: <CheckCircleIcon color='#00AA38' />, text: 'Precise & Advanced Filter Tools' },
      { icon: <CheckCircleIcon color='#00AA38' />, text: 'Data Enrichment' },
      { icon: <CheckCircleIcon color='#00AA38' />, text: `${support} Support` },
      { icon: <CheckCircleIcon color='#00AA38' />, text: 'Lead Enrichment' }
    ]
  }
  const featureList = type === 'warmup' ? generateWarmupFeature(plan) : generateLeadsFeature(plan)
  return (
    <Box>
      <Typography
        sx={{ fontWeight: '700', fontSize: '14px', color: `${theme.palette.primary.primaryText}` }}
      >
        Includes
      </Typography>
      <Box>
        {featureList.map((feature, index) => (
          <Typography
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '5px',
              py: 1
            }}
          >
            {feature.icon}
            <Typography
              sx={{
                color: `${theme.palette.primary.primaryText}`,
                fontSize: '13px',
                fontWeight:
                  index === 3 && (plan === 'skyrocket' || plan === 'enterprise') ? '700' : '500'
              }}
            >
              {feature.text}
            </Typography>
          </Typography>
        ))}
      </Box>
    </Box>
  )
}

Features.propTypes = {
  type: PropTypes.string.isRequired,
  plan: PropTypes.string.isRequired,
  typePlan: PropTypes.string
}
const BillingAndUsage = (props) => {
  const { mode, fromCreateWorkspace, canEdit, canDelete } = props
  const theme = useTheme()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const [leadCreditDialogOpen, setLeadCreditDialogOpen] = useState(false)
  const [leadCreditTab, setLeadCreditTab] = useState('')
  const [billingMonthly, setBillingMonthly] = useState(false)
  const [isPlanUpdating, setIsPlanUpdating] = useState(false)
  const [UpdatePayment] = useUpdatePaymentMutation()
  const { data: fetchedCurrentPlan, refetch: refetchCurrentPlan } = useGetCurrentPlanQuery()
  const [currentPlan, setCurrentPlan] = useState(fetchedCurrentPlan)
  const [getWorkSpaces] = useGetWorkSpacesMutation()
  const [timelineButtons, setTimeLineButtons] = useState([])
  const [timeline, setTimeline] = useState(null)
  const [deleteWorkSpace, { isLoading: isDeletingWorkspace }] = useDeleteWorkSpaceMutation()
  const [activeWorkspace, setActiveWorkspace] = useState({})
  const workspace = useSelector((state) => state.workspace)
  const [totalCount, setTotalCount] = useState(0)
  const { newAppSumoUser, appSumoPlanNames, newAppSumoEmailExports, newAppSumoLeadExports, isFreeUser, isAppSumoUser: userVerifiedStatus } =
    useUserVerification()
  const [getAccountsLength] = useGetAccountsLengthMutation()
  const [workspaceId, setWorkspaceId] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    if (fromCreateWorkspace) {
      const timer = setTimeout(async () => {
        const docs = await getWorkSpaces().unwrap()
        setTimeLineButtons(docs?.uniqueWorkspaces)
        setActiveWorkspace(docs?.currentWorkspace)
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [getWorkSpaces])

  useEffect(() => {
    getAccountsLength({ workspaceId }).then((res) => {
      setTotalCount(res?.data?.total)
    })
  }, [workspaceId])

  useEffect(() => {
    setTimeline(activeWorkspace)
    dispatch(setWorkspace(activeWorkspace))
    if (activeWorkspace) {
      window?.localStorage.setItem('workspace', JSON.stringify(activeWorkspace))
    }
  }, [timelineButtons])
  const { data, isLoading: isCardLoading } = useGetPaymentMethodQuery()
  const payment = data?.length > 0 ? data[0] : []

  const [updatePlan] = useUpdatePlanMutation()

  const { data: usage, isLoading: isLoadingUsage, refetch: refetchUsage } = useBillingUsageQuery()

  const activeLeadsExceeded = Math.abs(usage?.usedActiveLeads) >= usage?.activeLeads
  const emailCreditExceeded = Math.abs(usage?.usedEmailCredit) >= usage?.montlyEmailCredit
  const aiCreditExceeded = Math.abs(usage?.usedAiCredit) >= usage?.aiWriterLimit
  const dailyLeadLimitCount = Math.abs(usage?.dailyLeadLimit) >= usage?.dailyLeadLimit
  // const leadsPhoneNumberExportsPerMonthCount =
  //   Math.abs(usage?.leadsPhoneNumberExportsPerMonth) >= usage?.leadsPhoneNumberExportsPerMonth;
  const bounceCreditCount = Math.abs(usage?.bounceCredit) >= usage?.bounceCredit
  const {
    data: invoices,
    refetch: refetchInvoice
  } = useInvoicesQuery()

  const [updateFreePlan] = useUpdateFreePlanMutation()
  const [updateFreeTrialPlan] = useUpdateFreeTrialPlanMutation()

  const handleReload = () => {
    navigate(0)
  }

  const handleUpdateFreePlan = () => {
    updateFreePlan({ workspaceId })
      .then((response) => {
        toast.success(response?.data?.data?.message)
        refetchCurrentPlan()
        navigate('/free-forever-confirmation')
      })
      .catch((error) => {
        console.log('Error updating plan:', error)
      })
  }
  const [openModal, setOpenModal] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(null) // Store the selected plan type
  const [valuePlan, setValuePlan] = useState(null) // Store the selected plan type

  const handleOpenModal = (planType, value) => {
    setSelectedPlan(planType) // Set the selected plan type
    setValuePlan(value)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setSelectedPlan(null) // Reset the selected plan type
    setValuePlan(null)
  }

  const handleUpdateFreeTrialPlan = () => {
    if (!selectedPlan) return // Safety check
    updateFreeTrialPlan({ workspaceId, planType: selectedPlan, value: valuePlan })
      .then((response) => {
        refetchCurrentPlan()
        handleReload()
        handleCloseModal() // Close the modal after success
      })
      .catch((error) => {
        console.log('Error updating plan:', error)
        handleCloseModal() // Close the modal on error
      })
  }

  const { data: leadUsage, refetch: refetchLeadUsage } = useGetLeadsUsageQuery()
  // const { data: downloadFiles, refetch: refetchSavedFiles } = useGetSavedFilesQuery();
  const [downloadFiles, setDownloadFiles] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalFiles, setTotalFiles] = useState(0)
  const [getSaveFiles, { isLoading: isDownloadFilesLoading }] = useGetSaveFilesMutation()

  const handleLimitChange = (event) => {
    setLimit(event.target.value)
    setPage(1)
  }
  const offset = (page - 1) * limit

  const fetchFiles = async () => {
    try {
      const files = await getSaveFiles({ limit, offset })
      setDownloadFiles(files?.data?.docs)
      setTotalFiles(files?.data?.total)
    } catch (error) {
      console.error('Error fetching files:', error)
    }
  }

  useEffect(() => {
    fetchFiles()
  }, [page, limit])

  const closeCreditModal = () => {
    setLeadCreditDialogOpen(false)
    setPage(1)
    setLimit(10)
  }

  useEffect(() => {
    refetchUsage()
    refetchInvoice()
    refetchLeadUsage()
    // refetchSavedFiles();
  }, [refetchUsage, refetchInvoice, refetchLeadUsage])

  const location = useLocation()

  useEffect(() => {
    if (fromCreateWorkspace) {
      setCurrentPlan({})
    } else {
      setCurrentPlan(fetchedCurrentPlan)
    }
  }, [fromCreateWorkspace, fetchedCurrentPlan])

  const [timezone, setTimezone] = useState('')
  useEffect(() => {
    setWorkspaceId(workspace?._id)
    const userTimezone = workspace?.timezone?.split(' ')[0]
    if (userTimezone) {
      setTimezone(userTimezone)
    }
  }, [workspace])

  const navigateToCreateWorkspace = () => {
    navigate('/new/workSpace/create', { state: { from: location.pathname } })
  }

  const handleUpdatePlanClick = async (priceId) => {
    if (isCurrentSendingWarmupPlan(priceId) || isCurrentLeadsPlan(priceId)) return
    let planName = null
    const matchedPlanName = planNames[priceId]
    if (matchedPlanName) {
      planName = matchedPlanName.toLowerCase().split(' ')[1]
    }
    const toastId = toast.loading('Updating...', { duration: Infinity })
    setIsPlanUpdating(true)
    const session = await updatePlan({ priceId, workspaceId, planName }).unwrap()
    if (session.url) {
      window.location.href = session.url
    } else if (session.errorMsg) {
      toast.error(session.errorMsg, { id: toastId, duration: 5000 })
    } else {
      toast.success('Plan Updated', { id: toastId, duration: 2000 })
      await refetchCurrentPlan()
      refetchUsage()
    }
    setIsPlanUpdating(false)
  }

  const handelDowloadCsv = (item) => {
    downloadCsv(item.name, item.data)
  }

  const isCurrentSendingWarmupPlan = (priceId) => {
    return currentPlan?.subscription?.sendingWarmup?.planId === priceId
  }

  const handelBillingCheckout = async () => {
    const { data: url } = await UpdatePayment()
    window.location.href = url
  }

  const isCurrentLeadsPlan = (priceId) => {
    return currentPlan?.subscription?.leads?.planId === priceId
  }

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const scrollBarStyle = {
    // width
    '&::-webkit-scrollbar': {
      width: '14px'
    },

    // Track
    '&::-webkit-scrollbar-track': {
      borderRadius: '60px'
    },

    // /* Handle */
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E4E4E5',
      borderRadius: '10px',
      border: '4px solid rgba(0, 0, 0, 0)',
      backgroundClip: 'padding-box'
    },

    // /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#d5d5d5'
    }
  }

  const getUserPlan = (user) => {
    const plan = user?.EarlyBirdPlan?.replace('EarlyBird', '') ?? ''
    return `You Are Currently On ${plan} Plan.`
  }

  const [getAccountAnalytics] = useGetAccountAnalyticsMutation()
  const fetchGraphData = useCallback(async () => {
    if (currentPlan?.subscription?.sendingWarmup?.expiresAt) {
      const expiresAtValueOne = new Date(currentPlan?.subscription?.sendingWarmup?.expiresAt) // Replace with your actual date
      const expiresAtDateNo = new Date(expiresAtValueOne)
      expiresAtDateNo.setMonth(expiresAtDateNo.getMonth() - 1)
    } else {
      const expiresAtValueFree = new Date(currentPlan?.freeTrialExpiresAt) // Replace with your actual date
      // const expiresAtValueFree = "2028-11-16T07:16:15.962+00:00";
      const expiresAtDateNoFree = new Date(expiresAtValueFree)
      expiresAtDateNoFree.setMonth(expiresAtDateNoFree.getMonth() - 1)
    }
  }, [getAccountAnalytics, currentPlan, workspaceId])

  useEffect(() => {
    fetchGraphData()
  }, [fetchGraphData])

  const credits = usage?.leadsCredits?.toLocaleString() || 0
  const linkedinCredits = usage?.linkedinCredits?.toLocaleString() || 0
  const usedAiCredit = usage?.usedAiCredit?.toLocaleString() || 0
  const connectedEmailAccount = usage?.emailAccountsLimit?.toLocaleString() || 0
  const aiWriterLimit = usage?.aiWriterLimit?.toLocaleString() || 0
  const dailyLeadLimit = usage?.dailyLeadLimit?.toLocaleString() || 0
  const leadsPhoneNumberExportsPerMonth =
    usage?.leadsPhoneNumberExportsPerMonth?.toLocaleString() || 0
  const bounceCredit = usage?.bounceCredit?.toLocaleString() || 0
  const montlyEmailCredit = usage?.montlyEmailCredit?.toLocaleString() || 0
  const usedActiveLeads = usage?.usedActiveLeads?.toLocaleString() || 0
  const activeLeads = usage?.activeLeads?.toLocaleString() || 0
  const emailSentCount = usage?.usedEmailCredit.toLocaleString() || 0
  const [anchorEl1, setAnchorEl1] = useState(null)
  const [openRemoveWorkspaceDialog, setOpenRemoveWorkspaceDialog] = useState(false)
  const [updateWorkSpaceStatus] = useUpdateWorkSpaceStatusMutation()
  const dispatch = useDispatch()

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget)
  }

  const handleClose1 = () => {
    setAnchorEl1(null)
  }

  const handleRemoveWorkspaceClick = async () => {
    const { workSpace } = await deleteWorkSpace(timeline._id).unwrap()
    setTimeline(workSpace.defaultWorkspace)
    dispatch(setWorkspace(workSpace.defaultWorkspace))
    setOpenRemoveWorkspaceDialog(false)
    navigate('/accounts')
    refetchCurrentPlan()
  }

  const handleWorkspaceChange = async (newTimeline) => {
    const { data } = await updateWorkSpaceStatus({ workspaceId: newTimeline?._id })
    const updatedWorkspace = data?.updatedWorkspace
    const currentPlan = data?.currentPlan
    refetchCurrentPlan()
    if (currentPlan || updatedWorkspace?.appSumoCode) {
      navigate('/accounts')
      handleReload()
    } else {
      navigate('/workSpace/billing', { state: { fromCreateWorkspace: true }, replace: true })
    }
    setTimeline(updatedWorkspace?.data?.workSpace)
    dispatch(setWorkspace(updatedWorkspace?.data?.workSpace))
  }


  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    })
  }

  const addDays = (days) => {
    const currentDate = new Date()
    const newDate = new Date(currentDate)
    newDate.setDate(currentDate.getDate() + days)
    return newDate
  }

  const currentDate = new Date()
  const datePlus4 = addDays(4)
  const datePlus7 = addDays(7)
  return (
    <>
      {fromCreateWorkspace && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
          <Box sx={{ display: 'flex', width: '100%', maxWidth: '800px' }}>
            <Button
              sx={{
                backgroundColor: '#007bff',
                color: 'white',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'bold',
                width: '40%',
                mb: 1,
                mt: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  backgroundColor: '#007bff'
                }
              }}
              onClick={handleClick1}
            >
              {timeline?.name}
              <ArrowDropDown sx={{ fontSize: '32px', ml: 2 }} />
            </Button>

            <PopoverMenu
              anchorEl={anchorEl1}
              handleClose={handleClose1}
              timelineButtons={timelineButtons}
              setTimeline={handleWorkspaceChange}
              navigateToCreateWorkspace={navigateToCreateWorkspace}
              timeline={timeline}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '800px' }}>
            <Typography sx={{ mb: 1, fontWeight: 'bold', fontSize: '32px' }}>
              Let&apos;s get your new workspace ready
            </Typography>
            <Typography sx={{ color: '#6B7280', fontSize: '20px', mb: 3 }}>
              Upgrade to one of our plans to continue.
            </Typography>
          </Box>
        </Box>
      )}
      {!fromCreateWorkspace &&
        workspace?.assignedPlan &&
        workspace?.assignedPlan.length > 0 &&
        !workspace?.isAppSumoRefund && (
          <Box
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '26px',
              color: `${theme.palette.primary.primaryText}`,
              ml: 1.5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              boxShadow: '0px 12px 15px 0px #4B71970D',
              borderRadius: '12px',
              backgroundColor: 'white',
              width: '100%',
              p: { xs: 1, sm: 2, md: 3 }
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '26px',
                color: '#28287B',
                ml: 1.5
              }}
            >
              You Are Currently On App Sumo <i> {appSumoPlanNames[workspace?.assignedPlan]} </i>{' '}
              Plan.
            </Typography>
          </Box>
        )}

      {!fromCreateWorkspace &&
        workspace?.isEarlyBirdAccessUser &&
        workspace?.EarlyBirdPlan.length > 0 &&
        !workspace?.isEarlyBirdDealRefund && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              boxShadow: '0px 12px 15px 0px #4B71970D',
              borderRadius: '12px',
              backgroundColor: 'white',
              width: '100%',
              p: { xs: 1, sm: 2, md: 3 }
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '26px',
                color: `${theme.palette.primary.primaryText}`,
                ml: 1.5
              }}
            >
              {getUserPlan(workspace)}
            </Typography>
          </Box>
        )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        {(fromCreateWorkspace || !newAppSumoUser) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              boxShadow: '0px 12px 15px 0px #4B71970D',
              borderRadius: '12px',
              backgroundColor: 'white',
              width: '100%',
              p: { xs: 1, sm: 2, md: 3 }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <Grid
                container
                sx={{
                  backgroundColor: '#F2F4F6',
                  width: '100%',
                  borderRadius: '8px',
                  p: 0.4,
                  border: '1px solid #F2F4F7'
                }}
              >
                <Grid item xs={6}>
                  <Button
                    // variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor:
                        mode === 'trial'
                          ? !billingMonthly
                            ? `${theme.palette.primary.main}`
                            : 'transparent'
                          : !billingMonthly
                            ? 'white'
                            : 'transparent',
                      color:
                        mode === 'trial'
                          ? !billingMonthly
                            ? '#fff'
                            : `${theme.palette.primary.primaryText}`
                          : !billingMonthly
                            ? `${theme.palette.primary.main}`
                            : '#8181B0',
                      '&:hover': {
                        backgroundColor:
                          mode === 'trial'
                            ? !billingMonthly
                              ? `${theme.palette.primary.main}`
                              : 'transparent'
                            : !billingMonthly
                              ? 'white'
                              : 'transparent'
                      },
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      letterSpacing: '0em',
                      boxShadow: !billingMonthly && '0px 1px 2px 0px #1018280F',
                      borderRadius: '5px',
                      // mr: 0.5,
                      py: 1
                    }}
                    onClick={() => {
                      setBillingMonthly(false)
                    }}
                  >
                    Annual Summer Offer (Save 40%)
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    // variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor:
                        mode === 'trial'
                          ? billingMonthly
                            ? `${theme.palette.primary.main}`
                            : 'transparent'
                          : billingMonthly
                            ? 'white'
                            : 'transparent',
                      color:
                        mode === 'trial'
                          ? billingMonthly
                            ? '#fff'
                            : `${theme.palette.primary.primaryText}`
                          : billingMonthly
                            ? '#0071F6'
                            : '#8181B0',
                      '&:hover': {
                        backgroundColor:
                          mode === 'trial'
                            ? billingMonthly
                              ? `${theme.palette.primary.main}`
                              : 'transparent'
                            : billingMonthly
                              ? 'white'
                              : 'transparent'
                      },
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      letterSpacing: '0em',
                      boxShadow: billingMonthly && '0px 1px 2px 0px #1018280F',
                      borderRadius: '5px',
                      // mr: 0.5,
                      py: 1,
                      height: '100%'
                    }}
                    onClick={() => {
                      setBillingMonthly(true)
                    }}
                  >
                    Monthly Billing
                  </Button>
                </Grid>
              </Grid>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '12px',
                  border: '1px solid #E4E4E5',
                  p: 3,
                  mt: 3
                }}
              >
                <Grid container columnSpacing={3} rowSpacing={2} sx={{ mt: 2 }}>
                  {!fromCreateWorkspace && (
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '8px',
                        border: '1px solid #3F4FF8',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                        // backgroundColor:"red"
                      }}
                      >
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                width: '60%'
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '20px',
                                    fontWeight: 700,
                                    lineHeight: '26px',
                                    color: '#28287B',
                                    ml: 1.5
                                  }}
                                >
                                  Free
                                </Typography>
                              </Box>

                              <Typography
                                sx={{
                                  fontSize: '38px',
                                  fontWeight: 700,
                                  lineHeight: '20px',
                                  color: '#0071F6',
                                  px: 2,
                                  py: 1.5,
                                  my: 1.5
                                }}
                              >
                                $0
                                <Typography
                                  component='span'
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: '#28287B'
                                  }}
                                >
                                  /Month
                                </Typography>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 700,
                                  color: '#28287B'
                                }}
                              >
                                {/* {!billingMonthly ? '(Billed Annually)' : '(Billed Monthly)'} */}
                              </Typography>
                              <Box style={{ marginBottom: '24px' }}>
                                <Typography
                                  variant='body1' style={{
                                    borderRadius: '4px',
                                    marginBottom: '16px',
                                    fontSize: '13px',
                                    fontWeight: 500
                                  }}
                                >
                                  Unlimited Emails & Phone Number Lookups
                                </Typography>

                                <Box style={{
                                  borderRadius: '4px',
                                  marginBottom: '16px',
                                  fontSize: '13px',
                                  fontWeight: 500
                                }}
                                >
                                  50 Email Exports
                                </Box>
                                <Box style={{
                                  borderRadius: '4px',
                                  marginBottom: '16px',
                                  fontSize: '13px',
                                  fontWeight: 500
                                }}
                                >
                                  50 Phone Number Exports
                                </Box>

                                {[
                                  '300 Emails / Day',
                                  'Unlimited Email Warmup',
                                  '50  Active Contacts',
                                  'Lead Enrichment',
                                  '500 AI Writer credits',
                                  'Live Chat Support'
                                ].map((feature, index) => (
                                  <Box
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '8px',
                                      marginBottom: '12px'
                                    }}
                                  >
                                    <CheckCircleIcon color='#00AA38' />
                                    {/* <CheckCircleOutlineIcon style={{ color: '#4caf50', fontSize: '22px' }} /> */}
                                    <Typography
                                      variant='body2' sx={{
                                        margin: 0,
                                        lineHeight: 1.5,
                                        fontFamily: 'Plus Jakarta Sans, sans- serif',
                                        color: '#28287B',
                                        fontSize: '13px',
                                        fontWeight: 500
                                      }}
                                    >
                                      {feature} 1
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              mt: 'auto',
                              width: '40%',
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <Button
                              variant={currentPlan?.resetPlanDate ? 'outlined' : 'contained'}
                              disabled={!!currentPlan?.resetPlanDate}
                              sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                lineHeight: '18px',
                                borderRadius: '8px',
                                px: 2,
                                py: 1.5,
                                color: currentPlan?.resetPlanDate ? '#C2C6C8' : '#ffffff',
                                cursor: currentPlan?.resetPlanDate ? 'not-allowed' : 'pointer',
                                backgroundColor: currentPlan?.resetPlanDate ? '#f2f4f6' : '#004EEB'
                              }}
                              onClick={() => handleUpdateFreePlan()}
                            >
                              Get Offer Now
                            </Button>
                          </Box>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 700,
                              color: '#28287B'
                            }}
                          >
                            * Coming Soon
                          </Typography>

                        </Box>

                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={fromCreateWorkspace ? 4 : 3}>
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '8px',
                        border: `1px solid ${theme?.palette.primary.main}`,
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                        // backgroundColor:"red"
                      }}
                    >
                      <PlanCard
                        title='Starter'
                        price={billingMonthly ? '59' : '35'}
                        billingMonthly={billingMonthly}
                        handleUpdatePlanClick={handleUpdatePlanClick}
                        isCurrentPlan={isCurrentSendingWarmupPlan(
                          billingMonthly
                            ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
                            : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID
                        )}
                        planId={
                          billingMonthly
                            ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
                            : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID
                        }
                        isPlanUpdating={isPlanUpdating}
                        discountPrice={billingMonthly ? '' : '59'}
                        testlines={
                          <Features
                            type='warmup'
                            plan='growth'
                            typePlan={billingMonthly}
                            theme={theme}
                          />
                        }
                      />
                      {fromCreateWorkspace || !isFreeUser || (currentPlan?.subscription?.sendingWarmup?.planType === 'weekly' &&
                        new Date(currentPlan?.subscription?.sendingWarmup?.expiresAt) < new Date())
                        ? <></>
                        : (
                          <Button
                            variant={currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? 'outlined' : 'contained'}
                            disabled={currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID}
                            sx={{
                              fontSize: '14px',
                              fontWeight: 700,
                              lineHeight: '18px',
                              borderRadius: '8px',
                              px: 2,
                              py: 1.5,
                              color: currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? '#C2C6C8' : '#ffffff',
                              cursor: currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? 'not-allowed' : 'pointer',
                              backgroundColor: currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? '#f2f4f6' : '#004EEB'
                            }}
                            onClick={() => handleOpenModal(config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID, 'starter_trial_seven_days')}
                          >
                            {currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? 'Current Plan' : '7 days free trial'}
                          </Button>
                        )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={fromCreateWorkspace ? 4 : 3}>
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '8px',
                        border: `1px solid ${theme?.palette.primary.main}`,
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <PlanCard
                        title='Growth'
                        price={billingMonthly ? '99' : '59'}
                        billingMonthly={billingMonthly}
                        handleUpdatePlanClick={handleUpdatePlanClick}
                        isCurrentPlan={isCurrentSendingWarmupPlan(
                          billingMonthly
                            ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
                            : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID
                        )}
                        planId={
                          billingMonthly
                            ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
                            : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID
                        }
                        isPlanUpdating={isPlanUpdating}
                        discountPrice={billingMonthly ? '' : '99'}
                        testlines={
                          <Features
                            type='warmup'
                            plan='skyrocket'
                            typePlan={billingMonthly}
                            theme={theme}
                          />
                        }
                      />
                      {fromCreateWorkspace || !isFreeUser || (currentPlan?.subscription?.sendingWarmup?.planType === 'weekly' &&
                        new Date(currentPlan?.subscription?.sendingWarmup?.expiresAt) < new Date())
                        ? <></>
                        : (
                          <Button
                            variant={currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? 'outlined' : 'contained'}
                            disabled={currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID}
                            sx={{
                              fontSize: '14px',
                              fontWeight: 700,
                              lineHeight: '18px',
                              borderRadius: '8px',
                              px: 2,
                              py: 1.5,
                              color: currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? '#C2C6C8' : '#ffffff',
                              cursor: currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? 'not-allowed' : 'pointer',
                              backgroundColor: currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? '#f2f4f6' : '#004EEB'
                            }}
                            onClick={() => handleOpenModal(config.YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID, 'growth_trial_seven_days')}
                          >
                            {currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? 'Current Plan' : '7 days free trial'}
                          </Button>
                        )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={fromCreateWorkspace ? 4 : 3}>
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '8px',
                        border: `1px solid ${theme?.palette.primary.main}`,
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <PlanCard
                        title='Infinity'
                        price={billingMonthly ? '149' : '89'}
                        billingMonthly={billingMonthly}
                        handleUpdatePlanClick={handleUpdatePlanClick}
                        isCurrentPlan={isCurrentSendingWarmupPlan(
                          billingMonthly
                            ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
                            : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID
                        )}
                        planId={
                          billingMonthly
                            ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
                            : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID
                        }
                        isPlanUpdating={isPlanUpdating}
                        discountPrice={billingMonthly ? '' : '149'}
                        testlines={
                          <Features
                            type='warmup'
                            plan='enterprise'
                            typePlan={billingMonthly}
                            theme={theme}
                          />
                        }
                      />
                      {fromCreateWorkspace || !isFreeUser || (currentPlan?.subscription?.sendingWarmup?.planType === 'weekly' &&
                        new Date(currentPlan?.subscription?.sendingWarmup?.expiresAt) < new Date())
                        ? <></>
                        : (
                          <Button
                            variant={currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? 'outlined' : 'contained'}
                            disabled={currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID}
                            sx={{
                              fontSize: '14px',
                              fontWeight: 700,
                              lineHeight: '18px',
                              borderRadius: '8px',
                              px: 2,
                              py: 1.5,
                              color: currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? '#C2C6C8' : '#ffffff',
                              cursor: currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? 'not-allowed' : 'pointer',
                              backgroundColor: currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? '#f2f4f6' : '#004EEB'
                            }}
                            onClick={() => handleOpenModal(config.YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID, 'infinity_trial_seven_days')}
                          >
                            {currentPlan?.subscription?.sendingWarmup?.planId === config.YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID ? 'Current Plan' : '7 days free trial'}
                          </Button>
                        )}

                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogContent
                  sx={{
                    maxWidth: '525px'
                  }}
                >
                  <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
                    <Button variant='ghost' size='icon' onClick={handleCloseModal}>
                      <CloseIcon sx={{ width: 16, height: 16 }} />
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mb: 2
                    }}
                  >
                    <Logo
                      sx={{ color: '#103776' }}
                      logoTextColor='#103776'
                    />

                    <Box
                      sx={{
                        width: '32px',
                        height: '32px',
                        backgroundColor: 'blue.600',
                        borderRadius: '50%'
                      }}
                    />
                  </Box>
                  <DialogTitle
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.25rem',
                      fontWeight: 600
                    }}
                  >
                    Confirm your 7-day free trial
                  </DialogTitle>

                  <Box
                    sx={{
                      position: 'relative',
                      mt: 6,
                      mb: 8
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        borderRadius: '10px',
                        left: 0,
                        right: 0,
                        height: '45px',
                        background: 'linear-gradient(to right, #d4efdf, #fcf3cf, #f5b7b1)'
                      }}
                    />
                    <Box
                      sx={{
                        top: '10px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      {[
                        {
                          icon: <LockOutlinedIcon sx={{ fontSize: '24px', color: '#1ab54d' }} />,
                          label: 'Trial Start',
                          date: formatDate(currentDate)
                        },
                        {
                          icon: <NotificationsNoneOutlinedIcon sx={{ fontSize: '24px', color: '#b0790e' }} />,
                          label: 'Reminder',
                          date: formatDate(datePlus4)
                        },
                        {
                          icon: <CalendarMonthOutlinedIcon sx={{ fontSize: '24px', color: '#fe5d59' }} />,
                          label: 'Trial End',
                          date: formatDate(datePlus7)
                        }
                      ].map((item, index) => (
                        <Box key={index} sx={{ textAlign: 'center' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>{item.icon}</Box>
                          <Box sx={{ fontSize: '0.875rem', fontWeight: 700 }}>{item.label}</Box>
                          <Box
                            sx={{
                              fontSize: '0.645rem',
                              color: 'text.secondary'
                            }}
                          >
                            {item.date}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box sx={{ spaceY: 6 }}>
                    <Box
                      sx={{
                        textAlign: 'center',
                        fontSize: '0.875rem',
                        color: 'text.secondary',
                        top: '10px'
                      }}
                    >
                      Pay $199 today and get access to success.ai forever, never pay again.
                    </Box>

                    <Box sx={{ spaceY: 2, marginTop: '20px', marginBottom: '20px' }}>
                      {['No credit card required', 'Cancel anytime'].map((text, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '20px',
                              width: '20px',
                              borderRadius: '50%',
                              backgroundColor: 'green.100'
                            }}
                          >
                            <Box
                              sx={{
                                height: '8px',
                                width: '8px',
                                borderRadius: '50%',
                                backgroundColor: 'green.600'
                              }}
                            />
                          </Box>
                          <CheckCircleIcon color='#03aa3a' sx={{ fontSize: '24px' }} />
                          <Box sx={{ fontSize: '0.855rem', fontWeight: 700 }}>{text}</Box>
                        </Box>
                      ))}
                    </Box>

                    <Button
                      sx={{
                        width: '100%',
                        backgroundColor: '#3f50f8',
                        '&:hover': {
                          backgroundColor: '#3f50f8'
                        },
                        color: '#FFFFFF'
                      }}
                      onClick={handleUpdateFreeTrialPlan}
                    >
                      Confirm 7-Day Free Trial
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>

              {/* <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "12px",
                  border: "1px solid #E4E4E5",
                  mt: 3,
                  p: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "20px",
                      color: "#28287B",
                    }}
                  >
                    Lead Finder
                  </Typography>
                </Box>
                <Grid container columnSpacing={3} rowSpacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        border: "1px solid #3F4FF8",
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <PlanCard
                        title="Growth Leads"
                        price={billingMonthly ? "44" : "22"}
                        billingMonthly={billingMonthly}
                        handleUpdatePlanClick={handleUpdatePlanClick}
                        isCurrentPlan={isCurrentLeadsPlan(
                          billingMonthly
                            ? config.LEADS_MONTHLY_SKYROCKET_PRICE_ID
                            : config.LEADS_YEARLY_SKYROCKET_PRICE_ID
                        )}
                        planId={
                          billingMonthly
                            ? config.LEADS_MONTHLY_SKYROCKET_PRICE_ID
                            : config.LEADS_YEARLY_SKYROCKET_PRICE_ID
                        }
                        isPlanUpdating={isPlanUpdating}
                      />
                      <Features type="leads" plan="skyrocket" typePlan={billingMonthly}  />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        border: "1px solid #3F4FF8",
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <PlanCard
                        title="Skyrocket Leads"
                        price={billingMonthly ? "111" : "55.5"}
                        billingMonthly={billingMonthly}
                        handleUpdatePlanClick={handleUpdatePlanClick}
                        isCurrentPlan={isCurrentLeadsPlan(
                          billingMonthly
                            ? config.LEADS_MONTHLY_GROWTH_PRICE_ID
                            : config.LEADS_YEARLY_GROWTH_PRICE_ID
                        )}
                        planId={
                          billingMonthly
                            ? config.LEADS_MONTHLY_GROWTH_PRICE_ID
                            : config.LEADS_YEARLY_GROWTH_PRICE_ID
                        }
                        isPlanUpdating={isPlanUpdating}
                      />
                      <Features type="leads" plan="growth" typePlan={billingMonthly}  />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        border: "1px solid #3F4FF8",
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <PlanCard
                        title="10X Scale Leads"
                        price={billingMonthly ? "194" : "97"}
                        billingMonthly={billingMonthly}
                        handleUpdatePlanClick={handleUpdatePlanClick}
                        isCurrentPlan={isCurrentLeadsPlan(
                          billingMonthly
                            ? config.LEADS_MONTHLY_SCALE_PRICE_ID
                            : config.LEADS_YEARLY_SCALE_PRICE_ID
                        )}
                        planId={
                          billingMonthly
                            ? config.LEADS_MONTHLY_SCALE_PRICE_ID
                            : config.LEADS_YEARLY_SCALE_PRICE_ID
                        }
                        isPlanUpdating={isPlanUpdating}
                      />
                      <Features type="leads" plan="10xscale" typePlan={billingMonthly} />
                    </Box>
                  </Grid>
                </Grid>
              </Box> */}
            </Box>
          </Box>
        )}
        {!fromCreateWorkspace && !isFreeUser && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                boxShadow: '0px 12px 15px 0px #4B71970D',
                borderRadius: '12px',
                backgroundColor: 'white',
                width: '100%',
                mt: 3,
                border: activeLeadsExceeded ? '1px solid red' : 'none'
              }}
            >
              <AddOnsTable />
            </Box>
            {/* <Box sx={{ width: '100%', mt: 3 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{ borderBottom: '1px solid #E4E4E5' }}
              >
                <Tab label='Email' />
                <Tab label='Multichannel' />
                <Tab label='General' />
                <Tab label="Enterprise" />
              </Tabs>
              <Box sx={{ mt: 3 }}>
                <MultipleScrollableBoxes items={tabData[tabValue]} />
              </Box>
            </Box> */}
          </>
        )}
        {!fromCreateWorkspace && mode !== 'trial' && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                boxShadow: '0px 12px 15px 0px #4B71970D',
                borderRadius: '12px',
                backgroundColor: 'white',
                width: '100%',
                p: 3,
                mt: 3
              }}
            >
              {' '}
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  color: '#28287B',
                  mb: 3
                }}
              >
                {newAppSumoUser ? 'Lead Email Exports Per Month' : 'Lead Finder Usage Overview'}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  rowGap: 1
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: { xs: '100%', sm: '260px' },
                    p: 1.5,
                    border: '1px solid #E4E4E5',
                    borderRadius: '8px'
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {' '}
                    <Total />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: { xs: 'calc(100% - 44px)', sm: 'fit-content' },
                      justifyContent: { xs: 'space-between', sm: 'center' },
                      alignItems: { xs: 'center', sm: 'flex-start' },
                      flexDirection: { xs: 'row-reverse', sm: 'column' },
                      ml: 1.5
                    }}
                  >
                    {isLoadingUsage
                      ? (
                        <CircularProgress size={25} thickness={5} />
                      )
                      : (
                        <Typography
                          sx={{
                            fontSize: '20px',
                            color: '#28287B',
                            fontWeight: 700,
                            lineHeight: '25.2px'
                          }}
                        >
                          {newAppSumoEmailExports
                            ? 'Unlimited'
                            : usage?.leadsCredits > 0
                              ? credits
                              : 0}
                        </Typography>
                      )}
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '13px',
                        lineHeight: '16.38px',
                        color: '#8181B0',
                        mt: 0.5
                      }}
                    >
                      Lead Exports Left
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'row', sm: 'column' },
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    width: { xs: '100%', sm: 'fit-content' }
                  }}
                >
                  <Button
                    onClick={() => {
                      setLeadCreditDialogOpen(true)
                      setLeadCreditTab('usage')
                    }}
                  >
                    {isMobile ? 'Leads Usage' : 'View Leads Usage'}
                  </Button>
                  <Button
                    onClick={() => {
                      setLeadCreditDialogOpen(true)
                      setLeadCreditTab('download summary')
                    }}
                  >
                    {isMobile ? 'Downloads Summary' : 'Lead Finder Downloads Summary'}
                  </Button>
                </Box>
              </Box>
            </Box>
            {userVerifiedStatus
              ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    boxShadow: '0px 12px 15px 0px #4B71970D',
                    borderRadius: '12px',
                    backgroundColor: 'white',
                    width: '100%',
                    p: 3,
                    mt: 3,
                    border: dailyLeadLimitCount ? 'none' : '1px solid red'
                  }}
                >
                  {' '}
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '24px',
                      color: '#28287B'
                    }}
                  >
                    Lead Phone Number Export Credits Per Month
                  </Typography>
                  {isLoadingUsage
                    ? (
                      <CircularProgress size={25} thickness={5} />
                    )
                    : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 2
                        }}
                      >
                        <Typography
                          sx={{
                            mt: 2,
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            color: '#28287B'
                          }}
                        >
                          {newAppSumoLeadExports ? 'Unlimited' : leadsPhoneNumberExportsPerMonth}
                        </Typography>
                      </Box>
                    )}
                  <Box sx={{ width: '100%', mt: 2 }}>
                    {' '}
                    <LinearProgress variant='determinate' value={100} />
                  </Box>
                </Box>
              )
              : (
                <></>
              )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                boxShadow: '0px 12px 15px 0px #4B71970D',
                borderRadius: '12px',
                backgroundColor: 'white',
                width: '100%',
                p: 3,
                mt: 3,
                border: emailCreditExceeded ? '1px solid red' : 'none'
              }}
            >
              {' '}
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  color: '#28287B'
                }}
              >
                Email Sends Per Month
              </Typography>
              {isLoadingUsage
                ? (
                  <CircularProgress size={25} thickness={5} />
                )
                : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Typography
                      sx={{
                        mt: 2,
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: '#28287B'
                      }}
                    >
                      {emailCreditExceeded ? montlyEmailCredit : emailSentCount}/
                      {newAppSumoUser ? 'Unlimited' : montlyEmailCredit}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 2,
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: 'red',
                        display: emailCreditExceeded ? 'block' : 'none'
                      }}
                    >
                      (You have used up all of your email credits)
                    </Typography>
                  </Box>
                )}
              <Box sx={{ width: '100%', mt: 2 }}>
                {' '}
                <LinearProgress
                  variant='determinate'
                  value={(usage?.usedEmailCredit / usage?.montlyEmailCredit) * 100}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                boxShadow: '0px 12px 15px 0px #4B71970D',
                borderRadius: '12px',
                backgroundColor: 'white',
                width: '100%',
                p: 3,
                mt: 3,
                border: activeLeadsExceeded ? '1px solid red' : 'none'
              }}
            >
              {' '}
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  color: '#28287B'
                }}
              >
                Uploaded Contacts
              </Typography>
              {isLoadingUsage
                ? (
                  <CircularProgress size={25} thickness={5} />
                )
                : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Typography
                      sx={{
                        mt: 2,
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: '#28287B'
                      }}
                    >
                      {activeLeadsExceeded ? activeLeads : usedActiveLeads}/{activeLeads}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 2,
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: 'red',
                        display: activeLeadsExceeded ? 'block' : 'none'
                      }}
                    >
                      (You have exceeded the limit for uploading contacts)
                    </Typography>
                  </Box>
                )}
              <Box sx={{ width: '100%', mt: 2 }}>
                {' '}
                <LinearProgress
                  variant='determinate'
                  value={((usage?.usedActiveLeads || 0) / usage?.activeLeads) * 100}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                boxShadow: '0px 12px 15px 0px #4B71970D',
                borderRadius: '12px',
                backgroundColor: 'white',
                width: '100%',
                p: 3,
                mt: 3,
                border: aiCreditExceeded ? '1px solid red' : 'none'
              }}
            >
              {' '}
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  color: '#28287B'
                }}
              >
                AI Writer Credits
              </Typography>
              {isLoadingUsage
                ? (
                  <CircularProgress size={25} thickness={5} />
                )
                : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Typography
                      sx={{
                        mt: 2,
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: '#28287B'
                      }}
                    >
                      {aiCreditExceeded ? aiWriterLimit : usedAiCredit}/{aiWriterLimit}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 2,
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: 'red',
                        display: aiCreditExceeded ? 'block' : 'none'
                      }}
                    >
                      (You have used up all of your AI writer credits)
                    </Typography>
                  </Box>
                )}
              <Box sx={{ width: '100%', mt: 2 }}>
                {' '}
                <LinearProgress
                  variant='determinate'
                  value={(usage?.usedAiCredit / usage?.aiWriterLimit) * 100}
                />
              </Box>
            </Box>

            {newAppSumoUser && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  boxShadow: '0px 12px 15px 0px #4B71970D',
                  borderRadius: '12px',
                  backgroundColor: 'white',
                  width: '100%',
                  p: 3,
                  mt: 3,
                  border: aiCreditExceeded ? '1px solid red' : 'none'
                }}
              >
                {' '}
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    color: '#28287B'
                  }}
                >
                  Connected Email Accounts
                </Typography>
                {isLoadingUsage
                  ? (
                    <CircularProgress size={25} thickness={5} />
                  )
                  : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 2
                      }}
                    >
                      <Typography
                        sx={{
                          mt: 2,
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          color: '#28287B'
                        }}
                      >
                        {totalCount}/{connectedEmailAccount}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          color: 'red',
                          display: 'none'
                        }}
                      >
                        (You have used up all of your AI writer credits)
                      </Typography>
                    </Box>
                  )}
                <Box sx={{ width: '100%', mt: 2 }}>
                  {' '}
                  <LinearProgress
                    variant='determinate'
                    value={(totalCount / connectedEmailAccount) * 100}
                  />
                </Box>
              </Box>
            )}

            {!newAppSumoUser
              ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      boxShadow: '0px 12px 15px 0px #4B71970D',
                      borderRadius: '12px',
                      backgroundColor: 'white',
                      width: '100%',
                      p: 3,
                      mt: 3,
                      border: dailyLeadLimitCount ? 'none' : '1px solid red'
                    }}
                  >
                    {' '}
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 700,
                        lineHeight: '24px',
                        color: '#28287B'
                      }}
                    >
                      Email Export Credits{' '}
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 400
                        }}
                      >
                        (Per Day)
                      </span>
                    </Typography>
                    {isLoadingUsage
                      ? (
                        <CircularProgress size={25} thickness={5} />
                      )
                      : (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 2
                          }}
                        >
                          <Typography
                            sx={{
                              mt: 2,
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              color: '#28287B'
                            }}
                          >
                            {dailyLeadLimit}
                          </Typography>
                        </Box>
                      )}
                    <Box sx={{ width: '100%', mt: 2 }}>
                      {' '}
                      <LinearProgress variant='determinate' value={100} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      boxShadow: '0px 12px 15px 0px #4B71970D',
                      borderRadius: '12px',
                      backgroundColor: 'white',
                      width: '100%',
                      p: 3,
                      mt: 3,
                      border: bounceCreditCount ? 'none' : '1px solid red'
                    }}
                  >
                    {' '}
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 700,
                        lineHeight: '24px',
                        color: '#28287B'
                      }}
                    >
                      Email Verification Credits
                    </Typography>
                    {isLoadingUsage
                      ? (
                        <CircularProgress size={25} thickness={5} />
                      )
                      : (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 2
                          }}
                        >
                          <Typography
                            sx={{
                              mt: 2,
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              color: '#28287B'
                            }}
                          >
                            {bounceCredit}
                          </Typography>
                        </Box>
                      )}
                    <Box sx={{ width: '100%', mt: 2 }}>
                      {' '}
                      <LinearProgress variant='determinate' value={100} />
                    </Box>
                  </Box>
                </>
              )
              : (
                <></>
              )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                boxShadow: '0px 12px 15px 0px #4B71970D',
                borderRadius: '12px',
                backgroundColor: 'white',
                width: '100%',
                p: 3,
                mt: 3,
                border: bounceCreditCount ? 'none' : '1px solid red'
              }}
            >
              {' '}
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  color: '#28287B'
                }}
              >
                {newAppSumoUser ? 'Multi-channel LinkedIn Outreach' : 'Linkedin Credits'}
              </Typography>
              {isLoadingUsage
                ? (
                  <CircularProgress size={25} thickness={5} />
                )
                : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <Typography
                      sx={{
                        mt: 2,
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: '#28287B'
                      }}
                    >
                      {linkedinCredits}
                    </Typography>
                  </Box>
                )}
              <Box sx={{ width: '100%', mt: 2 }}>
                {' '}
                <LinearProgress variant='determinate' value={100} />
              </Box>
            </Box>
            {!newAppSumoUser && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  boxShadow: '0px 12px 15px 0px #4B71970D',
                  borderRadius: '12px',
                  backgroundColor: 'white',
                  width: '100%',
                  p: 3,
                  mt: 3
                }}
              >
                {' '}
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    color: '#28287B',
                    mb: 3
                  }}
                >
                  Payment Method
                </Typography>
                {isCardLoading
                  ? (
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}
                    >
                      <CircularProgress size={25} thickness={5} />
                      <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#4e88e6', ml: 2 }}>
                        Loading...
                      </Typography>
                    </Box>
                  )
                  : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        width: { xs: '100%', sm: '40%' },
                        p: 2,
                        border: '1px solid #E4E4E5',
                        borderRadius: '8px',
                        flexDirection: 'column',
                        mb: 2
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '13px',
                          lineHeight: '16.38px',
                          color: '#8181B0',
                          mb: 0.5
                        }}
                      >
                        Card Information
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          color: '#28287B',
                          mb: 3
                        }}
                      >
                        {payment?.brand?.toUpperCase()} Ending in {payment?.last4}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '13px',
                          lineHeight: '16.38px',
                          color: '#8181B0',
                          mb: 0.5
                        }}
                      >
                        Name On Card
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          color: '#28287B'
                        }}
                      >
                        {payment?.name}
                      </Typography>
                    </Box>
                  )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: { xs: '100%', sm: '40%' }
                  }}
                >
                  {' '}
                  {!isCardLoading
                    ? (
                      <>
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          {/* <Button onClick={handelBillingCheckout} disabled={!canEdit || !canDelete} variant='outlined' >
                            Cancel Plan
                          </Button> */}
                          <Button disabled={!canEdit || !canDelete} variant='outlined'>
                            Cancel Plan
                          </Button>
                          <Button
                            onClick={handelBillingCheckout}
                            disabled={!canEdit || !canDelete}
                            variant='contained'
                          >
                            Update Credit Card
                          </Button>
                        </Box>
                      </>
                    )
                    : (
                      ' '
                    )}
                </Box>
              </Box>
            )}
            {
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  boxShadow: '0px 12px 15px 0px #4B71970D',
                  borderRadius: '12px',
                  backgroundColor: 'white',
                  width: '100%',
                  p: 3,
                  mt: 3
                }}
              >
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    color: '#28287B',
                    mb: 3
                  }}
                >
                  Invoice
                </Typography>
                <Grid
                  container
                  sx={{ width: '100%', maxHeight: '500px', overflowY: 'auto', ...scrollBarStyle }}
                >
                  {invoices?.map((invoice) => (
                    <Grid
                      item
                      xs={12}
                      key={invoice?.id}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          width: '50%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            color: '#28287B'
                          }}
                        >
                          {/* {invoice.createdAt} */}
                          {moment(invoice.createdAt).format('MM/DD/YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: '50%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Button>
                          <Link to={invoice.pdfUrl} target='_blank'>
                            Download
                          </Link>
                        </Button>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    borderTop: `1px solid ${theme.palette.grey[300]}`,
                    pt: 2,
                    mt: 2,
                    gap: 2
                  }}
                >
                  <Typography
                    sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '20px', color: '#28287B' }}
                  >
                    Total Entries :
                  </Typography>{' '}
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '20px',
                      color: theme.palette.primary.main
                    }}
                  >
                    {invoices?.length || 0}
                  </Typography>
                </Box>
              </Box>
            }
          </>
        )}
      </Box>

      <UpdatePlanDialog open={open} handleClose={handleClose} theme={theme} />
      <Dialog
        open={leadCreditDialogOpen}
        onClose={closeCreditModal}
        sx={{
          backgroundColor: 'rgba(4, 4, 30, 0.5)',
          '& .MuiDialog-paper': { height: { xs: '100%', sm: '90vh' } }
        }}
        fullScreen={isMobile}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: { xs: '100%', sm: '500px' },
            py: 3,
            px: 3,
            overflow: 'hidden'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '28px',
                letterSpacing: '0em',
                color: `${theme.palette.primary.primaryText}`
              }}
            >
              {leadCreditTab === 'usage' ? 'Lead Usage' : 'Lead finder download summary'}
            </Typography>
            <IconButton onClick={closeCreditModal}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              mt: 2,
              height: { xs: '85vh', sm: '75vh' },
              overflow: 'hidden',
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: 1
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',

                width: '100%',
                height: '100%'
              }}
            >
              <TableContainer component={Paper} sx={{ height: '100%', ...scrollBarStyle }}>
                <Table aria-label='simple table' sx={{ borderCollapse: 'revert' }}>
                  <TableBody>
                    {leadCreditTab === 'usage'
                      ? (
                        leadUsage?.map((item) => (
                          <TableRow
                            key={item?.data?._id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component='th' scope='row'>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  letterSpacing: '0em',
                                  color: '#8181B0'
                                }}
                              >
                                {item?.data?.amount !== 0 ? item?.data?.amount : -1}
                              </Typography>
                            </TableCell>
                            <TableCell align='left'>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  letterSpacing: '0em',
                                  color: `${theme.palette.primary.primaryText}`
                                }}
                              >
                                {item?.data?.type}
                              </Typography>
                            </TableCell>
                            <TableCell align='left'>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  letterSpacing: '0em',
                                  color: `${theme.palette.primary.primaryText}`
                                }}
                              >
                                {timezone
                                  ? moment
                                    .utc(item?.data?.createdAt)
                                    .tz(timezone)
                                    .format('MM/DD/YYYY')
                                  : moment(item?.data?.createdAt).format('MM/DD/YYYY')}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      )
                      : isDownloadFilesLoading
                        ? (
                          <TableRow>
                            <TableCell colSpan={5} align='center'>
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        )
                        : (
                          downloadFiles?.map((item) => (
                            <TableRow
                              key={item?._id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component='th' scope='row'>
                                <Typography
                                  sx={{
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    lineHeight: '16px',
                                    letterSpacing: '0em',
                                    color: '#28287B'
                                  }}
                                >
                                  {item?.name}
                                </Typography>
                              </TableCell>
                              <TableCell align='left'>
                                <Typography
                                  sx={{
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    lineHeight: '16px',
                                    letterSpacing: '0em',
                                    color: '#28287B'
                                  }}
                                >
                                  {timezone
                                    ? moment
                                      .utc(item?.data?.createdAt)
                                      .tz(timezone)
                                      .format('MM/DD/YYYY')
                                    : moment(item?.createdAt).format('MM/DD/YYYY')}
                                </Typography>
                              </TableCell>
                              <TableCell align='left'>
                                <IconButton
                                  sx={{
                                    color: '#28287B'
                                  }}
                                  onClick={() => handelDowloadCsv(item)}
                                >
                                  <DownloadOutlined />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                  </TableBody>
                </Table>
              </TableContainer>
              {downloadFiles?.length > 0 &&
                !isDownloadFilesLoading &&
                leadCreditTab === 'download summary' && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Pagination
                      page={page}
                      setPage={setPage}
                      total={totalFiles}
                      length={downloadFiles?.length}
                      limit={limit}
                      handleLimitChange={handleLimitChange}
                    />
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
      </Dialog>
      {fromCreateWorkspace && timelineButtons.length > 1 && (
        <Box sx={{ display: 'flex', mt: 4 }}>
          <Button
            sx={{
              borderColor: '#dc3545',
              color: '#dc3545',
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 'bold',
              '&:hover': {
                borderColor: '#c82333',
                color: 'white',
                backgroundColor: '#dc3545'
              }
            }}
            variant='outlined'
            onClick={() => {
              setOpenRemoveWorkspaceDialog(true)
            }}
          >
            Delete workspace
          </Button>
        </Box>
      )}
      <RemoveWorkSpaceDialog
        open={openRemoveWorkspaceDialog}
        onClose={() => {
          setOpenRemoveWorkspaceDialog(false)
        }}
        onClick={handleRemoveWorkspaceClick}
        isLoading={isDeletingWorkspace}
      />
    </>
  )
}
BillingAndUsage.propTypes = {
  mode: PropTypes.string.isRequired,
  fromCreateWorkspace: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired
}

export default BillingAndUsage
