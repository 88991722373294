import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Line } from 'react-chartjs-2'
import SignalCellularAlt1BarRoundedIcon from '@mui/icons-material/SignalCellularAlt1BarRounded'
import SignalCellularAlt2BarRoundedIcon from '@mui/icons-material/SignalCellularAlt2BarRounded'
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded'
import { Box, CircularProgress, Grid, Paper } from '@mui/material'
import { experimentalStyled as styled } from '@mui/material/styles'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
let flattenedData
let compositeScores
let dataValues

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027'
  })
}))

const SimpleLineChart = ({ row = [] }) => {
  const currentDate = new Date()

  const lastSixMonths = [...Array(6)].map((_, index) => {
    const monthAgo = new Date(currentDate)
    monthAgo.setMonth(currentDate.getMonth() - index)
    return monthAgo
  }).reverse()

  const getCompositeScores = (flattenedData) => {
    const compositeScores = flattenedData.map(item => {
      if (item.intent && item.intent.composite_score) {
        const score = parseInt(item.intent.composite_score, 10)
        return isNaN(score) ? null : score
      }
      return null
    }).filter(score => score !== null)
    return compositeScores
  }

  const getScoreLevel = (score) => {
    if (score >= 76 && score <= 100) return 3
    if (score >= 63 && score <= 75) return 2
    if (score >= 0 && score <= 62) return 1
    return 0
  }

  const getIconForScore = (score) => {
    if (score >= 0 && score <= 62) {
      return <SignalCellularAlt1BarRoundedIcon style={{ color: '#3F4FF8' }} />
    } else if (score >= 63 && score <= 75) {
      return <SignalCellularAlt2BarRoundedIcon style={{ color: '#3F4FF8' }} />
    } else if (score >= 76 && score <= 100) {
      return <SignalCellularAltRoundedIcon style={{ color: '#3F4FF8' }} />
    }
    return null
  }

  const generateChartData = (data) => {
    flattenedData = data.flatMap(item => item)
    compositeScores = getCompositeScores(flattenedData)

    const filteredData = flattenedData.filter(item => {
      const itemDate = new Date(item?.intent?.date)
      return lastSixMonths.some(month => month.getMonth() === itemDate.getMonth() && month.getFullYear() === itemDate.getFullYear())
    })

    const labels = lastSixMonths.map(month => month.toLocaleString('default', { month: 'short' }))
    dataValues = lastSixMonths.map(month => {
      const monthData = filteredData.filter(item => {
        const itemDate = new Date(item?.intent?.date)
        return itemDate.getMonth() === month.getMonth() && itemDate.getFullYear() === month.getFullYear()
      })
      if (monthData.length > 0) {
        // Get the latest entry for the month
        const sortedData = monthData.sort((a, b) =>
          new Date(b.intent.date) - new Date(a.intent.date)
        )
        return getScoreLevel(sortedData[0].intent.composite_score)
      }
      return 0
    }).flat()
    // const dataValues =  filteredData.map((item) => getScoreLevel(item?.intent?.composite_score))
    return {
      labels,
      datasets: [
        {
          label: 'Intent Score',
          data: dataValues,
          borderColor: '#4F46E5',
          backgroundColor: 'rgba(79, 70, 229, 0.1)',
          tension: 0.4,
          pointRadius: (context) => {
            const value = context.raw
            return value === null ? 0 : 4
          },
          pointBackgroundColor: '#4F46E5',
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 6,
          spanGaps: true
        }
      ]
    }
  }
  const tooltipEnabled = dataValues?.length > 0 && dataValues?.some(value => value !== 0)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      tooltip: {
        enabled: tooltipEnabled,
        backgroundColor: '#1F2937',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 12,
        displayColors: false,
        filter: function (tooltipItem) {
          // Only show tooltip if value is not 0
          return tooltipItem.raw !== 0
        },
        callbacks: {
          title: () => '',
          label: (context) => {
            const value = context.parsed.y
            const scoreLevel = value === 3 ? 'High' : value === 2 ? 'Medium' : 'Low'
            const monthIndex = context.dataIndex
            const month = lastSixMonths[monthIndex]
            const monthData = flattenedData.filter(item => {
              const itemDate = new Date(item?.intent?.date)
              return itemDate.getMonth() === month.getMonth() &&
                     itemDate.getFullYear() === month.getFullYear()
            })
            if (monthData.length > 0) {
              const sortedData = monthData.sort((a, b) =>
                new Date(b.intent.date) - new Date(a.intent.date)
              )
              const date = new Date(sortedData[0].intent.date).toLocaleDateString()
              return `${scoreLevel} - ${date}`
            }
          }
        }
      }
    },
    scales: {
      y: {
        min: 0,
        max: 4,
        grid: {
          display: true,
          color: '#E5E7EB',
          drawBorder: false
        },
        ticks: {
          stepSize: 1,
          callback: (value) => {
            return value === 3 ? 'High' : value === 2 ? 'Medium' : value === 1 ? 'Low' : ''
          },
          font: {
            family: 'Inter, sans-serif',
            size: 12
          },
          color: '#6B7280'
        },
        border: {
          display: false
        }
      },
      x: {
        grid: {
          display: true
        },
        ticks: {
          font: {
            family: 'Inter, sans-serif',
            size: 12
          },
          color: '#6B7280'
        },
        border: {
          display: false
        }
      }
    }
  }

  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 4, md: 6, lg: 6 }}>
      {row && row.length > 0
        ? (
            row.map((dataItem, index) => {
              if (dataItem.data && dataItem.data.length > 0) {
                const chartData = generateChartData(dataItem.data)
                return (
                  <Grid
                    key={index} xs={12} sm={6} md={4} lg={3}
                    sx={{
                      display: row.length === 1 ? 'flex' : 'block'
                    }}
                  >
                    <Item>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1px'
                      }}
                      >
                        <Box sx={{
                          backgroundColor: '#F4F4F4',
                          borderRadius: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '12px'
                        }}
                        >
                          {compositeScores.length > 0 && getIconForScore(compositeScores[compositeScores.length - 1])}
                        </Box>
                        <h2 style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          color: '#1F2937'
                        }}
                        >
                          {dataItem.topic}
                        </h2>
                      </Box>
                      <Box sx={{ height: '140px' }}>
                        <Line data={chartData} options={options} />
                      </Box>
                    </Item>
                  </Grid>
                )
              } else {
                return null
              }
            })
          )
        : (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
            <CircularProgress size={25} thickness={5} />
          </Box>
          )}
    </Grid>
  )
}

export default SimpleLineChart
