import { useState, useEffect, useCallback } from 'react'
import useUserVerification from 'src/hooks/useUserVerification';
import { useGetCurrentPlanQuery, useGetPlanDetailsMutation } from 'src/services/billing-service'

const usePlan = () => {
  const [planDetails, setPlanDetails] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const { isFreeUser } = useUserVerification()
  const { data: currentPlan } = useGetCurrentPlanQuery()
  const [getPlanDetails] = useGetPlanDetailsMutation()

  const fetchPlan = useCallback(async () => {
    if (!currentPlan || !currentPlan.subscription) {
      const result = {
        isFreeUser: isFreeUser,
        planDetails: null,
        isGrowthPlan: false,
        isStarterPlan: false,
        isInifinityPlan: false
      }
      setPlanDetails(result)
      return result
    }

    const planId =
      currentPlan.subscription.sendingWarmup?.planId ||
      currentPlan.subscription.leads?.planId

    if (!planId) {
      console.log('No planId found. It is a free user.')
      const result = {
        isFreeUser: planId === undefined || planId === null ? true : isFreeUser,
        planDetails: null,
        isGrowthPlan: false,
        isStarterPlan: false,
        isInifinityPlan: false
      }
      setPlanDetails(result)
      return result
    }

    setLoading(true)
    setError(null)

    try {
      const planId =
        currentPlan?.subscription?.sendingWarmup?.planId ||
        currentPlan?.subscription?.leads?.planId

      const plansDetails = await getPlanDetails({ priceId: planId }).unwrap()

      const subscriptionType = plansDetails?.subscriptionType || null

      const result = {
        planId,
        isFreeUser: isFreeUser,
        planDetails: plansDetails,
        isGrowthPlan: subscriptionType === 'skyrocket',
        isStarterPlan: subscriptionType === 'growth',
        isInifinityPlan: subscriptionType === 'scale'
      }

      setPlanDetails(result)
      return result
    } catch (err) {
      setError(err.message || 'Failed to fetch plan details')
      return null
    } finally {
      setLoading(false)
    }
  }, [currentPlan, getPlanDetails])

  useEffect(() => {
    fetchPlan()
  }, [fetchPlan])

  return { planDetails, fetchPlan, loading, error }
}

export default usePlan
