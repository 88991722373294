import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'

// import PhoneIcon from '@mui/icons-material/Phone'
// import ExportIcon from '@mui/icons-material/ImportExport'
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
// import { Enrich } from 'src/icons/enrich'
// import { ExportBlueIcon } from 'src/icons/export'
// import { PhoneBlueIcon } from './phone'
// import { useTheme } from '@emotion/react'
import CreditBalance from './leadsFinder/CreditBalance'

const HeaderWithPopover = ({ title, usage }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width:
          title === 'InboxHub' || title === 'Onboarding Hub'
            ? { xs: '100%', lg: 'calc(100% - 30px)', xl: 'calc(100% - 70px)' }
            : { xs: '100%', xl: 'calc(100% + 90px)' },
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        ...(title !== 'Lead Finder' && {
          borderBottom: '1px solid #E4E4E5',
          pb: 2,
          ml: { xs: '0px', lg: '-10px' },
          mb: { xs: 2, md: 3 },
        })
      }}
    >
      <Typography
        sx={{
          color: theme.palette.primary.primaryText,
          fontSize: { xs: '26px', md: '32px' },
          fontWeight: 700,
          lineHeight: '40px',
          letterSpacing: '0px',
          mb: { xs: 2, md: 0 }
        }}
      >
        {title}
      </Typography>
      {title === 'Lead Finder' && <CreditBalance usage={usage} />}
      {/* <WorkspaceSelector /> */}
    </Box>
  )
}

export default HeaderWithPopover
