import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { planNames } from 'src/config'
import { useGetCurrentPlanQuery } from 'src/services/billing-service.js'
import { useGetMeAndWorkspaceQuery } from 'src/services/user-service'
import { useGetAccountsLengthMutation } from 'src/services/account-service'

const useUserVerification = () => {
  const [totalCount, setTotalCount] = useState(0)
  const [accountUsage, setAccountUsage] = useState(0)
  const { data, isLoading: isUserDataLoading } = useGetMeAndWorkspaceQuery(undefined, {
    refetchOnMountOrArgChange: false,
    refetchOnFocus: false,
    refetchOnReconnect: false
  })
  const { data: currentPlan, isLoading: isPlanLoading } = useGetCurrentPlanQuery(undefined, {
    refetchOnMountOrArgChange: false,
    refetchOnFocus: false,
    refetchOnReconnect: false
  })
  const [getAccountsLength, { isLoading: isAccountsLoading }] = useGetAccountsLengthMutation(undefined, {
    refetchOnMountOrArgChange: false,
    refetchOnFocus: false,
    refetchOnReconnect: false
  })
  const accountsLimit = useSelector((state) => state.accounts)
  const { _id: workspaceId } = useSelector((state) => state.workspace)
  const user = useSelector((state) => state.user)

  const advanceApis = { successai_tier1_: true, successai_tier2_: true, successai_tier3_: true, successai_tier4_: false, successai_tier5_: false }
  // const buyersIntendData = { successai_tier1_: false, successai_tier2_: false, successai_tier3_: true, successai_tier4_: true, successai_tier5_: true };
  const newAppSumoPlans = { successai_tier1_: true, successai_tier2_: true, successai_tier3_: true, successai_tier4_: true, successai_tier5_: true }
  // const appSumoPlans = { successai_tier1: true, successai_tier2: true, successai_tier3: true, successai_tier4: true, successai_tier5: true };
  const appSumoPlanNames = { successai_tier1_: 'License Tier 1', successai_tier2_: 'License Tier 2', successai_tier3_: 'License Tier 3', successai_tier4_: 'License Tier 4', successai_tier5_: 'License Tier 5' }
  const newAppSumoEmailExports = { successai_tier1_: false, successai_tier2_: false, successai_tier3_: true, successai_tier4_: true, successai_tier5_: true }
  const newAppSumoLeadExports = { successai_tier1_: false, successai_tier2_: false, successai_tier3_: false, successai_tier4_: true, successai_tier5_: true }

  const isWhiteLabelUser = user?.isWhiteLabelUser;
  const isLoading = isUserDataLoading || isPlanLoading || isAccountsLoading

  // Helper functions to determine user type
  const isAppSumoUser = () => data?.workspace?.assignedPlan?.length > 0 && !data?.workspace?.isAppSumoRefund
  const newAppSumoUser = () => isAppSumoUser() && newAppSumoPlans[data?.workspace?.assignedPlan]
  const isEarlyBirdAccessUser = () => data?.workspace?.isEarlyBirdAccessUser && !data?.workspace?.isEarlyBirdDealRefund

  const isPaidUser = () => {
    if (!data?.workspace || !currentPlan) return false

    const warmupPlan = currentPlan.subscription?.sendingWarmup
    const leadsPlan = currentPlan.subscription?.leads

    const isWarmupPaid = warmupPlan?.planType !== 'weekly' && new Date(warmupPlan?.expiresAt) > new Date()
    const isLeadsPaid = new Date(leadsPlan?.expiresAt) > new Date()

    return isWarmupPaid || isLeadsPaid || isWhiteLabelUser
  }

  const checkIfDisableButton = () => {
    if (!user?.emailVerified && !isPaidUser()) {
      return true
    }

    if (newAppSumoUser()) {
      return totalCount >= accountUsage
    }

    if (isAppSumoUser() || isEarlyBirdAccessUser()) {
      return false
    }

    if ((totalCount ?? accountsLimit.length) >= accountUsage && !isPaidUser()) {
      return true
    }

    if (isPaidUser()) {
      const planId = currentPlan?.subscription?.sendingWarmup?.planId || currentPlan?.subscription?.leads?.planId
      const plan = planNames[planId]

      if (plan?.includes('Starter') && (accountUsage > 0 ? totalCount >= accountUsage : totalCount >= 5)) return true
      if (plan?.includes('Growth') && (accountUsage > 0 ? totalCount >= accountUsage : totalCount >= 100)) return true
      if (plan?.includes('Infinity') && (accountUsage > 0 ? totalCount >= accountUsage : totalCount >= 250)) return true

      if (isWhiteLabelUser) {
        return totalCount >= accountUsage
      }
    }
    return false
  }

  useEffect(() => {
    if (workspaceId) {
      getAccountsLength({ workspaceId }).then((res) => {
        setTotalCount(res?.data?.total)
        setAccountUsage(res?.data?.emailAccountUsage)
      })
    }
    // console.log('isFreeUser ', data)
  }, [accountsLimit, workspaceId])

  return {
    isAppSumoUser: isAppSumoUser(),
    newAppSumoUser: newAppSumoUser(),
    isEarlyBirdAccessUser: isEarlyBirdAccessUser(),
    isPaidUser: isPaidUser(),
    isFreeUser: !isAppSumoUser() && !isEarlyBirdAccessUser() && !isPaidUser() && !newAppSumoUser(),
    shouldDisableButton: checkIfDisableButton(),
    advancedApiButton: data?.workspace?.assignedPlan ? advanceApis[data?.workspace.assignedPlan] : false,
    buyersIntendFields: !isAppSumoUser() && !isEarlyBirdAccessUser() && !isPaidUser() && !newAppSumoUser(),
    accountsLimit,
    appSumoPlanNames,
    newAppSumoEmailExports: data?.workspace?.assignedPlan ? newAppSumoEmailExports[data?.workspace.assignedPlan] : false,
    newAppSumoLeadExports: data?.workspace?.assignedPlan ? newAppSumoLeadExports[data?.workspace.assignedPlan] : false,
    isWhiteLabelUser,
    isPartner: user.isPartner,
    partnerAccountId: user.partnerAccountId,
    isLoading
  }
}

export default useUserVerification
