import { baseQuery } from 'src/utils/base-query'
import { createApi } from '@reduxjs/toolkit/query/react'

export const emailValidationApi = createApi({
  reducerPath: 'emailValidationApi',
  baseQuery,
  tagTypes: ['ValidateSingleEmail', 'FetchEmailStatus', 'ClearEmailHistory', 'ValidateBulkEmail', 'GetEmailBatch', 'GetEmailBatches'],
  endpoints: (builder) => ({
    validateSingleEmail: builder.mutation({
      query: (email) => ({
        url: `/email-validation/validateSingleEmail?email=${email}`,
        method: 'GET'
      }),
      providesTags: ['ValidateSingleEmail'],
      invalidatesTags: ['FetchEmailStatus']
    }),
    fetchEmailStatus: builder.query({
      query: () => ({
        url: '/email-validation/fetchEmailStatus',
        method: 'GET'
      }),
      providesTags: ['FetchEmailStatus']
    }),
    clearHistory: builder.mutation({
      query: () => ({
        url: '/email-validation/clearHistory',
        method: 'GET'
      }),
      providesTags: ['ClearEmailHistory'],
      invalidatesTags: ['FetchEmailStatus']
    }),
    validateBulkEmail: builder.mutation({
      query: (data) => {
        // Handle both CSV file upload and direct email list
        const formData = new FormData()
        if (data.file) {
          formData.append('file', data.file)
        }
        if (data.emails) {
          formData.append('emails', JSON.stringify(data.emails))
        }
        if (data.batchName) {
          formData.append('batchName', data.batchName)
        }

        return {
          url: '/email-validation/batch',
          method: 'POST',
          body: formData,
          formData: true
        }
      },
      providesTags: ['ValidateBulkEmail'],
      invalidatesTags: ['GetEmailBatches']
    }),
    getEmailBatch: builder.query({
      query: (batchId) => ({
        url: `/email-validation/batch/${batchId}?with-stats=true`,
        method: 'GET'
      }),
      providesTags: (result, error, { batchId }) => [{ type: 'GetEmailBatch', id: batchId }]
    }),
    getEmailBatches: builder.query({
      query: (withStats = false) => ({
        url: '/email-validation/batch',
        method: 'GET',
        params: withStats ? { 'with-stats': true } : {}
      }),
      providesTags: ['GetEmailBatches']
    }),
    deleteEmailBatch: builder.mutation({
      query: (batchId) => ({
        url: `/email-validation/batch/${batchId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, batchId) => [
        'GetEmailBatches',
        { type: 'GetEmailBatch', id: batchId }
      ]
    }),
    downloadEmailBatch: builder.query({
      query: (batchId) => ({
        url: `/email-validation/batch/${batchId}/download`,
        method: 'GET'
      })
    })
  })
})

export const {
  useFetchEmailStatusQuery,
  useLazyFetchEmailStatusQuery,
  useValidateSingleEmailMutation,
  useClearHistoryMutation,
  useValidateBulkEmailMutation,
  useGetEmailBatchQuery,
  useLazyGetEmailBatchQuery,
  useGetEmailBatchesQuery,
  useLazyGetEmailBatchesQuery,
  useDeleteEmailBatchMutation,
  useLazyDownloadEmailBatchQuery
} = emailValidationApi

// url: `/email-validation/batch/${batchId}${withStats ? '?with-stats=true' : ''}`,
