// Helper function to capitalize the first letter
export const capitalizeFirstLetter = (string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

// Helper function to replace underscore with space and capitalize all letters
export const removeUnderscoreAndCapitalize = (string) => {
  if (!string) return ''
  return string.replace(/_/g, ' ').toUpperCase()
}
