import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'src/utils/base-query.js'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  theme: {
    companyName: '',
    supportEmail: '',
    companyAddress: '',
    companyWebsite: '',
    scriptContent: '',
    colors: {
      light: '#EBEFFF',
      main: '#216fed',
      dark: '#004EEB',
      contrastText: '#FFFFFF',
      primaryText: '#28287B'
    }
  },
  customDomain: {
    name: ''
  },
  seo: {
    title: '',
    description: ''
  },
  configs: {
    calendlyURL: '',
  },
  isInitialized: false
}

export const partnerDetailSlice = createSlice({
  name: 'partnerDetails',
  initialState,
  reducers: {
    initializeTheme: (state, action) => {
      state.theme = action.payload.theme
      state.customDomain = action.payload.customDomain
      state.seo = action.payload.seo
      state.configs = action.payload.configs
      state.isInitialized = true
    },
    setTheme: (state, action) => {
      state.theme = { ...state.theme, ...action.payload }
    },
    setCustomDomain (state, action) {
      state.customDomain = action.payload
    },
    setSEODetails (state, action) {
      state.seo = action.payload
    },
    setConfigs (state, action) {
      state.configs = action.payload
    }
  }
})

export const { initializeTheme, setTheme, setCustomDomain, setSEODetails, setConfigs } = partnerDetailSlice.actions

export const partnerDetailsApi = createApi({
  reducerPath: 'partnerDetailsApi',
  tagTypes: ['THEME'],
  baseQuery,
  endpoints: (builder) => ({
    getPartnerDetails: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: 'partner-details'
        }
      }
    }),
    createTheme: builder.mutation({
      query: (data) => ({
        method: 'POST',
        url: 'partner-details/theme/add-theme',
        body: data,
        formData: true
      }),
      invalidatesTags: ['THEME']
    }),
    getCustomDomain: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: 'partner-details/custom-domain'
        }
      }
    }),
    createCustomDomain: builder.mutation({
      query: (data) => ({
        method: 'POST',
        url: 'partner-details/custom-domain/add-domain',
        body: data
      }),
      invalidatesTags: ['THEME']
    }),
    getSEO: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: 'partner-details/seo'
        }
      }
    }),
    createSEO: builder.mutation({
      query: (data) => ({
        method: 'POST',
        url: 'partner-details/seo/add-seo',
        body: data
      }),
      invalidatesTags: ['THEME']
    }),
    checkDomain: builder.query({
      query: (params) => {
        return {
          method: 'GET',
          url: 'partner-details/custom-domain',
          params
        }
      }
    }),
    createConfigs: builder.mutation({
      query: (data) => ({
        method: 'POST',
        url: 'partner-details/configs/add-config',
        body: data
      }),
      invalidatesTags: ['THEME']
    }),
  })
})

export const { useGetPartnerDetailsQuery, useLazyGetPartnerDetailsQuery, useCreateThemeMutation, useGetCustomDomainQuery, useCreateCustomDomainMutation, useGetSEOQuery, useCreateSEOMutation, useCheckDomainQuery, useLazyCheckDomainQuery, useCreateConfigsMutation } = partnerDetailsApi
