export const ClockIcon = () => {
  return (
    <>
      <svg
        width='20'
        height='20'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.1486 10.3586C10.5677 10.4983 11.0208 10.2717 11.1605 9.85259C11.3002 9.43344 11.0737 8.98038 10.6545 8.84066L10.1486 10.3586ZM8.00156 8.79961H7.20156C7.20156 9.14395 7.42191 9.44966 7.74858 9.55855L8.00156 8.79961ZM8.80156 5.45445C8.80156 5.01262 8.44339 4.65445 8.00156 4.65445C7.55973 4.65445 7.20156 5.01262 7.20156 5.45445H8.80156ZM10.6545 8.84066L8.25454 8.04066L7.74858 9.55855L10.1486 10.3586L10.6545 8.84066ZM8.80156 8.79961V5.45445H7.20156V8.79961H8.80156ZM13.6016 7.99961C13.6016 11.0924 11.0944 13.5996 8.00156 13.5996V15.1996C11.978 15.1996 15.2016 11.9761 15.2016 7.99961H13.6016ZM8.00156 13.5996C4.90877 13.5996 2.40156 11.0924 2.40156 7.99961H0.801562C0.801562 11.9761 4.02511 15.1996 8.00156 15.1996V13.5996ZM2.40156 7.99961C2.40156 4.90681 4.90877 2.39961 8.00156 2.39961V0.799609C4.02511 0.799609 0.801562 4.02316 0.801562 7.99961H2.40156ZM8.00156 2.39961C11.0944 2.39961 13.6016 4.90681 13.6016 7.99961H15.2016C15.2016 4.02316 11.978 0.799609 8.00156 0.799609V2.39961Z'
          fill='#8181B0'
        />
      </svg>
    </>
  )
}

export const OnboardingHubUncheckedIcon = () => {
  return (
    <>
      <svg
        width='34'
        height='34'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect x='0.5' y='0.5' width='23' height='23' rx='11.5' fill='#F2F4F6' />
        <rect x='0.5' y='0.5' width='23' height='23' rx='11.5' stroke='#D7D9E6' />
      </svg>
    </>
  )
}
export const OnboardingHubCheckedIcon = () => {
  return (
    <>
      <svg
        width='34'
        height='34'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z'
          fill='#00AA38'
        />
        <path
          d='M16.3068 8.76953L10.3837 14.6926L7.69141 12.0003'
          stroke='white'
          stroke-width='1.6'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const ChevronDownArrow = () => {
  return (
    <>
      <svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 1L6.00081 5.58L11 1'
          stroke='#101010'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const BlueUnderline = () => {
  return (
    <svg width='92' height='7' viewBox='0 0 92 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 2.82556C21.6758 2.48218 42.3471 1.56002 63.0352 1.23834C71.8565 1.10117 80.678 0.957783 89.5018 1.01159C90.7041 1.01892 92.1955 1.01788 89.4372 1.31392C84.1398 1.88248 78.8066 2.26613 73.4927 2.72478C57.4898 4.10604 41.5013 5.41368 25.4009 5.87403C20.9495 6.00131 7.58405 6 12.0385 6C36.6413 6 61.2253 5.45873 85.8223 5.09302'
        stroke='#3F4FF8'
        stroke-width='2'
        stroke-linecap='round'
      />
    </svg>
  )
}
