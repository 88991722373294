import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/system'

const ShiningButton = styled(Button)(({ theme, width }) => ({
  position: 'relative',
  border: 'none',
  borderRadius: '12px',
  maxHeight: '42px',
  width: width || 'auto',
  background: 'linear-gradient(30deg, #3F4FF8, #3F4FF8)',
  color: 'white',
  textTransform: 'none',
  paddingLeft: '16px',
  paddingRight: '16px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '&:hover': {
    background: 'linear-gradient(45deg, #3F4FF8, #3F4FF8)'
  },
  '& .plusSign': {
    position: 'absolute',
    fontWeight: '400',
    color: 'white',
    opacity: 0.3
  },
  '& .plusSign:nth-of-type(1)': { top: '2%', left: '10%', fontSize: '8px' },
  '& .plusSign:nth-of-type(2)': { top: '3%', left: '30%', fontSize: '8px' },
  '& .plusSign:nth-of-type(3)': { top: '4%', left: '5%', fontSize: '2px' },
  '& .plusSign:nth-of-type(4)': { top: '60%', left: '50%', fontSize: '3px' },
  '& .plusSign:nth-of-type(5)': { top: '4%', left: '75%', fontSize: '8px' },
  '& .plusSign:nth-of-type(6)': { top: '50%', left: '90%', fontSize: '6px' },
  '& .plusSign:nth-of-type(7)': { top: '70%', left: '30%', fontSize: '6px' },
  '& .plusSign:nth-of-type(8)': { top: '80%', left: '50%', fontSize: '8px' },
  '& .plusSign:nth-of-type(9)': { top: '90%', left: '70%', fontSize: '7px' },
  '& .plusSign:nth-of-type(10)': { top: '10%', left: '90%', fontSize: '7px' },

  '@keyframes shine': {
    '0%': {
      textShadow: '0 0 10px rgba(255, 255, 255, 0.7), 0 0 20px rgba(255, 255, 255, 0.7), 0 0 30px rgba(0, 0, 255, 0.7)'
    },
    '100%': {
      textShadow: '0 0 20px rgba(255, 255, 255, 0.9), 0 0 40px rgba(0, 0, 255, 1), 0 0 60px rgba(0, 0, 255, 0.7)'
    }
  }
}))

const UpgradeButton = ({ setIsPlanUpgradeModalOpen, UpgradePlanIcon, width, buttonText, handleUpdatePlanClick }) => {
  return (
    <ShiningButton
      variant='contained'
      onClick={() => setIsPlanUpgradeModalOpen ? setIsPlanUpgradeModalOpen(true) : handleUpdatePlanClick()}
      width={width}
    >
      <img src={UpgradePlanIcon} alt='Upgrade Plan' style={{ width: '24px', height: '24px' }} />
      <span>{buttonText}</span>
      {Array.from({ length: 10 }).map((_, index) => (
        <span key={index} className='plusSign'>+</span>
      ))}
    </ShiningButton>
  )
}

export default UpgradeButton
