import React from 'react'
import SimpleLineChart from 'src/pages/SimpleBarChart'
import { Box, CircularProgress, Dialog, DialogContent } from '@mui/material'

const SimpleBarChartDialog = ({ open, score, onClose, date, row, intentGraphLoading }) => (
  <Dialog open={open} onClose={onClose} sx={{ maxWidth: '100vw', width: '100%' }}>
    <DialogContent>
      {intentGraphLoading
        ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
            <CircularProgress size={25} thickness={5} />
          </Box>)
        : <SimpleLineChart value={score} date={date} row={row} intentGraphLoading={intentGraphLoading} />}
    </DialogContent>
  </Dialog>
)

export default SimpleBarChartDialog
