export const getIntercomScript = () => {
  return `
      (function () {
        function getUrlParams() {
          var params = {};
          var queryString = window?.location?.search?.substring(1);
          var regex = /([^&=]+)=([^&]*)/g;
          var match;
          while ((match = regex.exec(queryString))) {
            params[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
          }
          return params;
        }

        var params = getUrlParams();
        if (params?.iframe !== undefined) {
          return;
        }
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/h0vx7ot8";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    `
}
