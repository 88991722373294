import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
// import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { useFindLeadsMutation, useFindCompaniesMutation } from 'src/services/leads-service.js'

import {
  Paper,
  IconButton,
  Typography,
  Box,
  Divider,
  InputBase,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack
  // Tooltip
} from '@mui/material'
import {
  KeyboardArrowDown,
  Close,
  // RocketLaunch as IntentIcon,
  Search as SearchIcon,
  KeyboardArrowRight
  // , LockOutlined
} from '@mui/icons-material'
import { IntentTopic } from 'src/assets/data'
import { FilterDropdown, Tag } from '../filterComponents'
import UpgradePlanIcon from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/upgrade-plan-white.svg'
import UpgradeModal from './buyingIntent/upgradeModal'
import { BuyingIntentIcon } from 'src/icons/buyingIntent'
import toast from 'react-hot-toast'
import { useTheme } from '@emotion/react'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'
import { InfoIcon } from 'src/icons/infoIcon'
import { PersonIcon } from 'src/icons/People'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'
import useUserVerification from 'src/hooks/useUserVerification'
import UpgradeButton from '../UpgradeButton'

const checkboxOfIcon = <OffCheckboxCustomIcon />
const checkboxOnIcon = <OnCheckboxCustomIcon />

const BuyingIntent = ({ plan, handleFilterChange, clear, setClear, value, isOpen, onToggle, topics = 0, alignment = 'People' }) => {
  const theme = useTheme()
  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false)
  const [isPlanUpgradeModalOpen, setIsPlanUpgradeModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedTopics, setSelectedTopics] = useState([])
  const [intentScore, setIntentScore] = useState([])
  const [filteredTopicList, setFilteredTopicList] = useState(IntentTopic)
  const [expandedCategories, setExpandedCategories] = useState([])
  const intentTopic = useSelector((state) => state.search.filter.intentTopic)
  const selectedIntentScore = useSelector((state) => state.search.filter.intentScore)
  const ref = useRef({ internalStateChange: false })
  const { isWhiteLabelUser } = useUserVerification()
  const numberOfAvailableTopics = useMemo(() => {
    let value
    switch (plan) {
      case 'free':
        value = 1
        break
      case 'starter':
      case 'starter-yearly':
        value = 2
        break
      case 'growth':
      case 'growth-yearly':
        value = 6
        break
      case 'infinity':
      case 'infinity-yearly':
        value = 12
        break
      case 'earlyBird':
        value = 6
        break
      default:
        value = 1
    }
    return isWhiteLabelUser ? topics : value
  }, [plan])

  const scores = ['High', 'Medium', 'Low', 'None']

  // const dispatch = useDispatch()
  const [topicCounts, setTopicCounts] = useState({})
  const [topicLoading, setTopicLoading] = useState({})
  const [totalCount, setTotalCount] = useState(0)
  const [isTotalLoading, setIsTotalLoading] = useState(false)
  const [findLeads] = useFindLeadsMutation()
  const [findCompanies] = useFindCompaniesMutation()

  useEffect(() => {
    if (clear) {
      clearAllSelections()
      setClear(false)
    }
  }, [clear, setClear])

  const formatCount = (count) => {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1)}M`
    } else if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`
    }
    return count.toLocaleString()
  }

  // Create a debounced function for API calls
  const fetchTopicCount = useCallback(
    debounce(async (topic, allSelected) => {
      try {
        // Set loading state for this topic
        setTopicLoading(prev => ({
          ...prev,
          [topic]: true
        }))

        const filter = {
          ...value,
          intentTopic: [topic]
        }

        let result
        if (alignment === 'People') {
          const { pagination } = await findLeads({
            start: 1,
            page_size: 1,
            query: filter
          }).unwrap()
          result = pagination.total_entries
        } else if (alignment === 'Companies') {
          const { pagination } = await findCompanies({
            start: 1,
            page_size: 1,
            query: filter
          }).unwrap()
          result = pagination.total_entries
        }

        // Update the count for this specific topic
        setTopicCounts(prev => ({
          ...prev,
          [topic]: result || 0
        }))

        // Clear loading state
        setTopicLoading(prev => ({
          ...prev,
          [topic]: false
        }))

        // If this is all selected topics, update the total count
        if (allSelected) {
          setTotalCount(result || 0)
        }
      } catch (error) {
        // Clear loading state on error
        setTopicLoading(prev => ({
          ...prev,
          [topic]: false
        }))
        if (allSelected) {
          setIsTotalLoading(false)
        }
        throw error
      }
    }, 300),
    [findLeads, findCompanies, alignment, value]
  )

  // Function to fetch count for all selected topics
  const fetchTotalCount = useCallback(
    debounce(async (topics) => {
      if (topics.length === 0) {
        setTotalCount(0)
        return
      }

      try {
        // Set loading state for total count
        setIsTotalLoading(true)

        const filter = {
          ...value,
          intentTopic: topics
        }

        let result
        if (alignment === 'People') {
          const { pagination } = await findLeads({
            start: 1,
            page_size: 1,
            query: filter
          }).unwrap()
          result = pagination.total_entries
        } else if (alignment === 'Companies') {
          const { pagination } = await findCompanies({
            start: 1,
            page_size: 1,
            query: filter
          }).unwrap()
          result = pagination.total_entries
        }

        setTotalCount(result || 0)
        setIsTotalLoading(false)
      } catch (error) {
        setIsTotalLoading(false)
        throw error
      }
    }, 300),
    [findLeads, findCompanies, alignment, value]
  )

  const handleTopicSelect = (topic, parentCategory) => {
    // If topic is already selected, allow deselection
    if (selectedTopics.includes(topic)) {
      const newSelected = selectedTopics.filter((t) => t !== topic)
      setSelectedTopics(newSelected)
      handleFilterChange && handleFilterChange('intentTopic', newSelected)
      // Update counts after deselection
      if (newSelected.length > 0) {
        fetchTotalCount(newSelected)
      } else {
        setTotalCount(0)
      }

      return
    }

    // If at max limit and trying to add a new topic
    if (selectedTopics.length >= numberOfAvailableTopics) {
      // For infinity and whitelabel users, show hint instead of upgrade modal
      if (plan === 'infinity' || plan === 'infinity-yearly' || plan === 'earlyBird' || isWhiteLabelUser) {
        toast.error(`You've reached your maximum limit of ${numberOfAvailableTopics} topics.`)
      } else {
        // For other users, show upgrade modal
        setIsPlanUpgradeModalOpen(true)
      }
      return
    }

    // If under the limit, add the topic
    const newSelected = [...selectedTopics, topic]
    setSelectedTopics(newSelected)
    handleFilterChange && handleFilterChange('intentTopic', newSelected)

    // Set loading state for the newly added topic
    setTopicLoading(prev => ({
      ...prev,
      [topic]: true
    }))

    // Fetch count for the newly added topic
    fetchTopicCount(topic, false)

    // Fetch total count for all selected topics
    fetchTotalCount(newSelected)
  }

  const filterTopics = (input) => {
    if (input === '') {
      setFilteredTopicList(IntentTopic)
      return
    }

    const searchTerm = input.toLowerCase()
    const newOptions = IntentTopic.reduce((acc, category) => {
      const matchingChildren = category.children.filter((child) =>
        child.toLowerCase().includes(searchTerm)
      )

      if (category.label.toLowerCase().includes(searchTerm) || matchingChildren.length > 0) {
        acc.push({
          ...category,
          children: matchingChildren.length > 0 ? matchingChildren : category.children
        })
      }
      return acc
    }, [])

    setFilteredTopicList(newOptions)
  }

  const handleScoreSelect = (score) => {
    const newScores = intentScore?.includes(score)
      ? intentScore?.filter((s) => s !== score)
      : [...intentScore, score]

    setIntentScore(newScores)
    handleFilterChange && handleFilterChange('intentScore', newScores)
  }

  const clearAllSelections = (e) => {
    e?.stopPropagation()
    setSelectedTopics([])
    setIntentScore([])
    setSearchQuery('')
    setTopicCounts({})
    setTotalCount(0)
    setIsTotalLoading(false)
    handleFilterChange && handleFilterChange('intentScore', [])
    handleFilterChange && handleFilterChange('intentTopic', [])
    ref.current.internalStateChange = true
  }
  useEffect(() => {
    if (ref.current.internalStateChange) {
      ref.current.internalStateChange = false
    }
  }, [selectedTopics, intentScore])
  useEffect(() => {
    setSelectedTopics(intentTopic || [])
    setIntentScore(selectedIntentScore || [])
  }, [intentTopic, selectedIntentScore])
  const totalSelections = intentScore?.length + selectedTopics?.length

  const handleCategorySelect = (category) => {
    // If all children are selected, deselect them
    if (category.children.every((child) => selectedTopics.includes(child))) {
      const newTopics = selectedTopics.filter((topic) => !category.children.includes(topic))
      setSelectedTopics(newTopics)
      handleFilterChange('intentTopic', newTopics) // Update Redux
    } else {
      // If some or none are selected, select all children
      const newTopics = [...selectedTopics]
      let reachedLimit = false

      category.children.forEach((child) => {
        if (!selectedTopics.includes(child) && newTopics.length < numberOfAvailableTopics) {
          newTopics.push(child)
        } else if (!selectedTopics.includes(child)) {
          reachedLimit = true
        }
      })

      // Show message if limit reached during bulk selection
      if (reachedLimit) {
        if (plan === 'infinity' || plan === 'infinity-yearly' || plan === 'earlyBird' || isWhiteLabelUser) {
          toast.error(`You've reached your maximum limit of ${numberOfAvailableTopics} topics.`)
        } else {
          toast.error(`Maximum ${numberOfAvailableTopics} topics allowed. Upgrade to add more.`)
        }
      }

      setSelectedTopics(newTopics)
      handleFilterChange('intentTopic', newTopics) // Update Redux
    }
  }
  const preview = (
    <>
      {intentScore?.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
          <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
          <Typography
            sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}
          >
            Intent Score
          </Typography>
          {intentScore?.map((score) => (
            <Tag
              key={score}
              label={score}
              onDelete={(e) => {
                e.stopPropagation()
                handleScoreSelect(score)
              }}
            />
          ))}
        </Box>
      )}
      {selectedTopics?.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center', marginTop: '8px' }}>
          <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
          <Typography
            sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}
          >
            Intent Topics
          </Typography>
          {selectedTopics.map((topic) => (
            <Tag
              key={topic}
              label={`${topic}${topicCounts[topic] ? ` (${topicCounts[topic].toLocaleString()})` : ''}`}
              onDelete={(e) => {
                e.stopPropagation()
                handleTopicSelect(topic)
              }}
            />
          ))}
        </Box>
      )}
    </>
  )

  return (
    <>
      <FilterDropdown
        title='Buying Intent'
        filterCount={totalSelections}
        clearAll={clearAllSelections}
        icon={<BuyingIntentIcon sx={{ stroke: `${theme.palette.primary.main}` }} />}
        preview={preview}
        isOpen={isOpen}
        onToggle={onToggle}
      >
        <>
          {/* Intent Topics Section */}
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1.5
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 700, color: '#111927' }}>
                  Intent Topics
                </Typography>
                {/* <InfoIcon sx={{ fontSize: 16, color: '#6C737F' }} /> */}
                <InfoIcon sx={{ fontSize: 16 }} />
              </Box>
              {selectedTopics?.length > 0 && (
                <Button
                  sx={{ color: `${theme.palette.primary.main}`, textTransform: 'none', fontSize: '13px', fontWeight: 700, p: 0, minWidth: 0 }}
                  onClick={clearAllSelections}
                >
                  Clear
                </Button>
              )}
            </Box>

            {selectedTopics?.length === 0
              ? (
                <Button
                  fullWidth
                  variant='contained'
                  onClick={() => setIsTopicModalOpen(true)}
                  sx={{
                    maxHeight: '42px',
                    bgcolor: `${theme.palette.primary.main}`,
                    color: 'white',
                    border: 'none',
                    borderRadius: '12px',
                    textTransform: 'none',
                    py: 1.5,
                    '&:hover': { bgcolor: `${theme.palette.primary.dark}` }
                  }}
                >
                  Add Intent Topic
                </Button>
              )
              : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                    <Stack direction='row' gap={0.5} flexWrap='wrap'>
                      {selectedTopics?.map((topic) => (
                        <Tag key={topic} label={topic} onDelete={(e) => { handleTopicSelect(topic) }} />
                      ))}
                    </Stack>
                    <Button
                      onClick={() => setIsTopicModalOpen(true)}
                      sx={{ color: `${theme.palette.primary.main}`, textTransform: 'none', fontSize: '13px', fontWeight: 700, p: 0, minWidth: 'auto' }}
                    >
                      Edit
                    </Button>
                  </Box>

                </Box>
              )}
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Intent Score Section */}
          <Box sx={{ mt: 1 }}>
            <Stack direction='row' justifyContent='space-between'>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1.5 }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 700, color: '#111927' }}>Intent Score</Typography>
                {/* <InfoIcon sx={{ fontSize: 16, color: '#6C737F' }} /> */}
              </Box>

              {(!isWhiteLabelUser && plan !== 'infinity' && plan !== 'infinity-yearly' && plan !== 'earlyBird') && (
                <UpgradeButton
                  buttonText='Upgrade'
                  setIsPlanUpgradeModalOpen={setIsPlanUpgradeModalOpen}
                  UpgradePlanIcon={UpgradePlanIcon}
                />
              )}
            </Stack>
            {scores.map((score) => (
              <Box
                key={score}
                onClick={() => {
                  if (selectedTopics?.length > 0) {
                    handleScoreSelect(score)
                  } else {
                    toast.error('Please select the Intent Topic first')
                  }
                }}
                // onClick={() => handleScoreSelect(score)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: 1,
                  '&:hover': { bgcolor: '#F8F9FB' }
                }}
              >
                <Checkbox
                  icon={checkboxOfIcon}
                  checkedIcon={checkboxOnIcon}
                  checked={intentScore?.includes(score)}
                  sx={{
                    color: '#D7D9E6',
                    '&.Mui-checked': {
                      color: '#3F4FF8'
                    }
                  }}
                />
                <Typography sx={{ fontSize: '13px', fontWeight: 500, color: `${theme.palette.primary.primaryText}` }}>{score}</Typography>
              </Box>
            ))}

            {(!isWhiteLabelUser && plan !== 'infinity' && plan !== 'infinity-yearly' && plan !== 'earlyBird') && (
              <UpgradeButton
                setIsPlanUpgradeModalOpen={setIsPlanUpgradeModalOpen}
                UpgradePlanIcon={UpgradePlanIcon}
                buttonText='Upgrade to add more'
                width='100%'
              />
            )}
          </Box>

        </>
      </FilterDropdown>
      {/* Topic Selection Dialog */}
      <Dialog
        open={isTopicModalOpen}
        onClose={() => setIsTopicModalOpen(false)}
        maxWidth='sm'
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '8px',
            maxHeight: '90vh'
          }
        }}
      >
        <DialogTitle sx={{ position: 'relative', pb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 700, color: '#101010' }}>Intent Topics</Typography>
              {/* <InfoIcon sx={{ fontSize: 18, color: '#6C737F' }} /> */}
              <InfoIcon sx={{ fontSize: 16 }} />
            </Box>
            <IconButton
              onClick={() => setIsTopicModalOpen(false)}
              sx={{ position: 'absolute', right: 16, top: 8 }}
            >
              <Close sx={{ fontSize: '20px', color: '#101010' }} />
            </IconButton>
          </Box>
          <Box sx={{ height: '0px', border: '1px solid', borderColor: '#E4E4E5', mx: 'auto', mt: '10px' }} />
          <Typography sx={{ color: '#6C737F', fontSize: '13px', fontWeight: 400, mt: '16px' }}>
            Capture an intent signal if someone at a company engages with any of these topics:
          </Typography>
        </DialogTitle>
        {/* Serch topic box  */}
        <Box sx={{ mb: 2 }}>
          <Paper
            variant='outlined'
            sx={{
              p: 1.5,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              bgcolor: '#fff',
              border: '1px solid #E4E4E5',
              borderRadius: 2,
              mx: 'auto',
              maxWidth: '584px'
            }}
          >
            <SearchIcon sx={{ color: '#6C737F' }} />
            <InputBase
              placeholder='Search topic...'
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value)
                filterTopics(e.target.value)
              }}
              fullWidth
              sx={{ fontSize: '14px' }}
            />
          </Paper>
        </Box>

        <Box sx={{
          height: '400px',
          maxWidth: '584px',
          border: '1px solid #E4E4E5',
          borderRadius: 2,
          ml: '8px',
          padding: '6px',
          overflowY: 'scroll', // Enable vertical scrolling
          '&::-webkit-scrollbar': {
            display: 'none' // Hides scrollbar in Webkit-based browsers (Chrome, Edge, Safari)
          },
          '-ms-overflow-style': 'none', // Hides scrollbar in Internet Explorer
          'scrollbar-width': 'none' // Hides scrollbar in Firefox
        }}
        >

          {
            (
              filteredTopicList.map((category) => (
                <Box
                  key={category.label}
                  sx={{
                    mb: 1,
                    backgroundColor: '#F8F9FB',
                    borderRadius: '8px'
                  }}
                >
                  {/* Category Header */}
                  <Box
                    onClick={() => {
                      setExpandedCategories((prev) =>
                        prev.includes(category.label)
                          ? prev.filter((c) => c !== category.label)
                          : [...prev, category.label]
                      )
                    }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 2,
                      cursor: 'pointer',
                      height: '40px',
                      borderRadius: '8px'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      {expandedCategories.includes(category.label)
                        ? (
                          <KeyboardArrowDown />
                        )
                        : (
                          <KeyboardArrowRight />
                        )}
                      <Checkbox
                        checked={
                          category.children &&
                          category.children.length > 0 &&
                          category.children.every((child) => selectedTopics.includes(child))
                        }
                        indeterminate={
                          category.children &&
                          category.children.length > 0 &&
                          category.children.some((child) => selectedTopics.includes(child)) &&
                          !category.children.every((child) => selectedTopics.includes(child))
                        }
                        onChange={() => handleCategorySelect(category)}
                        sx={{
                          color: '#D7D9E6',
                          '&.Mui-checked': {
                            color: '#3F4FF8'
                          }
                        }}
                      />

                      <Typography sx={{ fontSize: '13px', fontWeight: 700 }}>{category.label}</Typography>
                      <Typography variant='body2' color='text.secondary'>
                        ({category.children?.length || 0})
                      </Typography>
                    </Box>
                  </Box>

                  {/* Category Children */}
                  {expandedCategories.includes(category.label) && category.children && (
                    <Box sx={{ pl: 6, bgcolor: '#fff' }}>
                      {category.children
                        .filter((child) =>
                          searchQuery ? child.toLowerCase().includes(searchQuery.toLowerCase()) : true
                        )
                        .map((child) => (
                          <Box
                            key={child}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              height: '40px',
                              borderRadius: '8px',
                              p: 1.5,
                              cursor: 'pointer',
                              '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' }
                            }}
                            onClick={() => handleTopicSelect(child, category.label)}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Checkbox
                                checked={selectedTopics.includes(child)}
                                onChange={() => handleTopicSelect(child, category.label)}
                              />
                              <Typography sx={{ fontSize: '13px', fontWeight: 500, color: `${theme.palette.primary.primaryText}` }}>{child}</Typography>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  )}
                </Box>
              ))
            )

          }
        </Box>

        {/* Selected Items Display */}
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            bgcolor: theme.palette.primary.contrastText,
            borderTop: '1px solid #E4E4E5',
            p: 2,
            zIndex: 1,
            mt: 2,
            maxWidth: '584px',
            border: '1px solid #E4E4E5',
            borderRadius: 2,
            ml: '8px'
          }}
        >
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
          >
            <Typography sx={{ color: '#101010', fontSize: '13px', fontWeight: 600 }}>
              Selected ({selectedTopics?.length}/{numberOfAvailableTopics})
              {selectedTopics?.length > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <PersonIcon sx={{ fontSize: 14, color: '#6C737F' }} />
                  {isTotalLoading
                    ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size={12} sx={{ color: theme.palette.primary.main }} />
                      </Box>
                      )
                    : (
                      <Typography sx={{ color: '#6C737F', fontSize: '12px' }}>
                        {formatCount(totalCount)}
                      </Typography>
                      )}
                </Box>
              )}
            </Typography>
            {selectedTopics?.length > 0 && (
              <Button onClick={clearAllSelections} sx={{ color: `${theme.palette.primary.main}`, textTransform: 'none', fontSize: '13px', fontWeight: 700 }}>
                Clear
              </Button>
            )}
          </Box>
          <Box
            sx={{
              p: 2,
              borderRadius: '8px',
              height: '70px',
              maxHeight: '70px',
              overflow: 'auto', // Enable scrolling
              bgcolor: selectedTopics?.length === 0 ? '#F2F4F6' : '#FFF'
            }}
          >
            {selectedTopics?.length === 0
              ? (
                <Typography sx={{ color: '#6C737F', fontSize: '14px', fontWeight: 500 }}>No topic selected yet...</Typography>
              )
              : (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1,
                    maxHeight: '60px',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '8px'
                    },
                    '&::-webkit-scrollbar-track': {
                      background: '#f1f1f1',
                      borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#888',
                      borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#555'
                    }
                  }}
                >
                  {selectedTopics?.map((topic) => (
                    <Tag
                      key={topic}
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <Typography>{topic}</Typography>
                          {topicLoading[topic]
                            ? (
                              <CircularProgress size={10} sx={{ color: '#fff', ml: 0.5 }} />
                              )
                            : topicCounts[topic]
                              ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                  <PersonIcon sx={{ fontSize: 12, color: '#fff' }} />
                                  <Typography sx={{ fontSize: '11px' }}>
                                    {formatCount(topicCounts[topic])}
                                  </Typography>
                                </Box>
                                )
                              : null}
                        </Box>
                      }
                      onDelete={() => { handleTopicSelect(topic) }}
                    />
                  ))}
                </Box>
              )}
          </Box>
        </Box>
        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button
            variant='outlined'
            onClick={() => {
              clearAllSelections()
              setIsTopicModalOpen(false)
            }}
            sx={{
              fontSize: '13px',
              fontWeight: 700,
              color: '#101010',
              borderColor: '#D7D9E6',
              '&:hover': {
                borderColor: '#B8BCC8',
                bgcolor: 'rgba(0,0,0,0.04)'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => setIsTopicModalOpen(false)}
            sx={{
              width: '144px',
              fontSize: '14px',
              fontWeight: 700,
              bgcolor: `${theme.palette.primary.main}`,
              '&:hover': {
                bgcolor: `${theme.palette.primary.dark}`
              }
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upgrade Dialog */}
      <Dialog
        open={isPlanUpgradeModalOpen}
        onClose={() => setIsPlanUpgradeModalOpen(false)}
        maxWidth='lg'
        fullWidth
      >
        <UpgradeModal currentPlan={plan} closeModal={() => setIsPlanUpgradeModalOpen(false)} />
      </Dialog>
    </>
  )
}

export default BuyingIntent
