import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'src/utils/base-query.js'

export const customPlanApi = createApi({
  reducerPath: 'customPlanApi',
  tagTypes: ['CUSTOMPLAN'],
  baseQuery,
  endpoints: (builder) => ({
    getCustomPlans: builder.query({
      query: (params) => {
        return {
          method: 'GET',
          url: 'custom-plan-details/get-plan',
          params
        }
      }
    }),
    getCustomPlanById: builder.query({
      query: (id) => {
        return {
          method: 'GET',
          url: `custom-plan-details/${id}`
        }
      }
    }),
    createCustomPlan: builder.mutation({
      query: (data) => ({
        method: 'POST',
        url: 'custom-plan-details/add-plan',
        body: data
      }),
      invalidatesTags: ['CUSTOMPLAN']
    }),
    updateCustomPlan: builder.mutation({
      query: ({ _id, ...data }) => ({
        method: 'PUT',
        url: `custom-plan-details/update-plan/${_id}`,
        body: data
      }),
      invalidatesTags: ['CUSTOMPLAN']
    }),
    deleteCustomPlan: builder.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `custom-plan-details/delete-plan/${id}`
      }),
      invalidatesTags: ['CUSTOMPLAN']
    })

  })
})

export const { useGetCustomPlansQuery, useCreateCustomPlanMutation, useUpdateCustomPlanMutation, useDeleteCustomPlanMutation, useGetCustomPlanByIdQuery } = customPlanApi
