export const employeeCountRangeData = [
  { min: 'Unknown', max: 'Unknown' },
  { min: '1', max: '10' },
  { min: '21', max: '50' },
  { min: '100', max: '200' },
  { min: '201', max: '500' },
  { min: '501', max: '1000' },
  { min: '2001', max: '5000' },
  { min: '5001', max: '10000' },
  { min: '10001', max: Infinity }
]

export const technologies = [
  'Programming Languages',
  'JavaScript',
  'Python',
  'Java',
  'Ruby',
  'PHP',
  'C#',
  'Go',
  'Swift',
  'Kotlin',
  'TypeScript',
  'Web Technologies & Frameworks',
  'React',
  'Angular',
  'Vue.js',
  'jQuery',
  'Django',
  'Flask',
  'Ruby on Rails',
  'Laravel',
  'Spring',
  'ASP.NET',
  'Databases',
  'MySQL',
  'PostgreSQL',
  'MongoDB',
  'Redis',
  'SQLite',
  'Elasticsearch',
  'Cassandra',
  'DynamoDB',
  'Firebase',
  'Cloud Services',
  'AWS',
  'Google Cloud',
  'Azure',
  'Heroku',
  'DigitalOcean',
  'Cloudflare',
  'Kubernetes',
  'Docker',
  'DevOps & CI/CD',
  'Jenkins',
  'Travis CI',
  'CircleCI',
  'GitLab CI',
  'Ansible',
  'Terraform',
  'Chef',
  'Puppet',
  'GitHub Actions',
  'CRM & Marketing Tools',
  'Salesforce',
  'HubSpot',
  'Marketo',
  'Pipedrive',
  'Zoho CRM',
  'Intercom',
  'E-commerce Platforms',
  'Shopify',
  'WooCommerce',
  'Magento',
  'BigCommerce',
  'Mobile Development',
  'React Native',
  'Flutter',
  'Ionic',
  'Swift',
  'Kotlin',
  'Xamarin',
  'Analytics & BI Tools',
  'Google Analytics',
  'Tableau',
  'Power BI',
  'Mixpanel',
  'Segment',
  'Looker',
  'Email & Communication Platforms',
  'SendGrid',
  'Mailchimp',
  'Twilio',
  'Slack',
  'Zendesk',
  'Content Management Systems (CMS)',
  'WordPress',
  'Drupal',
  'Joomla',
  'Contentful',
  'Wix',
  'Cybersecurity Tools',
  'Cloudflare',
  'Palo Alto Networks',
  'Norton',
  'Okta',
  'Splunk',
  'Citrix'
]

export const companies = [
  { label: 'Google', domain: 'google.com', secondaryLabel: 'google.com', img: 'https://logo.url/google' },
  { label: 'Apple', domain: 'apple.com', secondaryLabel: 'apple.com', img: 'https://logo.url/apple' },
  { label: 'Amazon', domain: 'amazon.com', secondaryLabel: 'amazon.com', img: 'https://logo.url/amazon' },
  { label: 'Success.ai', domain: 'success.ai', secondaryLabel: 'success.ai', img: 'https://logo.url/success' },
  { label: 'Microsoft', domain: 'microsoft.com', secondaryLabel: 'microsoft.com', img: 'https://logo.url/microsoft' }
]

export const industries = [
  'Accounting',
  'Agriculture',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel & Fashion',
  'Architecture & Planning',
  'Arts & Crafts',
  'Automotive',
  'Aviation & Aerospace',
  'Banking',
  'Biotechnology',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies & Equipment',
  'Calibration',
  'Capital Markets',
  'Chemical',
  'Civic & Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer & Network Security',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense & Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Entertainment',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food & Beverages',
  'Food Production',
  'Fund-Raising',
  'Furniture',
  'Gambling & Casinos',
  'Glass, Ceramics & Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design',
  'Health, Wellness & Fitness',
  'Higher Education',
  'Hospitals and Health Care',
  'Hospitality',
  'Human Resources',
  'Import & Export',
  'Individual & Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology & Services',
  'Insurance',
  'International Affairs',
  'International Trade & Development',
  'Internet',
  'Investment Banking',
  'Investment Management',
  'Judiciary',
  'Law Enforcement',
  'Law Practice',
  'Legal Services',
  'Legislative Office',
  'Leisure, Travel & Tourism',
  'Libraries',
  'Logistics & Supply Chain',
  'Luxury Goods & Jewelry',
  'Machinery',
  'Managed Service Providers',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing & Advertising',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Devices',
  'Medical Practices',
  'Mental Health Care',
  'Military',
  'Mining & Metals',
  'Motion Pictures & Film',
  'Museums & Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers',
  'Nonprofit Organization Management',
  'Oil & Energy',
  'Online Media',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging & Containers',
  'Paper & Forest Products',
  'Performing Arts',
  'Pharmaceutical',
  'Paralegal',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training & Coaching',
  'Program Development',
  'Public Policy',
  'Public Relations & Communications',
  'Public Safety',
  'Publishing',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate',
  'Recreational Facilities & Services',
  'Religious Institutions',
  'Renewables & Environment',
  'Research',
  'Restaurants',
  'Retail',
  'Security & Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing & Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation & Localization',
  'Transportation/Trucking/Railroad',
  'Utilities',
  'Venture Capital & Private Equity',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine & Spirits',
  'Wireless',
  'Writing & Editing'
]

export const titleRole = {
  jobTitles: [
    'manager',
    'project manager',
    'teacher',
    'owner',
    'student',
    'director',
    'software engineer',
    'consultant',
    'account manager',
    'engineer',
    'professor',
    'sales manager',
    'sales',
    'partner',
    'associate',
    'president',
    'administrative assistant',
    'supervisor',
    'general manager',
    'realtor',
    'chief executive officer (ceo)',
    'chief operating officer (coo)',
    'chief financial officer (cfo)',
    'chief marketing officer (cmo)',
    'chief technology officer (cto)',
    'chief information officer (cio)',
    'chief sales officer (cso)',
    'chief revenue officer (cro)',
    'senior management',
    'vice president of sales',
    'vice president of marketing',
    'vice president of operations',
    'vice president of finance',
    'vice president of technology',
    'vice president of business development',
    'mid-level management',
    'sales director',
    'marketing director',
    'operations director',
    'finance director',
    'product manager',
    'business development manager',
    'it manager',
    'customer success manager',
    'specialized roles',
    'lead generation specialist',
    'digital marketing manager',
    'content marketing manager',
    'data analyst',
    'sales enablement manager',
    'product marketing manager',
    'brand manager',
    'administrative and support',
    'executive assistant',
    'office manager',
    'sales and business development',
    'account executive',
    'sales operations manager',
    'sales development representative (sdr)',
    'business development representative (bdr)',
    'regional sales manager',
    'enterprise sales manager',
    'channel sales manager',
    'sales strategy manager',
    'marketing',
    'growth marketing manager',
    'digital advertising specialist',
    'seo specialist',
    'ppc specialist',
    'email marketing manager',
    'marketing operations manager',
    'marketing analytics manager',
    'event marketing manager',
    'social media manager',
    'public relations manager',
    'product and development',
    'product development manager',
    'product owner',
    'product director',
    'ux/ui designer',
    'technical product manager',
    'engineering manager',
    'head of product',
    'innovation manager',
    'finance and administration',
    'controller',
    'treasurer',
    'finance manager',
    'accounting manager',
    'financial analyst',
    'audit manager',
    'compliance officer',
    'operations',
    'operations manager',
    'supply chain manager',
    'logistics manager',
    'procurement manager',
    'facilities manager',
    'quality assurance manager',
    'customer and support',
    'customer support manager',
    'customer experience manager',
    'client success manager',
    'technical support specialist',
    'customer service representative',
    'human resources',
    'hr director',
    'hr manager',
    'talent acquisition manager',
    'recruitment specialist',
    'learning and development manager',
    'compensation and benefits manager',
    'technology and it',
    'it director',
    'network administrator',
    'systems administrator',
    'database administrator',
    'cloud solutions architect',
    'it support specialist',
    'cybersecurity specialist',
    'legal and compliance',
    'general counsel',
    'corporate counsel',
    'compliance manager',
    'legal advisor',
    'contract manager'
  ],
  managementLevel: [
    'owner',
    'founder',
    'c-suite',
    'partner',
    'vp',
    'head',
    'director',
    'manager',
    'senior',
    'entry',
    'intern'
  ],
  department: {
    'c-suite': [
      'Finance Executive',
      'Information Technology Executive',
      'Legal Executive',
      'Marketing Executive',
      'Operations Executive'
    ],
    communications: [
      'Master Sales',
      'Public Relations'
    ],
    executive: [
      'Business Intelligence',
      'Corporate Strategy',
      'Human Resources Executive',
      'Product Management',
      'Research & Development'
    ],
    finance: [
      'Accounting',
      'Financial Planning & Analysis',
      'Investor Relations'
    ],
    founder: [
      'Founder'
    ],
    operations: [
      'Customer Service / Support',
      'Logistics',
      'Support / Technical Services',
      'Test / Quality Assurance'
    ]
  }
}

export const languages = [
  'Abkhazian',
  'Afar',
  'Afrikaans',
  'Akan',
  'Albanian',
  'Amharic',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Assamese',
  'Avaric',
  'Avestan',
  'Aymara',
  'Azerbaijani',
  'Bambara',
  'Bashkir',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bihari languages',
  'Bislama',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan, Valencian',
  'Central Khmer',
  'Chamorro',
  'Chechen',
  'Chichewa, Chewa, Nyanja',
  'Chinese',
  'Church Slavonic, Old Bulgarian, Old Church Slavonic',
  'Chuvash',
  'Cornish',
  'Corsican',
  'Cree',
  'Croatian',
  'Czech',
  'Danish',
  'Divehi, Dhivehi, Maldivian',
  'Dutch, Flemish',
  'Dzongkha',
  'English',
  'Esperanto',
  'Estonian',
  'Ewe',
  'Faroese',
  'Fijian',
  'Finnish',
  'French',
  'Fulah',
  'Gaelic, Scottish Gaelic',
  'Galician',
  'Ganda',
  'Georgian',
  'German',
  'Gikuyu, Kikuyu',
  'Greek (Modern)',
  'Greenlandic, Kalaallisut',
  'Guarani',
  'Gujarati',
  'Haitian, Haitian Creole',
  'Hausa',
  'Hebrew',
  'Herero',
  'Hindi',
  'Hiri Motu',
  'Hungarian',
  'Icelandic',
  'Ido',
  'Igbo',
  'Indonesian',
  'Interlingua (International Auxiliary Language Association)',
  'Interlingue',
  'Inuktitut',
  'Inupiaq',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kannada',
  'Kanuri',
  'Kashmiri',
  'Kazakh',
  'Kinyarwanda',
  'Komi',
  'Kongo',
  'Korean',
  'Kwanyama, Kuanyama',
  'Kurdish',
  'Kyrgyz',
  'Lao',
  'Latin',
  'Latvian',
  'Letzeburgesch, Luxembourgish',
  'Limburgish, Limburgan, Limburger',
  'Lingala',
  'Lithuanian',
  'Luba-Katanga',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Manx',
  'Maori',
  'Marathi',
  'Marshallese',
  'Moldovan, Moldavian, Romanian',
  'Mongolian',
  'Nauru',
  'Navajo, Navaho',
  'Northern Ndebele',
  'Ndonga',
  'Nepali',
  'Northern Sami',
  'Norwegian',
  'Norwegian Bokmål',
  'Norwegian Nynorsk',
  'Nuosu, Sichuan Yi',
  'Occitan (post 1500)',
  'Ojibwa',
  'Oriya',
  'Oromo',
  'Ossetian, Ossetic',
  'Pali',
  'Panjabi, Punjabi',
  'Pashto, Pushto',
  'Persian',
  'Polish',
  'Portuguese',
  'Quechua',
  'Romansh',
  'Rundi',
  'Russian',
  'Samoan',
  'Sango',
  'Sanskrit',
  'Sardinian',
  'Serbian',
  'Shona',
  'Sindhi',
  'Sinhala, Sinhalese',
  'Slovak',
  'Slovenian',
  'Somali',
  'Sotho, Southern',
  'South Ndebele',
  'Spanish, Castilian',
  'Sundanese',
  'Swahili',
  'Swati',
  'Swedish',
  'Tagalog',
  'Tahitian',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tigrinya',
  'Tonga (Tonga Islands)',
  'Tsonga',
  'Tswana',
  'Turkish',
  'Turkmen',
  'Twi',
  'Uighur, Uyghur',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Venda',
  'Vietnamese',
  'Volap_k',
  'Walloon',
  'Welsh',
  'Western Frisian',
  'Wolof',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zhuang, Chuang',
  'Zulu'
]
