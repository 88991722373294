import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
  useTheme,
  InputAdornment,
  IconButton,
  Checkbox,
  ButtonBase
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useState, useEffect } from 'react'
import {
  setAuthToken,
  useRegisterMutation
} from 'src/services/auth-service.js'
import * as Yup from 'yup'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import SuccessAiBlueLogo from 'src/assets/successAiBlueLogo.svg'
import { useLazyGetMeQuery } from 'src/services/user-service'
import { setTheme, setSEODetails, useCheckDomainQuery } from 'src/services/theme-service'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import Loader from 'src/utils/loader'
import TermsAndConditionsModal from './termsAndConditionsModal'
import PrivacyPolicyModal from './privacyPolicyModal'
import { s3ImageURL } from 'src/config'

const WhiteLabelRegisterPage = () => {
  let currentDomain
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [register] = useRegisterMutation()
  const [getMe, { data }] = useLazyGetMeQuery()
  const [queryParameters] = useSearchParams()
  const theme = useTheme()
  const encodedPartnerKey = queryParameters.get('ps_partner_key')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [openTermsAndConditionsModal, setOpenTermsAndConditionsModal] = useState(false)
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false)
  const [whiteLabelTheme, setWhiteLabelTheme] = useState(null)
  const companyName = whiteLabelTheme?.companyName || 'Success.ai'
  const companyWebsite = whiteLabelTheme?.companyWebsite || 'www.success.ai'
  const supportEmail = whiteLabelTheme?.supportEmail || 'support@success.ai'

  const [searchParams] = useSearchParams()
  const invitedEmail = searchParams.get('email')

  // for localhost
  // currentDomain = searchParams.get('domain')

  if (typeof window !== 'undefined') {
    currentDomain = window.location.hostname
  }

  const { data: whiteLabelUser } = useCheckDomainQuery({ domain: currentDomain })
  const validationSchema = () => {
    const schema = Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required')
        .test('no-plus', 'Please add a valid email address', (value) => {
          return !value || !value.includes('+')
        }),
      name: Yup.object({
        first: Yup.string(),
        last: Yup.string()
      }),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .max(255)
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
        .required('Password is required'),
      confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
      terms: Yup.bool().oneOf([true], 'You must accept the Terms and Conditions')
    })

    return schema
  }

  useEffect(() => {
    setWhiteLabelTheme(whiteLabelUser?.data?.theme)
    if (whiteLabelUser?.data?.seo) {
      dispatch(setSEODetails({ ...whiteLabelUser?.data?.seo }))
      dispatch(setTheme({ ...whiteLabelUser?.data?.theme }))
    }
  }, [whiteLabelUser])

  const formik = useFormik({
    initialValues: {
      name: {
        first: '',
        last: ''
      },
      email: invitedEmail || '',
      password: '',
      confirmPassword: '',
      terms: false
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const { terms, ...submissionData } = values
        await handleSignUp(submissionData, helpers)
      } catch (err) {
        helpers.setErrors({ submit: err.response?.data?.error?.message || err?.message })
      }
    }
  })

  useEffect(() => {
    if (data) {
      toast.success('Successfully logged in')
      navigate('/accounts')
    }
  }, [data])

  const handleSignUp = async (values, helpers) => {
    let stripeCustomerId
    try {
      const { confirmPassword, ...rest } = values
      values = { partnerAccountId: whiteLabelUser?.data?.createdBy, ...rest }
      const result = await register(values).unwrap();
      ({ stripeCustomerId } = result)
      setAuthToken(result.authToken)
      getMe()
      /*****
                                     * Store data in growsumo and createSignup method call for create a customer
                                     *****/
      /* eslint-disable no-undef */
      growsumo.data.name = `${values?.name?.first} ${values?.name?.last}`
      growsumo.data.email = values?.email
      growsumo.data.customer_key = values?.email
      growsumo.data.partner_key = atob(encodedPartnerKey)
      growsumo.data.provider_key = stripeCustomerId
      if (stripeCustomerId && encodedPartnerKey) {
        growsumo.createSignup()
      }
      /* eslint-disable no-undef */
      const encodedEmail = window.btoa(values.email)

      navigate(`/signup-confirmation?token=${encodedEmail}`)
    } catch (err) {
      helpers.setErrors({ submit: err.data?.error.message })
    }
  }

  const againLogin = async () => {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      window.localStorage.clear()
    }
    navigate('/w-login')
  }

  return (
    <>
      {
        !whiteLabelTheme
          ? (
            <Loader />
            )
          : (
            <Box
              sx={{
                width: '100%',
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'flex-start',
                backgroundColor: '#F2F4F6'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  paddingBlock: '24px',
                  paddingInline: '5%',
                  backgroundColor: theme.palette.background.default
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    maxWidth: '550px',
                    width: '100%',
                    marginInline: 'auto'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: '16px',
                      paddingTop: '16px',
                      alignItems: 'center'
                    }}
                  >
                    <Box>
                      {whiteLabelTheme?.logo
                        ? <img src={s3ImageURL + whiteLabelTheme?.logo} style={{ height: '36px', width: 'auto' }} />
                        : <img src={SuccessAiBlueLogo} style={{ height: '36px', width: 'auto' }} />}
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        borderRadius: '12px',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '26px',
                            fontWeight: 600,
                            color: '#101010'
                          }}
                        >
                          Create a new account
                        </Typography>
                      </Box>
                      <form noValidate onSubmit={formik.handleSubmit}>
                        <Grid container columnSpacing={2}>
                          <Grid item xs={12}>
                            {' '}
                            <Typography
                              sx={{
                                width: '100%',
                                textAlign: 'left',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                color: '#101010',
                                mt: 2
                              }}
                            >
                              First name *
                            </Typography>
                            <TextField
                              variant='outlined'
                              error={!!(formik.touched.name?.first && formik.errors.name?.first)}
                              fullWidth
                              helperText={formik.touched.name?.first && formik.errors.name?.first}
                              name='name.first'
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={
                              formik?.values?.name?.first
                            }
                              placeholder='Enter first name'
                              sx={{
                                mt: 1,
                                width: '100%',
                                backgroundColor: 'white',
                                '& div': { pl: 0.3 },
                                '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                                '& div input': {
                                  py: 1.5,
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  letterSpacing: '0em',
                                  '&::placeholder': {
                                    color: 'rgba(40, 40, 123, 0.5)'
                                  }
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            {' '}
                            <Typography
                              sx={{
                                width: '100%',
                                textAlign: 'left',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                color: '#101010',
                                mt: 2
                              }}
                            >
                              Last name *
                            </Typography>
                            <TextField
                              variant='outlined'
                              error={!!(formik.touched.name?.last && formik.errors.name?.last)}
                              fullWidth
                              helperText={formik.touched.name?.last && formik.errors.name?.last}
                              name='name.last'
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik?.values?.name?.last}
                              placeholder='Enter last name'
                              sx={{
                                mt: 1,
                                width: '100%',
                                backgroundColor: 'white',
                                '& div': { pl: 0.3 },
                                '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                                '& div input': {
                                  py: 1.5,
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  letterSpacing: '0em',
                                  '&::placeholder': {
                                    color: 'rgba(40, 40, 123, 0.5)'
                                  }
                                }
                              }}
                            />
                          </Grid>
                          <Grid xs={12} item>
                            {' '}
                            <Typography
                              sx={{
                                width: '100%',
                                textAlign: 'left',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                color: '#101010',
                                mt: 2
                              }}
                            >
                              Email *
                            </Typography>
                            <TextField
                              variant='outlined'
                              error={!!(formik.touched.email && formik.errors.email)}
                              fullWidth
                              helperText={formik.touched.email && formik.errors.email}
                              name='email'
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              type='email'
                              value={formik.values.email}
                              placeholder='Enter email'
                              disabled={!!invitedEmail}
                              sx={{
                                mt: 1,
                                width: '100%',
                                backgroundColor: 'white',
                                '& div': { pl: 0.3 },
                                '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                                '& div input': {
                                  py: 1.5,
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  letterSpacing: '0em',
                                  '&::placeholder': {
                                    color: 'rgba(40, 40, 123, 0.5)'
                                  }
                                }
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            {' '}
                            <Typography
                              sx={{
                                width: '100%',
                                textAlign: 'left',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                color: '#101010',
                                mt: 2
                              }}
                            >
                              Password *
                            </Typography>
                            <TextField
                              variant='outlined'
                              error={!!(formik.touched.password && formik.errors.password)}
                              fullWidth
                              helperText={formik.touched.password && formik.errors.password}
                              name='password'
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              type={showPassword ? 'text' : 'password'}
                              value={formik.values.password}
                              placeholder='Enter password'
                              sx={{
                                mt: 1,
                                width: '100%',
                                backgroundColor: 'white',
                                '& div': { pl: 0.3 },
                                '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                                '& div input': {
                                  py: 1.5,
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  letterSpacing: '0em',
                                  '&::placeholder': {
                                    color: 'rgba(40, 40, 123, 0.5)'
                                  }
                                },
                                '& .MuiFormHelperText-root': { textAlign: 'right', mx: 0 }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      onClick={() => setShowPassword(!showPassword)}
                                      edge='end'
                                      aria-label='toggle password visibility'
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            {' '}
                            <Typography
                              sx={{
                                width: '100%',
                                textAlign: 'left',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                color: '#101010',
                                mt: 2
                              }}
                            >
                              Confirm password *
                            </Typography>
                            <TextField
                              variant='outlined'
                              error={!!(formik.touched.password && formik.errors.confirmPassword)}
                              fullWidth
                              helperText={formik.touched.password && formik.errors.confirmPassword}
                              name='confirmPassword'
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              type={showConfirmPassword ? 'text' : 'password'}
                              value={formik.values.confirmPassword}
                              placeholder='Confirm password'
                              sx={{
                                mt: 1,
                                width: '100%',
                                backgroundColor: 'white',
                                '& div': { pl: 0.3 },
                                '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                                '& div input': {
                                  py: 1.5,
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  letterSpacing: '0em',
                                  '&::placeholder': {
                                    color: 'rgba(40, 40, 123, 0.5)'
                                  }
                                },
                                '& .MuiFormHelperText-root': { textAlign: 'right', mx: 0 }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                      edge='end'
                                      aria-label='toggle password visibility'
                                    >
                                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            sx={{ paddingTop: '20px', display: 'flex', justifyContent: 'space-between' }}
                            xs={12}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Checkbox
                                name='terms'
                                checked={formik.values.terms}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                sx={{
                                  padding: '0',
                                  marginRight: '6px',
                                  transform: 'scale(0.9)'
                                }}
                              />
                              <Typography
                                sx={{
                                  color: '#101010',
                                  cursor: 'pointer',
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  display: 'inline'
                                }}
                              >
                                I have read the{' '}
                                <ButtonBase
                                  variant='text'
                                  onClick={() => setOpenTermsAndConditionsModal(true)}
                                  sx={{
                                    display: 'inline',
                                    '&:hover': {
                                      color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.dark : '#164694'
                                    },
                                    color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.main : '#0071F6',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    fontFamily: 'inherit',
                                    verticalAlign: 'baseline'
                                  }}
                                >
                                  Terms and Conditions
                                </ButtonBase>
                                <TermsAndConditionsModal open={openTermsAndConditionsModal} onClose={() => setOpenTermsAndConditionsModal(false)} companyName={companyName} companyWebsite={companyWebsite} supportEmail={supportEmail} isPartner />
                                {' '}
                                and
                                {' '}
                                <ButtonBase
                                  variant='text'
                                  onClick={() => setOpenPrivacyPolicyModal(true)}
                                  sx={{
                                    display: 'inline',
                                    '&:hover': {
                                      color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.dark : '#164694'
                                    },
                                    color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.main : '#0071F6',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    fontFamily: 'inherit',
                                    verticalAlign: 'baseline'
                                  }}
                                >
                                  Privacy Policy.
                                </ButtonBase>
                                <PrivacyPolicyModal open={openPrivacyPolicyModal} onClose={() => setOpenPrivacyPolicyModal(false)} companyName={companyName} companyWebsite={companyWebsite} supportEmail={supportEmail} isPartner />
                              </Typography>
                              {formik.touched.terms && formik.errors.terms && (
                                <Typography color='error' sx={{ fontSize: '14px', fontWeight: '400', mt: 1 }}>
                                  {formik.errors.terms}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </Grid>

                        {formik.errors.submit && (
                          <Typography color='error' sx={{ mt: 3, textAlign: 'right' }} variant='body2'>
                            {formik.errors.submit}
                          </Typography>
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '20px'
                          }}
                        >
                          <Button
                            fullWidth
                            sx={{
                              mt: 2,
                              py: 2,
                              fontSize: '14px',
                              fontWeight: 700,
                              borderRadius: '14px',
                              bgcolor: whiteLabelTheme?.colors && whiteLabelTheme?.colors?.main,
                              '&:hover': {
                                bgcolor: whiteLabelTheme?.colors && whiteLabelTheme?.colors?.dark
                              }
                            }}
                            type='submit'
                            variant='contained'
                          >
                            {formik.isSubmitting
                              ? (
                                <CircularProgress size={20} color='inherit' />
                                )
                              : (
                                <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                                  Join Now
                                </Typography>
                                )}
                          </Button>
                        </Box>

                      </form>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignSelf: 'center' }}>
                    <Typography
                      sx={{
                        color: '#101010',
                        fontSize: '14px',
                        fontWeight: 500,
                        display: 'inline'
                      }}
                    >
                      Already have an account?
                      <Link
                        onClick={againLogin}
                        sx={{
                          textDecoration: 'none',
                          '&:hover': {
                            color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.dark : '#164694'
                          },
                          color: whiteLabelTheme?.colors ? whiteLabelTheme?.colors?.main : '#0071F6',
                          ml: 0.3,
                          fontWeight: 700,
                          cursor: 'pointer'
                        }}
                      >
                        Log In
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            )
      }
    </>
  )
}

export default WhiteLabelRegisterPage
