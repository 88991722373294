import React, { useState } from 'react'
import {
    Box,
    Typography,
    TextField,
    Container,
    Button,
    useTheme,
    Paper
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setConfigs, useCreateConfigsMutation } from 'src/services/theme-service'
import toast from 'react-hot-toast'

const WhiteLabelConfig = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [createConfigs] = useCreateConfigsMutation()
    const { configs } = useSelector((state) => state.partnerDetails)

    // Formik setup
    const formik = useFormik({
        initialValues: {
            calendlyURL: configs?.calendlyURL || '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            calendlyURL: Yup.string()
            .required("Calendly URL is required")
        }),
        onSubmit: async (values) => {
            const payload = {
                calendlyURL: values.calendlyURL
            }
            setLoading(true)
            try {
                const { message, configs: updatedData } = await createConfigs({ configs: payload }).unwrap()
                if (updatedData) {
                    dispatch(setConfigs({ ...updatedData }))
                }
                toast.success(`${message || 'Configurations updated successfully!'}`)
            } catch ({ data: error }) {
                toast.error(`${error.status || 'Error'}: ${error.message || 'Falied to update Configurations!'}`)
                return
            } finally {
                setLoading(false)
            }
        }
    })

    return (
        <Container sx={{ maxWidth: { xs: 800, xl: '100%', mx: 'auto'}, padding: '0px !important', marginTop: 2, marginX: 'auto' }}>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 3, md: 4 },
                    background: '#FFFFFF',
                    p: 3
                }}
                component='form'
                onSubmit={formik.handleSubmit}
            >
                {/* Left column */}
                <Box
                    sx={{
                        width: { xs: '100%', md: '40%' }
                    }}
                >

                    <Typography variant='h5' gutterBottom sx={{ color: theme.palette.primary.main }}>
                        Configurations
                    </Typography>
                    <Typography
                        sx={{
                            color: 'text.secondary',
                            fontSize: '0.875rem',
                            mb: { xs: 2, md: 6 }
                        }}
                    >
                        Enter your Calendly booking URL to allow users to schedule a meeting with you. Ensure the link is correct and publicly accessible.
                    </Typography>
                </Box>

                {/* Right column */}
                <Box
                    sx={{
                        width: { xs: '100%', md: '60%' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                color: theme.palette.primary.main,
                                fontSize: '0.875rem',
                                mb: 1
                            }}
                        >
                            Calendly URL
                        </Typography>
                        <TextField
                            fullWidth
                            size='small'
                            name='calendlyURL'
                            value={formik.values.calendlyURL}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.calendlyURL && Boolean(formik.errors.calendlyURL)}
                            helperText={formik.touched.calendlyURL && formik.errors.calendlyURL}
                            sx={{
                                backgroundColor: 'white',
                                '& .MuiOutlinedInput-root': {
                                    fontSize: '14px',
                                    borderRadius: '6px',
                                    backgroundColor: 'white'
                                }
                            }}
                        />
                    </Box>
                    <Button
                        variant='contained'
                        type='submit'
                        size='small'
                        disabled={loading}
                        sx={{
                            alignSelf: 'flex-end',
                            bgcolor: theme.palette.primary.main,
                            '&:hover': {
                                bgcolor: theme.palette.primary.dark
                            }
                        }}
                    >
                        {loading ? 'Saving...' : 'Save'}
                    </Button>
                </Box>
            </Paper>
        </Container>
    )
}

export default WhiteLabelConfig
