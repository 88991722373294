import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack, Typography, RadioGroup } from '@mui/material'
import { ComboBox, Tag, FilterDropdown, LockedFilter } from '../filterComponents'
import { KeywordsIcon } from 'src/icons/Keywords'
import { useTheme } from '@emotion/react'

export default function CompanyKeywordFilter ({ isLocked, onChange, plan, isOpen, onToggle }) {
  const theme = useTheme()
  const [selectedMethod, setSelectedMethod] = useState('')
  const [includedKeywords, setIncludedKeywords] = useState([])
  const [excludedKeywords, setExcludedKeywords] = useState([])
  const ref = useRef({ internalStateChange: false })
  const keywordsData = useSelector((state) => state.search.filter.company_keywords)
  const excludedKeywordsData = useSelector((state) => state.search.filter.exclude_company_keywords)

  const handleIncludedKeywords = useCallback((newValue, updateRedux = true) => {
    setIncludedKeywords(newValue)
    if (updateRedux) onChange('company_keywords', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const handleExcludedKeywords = useCallback((newValue, updateRedux = true) => {
    setExcludedKeywords(newValue)
    if (updateRedux) onChange('exclude_company_keywords', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const handleRadioBtn = useCallback((e) => {
    setSelectedMethod(e.target.value)
    handleIncludedKeywords([])
    handleExcludedKeywords([])
  }, [handleIncludedKeywords, handleExcludedKeywords])

  const clearAll = useCallback(() => {
    setSelectedMethod('')
    handleIncludedKeywords([])
    handleExcludedKeywords([])
  }, [handleIncludedKeywords, handleExcludedKeywords])

  useEffect(() => {
    if (ref.current.internalStateChange) {
      ref.current.internalStateChange = false
      return
    }
    setIncludedKeywords(keywordsData ?? [])
    setExcludedKeywords(excludedKeywordsData ?? [])
    setSelectedMethod(keywordsData ? 'include' : '')
  }, [keywordsData, excludedKeywordsData])

  const preview = (
    <>
      {(includedKeywords.length > 0 || excludedKeywords.length > 0) &&
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          {includedKeywords.length > 0 &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Include</Typography>
              {includedKeywords.map((lang, index) => <Tag key={index} label={lang} onDelete={() => handleIncludedKeywords(includedKeywords.filter(l => l !== lang))} />)}
            </Box>}
          {excludedKeywords.length > 0 &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Exclude</Typography>
              {excludedKeywords.map((lang, index) => <Tag key={index} label={lang} onDelete={() => handleExcludedKeywords(excludedKeywords.filter(l => l !== lang))} isExclude />)}
            </Box>}
        </Box>}
    </>
  )

  if (isLocked) {
    return (
      <LockedFilter
        title='Company Keywords'
        icon={<KeywordsIcon sx={{ stroke: `${theme.palette.primary.main}` }} />}
        plan={plan}
      />
    )
  }

  return (
    <FilterDropdown
      title='Company Keywords'
      filterCount={includedKeywords.length + excludedKeywords.length}
      clearAll={clearAll}
      icon={<KeywordsIcon sx={{ stroke: `${theme.palette.primary.main}` }} />}
      preview={preview}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Stack direction='column' gap={2} mt={2}>
        <RadioGroup
          value={selectedMethod}
          onChange={handleRadioBtn}
        >
          <Stack direction='column' gap={1}>
            <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>Include keywords</Typography>
            <ComboBox
              freeSolo
              placeholder='e.g. Cloud AWS'
              options={[]}
              value={includedKeywords}
              onChange={(e, newValue) => { handleIncludedKeywords(newValue) }}
            />

            <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>Exclude keywords</Typography>
            <ComboBox
              freeSolo
              placeholder='e.g. Cloud AWS'
              options={[]}
              value={excludedKeywords}
              onChange={(e, newValue) => { handleExcludedKeywords(newValue) }}
              isExclude
            />
          </Stack>
        </RadioGroup>
      </Stack>
    </FilterDropdown>
  )
}
