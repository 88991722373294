import React, { useState } from 'react'
import { Dialog, Box, Typography, Button, IconButton, Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Logo } from 'src/components/logo'
import { useUpdatePaymentMutation } from 'src/services/billing-service'

const UpgradePlanPopup = ({ isOpen, onClose, handleOpencancellationPlans }) => {
  const [selectedBox, setSelectedBox] = useState(null)
  const [UpdatePayment] = useUpdatePaymentMutation()

  // Handler for when a button is clicked
  const handleBoxClick = (boxIndex) => {
    setSelectedBox(boxIndex)
  }
  const handelBillingCheckout = async () => {
    const { data: url } = await UpdatePayment()
    window.location.href = url
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      sx={{ height: 'calc(100%-64px)' }}
    >
      {/* Header Section */}
      <Box sx={{ position: 'relative', p: 3, textAlign: 'center' }}>
        <Typography variant='h6' sx={{ fontWeight: 'bold', color: '#333' }}>
          <Logo color='#3E50F8' />
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            border: '1px solid #ccc',
            borderRadius: '20%', // Makes the border circular
            padding: '4px', // Adjusts the spacing inside the button
            backgroundColor: 'white', // Optional: Background for contrast
            '&:hover': {
              backgroundColor: '#f0f0f0' // Hover effect
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Main Content */}
      <Box sx={{ px: 4, pb: 3, mt: 2 }}>
        <Typography
          sx={{ fontWeight: 500, textAlign: 'center', fontSize: '13px', color: '#8181B0' }}
        >
          [FIRST_NAME], we're sad to let you go! We are hoping that you'd stay.
        </Typography>
        <Typography
          sx={{ textAlign: 'center', color: '#101010', mb: 5, fontSize: '22px', fontWeight: 700 }}
        >
          What can we offer you to continue with us?
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            px: 4,
            // flexWrap: "wrap",
            mb: 2
          }}
        >
          {/* Offer 1 */}
          <Box
            sx={{
              border: selectedBox === 1 ? '2px solid #0070f3' : '1px solid #e0e0e0',
              borderRadius: '10px',
              p: 3,
              width: '400px',
              boxShadow:
                selectedBox === 1
                  ? '0 4px 15px rgba(0, 112, 243, 0.5)' // Blue shadow
                  : '0 2px 10px rgba(0,0,0,0.1)', // Default shadow
              transition: 'box-shadow 0.3s, border 0.3s'
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '16px',
                mb: '12px',
                textAlign: 'center'
              }}
            >
              🎁 3 Months for the price of 1
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/assets/2-month-freeplan.png`}
              alt='Campaign Offer'
              style={{
                width: '100%',
                height: 'auto',
                marginBottom: '16px'
              }}
            />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '13px',
                mb: 2,
                textAlign: 'center'
              }}
            >
              Get an additional 2 months at no cost to test the platform further and improve your
              campaigns
            </Typography>

            <Button
              variant='contained'
              fullWidth
              onClick={() => handleBoxClick(1)}
              disabled
              sx={{
                backgroundColor: '#0070f3',
                color: '#fff',
                textTransform: 'none',
                fontWeight: 'bold',
                borderRadius: '12px',
                py: 1
              }}
            >
              Get 2 months free!
            </Button>
          </Box>

          {/* Offer 2 */}
          <Box
            sx={{
              border: selectedBox === 2 ? '2px solid #0070f3' : '1px solid #e0e0e0',
              borderRadius: '10px',
              px: 3,
              pb: 3,
              width: '400px',
              boxShadow:
                selectedBox === 2
                  ? '0 4px 15px rgba(0, 112, 243, 0.5)' // Blue shadow
                  : '0 2px 10px rgba(0,0,0,0.1)', // Default shadow
              transition: 'box-shadow 0.3s, border 0.3s'
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '13px',
                color: '#3F4FF8',
                textAlign: 'center',
                background: '#E7F0FF',
                py: 1,
                borderRadius: '12px'
              }}
            >
              Limited offer: 15:59 remaining
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '16px',
                mb: '12px',
                textAlign: 'center',
                mt: 2
              }}
            >
              🎁 Get 25% discount forever!
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/assets/discountplan.png`}
              alt='Campaign Offer'
              style={{
                width: '100%',
                height: 'auto',
                marginBottom: '16px'
              }}
            />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '13px',
                mb: 2,
                textAlign: 'center'
              }}
            >
              Get an additional 2 months at no cost to test the platform further and improve your
              campaigns
            </Typography>
            <Button
              variant='contained'
              fullWidth
              onClick={() => handleBoxClick(2)}
              disabled
              sx={{
                backgroundColor: '#0070f3',
                color: '#fff',
                textTransform: 'none',
                fontWeight: 'bold',
                borderRadius: '12px',
                py: 1
              }}
            >
              Claim 25% Discount forever!
            </Button>
          </Box>
        </Box>

        <Divider
          sx={{
            my: 2,
            // marginTop: "50px",
            borderColor: '#e0e0e0',
            borderWidth: '1px',
            width: '90%',
            margin: '0 auto',
            position: 'relative',
            '::before, ::after': {
              borderColor: '#e0e0e0'
            },
            color: '#555',
            fontWeight: 500,
            fontSize: '13px'
          }}
        >
          or
        </Divider>

        {/* Cancel Button */}
        <Box
          sx={{
            textAlign: 'center',
            // mt: 3,
            // borderTop: "1px solid #e0e0e0",
            pt: 3
          }}
        >
          <Button
            onClick={handelBillingCheckout}
            variant='text'
            sx={{
              textTransform: 'none',
              color: '#101010',
              fontWeight: 'bold',
              border: '1px solid #E4E4E5',
              borderRadius: '8px',
              px: 14,
              py: '10px',
              ':hover': {
                backgroundColor: '#f9f9f9', // Optional: Light hover background
                borderColor: '#0070f3' // Optional: Change border color on hover
              },
              // fontWeight: 600,
              fontSize: '14px'
            }}
          >
            Cancel my subscription
          </Button>

          <Typography sx={{ fontSize: '13px', fontWeight: 500, color: '#818180', mt: 2 }}>
            Important: By canceling your subscription, you will lose your account, your data, and
            all features associated with your plan. Your new accounts will not have any discounts or
            legacy pricing.
          </Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: 500, color: '#818180', mt: 2 }}>
            "Offers do not cover DFY, as we pay external providers externally such as Google, Microsoft"
          </Typography>
          <Button
            // onClick={handleClose}
            sx={{ fontSize: '13px', fontWeight: 500, color: '#818180', mt: 1 }}
          >
            Never mind! Take me back to Success.ai
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default UpgradePlanPopup
