import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { Form, Formik } from 'formik'
import { ColorPicker, createColor } from 'material-ui-color'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { setTheme, useCreateThemeMutation } from 'src/services/theme-service'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import SEOMetadataForm from './SeoSetting'
import CustomDomainSetup from './CustomDomain'
import { ChevronDownArrow } from 'src/icons/OnboardingHubIcons'
import { Container } from '@mui/system'
import ScriptContent from 'src/components/ScriptContentField'
import WhiteLabelConfig from 'src/components/WhiteLabelConfig'
import { s3ImageURL } from 'src/config'

// Color field configuration
const colorFields = [
  { key: 'light', label: 'Light Color' },
  { key: 'main', label: 'Main Color' },
  { key: 'dark', label: 'Dark Color' },
  { key: 'contrastText', label: 'Contrast Text' },
  { key: 'primaryText', label: 'Primary Text' }
]

const MAX_LOGO_SIZE = 4 * 1024 * 1024 // 4MB
const MAX_FAVICON_SIZE = 1 * 1024 * 1024 // 1MB
const VALID_LOGOTYPES = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/jpg']
const VALID_FAVICONTYPES = ['image/x-icon', 'image/png']

const removePrefix = (url) => {
  return url?.startsWith(s3ImageURL) ? url.replace(s3ImageURL, "") : url;
};

const validateFileSize = (value, maxSize, isOptional) => {
  if (isOptional && !value) return true // Allow empty value if optional

  if (!value) return false

  // Allow URLs (e.g., S3 stored images)
  if (typeof value === 'string' && (value.startsWith('http') || value.startsWith('https'))) {
    return true
  }

  if (value instanceof File) {
    return value.size <= maxSize
  }

  return false
}

const validateFileFormat = (value, validFormats, isOptional) => {
  if (isOptional && !value) return true // Allow empty value if optional

  if (!value) return false

  // Allow URLs (e.g., S3 stored images)
  if (typeof value === 'string' && (value.startsWith('http') || value.startsWith('https'))) {
    return true
  }

  // Handle file objects
  if (value instanceof File) {
    return validFormats.includes(value.type)
  }

  return false
}

// Validation schema with Yup
const validationSchema = Yup.object({
  companyName: Yup.string()
    .trim()
    .required('Company name is a mandatory field')
    .min(3, 'Company name must be at least 3 characters')
    .max(100, 'Company name must not exceed 100 characters'),
  supportEmail: Yup.string().email('Please enter a valid email address').required('Support Email is mandatory.'),
  companyAddress: Yup.string().min(3).required('Company Address is mandatory.'),
  companyWebsite: Yup.string().min(3).required('Company Website is mandatory.'),
  colors: Yup.object().shape({
    light: Yup.string().matches(/^#[0-9A-Fa-f]{6}$/, 'Invalid hex color format'),
    main: Yup.string().matches(/^#[0-9A-Fa-f]{6}$/, 'Invalid hex color format'),
    dark: Yup.string().matches(/^#[0-9A-Fa-f]{6}$/, 'Invalid hex color format'),
    contrastText: Yup.string().matches(/^#[0-9A-Fa-f]{6}$/, 'Invalid hex color format'),
    primaryText: Yup.string().matches(/^#[0-9A-Fa-f]{6}$/, 'Invalid hex color format')
  }),
  logo: Yup.mixed()
    .required('Logo is a mandatory field')
    .test('fileFormat', 'Logo must be in jpeg, png, svg, or jpg format', (value) =>
      validateFileFormat(value, VALID_LOGOTYPES, false)
    )
    .test('fileSize', 'Logo must be less than 4 MB', (value) =>
      validateFileSize(value, MAX_LOGO_SIZE, false)
    ),

  favicon: Yup.mixed()
    .nullable()
    .notRequired()
    .test('fileFormat', 'Favicon must be in .ico or .png format', (value) =>
      validateFileFormat(value, VALID_FAVICONTYPES, true)
    )
    .test('fileSize', 'Favicon must be less than 1 MB', (value) =>
      validateFileSize(value, MAX_FAVICON_SIZE, true)
    )
})

const ThemeSettings = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [createTheme] = useCreateThemeMutation()
  const { theme: userTheme } = useSelector((state) => state.partnerDetails)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const keysToKeep = ['light', 'main', 'dark', 'contrastText', 'primaryText']

  function filterColors (colors) {
    return Object.keys(colors)
      .filter(key => keysToKeep.includes(key))
      .reduce((filteredColors, key) => {
        filteredColors[key] = colors[key]
        return filteredColors
      }, {})
  }

  const initialValues = {
    ...userTheme,
    colors: filterColors(userTheme.colors)
  }

  const handleFileUpload = (fieldName, allowedTypes) => (setFieldValue) => (event) => {
    const file = event.dataTransfer ? event.dataTransfer?.files?.[0] : event.target?.files?.[0]
    if (file) {
      // Validate file type
      if (allowedTypes.includes(file.type) || allowedTypes.some(type => file.name.endsWith(type))) {
        setFieldValue(fieldName, file)
      } else {
        event.target.value = ''
      }
    }
  }
  const handleLogoUpload = handleFileUpload('logo', VALID_LOGOTYPES)
  const handleFaviconUpload = handleFileUpload('favicon', VALID_FAVICONTYPES)

  const resetTheme = async () => {
    try {
      const themeValues = {
        isReset: true,
        companyName: '',
        supportEmail: '',
        companyAddress: '',
        companyWebsite: '',
        scriptContent: '',
        colors: {
          light: '#EBEFFF',
          main: '#216fed',
          dark: '#004EEB',
          contrastText: '#FFFFFF',
          primaryText: '#28287B'
        }
      }

      const { theme: updatedTheme } = await createTheme({ theme: themeValues, logo: null, favicon: null }).unwrap()
      if (updatedTheme) {
        dispatch(setTheme({ ...updatedTheme, colors: updatedTheme?.colors }))
      }
      toast.success('Theme Reset Successfully')
    } catch ({ data: error }) {
      toast.error(`${error.status || 'Error'}: ${error.message || 'Error Reset theme'}`)
    }
  }

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true)

      const formData = new FormData()
      const themeData = {
        isReset: false,
        companyName: values.companyName,
        supportEmail: values.supportEmail,
        companyAddress: values.companyAddress,
        companyWebsite: values.companyWebsite,
        scriptContent: values.scriptContent,
        colors: filterColors(values.colors)
      }

      formData.append('theme', JSON.stringify(themeData))
      formData.append('logo', values.logo instanceof File ? values.logo : removePrefix(values.logo))
      formData.append('favicon', values.favicon instanceof File ? values.favicon : removePrefix(values.favicon))

      const response = await createTheme(formData).unwrap()
      const { message, theme: updatedTheme } = response
      const currentTheme = updatedTheme?.theme ? updatedTheme?.theme : updatedTheme

      if (currentTheme) {
        dispatch(setTheme({ ...currentTheme, colors: currentTheme?.colors, logo: s3ImageURL + currentTheme.logo, favicon: currentTheme.favicon ? s3ImageURL + currentTheme.favicon : null }))
      }

      toast.success(message)
    } catch (error) {
      toast.error(`${error.data?.status || 'Error'}: ${error.data?.message || 'Error Update/Create theme'}`)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Box>
      <Grid container spacing={3} sx={{ px: 3 }}>
        <Grid item xs={12} md={6}>
          <CustomDomainSetup />
          <SEOMetadataForm />
          <WhiteLabelConfig />
        </Grid>
        <Grid item xs={12} md={6}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ setFieldValue, values, errors, touched }) => {
              // Fetch the data on first render
              React.useEffect(() => {
                setFieldValue('companyName', userTheme.companyName || '')
                setFieldValue('supportEmail', userTheme.supportEmail || '')
                setFieldValue('companyAddress', userTheme.companyAddress || '')
                setFieldValue('companyWebsite', userTheme.companyWebsite || '')
                setFieldValue('scriptContent', userTheme.scriptContent || '')
                setFieldValue('colors', userTheme.colors)
              }, [])

              return (
                <Container sx={{ maxWidth: { xs: 800, xl: '100%' }, margin: 'auto', padding: '0px !important' }}>
                  <Form>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ChevronDownArrow />}
                        aria-controls='theme-settings-content'
                        id='theme-settings-header'
                      >
                        <Typography variant='h5' gutterBottom sx={{ color: theme.palette.primary.main }}>
                          Theme Settings *
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label='Company Name *'
                              value={values.companyName}
                              onChange={(e) => setFieldValue('companyName', e.target.value)}
                              variant='outlined'
                              error={touched.companyName && !!errors.companyName}
                              helperText={touched.companyName && errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label='Company Address *'
                              value={values.companyAddress}
                              onChange={(e) => setFieldValue('companyAddress', e.target.value)}
                              variant='outlined'
                              error={touched.companyAddress && !!errors.companyAddress}
                              helperText={touched.companyAddress && errors.companyAddress}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label='Support Email *'
                              value={values.supportEmail}
                              onChange={(e) => setFieldValue('supportEmail', e.target.value)}
                              variant='outlined'
                              error={touched.supportEmail && !!errors.supportEmail}
                              helperText={touched.supportEmail && errors.supportEmail}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label='Company Website *'
                              value={values.companyWebsite}
                              onChange={(e) => setFieldValue('companyWebsite', e.target.value)}
                              variant='outlined'
                              error={touched.companyWebsite && !!errors.companyWebsite}
                              helperText={touched.companyWebsite && errors.companyWebsite}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    {/* Logo Section */}
                    <Accordion sx={{ mt: 3 }}>
                      <AccordionSummary
                        expandIcon={<ChevronDownArrow />}
                        aria-controls='logo-content'
                        id='logo-header'
                      >
                        <Typography variant='h5' gutterBottom sx={{ color: theme.palette.primary.main }}>
                          Logo *
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                            p: 2,
                            border: '2px dashed rgba(0, 0, 0, 0.12)',
                            borderRadius: 1,
                            bgcolor: 'white',
                            position: 'relative'
                          }}
                          onDrop={handleLogoUpload(setFieldValue)}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          {values.logo
                            ? (
                              <Box
                                sx={{
                                  width: 64,
                                  height: 64,
                                  position: 'relative',
                                  overflow: 'hidden',
                                  borderRadius: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <img
                                  src={values.logo instanceof File ? URL.createObjectURL(values.logo) : values.logo}
                                  alt='Uploaded Logo'
                                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    borderRadius: '50%',
                                    width: 24,
                                    height: 24,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                                  }}
                                  onClick={() => setFieldValue('logo', null)}
                                >
                                  <Typography sx={{ cursor: 'pointer', fontSize: '1.25rem', fontWeight: 'bold' }}>&times;</Typography>
                                </Box>
                              </Box>
                              )
                            : (
                              <Typography onClick={() => document.getElementById('logoInput').click()} sx={{ cursor: 'pointer', color: theme.palette.primary.primaryText, fontSize: '1rem' }}>
                                Drag & Drop or Click to Upload
                              </Typography>
                              )}
                          <input
                            id='logoInput'
                            type='file'
                            hidden
                            accept={VALID_LOGOTYPES.join(',')}
                            onChange={handleLogoUpload(setFieldValue)}
                          />
                        </Box>
                        {touched.logo && errors.logo && (
                          <FormHelperText error sx={{ mt: 1 }}>
                            {errors.logo}
                          </FormHelperText>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    {/* Favicon icon Section */}
                    <Accordion sx={{ mt: 3 }}>
                      <AccordionSummary
                        expandIcon={<ChevronDownArrow />}
                        aria-controls='favicon-content'
                        id='favicon-header'
                      >
                        <Typography variant='h5' gutterBottom sx={{ color: theme.palette.primary.main }}>
                          Favicon
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                            p: 2,
                            border: '2px dashed rgba(0, 0, 0, 0.12)',
                            borderRadius: 1,
                            bgcolor: 'white',
                            position: 'relative'
                          }}
                          onDrop={handleFaviconUpload(setFieldValue)}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          {values.favicon
                            ? (
                              <Box
                                sx={{
                                  width: 64,
                                  height: 64,
                                  position: 'relative',
                                  overflow: 'hidden',
                                  borderRadius: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <img
                                  src={values.favicon instanceof File ? URL.createObjectURL(values.favicon) : values.favicon}
                                  alt='Uploaded Favicon'
                                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    borderRadius: '50%',
                                    width: 24,
                                    height: 24,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                                  }}
                                  onClick={() => setFieldValue('favicon', null)}
                                >
                                  <Typography sx={{ cursor: 'pointer', fontSize: '1.25rem', fontWeight: 'bold' }}>&times;</Typography>
                                </Box>
                              </Box>
                              )
                            : (
                              <Typography onClick={() => document.getElementById('faviconInput').click()} sx={{ cursor: 'pointer', color: theme.palette.primary.primaryText, fontSize: '1rem' }}>
                                Drag & Drop or Click to Upload
                              </Typography>
                              )}
                          <input
                            id='faviconInput'
                            type='file'
                            hidden
                            accept={VALID_FAVICONTYPES.join(',')}
                            onChange={handleFaviconUpload(setFieldValue)}
                          />
                        </Box>
                        {touched.favicon && errors.favicon && (
                          <FormHelperText error sx={{ mt: 1 }}>
                            {errors.favicon}
                          </FormHelperText>
                        )}
                      </AccordionDetails>
                    </Accordion>

                    {/* Color Settings */}
                    <Accordion sx={{ mt: 3 }}>
                      <AccordionSummary
                        expandIcon={<ChevronDownArrow />}
                        aria-controls='colors-content'
                        id='colors-header'
                      >
                        <Typography variant='h5' gutterBottom sx={{ color: theme.palette.primary.main }}>
                          Theme Colors
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          {colorFields.map(({ key, label }) => (
                            <Grid item xs={12} sm={6} key={key}>
                              <TextField
                                fullWidth
                                label={label}
                                value={values.colors[key]}
                                onChange={(e) => setFieldValue(`colors.${key}`, e.target.value)}
                                variant='outlined'
                                error={touched.colors?.[key] && !!errors.colors?.[key]}
                                helperText={touched.colors?.[key] && errors.colors?.[key]}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <Box>
                                        <ColorPicker
                                          value={createColor(values.colors[key])}
                                          onChange={(color) =>
                                            setFieldValue(`colors.${key}`, `#${color.hex}`)}
                                          hideTextfield
                                          disableAlpha
                                        />
                                      </Box>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    {/* Tracking scripts */}
                    <Accordion sx={{ mt: 3 }}>
                      <AccordionSummary
                        expandIcon={<ChevronDownArrow />}
                        aria-controls='tracking-content'
                        id='tracking-header'
                      >
                        <Typography variant='h5' gutterBottom sx={{ color: theme.palette.primary.main }}>
                          Tracking scripts
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ScriptContent
                          value={values.scriptContent}
                          onChange={(value) => setFieldValue('scriptContent', value)}
                        />
                      </AccordionDetails>
                    </Accordion>

                    {/* Save Button */}
                    <Box sx={{ mt: 4 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Button
                            fullWidth
                            variant='contained'
                            sx={{
                              bgcolor: theme.palette.primary.main,
                              '&:hover': {
                                bgcolor: alpha(theme.palette.primary.main, 0.9)
                              }
                            }}
                            type='button'
                            onClick={resetTheme}
                          >
                            Reset Theme
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            fullWidth
                            variant='contained'
                            sx={{
                              bgcolor: theme.palette.primary.main,
                              '&:hover': {
                                bgcolor: alpha(theme.palette.primary.main, 0.9)
                              }
                            }}
                            type='submit'
                          >
                            {isSubmitting ? 'Updating...' : 'Update Theme'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                </Container>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ThemeSettings
