import {
  Box,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton
} from '@mui/material'
import { EditIcon } from 'src/assets/general/EditIcon'
import { DeleteIcon } from 'src/assets/general/DeleteIcon'
import { toast } from 'react-hot-toast'
import { useDeleteSearchMutation } from 'src/services/leads-service.js'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import { useTheme } from '@emotion/react'

const SavedSearchBlock = ({
  searches,
  isLoading,
  onSearchClick,
  onEditSearchClick,
  onAllSavedClick,
  showLess
}) => {
  const theme = useTheme()
  const calculatedSearches = showLess ? searches?.saved?.slice(0, 3) : searches?.saved
  const [deleteSearch, { isLoading: isDeleteSearchLoading }] = useDeleteSearchMutation()

  const formatQueryString = (query) => {
    if (typeof query === 'string') return query
    if (typeof query === 'object') {
      const parts = []
      if (query.current_title) parts.push(query.current_title)
      if (query.geo) parts.push(query.geo)
      return parts.join(', ') || 'No description'
    }
    return 'No description'
  }

  const handleSearchDelete = async (searchId, event) => {
    event.stopPropagation()
    try {
      const { message } = await deleteSearch(searchId).unwrap()
      toast.success(message)
    } catch (error) {
      toast.error('Failed to delete search')
    }
  }

  const handleSearchEdit = (searchId, event) => {
    event.stopPropagation()
    onEditSearchClick(searchId)
  }

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        p: { xs: 2, sm: 3 },
        boxShadow: '0px 8px 34px 0px #28287B1A',
        width: '100%',
        maxWidth: { xs: '100%', sm: '520px' },
        mx: 'auto',
        position: 'relative'
      }}
    >
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
          <CircularProgress size={25} thickness={5} />
        </Box>
      ) : (
        <Box sx={{ width: '100%' }}>
          {/* Header Section */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: { xs: 'flex-start', sm: 'center' },
              gap: { xs: 1, sm: 0 },
              mb: { xs: 2, sm: 3 }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FavoriteBorderRoundedIcon sx={{
                fontSize: { xs: '14px', sm: '16px' },
                color: '#8181B0'
              }}
              />
              <Typography
                variant='body1'
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '14px', sm: '16px' },
                  color: theme.palette.primary.primaryText,
                  ml: 1
                }}
              >
                Saved Searches
              </Typography>
            </Box>

            {calculatedSearches?.length > 0 && (
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '12px', sm: '14px' },
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                  '&:hover': {
                    color: theme.palette.primary.dark
                  },
                  alignSelf: { xs: 'flex-end', sm: 'auto' }
                }}
                onClick={onAllSavedClick}
              >
                See All Saved
              </Typography>
            )}
          </Box>

          {/* List Items */}
          <List sx={{ width: '100%', p: 0 }}>
            {!calculatedSearches?.length ? (
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#667085',
                  textAlign: 'center',
                  py: 2
                }}
              >
                No saved searches
              </Typography>
            ) : (
              calculatedSearches.map((search, index) => (
                <div key={search._id}>
                  <ListItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: { xs: 'flex-start', sm: 'center' },
                      p: '8px 0',
                      position: 'relative',
                      '&:hover .action-buttons': {
                        opacity: { xs: 1, sm: 1 },
                        transform: { xs: 'translateX(0)', sm: 'translateX(0)' }
                      },
                      cursor: 'pointer',
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: { xs: 1, sm: 0 }
                    }}
                    onClick={(e) => {
                      if (!e.target.closest('.action-buttons')) {
                        onSearchClick(search.query)
                      }
                    }}
                  >
                    {/* Action Buttons */}
                    <Box
                      className='action-buttons'
                      sx={{
                        position: { xs: 'static', sm: 'absolute' },
                        left: { xs: 0, sm: '-50px' },
                        display: 'flex',
                        flexDirection: { xs: 'row', sm: 'column' },
                        gap: { xs: 1, sm: 0 },
                        opacity: { xs: 1, sm: 0 },
                        transform: { xs: 'none', sm: 'translateX(10px)' },
                        transition: 'all 0.2s ease-in-out',
                        order: { xs: 2, sm: 0 },
                        width: { xs: '100%', sm: 'auto' },
                        justifyContent: { xs: 'flex-end', sm: 'center' }
                      }}
                    >
                      <IconButton
                        size='small'
                        onClick={(e) => handleSearchEdit(search._id, e)}
                        sx={{
                          width: { xs: 30, sm: 26 },
                          height: { xs: 30, sm: 26 },
                          backgroundColor: '#f5f5f5',
                          '&:hover': {
                            backgroundColor: '#e0e0e0'
                          },
                          mb: { xs: 0, sm: 1 }
                        }}
                      >
                        <EditIcon fontSize={window.innerWidth < 600 ? 'small' : 'inherit'} />
                      </IconButton>
                      {isDeleteSearchLoading
                        ? (
                          <CircularProgress size={20} sx={{ color: 'rgb(253, 30, 54)' }} />
                          )
                        : (
                          <IconButton
                            size='small'
                            onClick={(e) => handleSearchDelete(search._id, e)}
                            sx={{
                              width: { xs: 30, sm: 26 },
                              height: { xs: 30, sm: 26 },
                              backgroundColor: '#f5f5f5',
                              '&:hover': {
                                backgroundColor: '#e0e0e0'
                              }
                            }}
                          >
                            <DeleteIcon fontSize={window.innerWidth < 600 ? 'small' : 'inherit'} />
                          </IconButton>
                          )}
                    </Box>

                    <ListItemText
                      primary={
                        <Box sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row' },
                          alignItems: { xs: 'flex-start', sm: 'center' },
                          gap: { xs: 0.5, sm: 2 }
                        }}
                        >
                          <Typography
                            component='span'
                            sx={{
                              color: theme.palette.primary.main,
                              fontWeight: 600,
                              fontSize: '14px'
                            }}
                          >
                            {search.name}:
                          </Typography>
                          <Typography
                            component='span'
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              wordBreak: 'break-word'
                            }}
                          >
                            {formatQueryString(search.query)}
                          </Typography>
                        </Box>
                      }
                      sx={{ width: '100%' }}
                    />
                  </ListItem>
                  {index < calculatedSearches.length - 1 && (
                    <Divider sx={{ my: '5px', borderColor: '#E4E4E5' }} />
                  )}
                </div>
              ))
            )}
          </List>
        </Box>
      )}
    </Box>
  )
}

export default SavedSearchBlock
