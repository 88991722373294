import React, { createContext, useContext, useMemo } from 'react'
import { roles } from './roles'

const AuthContext = createContext()

const AuthProvider = React.memo(({ children, role }) => {
  const permissions = useMemo(() => roles[role] || roles?.Viewer, [role])
  // console.log('Role updated:', role)
  return <AuthContext.Provider value={permissions}>{children}</AuthContext.Provider>
})
export const useAuth = () => useContext(AuthContext)
export { AuthProvider }
