import { Box, Dialog, Typography, Button, useTheme, Grid } from '@mui/material'
import { useGetDeleteUserMutation } from 'src/services/partnerUser-service'
import toast from 'react-hot-toast'

const DeleteConformation = ({ onClose, open, userId, setRefetchUser }) => {
  const theme = useTheme()
  const [deleteUser] = useGetDeleteUserMutation()

  const handleDeleteUser = async () => {
    await deleteUser({ userId })
    toast.success('User Deleted Successfully!')
    onClose()
    setRefetchUser(true)
  }
  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        sx={{
          backdropFilter: 'blur(1px) sepia(3%)'
        }}
        PaperProps={{ sx: { borderRadius: '1rem', width: '25%', minWidth: '300px' } }}
      >
        <Box sx={{ width: '100%', height: '100%', p: 4 }}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-Between', alignItems: 'center' }}
          >
            <Typography
              variant='h4'
              component='h4'
              sx={{
                color: theme.palette.primary.primaryText,
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                pb: '4px'
              }}
            >
              Confirmation
            </Typography>

          </Box>
          <Box sx={{ mb: '16px' }}>
            <Typography sx={{ fontSize: '17px', padding: '10px 0px', color: theme.palette.primary.primaryText }}>
              Are you sure you want to delete ?
            </Typography>
            <Typography
              sx={{ height: '50px', fontSize: '14px', padding: '10px 0px', color: theme.palette.primary.primaryText }}
            >
              This action cannot be undone, all user data gets deleted!
            </Typography>
          </Box>
          <Grid container sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Grid item xs={5.8}>
              <Button
                type='button'
                onClick={onClose}
                fullWidth
                variant='outlined'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '14px',
                  py: 2,
                  borderColor: theme.palette.primary.main,
                  borderWidth: 1,
                  color: theme.palette.primary.main
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={5.8}>
              <Button
                fullWidth
                variant='contained'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '14px',
                  py: 2,
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark
                  }
                }}
                onClick={handleDeleteUser}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  )
}

export default DeleteConformation
