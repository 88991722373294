import React, { useEffect, useState } from 'react'
import { Box, Typography, TextField, Button, Tabs, Tab, Stack, Paper, IconButton, Grid, MenuItem, Select, InputLabel, FormControl, CircularProgress, Autocomplete, useTheme } from '@mui/material'
import { PersonAdd, CopyAll } from '@mui/icons-material'
import toast from 'react-hot-toast'
import RemoveMemberDialog from './RemoveMemberDialog'
import { useUpdateWorkSpaceNameMutation, useSendInvitationMutation, useGetMembersMutation, useDeleteMemberMutation, useUpdateMemberRoleMutation, useGetCurrentWorkSpaceMutation, useLeaveWorkspaceMutation } from 'src/services/workSpace-service'
import { emailRegex } from 'src/utils/util'
import MemberList from '../workSpaces/MemberList'
import { useGetMeQuery, useGetWorkSpaceUserMutation } from 'src/services/user-service'
import LeaveWorkspaceDialog from './LeaveWorkspaceDialog'
import { useNavigate } from 'react-router-dom'
import { timezoneArray } from 'src/assets/data'
import { useGetCustomAttributesQuery } from 'src/services/intercom-service'

const WorkspaceMembership = ({ tabIndex1, handleTabChange1, canEdit, canDelete, setSelectedTimeline }) => {
  const theme = useTheme()
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('Admin')
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [openRemoveMemberDialog, setOpenRemoveMemberDialog] = useState(false)
  const [, setAnchorEl] = useState(null)
  const [updateWorkSpaceName] = useUpdateWorkSpaceNameMutation()
  const [getMembers] = useGetMembersMutation()
  const [sendInvitation, { isLoading: isLoadingInvitation }] = useSendInvitationMutation()
  const [updateMemberRole] = useUpdateMemberRoleMutation()
  const [deleteMember, { isLoading: isDeletingMember }] = useDeleteMemberMutation()
  const [teamMembers, setTeamMembers] = useState([])
  const [pendingMembers, setPendingMembers] = useState([])
  const [workspace, setWorkspace] = useState({})
  const [workspaceName, setWorkspaceName] = useState(workspace?.name)
  const [originalWorkspaceName, setOriginalWorkspaceName] = useState('')
  const workspaceID = workspace?._id
  const [selectedMemberId, setSelectedMemberId] = useState(null)
  const [getWorkSpaceUser] = useGetWorkSpaceUserMutation()
  const { data: userData } = useGetMeQuery()
  const [user, setUser] = useState({})
  const [getCurrentWorkSpace] = useGetCurrentWorkSpaceMutation()
  const [leaveWorkspace, { isLoading: isLeaveWorkspaceLoading }] = useLeaveWorkspaceMutation()
  const [openLeaveWorkspaceDialog, setOpenLeaveWorkspaceDialog] = useState(false)
  const [error, setError] = useState('')
  const [emailError, setEmailError] = useState('')
  const { refetch } = useGetCustomAttributesQuery(undefined, {
    skip: userData.isWhiteLabelUser
  })

  const navigate = useNavigate()
  const currentWorkspace = async () => {
    if (userData?._id) {
      const currentWorkspace = await getCurrentWorkSpace(userData?.currentWorkspace)
      setWorkspace(currentWorkspace?.data)
      setWorkspaceName(currentWorkspace?.data?.name || '')
      setOriginalWorkspaceName(currentWorkspace?.data?.name || '')
    }
  }

  const [timezone, setTimezone] = useState('')

  const handleTimezoneChange = (_, newValue) => {
    const newTimezoneValue = newValue?.value || ''
    setTimezone(newTimezoneValue)
    setShowSaveButton(workspaceName !== originalWorkspaceName || newTimezoneValue !== timezone)
  }

  useEffect(() => {
    currentWorkspace()
  }, [])

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleRoleChange = (event) => {
    setRole(event.target.value)
  }

  const getUser = async () => {
    const user = await getWorkSpaceUser(workspace?.createdBy).unwrap()
    setUser(user)
  }

  const handleViewAccessChange = async (event, memberId) => {
    const role = event?.target?.value
    const { message } = await updateMemberRole({
      id: memberId,
      data: {
        role
      }
    }).unwrap()
    toast.success(message)
    const updatedPendingMembers = pendingMembers.map(member =>
      member._id === memberId ? { ...member, role } : member
    )

    const updatedTeamMembers = teamMembers.map(member =>
      member._id === memberId ? { ...member, role } : member
    )

    setPendingMembers(updatedPendingMembers)
    setTeamMembers(updatedTeamMembers)
  }

  const fetchTeamMembers = async () => {
    try {
      const { data } = await getMembers({ id: workspace?._id, status: 'accepted' })
      setTeamMembers(data)
    } catch (error) {
      console.error('Error fetching members:', error)
    }
  }

  const fetchPendingMembers = async () => {
    try {
      const { data } = await getMembers({ id: workspace?._id, status: 'pending' })
      setPendingMembers(data)
    } catch (error) {
      console.error('Error fetching members:', error)
    }
  }

  useEffect(() => {
    if (workspace?._id) {
      getUser()
      fetchTeamMembers()
      fetchPendingMembers()
      setTimezone(workspace?.timezone)
    }
  }, [workspace])

  const handleInviteClick = async () => {
    const trimmedEmail = email.trim()
    if (!emailRegex.test(trimmedEmail)) {
      setEmailError(`Invalid email address: ${trimmedEmail}`)
      return
    }
    if (user?.email === trimmedEmail) {
      toast.error("Can't send an invite to workspace Owner.")
      return
    }
    try {
      const { data } = await sendInvitation({
        id: workspaceID,
        email: trimmedEmail,
        role
      })
      if (!userData?.isWhiteLabelUser) refetch()
      if (data?.message === 'Already invited') {
        toast.error('This member has already been invited.')
      } else {
        toast.success(data?.message)
        fetchPendingMembers()
        setEmailError('')
        setEmail('')
        setRole('Admin')
      }
    } catch (error) {
      console.error('Error sending invitation', error)
      toast.error('Failed to send invitation')
    }
  }

  const handleWorkspaceNameChange = (event) => {
    setWorkspaceName(event.target.value)
    setShowSaveButton(workspaceName !== originalWorkspaceName || timezone !== 'America/New_York (GMT-05:00)')
  }

  const handleSaveClick = async () => {
    if (!workspaceName.trim()) {
      setError('Workspace name is required')
      return
    }

    if (workspaceName.trim().length < 3) {
      setError('Workspace name must be at least 3 characters long')
      return
    }

    const { message, workSpace } = await updateWorkSpaceName({
      id: workspaceID,
      data: {
        name: workspaceName === originalWorkspaceName ? '' : workspaceName,
        timezone
      }
    }).unwrap()
    setError('')
    if (message === 'Workspace with same name already exist') {
      setError(message)
      return
    }
    setWorkspace(workSpace)
    setSelectedTimeline(workSpace)
    toast.success(message)
    setOriginalWorkspaceName(workspaceName)
    setShowSaveButton(false)
    setTimezone(timezone)
    console.log(`Workspace name changed to ${workspaceName}`)
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(workspaceID).then(() => {
      toast.success('Workspace ID copied to clipboard')
    })
  }

  const handleRemoveMemberClick = async () => {
    if (selectedMemberId) {
      await deleteMember(selectedMemberId)
      toast.success('Member deleted from workspace.')
      setAnchorEl(null)
      setSelectedMemberId(null)
      setOpenRemoveMemberDialog(false)
      fetchTeamMembers()
      fetchPendingMembers()
    }
  }

  const handleReload = () => {
    navigate(0)
  }

  const handleLeaveWorkspaceClick = async () => {
    if (workspace?._id) {
      const { data } = await leaveWorkspace({ email: userData?.email, workspaceId: workspace?._id })
      toast.success(data?.message)
      handleReload()
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '12px',
        backgroundColor: 'white',
        width: '100%',
        p: 2
      }}
    >
      {/* Workspace Section */}
      <Box sx={{ mb: 4 }}>
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '24px',
            color: `${theme.palette.primary.primaryText}`,
            mb: 2
          }}
        >
          Workspace
        </Typography>
        <Paper variant='outlined' sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant='body2' sx={{ mb: 1 }}>Workspace Name</Typography>
              <TextField
                variant='outlined'
                fullWidth
                value={workspaceName}
                onChange={handleWorkspaceNameChange}
                error={!!error}
                helperText={error}
                disabled={user.isPartner}
                sx={{
                  backgroundColor: 'white',
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    '& fieldset': {
                      borderColor: '#E4E4E5'
                    },
                    '&:hover fieldset': {
                      borderColor: '#E4E4E5'
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#E4E4E5'
                    }
                  },
                  '& .MuiInputBase-input': {
                    py: 1.5,
                    fontSize: '14px',
                    '&::placeholder': {
                      color: '#A1A1A1'
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='body2' sx={{ mb: 1 }}>Default Timezone</Typography>
              <Autocomplete
                freeSolo
                id='timezone-autocomplete'
                disableClearable
                options={timezoneArray}
                getOptionLabel={(option) => option.label}
                value={timezoneArray.find((option) => option.value === timezone) || null} // Match selected value
                onChange={handleTimezoneChange}
                disabled={user.isPartner}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      style: { paddingTop: 0 }
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        paddingTop: 0
                      },
                      '& .MuiInputLabel-root': {
                        transform: 'translate(14px, 8px) scale(1)'
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box>
            {showSaveButton && (
              <Button
                variant='contained'
                onClick={handleSaveClick}
                sx={{
                  backgroundColor: '#2F80ED',
                  color: 'white',
                  textTransform: 'none',
                  fontWeight: 700,
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: '#2F80ED'
                  },
                  mb: 2
                }}
              >
                Save
              </Button>
            )}
          </Box>
          <Typography variant='body2' sx={{ mb: 1 }}>Workspace ID:</Typography>
          <Box display='flex' alignItems='center'>
            <Typography variant='body2' sx={{ mr: 1 }}>{workspaceID}</Typography>
            <IconButton size='small' onClick={handleCopyToClipboard}>
              <CopyAll fontSize='small' />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            {user?.email && userData?.email && user?.email !== userData?.email && (
              <Button
                variant='outlined'
                onClick={() => setOpenLeaveWorkspaceDialog(true)}
                sx={{
                  textTransform: 'none',
                  fontWeight: 700,
                  borderRadius: '8px',
                  color: 'red',
                  borderColor: 'red',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    borderColor: 'red'
                  }
                }}
              >
                Leave Workspace
              </Button>
            )}
          </Box>
        </Paper>
      </Box>

      {/* Members Section */}
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '12px',
          width: '100%'
        }}
      >
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '24px',
            color: `${theme.palette.primary.primaryText}`,
            mb: 2
          }}
        >
          Members
        </Typography>
        <Box
          sx={{
            border: '1px solid #E4E4E5',
            borderRadius: '8px',
            p: 2,
            mb: 2
          }}
        >
          <Stack direction='row' alignItems='center' spacing={2}>
            <PersonAdd sx={{ color: '#A1A1A1' }} />
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '20px',
                color: `${theme.palette.primary.primaryText}`
              }}
            >
              Add New Member
            </Typography>
          </Stack>
          <Box
            sx={{
              borderBottom: '1px solid #E4E4E5',
              mt: 1,
              mb: 2
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <TextField
                fullWidth
                variant='outlined'
                placeholder='Enter email address'
                value={email}
                onChange={handleEmailChange}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    '& fieldset': {
                      borderColor: '#E4E4E5'
                    },
                    '&:hover fieldset': {
                      borderColor: '#E4E4E5'
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#E4E4E5'
                    }
                  },
                  '& .MuiInputBase-input': {
                    py: 1.5,
                    fontSize: '14px',
                    '&::placeholder': {
                      color: '#A1A1A1'
                    }
                  }
                }}
                error={!!emailError}
                helperText={emailError}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant='outlined' sx={{ backgroundColor: 'white', borderRadius: '8px' }}>
                <InputLabel>Role</InputLabel>
                <Select
                  value={role}
                  onChange={handleRoleChange}
                  label='Role'
                  renderValue={(selected) => selected}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      '& fieldset': {
                        borderColor: '#E4E4E5'
                      },
                      '&:hover fieldset': {
                        borderColor: '#E4E4E5'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#E4E4E5'
                      }
                    },
                    '& .MuiInputBase-input': {
                      py: 1.5,
                      fontSize: '14px',
                      '&::placeholder': {
                        color: '#A1A1A1'
                      }
                    }
                  }}
                >
                  <MenuItem value='Admin'>
                    <Box>
                      <Typography sx={{ fontWeight: 700 }}>Admin</Typography>
                      <Typography sx={{ fontSize: '12px' }}>
                        Manage team, billing, and settings
                      </Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem value='Editor'>
                    <Box>
                      <Typography sx={{ fontWeight: 700 }}>Editor</Typography>
                      <Typography sx={{ fontSize: '12px' }}>
                        Edit everything except team and billing
                      </Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem value='Viewer'>
                    <Box>
                      <Typography sx={{ fontWeight: 700 }}>View / VA Access</Typography>
                      <Typography sx={{ fontSize: '12px' }}>
                        Can view everything but not make updates
                      </Typography>
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'flex-end', maxHeight: '65px' }}>
              <Button
                fullWidth
                variant='contained'
                onClick={handleInviteClick}
                sx={{
                  backgroundColor: '#2F80ED',
                  color: 'white',
                  textTransform: 'none',
                  fontWeight: 700,
                  borderRadius: '8px',
                  width: { sm: '100%', lg: '70%' },
                  '&:hover': {
                    backgroundColor: '#2F80ED'
                  }
                }}
                disabled={!canEdit || !canDelete || user.isPartner}
              >
                {isLoadingInvitation ? <CircularProgress sx={{ color: '#fff' }} size={20} thickness={5} /> : 'Invite'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Tabs for Team Members and Pending Invitations */}
      <Box sx={{ width: '100%' }}>
        <Tabs value={tabIndex1} onChange={handleTabChange1} aria-label='Workspace tabs'>
          <Tab label='Team Members' />
          <Tab label='Pending Invitations' />
        </Tabs>

        {tabIndex1 === 0 && (
          <Box sx={{ mt: 2 }}>
            <Paper variant='outlined' sx={{ p: 2, mb: 2 }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' alignItems='center'>
                <Box>
                  <Typography variant='body1'>
                    {user?.name?.first && user?.name?.last
                      ? `${user.name.first} ${user.name.last}`
                      : 'Super Admin'}
                  </Typography>
                  <Typography variant='body2'>{user?.email}</Typography>
                </Box>
                <Typography variant='body2' sx={{ mt: { xs: 2, sm: 0 } }}>Owner</Typography>
              </Stack>
            </Paper>
            <MemberList
              members={teamMembers}
              handleViewAccessChange={handleViewAccessChange}
              setOpenRemoveMemberDialog={(id) => {
                setSelectedMemberId(id)
                setOpenRemoveMemberDialog(true)
              }}
              setAnchorEl={setAnchorEl}
              canEdit={canEdit || !user.isPartner}
              canDelete={canDelete || !user.isPartner}
            />
          </Box>
        )}

        {tabIndex1 === 1 && (
          <Box sx={{ mt: 2 }}>
            <MemberList
              members={pendingMembers}
              handleViewAccessChange={handleViewAccessChange}
              setOpenRemoveMemberDialog={(id) => {
                setSelectedMemberId(id)
                setOpenRemoveMemberDialog(true)
              }}
              setAnchorEl={setAnchorEl}
              canEdit={canEdit || !user.isPartner}
              canDelete={canDelete || !user.isPartner}
            />
          </Box>
        )}
      </Box>
      <RemoveMemberDialog
        open={openRemoveMemberDialog}
        onClose={() => {
          setOpenRemoveMemberDialog(false)
        }}
        onClick={handleRemoveMemberClick}
        isDeletingMember={isDeletingMember}
      />
      <LeaveWorkspaceDialog
        open={openLeaveWorkspaceDialog}
        onClose={() => {
          setOpenLeaveWorkspaceDialog(false)
        }}
        onClick={handleLeaveWorkspaceClick}
        isLoading={isLeaveWorkspaceLoading}
      />
    </Box>
  )
}

export default WorkspaceMembership
