import React from 'react'
import { Box, Button, styled, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Upgrade } from 'src/icons/upgrade'
import { motion } from 'framer-motion'
import { useGetMeQuery } from 'src/services/user-service'

const UpgradeToPremiumButton = ({ navMenuOpen, isMobile }) => {
  const navigate = useNavigate()
  const { data: user } = useGetMeQuery()
  // Handler for navigating to the billing page
  const handleExplorePlans = () => {
    navigate(`/settingsUpdated/signup/billing?email_address=${user?.email}&user_id=${user?._id}&workspaceID=${user?.currentWorkspace}`)
  }

  // Determine if the button or logo should be shown
  const showButton = navMenuOpen || isMobile

  return (
    <>
      {showButton
        ? (
          <MotionButton onClick={handleExplorePlans}>
            <Upgrade />
            <Typography
              sx={{
                fontFamily: 'Inter, sans-serif',
                fontSize: '13px',
                fontWeight: 600,
                lineHeight: '14px',
                color: '#000',
                whiteSpace: 'nowrap'
              }}
            >
              Upgrade
            </Typography>
          </MotionButton>
        )
        : (
          <MotionBox onClick={handleExplorePlans}>
            <Upgrade />
          </MotionBox>
        )}
    </>
  )
}

export default UpgradeToPremiumButton

const AnimatedButton = styled(motion(Button))({
  width: '100%',
  height: '34px',
  padding: '5px 8px',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',
  textTransform: 'none', // Prevent text from being capitalized
  '&:hover': {
    backgroundColor: '#ffffff' // Keep the background color the same on hover
  },
  background:
    'linear-gradient(-45deg,rgb(255, 255, 255),rgb(143, 202, 250),rgb(255, 255, 255),rgb(192, 224, 255))',
  backgroundSize: '400% 400%',
  animation: 'moveGradient 5s ease infinite',
  '@keyframes moveGradient': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' }
  }
})

const MotionButton = ({ children, ...props }) => {
  return (
    <AnimatedButton
      direction={{
        xs: 'column',
        md: 'row'
      }}
      spacing={{ xs: 3, md: 0 }}
      whileHover={{
        boxShadow: '0px 5px 30px rgba(187, 214, 255, 0.42)', // Blue shadow
        scale: 1.001 // Slightly increase size
      }}
      transition={{ duration: 0.3 }} // Smooth transition
      {...props}
    >
      {children}
    </AnimatedButton>
  )
}
const AnimatedBox = styled(motion(Box))({
  height: '32.36px',
  width: '100%',
  minWidth: '0',
  cursor: 'pointer',
  padding: '4.18px 4.18px',
  borderRadius: '6.68px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8.36px',
  textTransform: 'none',
  background:
    'linear-gradient(-45deg,rgb(255, 255, 255),rgb(144, 203, 252),rgb(255, 255, 255),rgb(192, 224, 255))',
  backgroundSize: '400% 400%',
  animation: 'moveGradient 5s ease infinite',
  '@keyframes moveGradient': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' }
  }
})

const MotionBox = ({ children, ...props }) => {
  return (
    <AnimatedBox
      direction={{
        xs: 'column',
        md: 'row'
      }}
      spacing={{ xs: 3, md: 0 }}
      whileHover={{
        boxShadow: '0px 5px 30px rgba(168, 203, 255, 0.42)', // Blue shadow
        scale: 1.001 // Slightly increase size
      }}
      transition={{ duration: 0.3 }} // Smooth transition
      {...props}
    >
      {children}
    </AnimatedBox>
  )
}
