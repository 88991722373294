import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'src/utils/base-query.js'

export const hubspotApi = createApi({
  reducerPath: 'hubspotApi',
  baseQuery,
  tagTypes: ['HUBSPOT'],
  endpoints: (builder) => ({
    getHubspotIntegration: builder.query({
      query: () => {
        return {
          url: 'integrations/get-hubspot-integrations'
        }
      }
    }),

    saveToHubspot: builder.mutation({
      query: (data) => {
        return {
          method: 'POST',
          url: '/integrations/hubspot-create-deal',
          body: data
        }
      }
    }),

    getDealPipelines: builder.query({
      query: () => {
        return {
          url: 'integrations/hubspot-get-pipelines'
        }
      }
    }),

    getCampaignEmailLabels: builder.query({
      query: () => {
        return {
          url: 'integrations/hubspot-campaign-email-labels'
        }
      }
    }),

    // Save Setting Of Hubspot Integration
    saveHubspotSetting: builder.mutation({
      query: (data) => ({
        method: 'POST',
        url: 'integrations/hubspot-settings-save',
        body: data
      })
    }),

    getHubspotSetting: builder.query({
      query: () => {
        return {
          url: 'integrations/hubspot-get-settings'
        }
      }
    })

  })
})

export const {
  useGetHubspotIntegrationQuery,
  useSaveToHubspotMutation,
  useGetDealPipelinesQuery,
  useGetCampaignEmailLabelsQuery,
  useSaveHubspotSettingMutation,
  useGetHubspotSettingQuery
} = hubspotApi
