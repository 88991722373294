/* eslint-disable */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  TextField,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Typography,
  CircularProgress,
  Tooltip,
  useTheme,
  useMediaQuery,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Menu,
  Table,
  MenuItem,
  FormControlLabel,
  Popover,
  LinearProgress
} from '@mui/material'
import { useState, useEffect, useRef } from 'react'
import { debounce, isEqual } from 'lodash'
import { DataGrid, GridColumnMenuFilterItem, GridColumnMenuManageItem, useGridApiRef } from '@mui/x-data-grid'
import { makeStyles } from '@mui/styles'
import {
  useAddLeadsToCampaignMutation,
  useAddLeadsToListMutation,
  useCreateSavedSearchMutation,
  useFindLeadsMutation,
  useFindCompaniesMutation,
  useGetSearchesQuery,
  useLazyGetAllSavedSearchesQuery,
  useLookupLeadsMutation,
  useUpdateSearchMutation,
  useGetDownloadFileMutation,
  useGetLeadsUsageQuery,
  useChangeDownloadCsvResultsMutation,
  useLeadsConfirmationMutation,
  useGetSaveFilesMutation,
  useGetCompanyIntentMutation
} from 'src/services/leads-service.js'

import { useBillingUsageQuery, useGetCurrentPlanQuery } from 'src/services/billing-service.js'
import { downloadCsv, filterObject, formatNumberCommaSeparator, downloadCsvWithSignedURL } from 'src/utils/util.js'
import RecentSearchBlock from 'src/components/leadsFinder/RecentSearchBlockNew.js'
import SavedSearchBlock from 'src/components/leadsFinder/SavedSearchBlockNew.js'
import SavedSearchDrawer from 'src/components/leadsFinder/SavedSearchDrawer.js'
import {
  Close,
  CloseOutlined,
  DownloadOutlined,
  KeyboardArrowDown
} from '@mui/icons-material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { useLocation, useNavigate } from 'react-router-dom'
import { EACloseIcon } from 'src/assets/emailAccounts/EACloseIcon.js'
import { useGetCampaignsQuery } from 'src/services/campaign-service.js'
import { useGetListsQuery } from 'src/services/lists-service.js'
import { toast } from 'react-hot-toast'
import moment from 'moment-timezone'
import useUserVerifyCheck from 'src/hooks/use-user-verify'
import VerifyEmailAddressPage from '../components/auth/verifyEmailAddress.js'
import { LinkdineIcon } from 'src/assets/social/LinkdinIcon.js'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { setSeachData } from '../store/reducers/search.js'
import CsvImport from 'src/components/settings/CsvImport.js'
import GoogleSheetImport from 'src/components/settings/GoogleSheetImport.js'
import { ArrowRight } from 'src/assets/general/ArrowRight'
import { ArrowLeftIconBlue } from 'src/assets/emailAccounts/connect/ArrowLeftIconBlue'
import { BulkUploadIcon } from 'src/assets/campaignDetailsLeads/BulkUploadIcon'
import { Google } from 'src/assets/Google'
import InfoIcon from '@mui/icons-material/Info'
import HeaderWithPopover from 'src/components/HeaderWithPopover.js'
import { useAuth } from 'src/auth/auth.js'
import { useGetMeAndWorkspaceQuery } from 'src/services/user-service.js'
import SearchIcon from '@mui/icons-material/Search'
import useWindowWidth from 'src/components/useWindowWidth.js'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon.js'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon.js'
import { v4 as uuidv4 } from 'uuid'
import SignalCellularAlt1BarRoundedIcon from '@mui/icons-material/SignalCellularAlt1BarRounded'
import SignalCellularAlt2BarRoundedIcon from '@mui/icons-material/SignalCellularAlt2BarRounded'
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded'
// Filters
import FundingFilter from 'src/components/leadsFinder/filters/funding.js'
import TitleRoleFilter from 'src/components/leadsFinder/filters/titleRole.js'
import SearchInput from 'src/components/leadsFinder/filters/searchInput.js'
import DomainFilter from 'src/components/leadsFinder/filters/domain.js'
import Revenue from 'src/components/leadsFinder/filters/revenue.js'
import EmployeeCount from 'src/components/leadsFinder/filters/employeeCount.js'
import BuyingIntent from 'src/components/leadsFinder/filters/buyingIntent.js'

import IndustryFilter from 'src/components/leadsFinder/filters/industry.js'
import TechnologyFilter from 'src/components/leadsFinder/filters/technology.js'
import KeywordFilter from 'src/components/leadsFinder/filters/keyword'
import CompanyKeywordFilter from 'src/components/leadsFinder/filters/companyKeyword.js'
import LocationFilter from 'src/components/leadsFinder/filters/locationFilter.js'
import LanguageFilter from 'src/components/leadsFinder/filters/language.js'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import useUserVerification from 'src/hooks/useUserVerification'
import Pagination from 'src/components/Pagination.js'
import SimpleBarChartDialog from 'src/components/SimpleBarChartDialog.js'
import { alpha } from '@mui/material/styles'
import { config } from 'src/config.js'
import { Send } from 'src/icons/send.js'
import { ExportIcon } from 'src/icons/export.js'
import { FilterFunnel } from 'src/icons/filterFunnel.js'
import CustomPaginationFooter from 'src/components/customPaginationFooter.js'
import ExportCsvDialog from 'src/components/leadsFinder/ExportCsvDialog.js'
import LeadDetailModal from 'src/components/leadsFinder/LeadDetailModal.js'
import AddToCampaignDialog from 'src/components/leadsFinder/AddToCampaignDialog.js'
import { AllCheckboxCustomIcon } from 'src/assets/general/AllCheckboxCustomIcon.js'
import { Workspaces, Download } from '@mui/icons-material'
import RefreshButton from 'src/components/RefreshButton.js'
import { FilterIcon } from 'src/assets/general/FilterIcon.js'
import CommonUpgradeModal from 'src/components/leadsFinder/filters/commonUpgradeModal.js'

const checkboxOfIcon = <OffCheckboxCustomIcon />
const checkboxOnIcon = <OnCheckboxCustomIcon />
const CheckboxAllIcon = <AllCheckboxCustomIcon />

const CustomCheckbox = (props) => (
  <Checkbox
    {...props}
    icon={checkboxOfIcon}
    checkedIcon={checkboxOnIcon}
  />
)
const CustomColumnMenu = (props) => {
  const itemProps = {
    colDef: props.colDef,
    onClick: props.hideMenu,
  };
  return (
    <>
      <GridColumnMenuFilterItem {...itemProps} />
      <GridColumnMenuManageItem {...itemProps} />
    </>
  );
};
const getPhoneNumber = (phoneNumbers) => {
  const firstPhone = phoneNumbers.find(phone => phone.raw_number || phone.sanitized_number);
  return firstPhone ? (firstPhone.raw_number || firstPhone.sanitized_number) : 'N/A';
};
const columns = (theme, planName) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <Typography
          sx={{
            color: `${theme?.palette.primary.primaryText}`,
            // color: "#28287B",
            fontSize: '13px',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '0em',
            cursor: 'pointer',
            mr: '3px',
            maxWidth: '160px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {params.row.name}
        </Typography>
        <Box
          sx={{ height: '14px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation()
            window.open(params.row.linkedin_url, '_blank')
          }}
        >
          <LinkdineIcon />
        </Box>
      </Box>
    )
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 250
  },
  // {
  //   field: 'company',
  //   headerName: 'Company',
  //   width: 200,
  //   sortable: true,
  //   valueGetter: (params) => params?.row?.organization?.name,
  //   renderCell: (params) => {
  //     const score = params?.row?.organization?.intent?.[0]?.composite_score;
  //     const date = params?.row.intent?.[0]?.date;
  //     const row = params?.row;

  //     return (
  //       <Box
  //         sx={{
  //           display: 'flex',
  //           justifyContent: 'space-between',
  //           alignItems: 'center',
  //           width: '100%'
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             color: theme?.palette.primary.primaryText,
  //             fontSize: '13px',
  //             fontWeight: '500',
  //             lineHeight: '16px',
  //             letterSpacing: '0em',
  //             cursor: 'pointer',
  //             overflow: 'hidden',
  //             textOverflow: 'ellipsis',
  //             width: '150px'
  //           }}
  //         >
  //           {params?.row?.organization?.name || 'N/A'}
  //         </Typography>

  //         {score && (
  //           <div style={{ display: 'flex', alignItems: 'center' }}>
  //             {score >= 1 && score <= 35 && (
  //               <SignalCellularAlt1BarRoundedIcon
  //                 style={{ color: '#5feda6' }}
  //               />
  //             )}
  //             {score >= 36 && score <= 65 && (
  //               <SignalCellularAlt2BarRoundedIcon
  //                 style={{ color: '#5feda6' }}
  //               />
  //             )}
  //             {score >= 66 && score <= 100 && (
  //               <SignalCellularAltRoundedIcon
  //                 onMouseEnter={() => handleMouseEnter({ score, date, row })}
  //                 style={{ color: '#5feda6' }}
  //               />
  //             )}
  //           </div>
  //         )}
  //       </Box>
  //     );
  // }
  // },
  {
    field: 'company',
    headerName: 'company',
    width: 200
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200
  },
  {
    field: 'phone_numbers',
    headerName: 'Phone',
    width: 200,
    sortable: true,
    valueGetter: (params) => {
      return getPhoneNumber(params.row.phone_numbers || []);
    },
    renderCell: (params) => {
      const phoneNumbers = params.row.phone_numbers || []
      const [anchorEl, setAnchorEl] = useState(null);
      const [isPlanCommonUpgradeModalOpen, setIsPlanCommonUpgradeModalOpen] = useState(false)
      useEffect(() => {
        if (isPlanCommonUpgradeModalOpen) {
          setAnchorEl(null); // Close the popover when modal opens
        }
      }, [isPlanCommonUpgradeModalOpen]);

      const closeModal = (event) => {
        event.stopPropagation()
        setIsPlanCommonUpgradeModalOpen(false)
      }
      const openModal = (event) => {
        event.stopPropagation()
        setIsPlanCommonUpgradeModalOpen(true)
      }
      const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handlePopoverClose = () => {
        setAnchorEl(null);
      };

      const openPopover = Boolean(anchorEl);
      if (planName === 'free') {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              padding: '5px',
              borderRadius: '4px',
              width: 'fit-content',
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <Typography
              sx={{
                color: `${theme?.palette.primary.primaryText}`,
                fontSize: '13px',
                fontWeight: '500',
                lineHeight: '16px',
                letterSpacing: '0em',
                filter: 'blur(4px)',
                userSelect: 'none'
              }}
            >
              +1****89
            </Typography>
            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{ pointerEvents: 'none' }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: `${theme?.palette.primary.primaryText}`,
                  color: 'white',
                  padding: '5px 16px',
                  fontSize: '14px',
                  fontWeight: '600',
                  pointerEvents: 'auto',
                  '&:hover': {
                    backgroundColor: `${theme?.palette.primary.primaryText}`,
                  },
                }}
                onClick={openModal}
              >
                Upgrade to Unlock
              </Button>
            </Popover>
            <Dialog
              open={isPlanCommonUpgradeModalOpen}
              onClose={closeModal}
              maxWidth='lg'
              fullWidth
            >
              <CommonUpgradeModal currentPlan={planName} closeModal={closeModal} />
            </Dialog>
          </Box>
        );
      }

      return (
        <Box
          sx={{
            color: `${theme?.palette.primary.primaryText}`,
            // color: "#28287B",
            fontSize: '13px',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '0em',
            cursor: 'pointer'
          }}
        >
          {phoneNumbers.map((phone, index) => (
            <Typography
              sx={{
                color: `${theme?.palette.primary.primaryText}`,
                // color: "#28287B",
                fontSize: '13px',
                fontWeight: '500',
                lineHeight: '16px',
                letterSpacing: '0em',
                filter: 'blur(4px)',
                userSelect: 'none'
              }}
              key={index}
            >
              {phone?.raw_number
                ? phone?.raw_number
                : phone?.sanitized_number
                  ? phone?.sanitized_number
                  : 'N/A'}
            </Typography>
          ))}
        </Box>
      )
    }
  },
  {
    field: 'city',
    headerName: 'City',
    width: 150,
    renderCell: (params) => (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }}>
        <Typography sx={{
          color: theme?.palette.primary.primaryText,
          fontSize: '13px',
          fontWeight: '500',
          lineHeight: '16px',
          letterSpacing: '0em',
          cursor: 'pointer',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '140px'
        }}>
          {params.row.city || 'N/A'}
        </Typography>
      </Box>
    )
  },
  {
    field: 'state',
    headerName: 'State',
    width: 150,
    renderCell: (params) => (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }}>
        <Typography sx={{
          color: theme?.palette.primary.primaryText,
          fontSize: '13px',
          fontWeight: '500',
          lineHeight: '16px',
          letterSpacing: '0em',
          cursor: 'pointer',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '140px'
        }}>
          {params.row.state || 'N/A'}
        </Typography>
      </Box>
    )
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 150,
    renderCell: (params) => (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }}>
        <Typography sx={{
          color: theme?.palette.primary.primaryText,
          fontSize: '13px',
          fontWeight: '500',
          lineHeight: '16px',
          letterSpacing: '0em',
          cursor: 'pointer',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '140px'
        }}>
          {params.row.country || 'N/A'}
        </Typography>
      </Box>
    )
  },
  {
    field: 'employees',
    headerName: '# Employees',
    width: 200,
    renderCell: (params) => {
      const employeeCount = formatNumberCommaSeparator(params?.row?.organization?.estimated_num_employees)
      const [anchorEl, setAnchorEl] = useState(null);
      const [isPlanCommonUpgradeModalOpen, setIsPlanCommonUpgradeModalOpen] = useState(false)
      const closeModal = (event) => {
        event.stopPropagation()
        setIsPlanCommonUpgradeModalOpen(false)
      }
      const openModal = (event) => {
        event.stopPropagation()
        setIsPlanCommonUpgradeModalOpen(true)
      }
      const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handlePopoverClose = () => {
        setAnchorEl(null);
      };

      const openPopover = Boolean(anchorEl);
      if (planName === 'free') {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              padding: '5px',
              borderRadius: '4px',
              width: 'fit-content',
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <Typography
              sx={{
                color: `${theme?.palette.primary.primaryText}`,
                fontSize: '13px',
                fontWeight: '500',
                lineHeight: '16px',
                letterSpacing: '0em',
                filter: 'blur(4px)',
                userSelect: 'none'
              }}
            >
              N/A
            </Typography>
            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{ pointerEvents: 'none' }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: `${theme?.palette.primary.primaryText}`,
                  color: 'white',
                  padding: '5px 16px',
                  fontSize: '14px',
                  fontWeight: '600',
                  pointerEvents: 'auto',
                  '&:hover': {
                    backgroundColor: `${theme?.palette.primary.primaryText}`,
                  },
                }}
                onClick={openModal}
              >
                Upgrade to Unlock
              </Button>
            </Popover>
            <Dialog
              open={isPlanCommonUpgradeModalOpen}
              onClose={closeModal}
              maxWidth='lg'
              fullWidth
            >
              <CommonUpgradeModal currentPlan={planName} closeModal={closeModal} />
            </Dialog>
          </Box>
        );
      }
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <Typography
            sx={{
              color: `${theme?.palette.primary.primaryText}`,
              fontSize: '13px',
              fontWeight: '500',
              lineHeight: '16px',
              letterSpacing: '0em',
              cursor: 'pointer'
            }}
          >
            {employeeCount}
          </Typography>
        </Box>
      )
    }
  },
  {
    field: 'industry',
    headerName: 'Industry',
    width: 200,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <Typography
          sx={{
            color: `${theme?.palette.primary.primaryText}`,
            fontSize: '13px',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '0em',
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '180px'
          }}
        >
          {params?.row?.organization?.industry}
        </Typography>
      </Box>
    )
  },
  {
    field: 'organization.intent.composite_score',
    headerName: 'Intent Composite Score',
    width: 200,
    renderCell: (params) => {
      const intentData = params.row.organization?.intent;
      if (!intentData?.[0]) return 'N/A';

      const score = intentData[0].composite_score;
      let scoreLevel = 'N/A';

      if (score >= 76 && score <= 100) {
        scoreLevel = 'High';
      } else if (score >= 63 && score <= 75) {
        scoreLevel = 'Medium';
      } else if (score >= 0 && score <= 62) {
        scoreLevel = 'Low';
      }

      return (
        <Typography sx={{
          color: theme?.palette.primary.primaryText,
          fontSize: '13px',
          fontWeight: '500'
        }}>
          {scoreLevel}
        </Typography>
      );
    }
  },
  {
    field: 'organization.intent.topic',
    headerName: 'Intent Topic',
    width: 200,
    renderCell: (params) => {
      const intentData = params.row.organization?.intent;
      const topic = intentData?.[0]?.topic;

      return (
        <Typography sx={{
          color: theme?.palette.primary.primaryText,
          fontSize: '13px',
          fontWeight: '500'
        }}>
          {topic || 'N/A'}
        </Typography>
      );
    }
  },
  {
    field: 'organization.intent.category',
    headerName: 'Intent Category',
    width: 200,
    renderCell: (params) => {
      const intentData = params.row.organization?.intent;
      const category = intentData?.[0]?.category;

      return (
        <Typography sx={{
          color: theme?.palette.primary.primaryText,
          fontSize: '13px',
          fontWeight: '500'
        }}>
          {category || 'N/A'}
        </Typography>
      );
    }
  },
  {
    field: 'keywords',
    headerName: 'Keywords',
    width: 200,
    renderCell: (params) => {
      const keywords = params?.row?.organization?.keywords || []

      // if (keywords?.length === 0) {
      //   return (
      //     <Typography
      //       sx={{
      //         color: `${theme?.palette.primary.primaryText}`,
      //         fontSize: '13px',
      //         fontWeight: '500',
      //         lineHeight: '16px',
      //         letterSpacing: '0em'
      //       }}
      //     >
      //       N/A
      //     </Typography>
      //   )
      const [anchorEl, setAnchorEl] = useState(null);
      const [isPlanCommonUpgradeModalOpen, setIsPlanCommonUpgradeModalOpen] = useState(false)
      const closeModal = (event) => {
        event.stopPropagation()
        setIsPlanCommonUpgradeModalOpen(false)
      }
      const openModal = (event) => {
        event.stopPropagation()
        setIsPlanCommonUpgradeModalOpen(true)
      }
      const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handlePopoverClose = () => {
        setAnchorEl(null);
      };

      const openPopover = Boolean(anchorEl);
      if (planName === 'free') {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              padding: '5px',
              borderRadius: '4px',
              width: 'fit-content',
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <Typography
              sx={{
                color: `${theme?.palette.primary.primaryText}`,
                fontSize: '13px',
                fontWeight: '500',
                lineHeight: '16px',
                letterSpacing: '0em',
                filter: 'blur(4px)',
                userSelect: 'none'
              }}
            >
              N/A
            </Typography>
            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{ pointerEvents: 'none' }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: `${theme?.palette.primary.primaryText}`,
                  color: 'white',
                  padding: '5px 16px',
                  fontSize: '14px',
                  pointerEvents: 'auto',
                  fontWeight: '600',
                  '&:hover': {
                    backgroundColor: `${theme?.palette.primary.primaryText}`,
                  },
                }}
                onClick={openModal}
              >
                Upgrade to Unlock
              </Button>
            </Popover>
            <Dialog
              open={isPlanCommonUpgradeModalOpen}
              onClose={closeModal}
              maxWidth='lg'
              fullWidth
            >
              <CommonUpgradeModal currentPlan={planName} closeModal={closeModal} />
            </Dialog>
          </Box>
        );
      }

      return (
        <Box
          sx={{
            color: `${theme?.palette.primary.primaryText}`,
            fontSize: '13px',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '0em',
            cursor: 'pointer'
          }}
        >
          {keywords.map((keyword, index) => (
            <Typography
              sx={{
                color: `${theme?.palette.primary.primaryText}`,
                fontSize: '13px',
                fontWeight: '500',
                lineHeight: '16px',
                letterSpacing: '0em',
                filter: 'blur(4px)',
                userSelect: 'none'
              }}
              key={index}
            >
              {keyword || 'N/A'}
            </Typography>
          ))}
        </Box>
      )
    }
  },
  {
    field: 'company_location',
    headerName: 'Company Location',
    width: 200,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <Typography
          sx={{
            color: `${theme?.palette.primary.primaryText}`,
            fontSize: '13px',
            fontWeight: '500',
            lineHeight: '16px',
            letterSpacing: '0em',
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '180px'
          }}
        >
          {params?.row?.organization?.raw_address}
        </Typography>
      </Box>
    )
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    renderCell: (params) => {
      const emailStatus = params?.row?.email_status

      const getColor = (status) => {
        switch (status) {
          case 'reject':
            return theme?.palette.error.main
          case 'pending':
            return theme?.palette.warning.main
          case 'verified':
            return theme?.palette.success.main
          default:
            return theme?.palette.text.main
        }
      }
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <Typography
            sx={{
              color: getColor(emailStatus),
              // color: "#28287B",
              fontSize: '13px',
              fontWeight: '500',
              lineHeight: '16px',
              letterSpacing: '0em',
              cursor: 'pointer'
            }}
          >
            {emailStatus}
          </Typography>
        </Box>
      )
    }
  },

]

const useStyles = makeStyles((theme) => ({
  customDataGrid: {
    '& .MuiDataGrid-root': {
      backgroundColor: '#fff',
      border: '1px solid #E4E4E5'
    },

    '& .MuiDataGrid-columnHeader': {
      backgroundColor: '#F2F4F6',
      '&:focus': { outline: 'none' },
      '&:focus-within': { outline: 'none' },
      borderBottom: '1px solid #E4E4E5',
      '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0em',
        // color: "#101010",
        color: `${theme?.palette.primary.primaryText}`
      }
    },
    '& .MuiDataGrid-row': {
      border: '1px solid #E4E4E5'
    },
    '& .MuiDataGrid-cell': {
      padding: '8px',
      '&:focus': { outline: 'none' },
      '&:focus-within': { outline: 'none' },
      '& .MuiDataGrid-cellContent': {
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0em',
        // color: alpha(theme.palette.primary.main, 0.8),
        color: `${theme?.palette.primary.primaryText}`
      }
    }
  }
}))

const scrollBarStyle = {
  // width
  '&::-webkit-scrollbar': {
    width: '14px'
  },

  // Track
  '&::-webkit-scrollbar-track': {
    borderRadius: '60px'
  },

  // /* Handle */
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#E4E4E5',
    borderRadius: '10px',
    border: '4px solid rgba(0, 0, 0, 0)',
    backgroundClip: 'padding-box'
  },

  // /* Handle on hover */
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#d5d5d5'
  }
}

// const InfoTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
//     borderRadius: '12px',
//     backgroundColor: 'white',
//     maxWidth: 500,
//     py: '8px',
//     px: '12px'
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     '&:before': {
//       border: '1px solid #E6E8ED'
//     },
//     color: 'white'
//   }
// }))

const LeadInfo = ({
  isLoadingUsage,
  formattedNumber,
  usage,
  setLeadCreditDialogOpen,
  setLeadCreditTab,
  fetchDownloadedData,
  isMobile,
  theme,
  closedCount,
  handleClickOpenImportLeadsDialog,
  handleClickRelevanceButton,
  relevanceToggle,
  handleCloseRelevance,
  isPanelOpen,
  setIsPanelOpen,
  descendingOpen,
  setDescendingOpen,
  handleRelevanceFilter,
}) => {
  // const LightTooltip = withStyles((theme) => ({
  //   tooltip: {
  //     backgroundColor: theme.palette.common.white,
  //     border: '1px solid #E6E8ED',
  //     color: 'rgba(0, 0, 0, 0.87)',
  //     boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
  //     fontSize: 11
  //   }
  // }))(Tooltip)
  return (
    // <Grid
    //   container
    //   sx={{
    //     // boxShadow: "0px 12px 15px 0px #4B71970D",
    //     borderRadius: '12px',
    //     backgroundColor: 'white',
    //     width: '100%',
    //     py: 1,
    //     // px: 1.5,
    //     pb: 1.5,
    //     gap: 1,
    //     transition: 'all ease 1s'
    //   }}
    // >
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%', // Ensure it takes full width
        flexWrap: 'wrap'
      }}
    >
      {/* Tab-like section */}
      <Box
        sx={{
          backgroundColor: '#E1E3EC',
          border: '1px solid #D7D9E6',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: { xs: '8px', sm: '10px' }, // Adjust gap for smaller screens
          padding: '4px 4px',
          width: '100%',
          maxWidth: '600px',

        }}
      >
        {/* Tab-like Buttons */}
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '6px',
            // color: "#101010",
            color: `${theme?.palette.primary.primaryText}`,
            fontSize: '14px',
            fontWeight: '700',
            textAlign: 'center',
            padding: '10px 20px',
            boxShadow: '0px 12px 15px 0px rgba(75, 113, 151, 0.05)',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            flex: '1 1 auto', // Allow this box to take up space
            // minWidth: '150px', 
            textOverflow: 'ellipsis'
          }}
        >
          All
        </Box>
        {/* Using Tooltip for "New" tab */}
        <Tooltip
          title='Coming soon...'
          arrow
          placement='top'
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: theme?.palette.primary.main || '#4B71F7',
                color: '#fff',
                fontSize: '12px',
                padding: '6px 12px',
                borderRadius: '6px'
              },
              '& .MuiTooltip-arrow': {
                color: theme?.palette.primary.main || '#4B71F7'
              }
            }
          }}
        >
          <Box
            sx={{
              backgroundColor: 'transparent',
              borderRadius: '6px',
              color: `${theme?.palette.primary.primaryText}`,
              fontSize: '14px',
              fontWeight: '700',
              textAlign: 'center',
              padding: '10px 20px',
              boxShadow: '0px 12px 15px 0px rgba(75, 113, 151, 0.05)',
              cursor: 'not-allowed', // indicates it's not clickable
              whiteSpace: 'nowrap',
              flex: '1 1 auto',
              // minWidth: '150px',
              textOverflow: 'ellipsis',
              opacity: 0.7,
              transition: 'all 0.3s',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            New
          </Box>
        </Tooltip>

        {/* Using Tooltip for "Saved Leads" tab */}
        <Tooltip
          title='Coming soon...'
          arrow
          placement='top'
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: theme?.palette.primary.main || '#4B71F7',
                color: '#fff',
                fontSize: '12px',
                padding: '6px 12px',
                borderRadius: '6px'
              },
              '& .MuiTooltip-arrow': {
                color: theme?.palette.primary.main || '#4B71F7'
              }
            }
          }}
        >
          <Box
            sx={{
              backgroundColor: 'transparent',
              borderRadius: '6px',
              color: `${theme?.palette.primary.primaryText}`,
              fontSize: '14px',
              fontWeight: '700',
              textAlign: 'center',
              padding: '10px 20px',
              boxShadow: '0px 12px 15px 0px rgba(75, 113, 151, 0.05)',
              cursor: 'not-allowed', // indicates it's not clickable
              whiteSpace: 'nowrap',
              flex: '1 1 auto',
              // minWidth: '150px',
              textOverflow: 'ellipsis',
              opacity: 0.7,
              transition: 'all 0.3s',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            Saved
          </Box>
        </Tooltip>
      </Box>

      {/* Relevance Button */}

      <Box
        sx={{
          marginLeft: 'auto',
          width: { xs: '100%', sm: 'auto' },
          gap: { xs: '8px', sm: '10px' },
          display: 'flex',
          gap: '8px'
        }}
      >
        <Button
          sx={{
            p: '10px 14px 10px 14px',
            gap: '6px',
            display: 'flex',
            justifyContent: 'center',
            minWidth: '110px',
            alignItems: 'center',
            border: '1px solid #E4E4E5',
            background: theme.palette.primary.contrastText,
            '&:hover': {
              background: ' #E1E3EC'
            }
          }}
          onClick={() => {
            setLeadCreditDialogOpen(true)
            setLeadCreditTab('download summary')
            fetchDownloadedData()
          }}
        >
          {!isMobile && <Download sx={{ color: 'gray', width: '20px', height: '20px' }} />}
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '17.64px',
              letterSpacing: '0em',
              color: `${theme.palette.primary.primaryText}`
            }}
          >
            Summary
          </Typography>
        </Button>
        <Button
          sx={{
            p: '10px 10px 10px 14px',
            gap: '6px',
            display: 'flex',
            justifyContent: 'center',
            minWidth: '180px',
            alignItems: 'center',
            border: '1px solid #E4E4E5',
            background: theme.palette.primary.contrastText,
            mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 },
            width: { xs: "100%", sm: "100%", md: 0 },
            '&:hover': {
              background: ' #E1E3EC'
            }
          }}
          onClick={handleClickOpenImportLeadsDialog}
        >
          {!isMobile && <Workspaces sx={{ color: 'gray', width: '20px', height: '20px' }} />}
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '17.64px',
              letterSpacing: '0em',
              color: `${theme.palette.primary.primaryText}`
            }}
          >
            Lead Enrichment
          </Typography>
        </Button>
        <Menu
          anchorEl={relevanceToggle}
          open={Boolean(relevanceToggle)}
          onClose={handleCloseRelevance}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiListItemIcon-root': {
              mr: 0
            },
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '& .MuiPaper-root': {
              width: '250px',
              padding: '10px'
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }}
        >
          <MenuItem disableRipple sx={{ p: 0, '&:hover': { backgroundColor: 'transparent' } }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px' }}>
              <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>Sort By....</Typography>
              <Box>
                <Button
                  // variant="outlined"
                  fullWidth
                  size='small'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    border: ' 1px solid #E4E4E5',
                    color: 'black',
                    borderRadius: '8px',
                    py: 1.5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 2
                  }}
                  onClick={() => {
                    setIsPanelOpen(!isPanelOpen)
                  }}
                >
                  Relevance
                  <IconButton size='small' onClick={() => setIsPanelOpen(!isPanelOpen)}>
                    <KeyboardArrowDown
                      sx={{
                        color: 'action.active',
                        rotate: isPanelOpen ? '180deg' : '0deg',
                        transition: '0.2s linear rotate'
                      }}
                    />
                  </IconButton>
                </Button>
              </Box>

              {isPanelOpen && (
                <Box>
                  {[
                    'Company',
                    'Name',
                    'Country',
                    'Number of Employees',
                    'Industry',
                    'Contact Stage',
                    'Contact Last Activity',
                    'Contact Owner',
                    'Contact Created',
                    'State',
                    'City',
                    'Email',
                    'Phone',
                    'Company Country',
                    'Company City',
                    'Account Stage',
                    'Account Owner'
                  ].map((item, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        borderRadius: item ? '8px' : '0px'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%'
                        }}

                      >
                        {item}
                        {/* Badge for Contact Stage */}
                        {/* {item === "Contact Stage" && (
                            <Badge
                              badgeContent={196}
                              color="error"
                              sx={{
                                "& .MuiBadge-badge": {
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  padding: "0 6px",
                                },
                              }}
                            />
                          )} */}
                      </Box>
                    </MenuItem>
                  ))}
                </Box>
              )}

              <Button
                // variant="contained"
                fullWidth
                size='small'
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  border: ' 1px solid #E4E4E5',
                  color: 'black',
                  borderRadius: '8px',
                  py: 1.5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 2
                }}
                onClick={() => {
                  setDescendingOpen(!descendingOpen)
                }}
              >
                Descending
                <IconButton
                  size='small'
                  onClick={() => {
                    setDescendingOpen(!descendingOpen)
                  }}
                >
                  <KeyboardArrowDown
                    sx={{
                      color: 'action.active',
                      rotate: isPanelOpen ? '180deg' : '0deg',
                      transition: '0.2s linear rotate'
                    }}
                  />
                </IconButton>
              </Button>
              {descendingOpen && (
                <Box>
                  {['Ascending', 'Descending'].map((item, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        borderRadius: item ? '8px' : '0px'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        {item}
                      </Box>
                    </MenuItem>
                  ))}
                </Box>
              )}
              <Button
                variant='contained'
                fullWidth
                size='small'
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',

                  // border: " 1px solid #E4E4E5",
                  // color: "black",
                  borderRadius: '8px',
                  py: 1.5
                }}
                onClick={handleRelevanceFilter}
              >
                Apply
              </Button>
            </Box>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
    // </Grid>
  )
}

const ToggleComponent = ({ alignment, handleChange, isMobile }) => {
  const windowWidth = useWindowWidth()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: { xs: 0, md: '0.5rem' },
        px: { xs: 2, md: '4px' },
        background: ' #E1E3EC',
        py: '4px',
        border: '1px soild #D7D9E6',
        borderRadius: '8px',
        width: { xs: "100%", md: "auto" },
        mt: { xs: "5%", md: "auto" }
      }}
    >
      <ToggleButtonGroup
        color='primary'
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label='Platform'
        sx={{
          backgroundColor: '#E1E3EC', // Background for the toggle button group
          border: 'none',
          borderRadius: '6px',
          // boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
          overflow: 'hidden',
          width: '100%',
          // mx: 1,
          gap: '8px'
        }}
      >
        <ToggleButton
          className={`round-btn ${alignment === 'People' ? 'selectedBg' : 'default'}`}
          value='People'
          sx={{
            pt: '9px',
            pb: '9px',
            width: '50%',
            border: 'none',
            borderRadius: '6px',
            height: '100%',
            fontWeight: alignment === 'People' ? '700' : '600',
            color: alignment === 'People' ? 'black' : '#6c757d',
            // backgroundColor: alignment === "People" ? "white" : "#E1E3EC",
            '&:hover': {
              backgroundColor: alignment === 'People' ? 'white' : '#f5f5f5'
            }
          }}
        >
          People{' '}
          {windowWidth > 1700
            ? (
              'Search'
            )
            : (
              <SearchIcon sx={{ marginLeft: '10px', height: 18, width: 18 }} />
            )}
        </ToggleButton>
        <ToggleButton
          className={`round-btn ${alignment === 'Companies' ? 'selectedBg' : 'default'}`}
          value='Companies'
          sx={{
            pt: '9px',
            pb: '9px',
            width: '50%',
            border: 'none', // Removes border between buttons
            borderRadius: '6px',
            height: '100%',
            fontWeight: alignment === 'Companies' ? '700' : '600',
            color: alignment === 'Companies' ? 'black' : '#6c757d',
            // backgroundColor: alignment === "Companies" ? "white" : "#E1E3EC",
            '&:hover': {
              backgroundColor: alignment === 'Companies' ? 'white' : '#f5f5f5'
            }
          }}
        >
          Company{' '}
          {windowWidth > 1700
            ? (
              'Search'
            )
            : (
              <SearchIcon sx={{ marginLeft: '10px', height: 18, width: 18 }} />
            )}
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

const planNames = {
  [config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID]: 'starter',
  [config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID]: 'starter-yearly',
  [config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID]: 'growth',
  [config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID]: 'growth-yearly',
  [config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID]: 'infinity',
  [config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID]: 'infinity-yearly',
  [config.LEADS_MONTHLY_GROWTH_PRICE_ID]: 'starter',
  [config.LEADS_YEARLY_GROWTH_PRICE_ID]: 'starter-yearly',
  [config.LEADS_MONTHLY_SKYROCKET_PRICE_ID]: 'growth',
  [config.LEADS_YEARLY_SKYROCKET_PRICE_ID]: 'growth-yearly',
  [config.LEADS_MONTHLY_SCALE_PRICE_ID]: 'infinity',
  [config.LEADS_YEARLY_SCALE_PRICE_ID]: 'infinity-yearly'
}

const FilterColumn = ({
  handleChange,
  isMobile,
  filterCount,
  handleClear,
  clearLocation,
  setClearLocation,
  isSavingSearch,
  handleSaveSearchClick,
  handleShowAllSavedSearchClick,
  filter,
  handleFilterChange,
  height,
  alignment,
  delayedSearch,
  prevFilter,
  onKeyPressSearch,
  toggle,
  handleClose,
  handleClickButton,

  summarytoggle,

  handleClickSummaryButton,
  handleSummaryClose,
  handleReturnPlan,
  theme
}) => {
  const currentPlan = useGetCurrentPlanQuery()
  const planId = currentPlan?.currentData?.subscription?.sendingWarmup?.planId ?? currentPlan?.currentData?.subscription?.leads?.planId
  const { isEarlyBirdAccessUser: isEarlyBirdUser, isAppSumoUser: isAppSumo, isWhiteLabelUser } = useUserVerification()
  const planName = isEarlyBirdUser || isAppSumo ? 'earlyBird' : (planNames[planId] ?? 'free')

  const isWithStartUserLocked = !isWhiteLabelUser && ['free', 'starter', 'starter-yearly'].includes(planName)
  const isFreeUserLocked = !isWhiteLabelUser && ['free'].includes(planName)
  const [openFilter, setOpenFilter] = useState(null)
  handleReturnPlan(planName)
  const handleFilterToggle = (filterKey) => {
    setOpenFilter(openFilter === filterKey ? null : filterKey)
  }
  return (
    <Box>
      {!isMobile && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'white',
            borderRadius: '12px',
            padding: 2,
            paddingTop: 2,
            paddingBottom: 1,
            position: 'sticky',
            top: 0,
            zIndex: 999
          }}
        >
          {!isMobile && (
            <ToggleComponent
              alignment={alignment}
              handleChange={handleChange}
              isMobile={isMobile}
            />
          )}
          {!isMobile && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                // position: 'sticky',
                // top: 0,
                // zIndex: 999,
                backgroundColor: 'white',
                py: 1,
                // height: "100%",
                // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                borderRadius: '12px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  // px: 2,
                  backgroundColor: 'white'
                }}
              >
                {' '}
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '0.9em',
                    fontWeight: 700,
                    lineHeight: '18px',
                    letterSpacing: '0px',
                    color: theme.palette.primary.primaryText
                  }}
                >
                  Filters{' '}
                  {filterCount !== 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        background: `${theme.palette.primary.main}`,
                        fontWeight: 700,
                        color: 'white',
                        borderRadius: '6px',
                        padding: '4px 8px',
                        fontSize: '12px',
                        ml: 1
                      }}
                    >
                      {filterCount}
                      <IconButton
                        size='small'
                        onClick={handleClear}
                        sx={{
                          color: 'white',
                          p: 0,
                          ml: 0.5,
                          '&:hover': { color: '#90caf9' }
                        }}
                      >
                        <Close sx={{ fontSize: 14 }} />
                      </IconButton>
                    </Box>
                  )}
                </Typography>
                <Button
                  variant='text'
                  onClick={handleClickButton}
                  sx={{
                    textTransform: 'none',
                    minWidth: '40px',
                    ml: 'auto',

                    border: '1px solid  #D7D9E6',
                    borderRadius: '10px'
                  }}
                >
                  <MoreVertIcon sx={{ color: 'black' }} />
                </Button>
                <Menu
                  anchorEl={toggle}
                  open={Boolean(toggle)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  sx={{
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1,
                    '& .MuiListItemIcon-root': {
                      mr: 0
                    },
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    '& .MuiPaper-root': {
                      width: '200px',
                      padding: '8px'
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }}
                >
                  <MenuItem disableRipple sx={{ p: 0, '&:hover': { backgroundColor: 'transparent' } }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 1 }}>
                      <Button
                        variant='outlined'
                        fullWidth
                        size='small'
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          lineHeight: '20px'
                        }}
                        onClick={handleShowAllSavedSearchClick}
                      >
                        Saved Searches
                      </Button>
                      <Button
                        variant='contained'
                        fullWidth
                        size='small'
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          lineHeight: '20px'
                        }}
                        disabled={filterCount === 0 || isSavingSearch}
                        onClick={handleSaveSearchClick}
                      >
                        Save Current Search
                      </Button>
                    </Box>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          )}
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          gap: 2,
          // paddingBottom: '60px',
          overflowY: 'auto',
          // maxHeight: '80vh',
          maxHeight: 'calc(100vh - 220px)',
          '&::-webkit-scrollbar': {
            display: 'none' // Hide the scrollbar
          },
          msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
          scrollbarWidth: 'none' // Hide scrollbar for Firefox
        }}
      >
        {/* Boxes container - changed to column */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 1
          }}
        >
          {/* First Box */}
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'white',
              p: 2,
              pb: 2,
              pt: 0,
              mt: { xs: 0, md: "8px" },
              display: 'flex',
              flexDirection: 'column',
              boxShadow: '0px 12px 15px 0px #4B71970D',
              borderRadius: '12px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '14px'
              },
              '&::-webkit-scrollbar-track': {
                borderRadius: '60px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#E4E4E5',
                borderRadius: '10px',
                border: '4px solid rgba(0, 0, 0, 0)',
                backgroundClip: 'padding-box'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#d5d5d5'
              }

            }}
          >
            <Box sx={{ width: '100%', mt: { xs: 0, md: 1 } }}>
              {alignment === 'Companies'
                ? (
                  <>
                    <h4 style={{ fontSize: '0.9em', fontWeight: 'bold', marginBottom: '1em' }}>Company Info</h4>

                    <SearchInput
                      key='person-name'
                      name='Search companies...'
                      onChange={handleFilterChange}
                      value={filter.name}
                    />
                    <LocationFilter
                      key='company-location'
                      type='company'
                      value={{ geo: filter.geo, cGeo: filter.cGeo, oGeo: filter.oGeo, excludeGeo: filter.excludeGeo, excludeOGeo: filter.excludeOGeo, excludeCGeo: filter.excludeCGeo }}
                      handleFilterChange={handleFilterChange}
                      clearLocation={clearLocation}
                      setClearLocation={setClearLocation}
                      isOpen={openFilter === 'company-location'}
                      onToggle={() => handleFilterToggle('company-location')}
                    />
                    <KeywordFilter
                      isLocked={isFreeUserLocked}
                      key='company-keywords'
                      type='company'
                      onChange={handleFilterChange}
                      plan={planName}
                      isOpen={openFilter === 'company-keywords'}
                      onToggle={() => handleFilterToggle('company-keywords')}
                    />
                    <DomainFilter
                      key='company-domain'
                      type='company'
                      onChange={handleFilterChange}
                      isOpen={openFilter === 'company-domain'}
                      onToggle={() => handleFilterToggle('company-domain')}
                    />
                    <TechnologyFilter
                      isLocked={isWithStartUserLocked}
                      plan={planName}
                      key='company-technology'
                      type='company'
                      onChange={handleFilterChange}
                      isOpen={openFilter === 'company-technology'}
                      onToggle={() => handleFilterToggle('company-technology')}
                    />
                    <IndustryFilter
                      key='company-industry'
                      type='company'
                      onChange={handleFilterChange}
                      isOpen={openFilter === 'company-industry'}
                      onToggle={() => handleFilterToggle('company-industry')}
                    />
                    <EmployeeCount
                      key='company-employee'
                      type='company'
                      onChange={handleFilterChange}
                      isOpen={openFilter === 'company-employee'}
                      onToggle={() => handleFilterToggle('company-employee')}
                    />
                  </>
                )
                : (
                  <>
                    <h4 style={{ fontSize: '0.9em', fontWeight: 'bold', marginBottom: '1em' }}>People Info</h4>

                    <SearchInput
                      key='person-name'
                      name='Search name, firstname...'
                      onChange={handleFilterChange}
                      value={filter.name}
                    />
                    <LocationFilter
                      key='person-location'
                      type='person'
                      value={{ geo: filter.geo, cGeo: filter.cGeo, oGeo: filter.oGeo, excludeGeo: filter.excludeGeo, excludeOGeo: filter.excludeOGeo, excludeCGeo: filter.excludeCGeo }}
                      handleFilterChange={handleFilterChange}
                      clearLocation={clearLocation}
                      setClearLocation={setClearLocation}
                      isOpen={openFilter === 'person-location'}
                      onToggle={() => handleFilterToggle('person-location')}
                    />
                    <TitleRoleFilter
                      key='person-title'
                      type='person'
                      onChange={handleFilterChange}
                      isOpen={openFilter === 'person-title'}
                      onToggle={() => handleFilterToggle('person-title')}
                    />
                    <LanguageFilter
                      key='person-language'
                      type='person'
                      onChange={handleFilterChange}
                      isOpen={openFilter === 'person-language'}
                      onToggle={() => handleFilterToggle('person-language')}
                    />
                    <KeywordFilter
                      isLocked={isFreeUserLocked}
                      key='people-keywords'
                      type='person'
                      onChange={handleFilterChange}
                      plan={planName}
                      isOpen={openFilter === 'person-keywords'}
                      onToggle={() => handleFilterToggle('person-keywords')}
                    />
                  </>
                )}
            </Box>
          </Box>

          {alignment === 'People' && (
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'white',
                p: 2,
                pb: 2,
                pt: 0,
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0px 12px 15px 0px #4B71970D',
                borderRadius: '12px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '14px'
                },
                '&::-webkit-scrollbar-track': {
                  borderRadius: '60px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#E4E4E5',
                  borderRadius: '10px',
                  border: '4px solid rgba(0, 0, 0, 0)',
                  backgroundClip: 'padding-box'
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#d5d5d5'
                }
              }}
            >
              <Box sx={{ width: '100%', mt: 1 }}>
                <h4 style={{ fontSize: '0.9em', fontWeight: 'bold', marginBottom: '10px' }}>Company Info</h4>

                <DomainFilter
                  key='person-domain'
                  type='person'
                  onChange={handleFilterChange}
                  isOpen={openFilter === 'person-domain'}
                  onToggle={() => handleFilterToggle('person-domain')}
                />
                <IndustryFilter
                  key='person-industry'
                  type='person'
                  onChange={handleFilterChange}
                  isOpen={openFilter === 'person-industry'}
                  onToggle={() => handleFilterToggle('person-industry')}
                />
                <EmployeeCount
                  key='person-employee'
                  type='person'
                  onChange={handleFilterChange}
                  isOpen={openFilter === 'person-employee'}
                  onToggle={() => handleFilterToggle('person-employee')}
                />
                <CompanyKeywordFilter
                  isLocked={isFreeUserLocked}
                  key='company-keywords'
                  type='company'
                  onChange={handleFilterChange}
                  plan={planName}
                  isOpen={openFilter === 'company-keywords'}
                  onToggle={() => handleFilterToggle('company-keywords')}
                />
                <FundingFilter
                  handleFilterChange={handleFilterChange}
                  clear={clearLocation}
                  setClear={setClearLocation}
                  isLocked={isWithStartUserLocked}
                  plan={planName}
                  isOpen={openFilter === 'funding'}
                  onToggle={() => handleFilterToggle('funding')}
                />
                <TechnologyFilter
                  isLocked={isWithStartUserLocked}
                  plan={planName}
                  key='person-technology'
                  type='person'
                  onChange={handleFilterChange}
                  isOpen={openFilter === 'person-technology'}
                  onToggle={() => handleFilterToggle('person-technology')}
                />
                <BuyingIntent
                  plan={planName}
                  value={{ intent: filter.intent }}
                  handleFilterChange={handleFilterChange}
                  clear={clearLocation}
                  setClear={setClearLocation}
                  isOpen={openFilter === 'buying-intent'}
                  onToggle={() => handleFilterToggle('buying-intent')}
                  topics={currentPlan?.currentData?.planUsage?.buyerIntendDataTopiscs}
                />
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            // boxShadow: '0px 12px 15px 0px #4B71970D',
            borderRadius: '12px',
            backgroundColor: 'white',
            paddingTop: 2,
            paddingBottom: 2,
            '&::before': {
              content: '""',
              display: 'block',
              height: '8px',
              width: '100%',
              position: 'absolute',
              top: -8,
              left: 0,
              backgroundColor: '#F9F9FA',
              zIndex: 1
            }
          }}
        >
          <Button
            variant='contained'
            color='primary'
            sx={{
              position: 'sticky',
              width: '95%',
              minHeight: '48px',
              zIndex: 10,
              bottom: '30px',
              left: 10,
              right: 0,
              mt: 'auto',
              borderRadius: '12px',
              color: 'white',
              backgroundColor: isEqual(filter, prevFilter) ? '#D7D9E6' : `${theme.palette.primary.main}`,
              '&:hover': {
                backgroundColor: isEqual(filter, prevFilter) ? ' #D7D9E6' : 'primary.dark'
              },
              '&.Mui-disabled': {
                backgroundColor: '#D7D9E6',
                color: 'white'
              }
            }}
            disabled={isEqual(filter, prevFilter)}
            onClick={delayedSearch}
          >
            {alignment === 'Companies' ? 'Search' : 'Search'}
          </Button>
        </Box>
      </Box>
    </Box>

  )
}

const Page = () => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const isInitialRender = useRef(true)
  const serachDataFromRedux = useSelector((state) => state?.search)
  const store = useStore()
  const [listType] = useState('email')
  const [isImportLeadsDialogOpen, setIsImportLeadsDialogOpen] = useState(false)
  const [workspaceId, setWorkspaceId] = useState()
  const currentWorkspace = useSelector((state) => state.workspace)
  const [timezone, setTimezone] = useState('')
  const [toggle, setToggle] = useState(null)
  const [relevanceToggle, setRelevanceToggle] = useState(null)
  const [summarytoggle, setSummaryToggle] = useState(null)
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [descendingOpen, setDescendingOpen] = useState(false)
  const [toggleButton, setToggleButton] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(true);
  const [planName, setPlanName] = useState(null);

  const handleClickButton = (event) => {
    setToggle(event.currentTarget)
  }
  const handleReturnPlan = (planName) => {
    setPlanName(planName)
  }
  const handleClose = () => {
    setToggle(null)
  }
  const handleClickToggleButton = (event) => {
    setToggleButton(event.currentTarget)
  }

  const handleCloseToggleButton = () => {
    setToggleButton(null)
  }
  const handleClickRelevanceButton = (event) => {
    setRelevanceToggle(event.currentTarget)
  }

  const handleCloseRelevance = () => {
    setRelevanceToggle(null)
  }
  const handleClickSummaryButton = (event) => {
    setSummaryToggle(event.currentTarget)
  }

  const handleSummaryClose = () => {
    setSummaryToggle(null)
  }
  const [leadCreditDialogOpen, setLeadCreditDialogOpen] = useState(false)
  const [leadCreditTab, setLeadCreditTab] = useState('')
  const [downloadFiles, setDownloadFiles] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50)
  const [totalFiles, setTotalFiles] = useState(0)
  const [selectedFileId, setSelectedFileId] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [getSaveFiles, { isLoading: isDownloadFilesLoading }] = useGetSaveFilesMutation()
  const [getDownloadFile, { isLoading: isDownloadCSVLoading }] = useGetDownloadFileMutation()

  const handleLimitChange = (event) => {
    setLimit(event.target.value)
    setPage(1)
  }
  const offset = (page - 1) * limit

  const fetchFiles = async () => {
    try {
      const files = await getSaveFiles({ limit, offset })
      setDownloadFiles(files?.data?.docs)
      setTotalFiles(files?.data?.total)
    } catch (error) {
      console.error('Error fetching files:', error)
    }
  }

  // const debounceRefetchSavedFiles = useCallback(debounce(fetchFiles, 500), [limit, offset])

  useEffect(() => {
    if (shouldFetch) {
      fetchFiles()
    }
  }, [page, limit, refresh])

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (leadCreditDialogOpen && leadCreditTab === 'download summary' && page === 1) {
  //       debounceRefetchSavedFiles()
  //     }
  //   }, 5000)

  //   return () => clearInterval(intervalId)
  // }, [leadCreditDialogOpen, leadCreditTab, debounceRefetchSavedFiles])

  const handleRefresh = () => {
    setRefresh(!refresh)
  }

  useEffect(() => {
    if (shouldFetch) {
      fetchFiles()
    }
  }, [page, limit, refresh])

  const closeCreditModal = () => {
    setLeadCreditDialogOpen(false)
    refetchUsage()
    setShouldFetch(false)
    setLeadCreditTab('')
    setPage(1)
    setLimit(50)
    setTimeout(() => setShouldFetch(true), 0);
  }

  useEffect(() => {
    if (currentWorkspace) {
      setWorkspaceId(currentWorkspace?._id)

      const userTimezone = currentWorkspace?.timezone?.split(' ')[0]

      if (userTimezone) {
        setTimezone(userTimezone)
      }
    }
  }, [currentWorkspace])

  const handleClickOpenImportLeadsDialog = () => {
    setIsImportLeadsDialogOpen(true)
  }

  const handleCloseOpenImportLeadsDialog = () => {
    setIsImportLeadsDialogOpen(false)
    setActiveStep(0)
  }

  const onLeadsCreate = (createdLeads) => {
    if (createdLeads) setLeadCreated(true)
    setActiveStep(0)
    setIsImportLeadsDialogOpen(false)
  }
  const [activeStep, setActiveStep] = useState(0)
  const [setLeadCreated] = useState(false)
  const [setSnackbarOpen] = useState(false)
  const [setSnackbarMsg] = useState('')
  const leadFinder = true
  const {
    total,
    selectedRows,
    leads,
    companies,
    filter,
    companyFilter,
    companiesTotal,
    alignment,
    shouldTrigger,
    infinityToasterId
  } = serachDataFromRedux || {}

  // console.log('selectedRows', leads);
  const isCompanyTab = alignment === 'Companies'
  let usedLeads = (leads || []).filter((row) => row?.company_id || row?.id)
  let usedFilter = filter
  let usedTotal = total

  if (isCompanyTab) {
    usedLeads = (companies || []).filter((row) => row?.company_id || row?.id)
    usedFilter = companyFilter
    usedTotal = companiesTotal
  }

  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 })
  const [rowClickedId, setRowClickedId] = useState(null)
  const [prevFilter, setPrevFilter] = useState({})
  const [clearLocation, setClearLocation] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)
  const [findLeads, { isLoading: isFindLeadsLoading }] = useFindLeadsMutation()

  const [findCompanies, { isLoading: isfindCompaniesLoading }] = useFindCompaniesMutation()
  const { data: searches, isLoading: isGetSearchesLoading } = useGetSearchesQuery({
    alignment
  })
  const { data: campaigns } = useGetCampaignsQuery({
    unibox: true,
    option: true,
    workspaceId
  })
  const campaignsList = campaigns?.updatedEmail?.map((e) => e) || []
  // const { data: downloadFiles, refetch: refetchSavedFiles } = useGetSavedFilesQuery();
  const [changeDownloadCsvResults] = useChangeDownloadCsvResultsMutation()
  // const closedCount = downloadFiles?.filter((file) => !file.isOpen).length;
  const closedCount = Array.isArray(downloadFiles)
    ? downloadFiles.filter((file) => !file.isOpen).length
    : 0

  const { data: usage, isLoading: isLoadingUsage, refetch: refetchUsage } = useBillingUsageQuery()
  const { data: leadUsage, refetch: refetchLeadUsage } = useGetLeadsUsageQuery()
  const [currentResults, setCurrentResults] = useState(false)
  const filterCount = Object.keys(filterObject(usedFilter)).length
  const prevCOunt = Object.keys(filterObject(prevFilter)).length
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [openDialog, setOpenDialog] = useState(false)
  const [verifyLeads, setVerifyLeads] = useState(false)
  // const [validateEmails, setValidateEmails] = useState({
  //   varified: false,
  //   unVerified: false,
  //   risky: false,
  //   all: false,
  // })
  const [phoneNumber, setPhoneNumber] = useState(false)
  // eslint-disable-next-line
  const [batchIdForDownload, setBatchIdForDownload] = useState(null)
  const [leadDuplicate, setLeadDuplicate] = useState(true)
  const [alreadyExist, setAlreadyExist] = useState(0)
  const [leadsConfirmation, { isLoading: isConfirmingLeads }] = useLeadsConfirmationMutation()
  const handleChange = (event, newAlignment) => {
    if (isMobile) setFilterOpen(!filterOpen);
    if (newAlignment) {
      if (newAlignment === 'Companies') {
        setPrevFilter(companyFilter)
      } else {
        setPrevFilter(filter)
      }
      dispatch(
        setSeachData({
          ...serachDataFromRedux,
          alignment: newAlignment
        })
      )
    }
  }
  // const handleEmailValidate = (event) => {
  //   event.preventDefault();
  //   const { name, checked } = event.target;
  //   setValidateEmails((prevState) => ({
  //     ...prevState,
  //     [name]: checked,
  //   }));
  // }
  useEffect(() => {
    if (currentResults === true) {
      refetchUsage()
    }
  }, [currentResults])

  useEffect(() => {
    refetchUsage()
    refetchLeadUsage()
    // refetchSavedFiles();
  }, [refetchUsage, refetchLeadUsage])
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await refetchSavedFiles();
  //     if (data) {
  //       handelDowloadCsv(data.data[0])
  //     }

  //     return data
  //   }

  //   if (batchIdForDownload) {
  //     fetchData()
  //   }
  // }, [batchIdForDownload]);

  const companyColumns = [
    {
      field: 'action',
      headerName: '',
      width: 55,
      renderCell: (params) => (
        <Tooltip title="Search Employees">
          <IconButton
            color="primary"
            onClick={() => handleEmployeesSearchClick(params)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
      )
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 250
    },
    {
      field: 'primary_domain',
      headerName: 'Email Domain',
      width: 200
    },
    {
      field: 'industry',
      headerName: 'Industry',
      width: 200
    },
    {
      field: 'technology_names',
      headerName: 'Technology Name',
      width: 200
    },
    {
      field: 'founded_year',
      headerName: 'Founded Year',
      width: 200
    },
    {
      field: 'raw_address',
      headerName: 'Location',
      width: 200
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 200
    },
    {
      field: 'city',
      headerName: 'City',
      width: 200
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 200
    },
    {
      field: 'estimated_num_employees',
      headerName: 'Employees',
      width: 200
    },
    {
      field: 'total_funding',
      headerName: 'Total Funding',
      width: 200
    },
    {
      field: 'keywords',
      headerName: 'Keywords',
      width: 200
    },
    {
      field: 'languages',
      headerName: 'Languages',
      width: 200
    },
    {
      field: 'twitter_url',
      headerName: 'Twitter',
      width: 200
    },
    {
      field: 'website_url',
      headerName: 'Website',
      width: 200
    },
    {
      field: 'crunchbase_url',
      headerName: 'CrunchBase URL',
      width: 200
    },
    {
      field: 'intent.composite_score',
      headerName: 'Intent Composite Score',
      width: 200
    },
    {
      field: 'intent.topic',
      headerName: 'Intent Topic',
      width: 200
    },
    {
      field: 'intent.category',
      headerName: 'Intent Category',
      width: 200
    }
  ]

  const skillsArray = [
    'departments',
    'education campus',
    'education major',
    'education specialization',
    'employment history description',
    'employement history start date',
    'employment history title',
    'languages',
    'title'
  ]

  const handleEmployeesSearchClick = (params) => {
    const validLinks = [params?.row?.primary_domain, params?.row?.name]
      .filter(value => value && value !== 'null' && value !== 'undefined');
    dispatch(
      setSeachData({
        ...serachDataFromRedux,
        leads: [],
        filter: {
          link: validLinks,
        }, // Passing Id instead of company_ID
        shouldTrigger: true,
        selectedRows: true,
        alignment: 'People'
      })
    )
  }

  const getRelevantIntentByScore = (row, intentScore) => {
    const getScoreRange = (intentScore) => {
      if (intentScore.includes('Low')) {
        return { min: 1, max: 35 }
      } else if (intentScore.includes('Medium')) {
        return { min: 36, max: 65 }
      } else if (intentScore.includes('High')) {
        return { min: 66, max: 100 }
      }
      return null
    }

    const scoreRange = getScoreRange(intentScore)
    if (scoreRange) {
      return row.intent?.find(
        (intent) =>
          intent.composite_score >= scoreRange.min && intent.composite_score <= scoreRange.max
      )
    }
    return null
  }

  const getRelevantIntentByTopic = (row, intentTopic) => {
    return row.intent?.find((intent) => intentTopic.includes(intent.topic))
  }

  const delayedSearch = debounce(async () => {
    try {
      setPrevFilter(usedFilter)
      if (filterCount === 0) {
        if (alignment === 'People') {
          dispatch(
            setSeachData({
              ...serachDataFromRedux,
              total: 0,
              leads: []
            })
          )
        } else if (alignment === 'Companies') {
          dispatch(
            setSeachData({
              ...serachDataFromRedux,
              total: 0,
              companies: []
            })
          )
        }
        return
      }
      if (alignment === 'People') {
        if (usedFilter?.LinkedIn && usedFilter?.LinkedIn?.length > 0) {
          const linkedinUrl = usedFilter?.LinkedIn[0]
          const isLinkedInUrlValid = linkedinUrl.includes('linkedin.com')
          if (!isLinkedInUrlValid) {
            toast.error('Only LinkedIn URLs are accepted.')
            return
          }
        }
        const { skills = [] } = usedFilter
        if (skills.length > 0) {
          const isUnique = skills.some((keyword) => !skillsArray.includes(keyword))

          if (!isUnique) {
            toast.error('Please enter a unique keyword that is not already in the list')
            return
          }
        }
        const { pagination, people } = await findLeads({
          start: paginationModel.page * paginationModel.pageSize + 1,
          page_size: paginationModel.pageSize,
          query: usedFilter
        }).unwrap()
        window.Intercom('trackEvent', 'Leads searched')
        dispatch(
          setSeachData({
            ...serachDataFromRedux,
            total: pagination.total_entries,
            leads: people,
            shouldTrigger: false
          })
        )
      } else if (alignment === 'Companies') {
        if (usedFilter?.linkedin_url && usedFilter?.linkedin_url?.length > 0) {
          const linkedinUrl = usedFilter.linkedin_url[0]
          const isLinkedInUrlValid = linkedinUrl.includes('linkedin.com')
          if (!isLinkedInUrlValid) {
            toast.error('Only LinkedIn URLs are accepted.')
            return
          }
        }
        if (usedFilter?.founded_year) {
          const foundedYear = Number(usedFilter?.founded_year)
          const isValidFoundedYear =
            !isNaN(foundedYear) && foundedYear >= 1000 && foundedYear <= new Date().getFullYear()
          if (!isValidFoundedYear) {
            toast.error('Please enter a valid founded year.')
            return
          }
        }
        const { pagination, companies } = await findCompanies({
          start: paginationModel.page + 1,
          page_size: paginationModel.pageSize,
          query: usedFilter
        }).unwrap()
        window.Intercom('trackEvent', 'Leads searched')
        dispatch(
          setSeachData({
            ...serachDataFromRedux,
            companiesTotal: pagination.total_entries,
            companies,
            shouldTrigger: false
          })
        )
      } else {
        dispatch(
          setSeachData({
            ...serachDataFromRedux,
            shouldTrigger: false
          })
        )
      }
    } catch (error) {
      if (error.data.error.message && error.data.error.message === "Email not verified!") {
        toast.error(error.data.error.message)
        setVerified('unverified');
      }
    }
  }, 1500)

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    delayedSearch()
    return () => {
      delayedSearch.cancel()
    }
  }, [paginationModel])

  useEffect(() => {
    const footer = document.getElementsByClassName('MuiTablePagination-displayedRows')[0]
    if (footer) {
      const prefix = footer?.textContent?.split('of ')?.[0]
      const suffix = footer?.textContent?.split('of ')?.[1]

      if (suffix) {
        const cleanText = suffix.replace(/[,]/g, '')

        const value = new Intl.NumberFormat('en-US').format(Number(cleanText))

        footer.innerText = `${prefix} of ${value}`
      }
    }
  }, [])
  const handleFilterToggle = () => {
    setFilterOpen(!filterOpen)
  }
  const handleFilterChange = (key, value, shouldTriggerSearch = false) => {
    if (alignment === 'Companies') {
      const updatedCompanyFilters = filterObject({ ...companyFilter, [key]: value })
      if (!isEqual(companyFilter, updatedCompanyFilters)) {
        const filterLength = Object.keys(updatedCompanyFilters)?.length
        dispatch(
          setSeachData({
            ...serachDataFromRedux,
            companyFilter: updatedCompanyFilters,
            shouldTrigger: shouldTriggerSearch,
            ...(filterLength === 0 && { companies: [] })
          })
        )
      }
    } else {
      const updatedFilter = filterObject({ ...filter, [key]: value })
      if (!isEqual(filter, updatedFilter)) {
        const filterLength = Object.keys(updatedFilter)?.length
        dispatch(
          setSeachData({
            ...serachDataFromRedux,
            filter: updatedFilter,
            selectedRows: [],
            shouldTrigger: shouldTriggerSearch,
            ...(filterLength === 0 && { leads: [] })
          })
        )
      }
    }
    // setPrevFilter({})
  }

  const handleConfirmDownload = () => {
    // Handle CSV download logic here
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  useEffect(() => {
    if (shouldTrigger) {
      delayedSearch()
    }
  }, [shouldTrigger])

  const handleClear = () => {
    setPaginationModel({ page: 0, pageSize: 25 })
    if (alignment === 'Companies') {
      dispatch(
        setSeachData({
          ...serachDataFromRedux,
          companies: [],
          companyFilter: {},
          companiesTotal: 0
        })
      )
    } else {
      toast.remove(infinityToasterId)
      dispatch(
        setSeachData({
          ...serachDataFromRedux,
          leads: [],
          filter: {},
          total: 0,
          selectedRows: [],
          infinityToasterId: null
        })
      )
    }
    setPrevFilter({})
    setClearLocation(true)
    setSelectFirstResults(1)
    setSelectLastResults(25)
    setSelectMenuDropdown({
      selectPage: false,
      selectAll: false,
      selectFirst: false
    })
  }

  const handleClearRange = () => {
    setSelectFirstResults(1)
    setSelectLastResults(25)
    setSelectMenuDropdown({
      selectPage: false,
      selectAll: false,
      selectFirst: false
    })
  }

  const [isLeadDetailDrawerOpen, setIsLeadDetailDrawerOpen] = useState(false)

  const handleLeadDetailDrawerClose = () => {
    setIsLeadDetailDrawerOpen(false)
  }

  const handleSearchClick = (query) => {
    setIsAllSavedSearchDrawerOpen(false)
    console.log('ali', alignment, query)
    dispatch(
      setSeachData({
        ...serachDataFromRedux,
        [alignment === 'Companies' ? 'companyFilter' : 'filter']: query,
        shouldTrigger: true,
        selectedRows: [],
        alignment
      })
    )
  }

  // all saved search
  const [isAllSavedSearchDrawerOpen, setIsAllSavedSearchDrawerOpen] = useState(false)

  const [
    triggerGetAllSavedSearches,
    { data: savedSearches, isFetching: isFetchingAllSavedSearches }
  ] = useLazyGetAllSavedSearchesQuery()

  const handleShowAllSavedSearchClick = () => {
    setIsAllSavedSearchDrawerOpen(true)
    triggerGetAllSavedSearches(alignment)
  }

  const handleAllSavedSearchDrawerClose = () => {
    setIsAllSavedSearchDrawerOpen(false)
  }

  // save & edit search
  const [searchId, setSearchId] = useState(null)
  const [searchName, setSearchName] = useState('')
  const [isSavedSearchDialogOpen, setIsSavedSearchDialogOpen] = useState(false)

  const [createSavedSearch, { isLoading: isCreatingSavedSearch }] = useCreateSavedSearchMutation()
  const [updateSearch, { isLoading: isUpdatingSearch }] = useUpdateSearchMutation()
  const isSavingSearch = isCreatingSavedSearch || isUpdatingSearch

  const getSearchName = () => Object.values(usedFilter).flat().slice(0, 3).join(', ')

  const handleSaveSearchClick = () => {
    const searchName = getSearchName().trim()
    setSearchName(searchName)
    setSearchId(null)
    setIsSavedSearchDialogOpen(true)
  }

  const handleEditSearchClick = (id) => {
    const search = searches.saved.find((s) => s._id === id)
    setSearchName(search.name.trim())
    setSearchId(id)
    setIsSavedSearchDialogOpen(true)
  }

  const doesSearchExist = (name) => {
    return savedSearches?.some(
      (search) => search.name.trim().toLowerCase() === name.trim().toLowerCase()
    )
  }

  const handleSaveSearchDialogSave = async () => {
    if (isSavingSearch) return

    const trimmedSearchName = searchName.trim()

    try {
      await triggerGetAllSavedSearches()

      if (doesSearchExist(trimmedSearchName)) {
        toast.error('Search already exists with this name')
        return
      }

      if (searchId) {
        // Update existing search
        const { message } = await updateSearch({
          id: searchId,
          data: { name: trimmedSearchName }
        }).unwrap()
        toast.success(message)
      } else {
        // Create new saved search
        const { message } = await createSavedSearch({
          name: trimmedSearchName,
          query: usedFilter,
          category: alignment === 'People' ? 'people' : 'company'
        }).unwrap()
        window.Intercom('trackEvent', 'Leads saved')
        toast.success(message)

        await triggerGetAllSavedSearches()
        setCurrentResults(true)
      }
    } catch (error) {
      if (error.data?.error?.message === 'Search name already exists') {
        toast.error('Search already exists with this name')
      } else {
        toast.error(error.data?.error?.message || 'An error occurred')
      }
    } finally {
      if (!doesSearchExist(trimmedSearchName)) {
        setIsSavedSearchDialogOpen(false)
        setCurrentResults(false)
      }
    }
  }

  const handleSaveSearchDialogClose = () => {
    setIsSavedSearchDialogOpen(false)
  }

  // add to list
  // const [isAddToListDialogOpen, setIsAddToListDialogOpen] = useState(false)
  const [dailogType, setDailogType] = useState('')
  // const [selectedList, setSelectedList] = useState(null)
  // const [addLeadsToCampaign] = useAddLeadsToCampaignMutation();
  const [addLeadsToList] = useAddLeadsToListMutation()
  // const { data: lists } = useGetListsQuery({
  //   workspaceId
  // })
  // console.log("lists", lists);
  const handleAddToListClick = () => {
    // setIsAddToListDialogOpen(true)
    setIsAddToCampaignDialogOpen(true)
    setDailogType('list')
  }

  // const handleAddToListDialogClose = () => {
  //   // setIsAddToListDialogOpen(false)
  //   setIsAddToCampaignDialogOpen(false)
  //   setDailogType('')
  //   // setSelectedList(null)
  //   setSelectedCampaign(null)
  // }

  const handleAddToListDialogSave = async () => {
    if (selectedRows.length > leadsCredits) {
      toast.error(`Add to List Leads is larger than Daily Limit ${selectedRows.length}...`)
      return
    }

    if (verifyLeads && selectedRows.length > bounceCredits) {
      toast.error('Insufficient Lead Verification Credits: Please add more credits')
      return
    }

    const toastId = toast.loading(`${selectedRows.length} Leads Adding to list`, {
      duration: 3000
    })
    // setIsAddToListDialogOpen(false)
    setIsAddToCampaignDialogOpen(false)
    setDailogType('')

    const selectedLeads = leads.filter((lead) => selectedRows.includes(lead?.id))
    if (!verifyLeads) {
      const limit = 1000
      const total = selectedLeads.length
      const batchId = uuidv4()

      // Process each chunk sequentially
      for (let i = 0; i < total; i += limit) {
        const start = i
        const end = i + limit > total ? i + (total % limit) : i + limit
        const chunk = selectedLeads.slice(start, end)

        try {
          // for (let campaignId of selectedCampaign) {
          await addLeadsToList({
            // listId: selectedList,
            listId: selectedCampaign?._id,
            leads: selectedRows,
            checkDuplicates: leadDuplicate,
            // useBounce: verifyLeads,
            workspaceId,
            dataSelected: chunk,
            batchId
          }).unwrap()

          setSnackbarMsg(`${end} / ${total} leads uploading`)
          // }
        } catch (error) {
          console.error('Error processing chunk:', error)
          continue
        }
      }
    } else {
      try {
        const batchId = uuidv4()
        // for (let campaignId of selectedCampaign) {
        const { message } = await addLeadsToList({
          // listId: selectedList,
          listId: selectedCampaign?._id,
          leads: selectedRows,
          checkDuplicates: leadDuplicate,
          // useBounce: verifyLeads,
          workspaceId,
          dataSelected: [],
          batchId
        }).unwrap()

        toast.success(message, { id: toastId, duration: 2000 })
        window.Intercom('trackEvent', 'Leads added to List')
        setCurrentResults(true)
        // }
      } catch (error) {
        toast.error(error?.data?.error?.message, { id: toastId, duration: 2000 })
        setCurrentResults(false)
      }
    }

    // setSelectedList(null)
    setSelectedCampaign(null)
  }

  // add to campaign
  // const [selectedCampaign, setSelectedCampaign] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  // eslint-disable-next-line
  const [isCampaign, setIsCampaign] = useState(false)
  // const [checkDuplicates] = useState(false);
  const [isAddToCampaignDialogOpen, setIsAddToCampaignDialogOpen] = useState(false)
  const [addToCampaignConfirmation, setAddToCampaignConfirmation] = useState(false)
  const navigate = useNavigate()

  const [addLeadsToCampaign] = useAddLeadsToCampaignMutation()
  const location = useLocation()
  const { campaignId } = location.state || {}
  const { isAppSumoUser: userVerifiedStatus } = useUserVerification()
  const [itemCountStatus, setItemCountStatus] = useState(true)
  useEffect(() => {
    let requestedLeadCount = 0
    let getLeadCount = 1
    if (downloadFiles?.length > 0) {
      requestedLeadCount = downloadFiles[0]?.totalLeads
      getLeadCount = downloadFiles[0]?.data?.length

      if (requestedLeadCount === getLeadCount) {
        setItemCountStatus(false)
      } else {
        setItemCountStatus(true)
      }
    }
  }, [downloadFiles])

  const handleAddToCampaignClick = () => {
    if (campaignId) {
      // showAlreadyAddedLeads();
      handleAddToCampaignDialogSave()
    } else {
      setIsAddToCampaignDialogOpen(true)
      setDailogType('campaign')
    }
    setIsLeadDetailDrawerOpen(false)
  }

  useEffect(() => {
    // Check if userVerifiedStatus is false
    if (!userVerifiedStatus) {
      setPhoneNumber(true) // Update phoneNumber to true
    }
  }, [userVerifiedStatus])

  const handleAddToCampaignDialogSave = async () => {
    if (!phoneNumber && selectedRows.length > leadsCredits) {
      toast.error(`Add to Campaign Leads is larger than ${selectedRows.length}...`)
      return
    }

    if (selectedRows.length > activeLeads && selectedRows.length > phoneNumberLimit) {
      toast.error('Insufficient Active Contacts Credits: Please add more credits')
      return
    }
    const range = (selectLastResults ?? 0) - (selectFirstResults ?? 0)

    if (range > activeLeads) {
      toast.error('Insufficient Active Contacts Credits: Please add more credits')
      return
    }

    if (verifyLeads && selectedRows.length > bounceCredits) {
      toast.error('Insufficient Lead Verification Credits: Please add more credits')
      return
    }

    if (verifyLeads && range > bounceCredits) {
      toast.error('Insufficient Lead Verification Credits: Please add more credits')
      return
    }

    if (userVerifiedStatus && phoneNumber && range > phoneNumberLimit) {
      toast.error('Insufficient Phone Number Credits: Please add more credits')
      return
    }
    setIsAddToCampaignDialogOpen(false)
    setDailogType('')
    setIsCampaign(true)

    const selectedLeads = leads.filter((lead) => verifyLeads ? (selectedRows.includes(lead?.id) && ['verified', 'risky', 'unverified'].includes(lead.email_status)) : selectedRows.includes(lead?.id))

    if (selectMenuDropdown?.selectFirst) {
      const range =
        (selectLastResults ?? 0) -
        (selectFirstResults ?? 0) +
        (selectLastResults === selectFirstResults ? 1 : 0)
      const batchId = uuidv4()
      setOpenDialog(false)
      const rangeValue =
        (selectLastResults ?? 0) -
        (selectFirstResults ?? 0) +
        (selectLastResults === selectFirstResults ? 1 : 0)
      const loadingToastId = toast.loading(`Adding ${rangeValue + 1} leads in campaign...`, {
        duration: Infinity
      })

      try {
        await addLeadsToCampaign({
          campaignId: campaignId || selectedCampaign?.id,
          leads: ['2466a089-ca64-45a7-bce5-476b6cb57e46'],
          checkDuplicates: leadDuplicate,
          useBounce: verifyLeads,
          workspaceId,
          dataSelected: [],
          batchId,
          all_results: true,
          start: selectFirstResults,
          end: selectLastResults,
          totalSelected: range,
          rangeValue,
          page_size: paginationModel?.pageSize,
          query: usedFilter,
          phoneNumber
        }).unwrap()
        setSelectMenuDropdown({
          selectPage: false,
          selectAll: false,
          selectFirst: false
        })
        setSelectFirstResults(1)
        setSelectLastResults(25)
        toast.dismiss(loadingToastId)
        // for (let campaignId of selectedCampaign) {
        //   const { message } = await addLeadsToCampaign({
        //     campaignId: campaignId,
        //     leads: ['2466a089-ca64-45a7-bce5-476b6cb57e46'],
        //     checkDuplicates: leadDuplicate, // needs to be false
        //     useBounce: verifyLeads,
        //     workspaceId,
        //     dataSelected: [],
        //     batchId,
        //     all_results: true,
        //     start: selectFirstResults,
        //     end: selectLastResults,
        //     totalSelected: range,
        //     rangeValue,
        //     page_size: paginationModel?.pageSize,
        //     query: usedFilter,
        //     phoneNumber
        //   }).unwrap();

        //   // Reset UI after processing
        //   setSelectMenuDropdown({
        //     selectPage: false,
        //     selectAll: false,
        //     selectFirst: false
        //   });
        //   setSelectFirstResults(1);
        //   setSelectLastResults(25);
        //   toast.dismiss(loadingToastId);
        // }
        // toast.success(message, { duration: 3000 });
      } catch (error) {
        if (error?.data?.error?.message && error?.data?.error?.message === "Email not verified!") {
          toast.error(error?.data?.error?.message, { duration: 3000 });
          toast.dismiss(loadingToastId)
          setVerified('unverified');
        } else {
          toast.dismiss(loadingToastId)
          toast.error('Failed to export leads. Please try again.', { duration: 3000 })
        }
      }
      return
    }
    if (!verifyLeads) {
      const limit = 1000
      const total = selectedLeads.length
      const batchId = uuidv4()
      // Process each chunk sequentially
      for (let i = 0; i < total; i += limit) {
        const start = i // Starting index
        const end = i + limit > total ? i + (total % limit) : i + limit // Calculate the end index, ensuring it doesn't exceed total
        // console.log("satrt", start, "end", end);
        const chunk = selectedLeads.slice(start, end) // Get the current chunk
        try {
          // Iterate over each campaign ID and add leads in chunks
          // for (let campaignId of selectedCampaign) {
          //   const { message } = await addLeadsToCampaign({
          //     campaignId: campaignId,
          //     leads: selectedRows, // or use chunk if necessary
          //     checkDuplicates: leadDuplicate,
          //     useBounce: verifyLeads,
          //     workspaceId,
          //     dataSelected: chunk,
          //     batchId,
          //     query: usedFilter,
          //     phoneNumber
          //   }).unwrap();

          //   toast.loading(`Adding ${total} leads in campaign...`, { duration: 3000 });
          //   setIsCampaign(false);
          //   setSelectedCampaign([]);
          //   setVerifyLeads(false);
          //   setLeadDuplicate(true);
          // }
          console.log('select>>', selectedCampaign)

          await addLeadsToCampaign({
            campaignId: campaignId || selectedCampaign?.id,
            leads: selectedRows,
            checkDuplicates: leadDuplicate,
            useBounce: verifyLeads,
            workspaceId,
            dataSelected: chunk,
            batchId,
            query: usedFilter,
            phoneNumber
          }).unwrap()
          toast.loading(`Adding ${total} leads in campaign...`, { duration: 3000 })
          setIsCampaign(false)
          setSelectedCampaign(null)
          setVerifyLeads(false)
          setLeadDuplicate(true)
          // Optionally, update any progress or success messages here
          setSnackbarMsg(`${end} / ${total} leads uploading`)
        } catch (error) {
          console.error('Error processing chunk:', error)
          // toast.error(error.data.error.message, { id: toastId, duration: 2000 });
          continue // Stop processing if an error occurs
        }
      }
    } else {
      try {
        const batchId = uuidv4()
        // Iterate over each campaign ID in selectedCampaign
        // for (let campaignId of selectedCampaign) {
        //   const { message } = await addLeadsToCampaign({
        //     campaignId: campaignId,
        //     leads: selectedRows, // or use selectedLeads
        //     checkDuplicates: leadDuplicate,
        //     useBounce: verifyLeads,
        //     workspaceId,
        //     dataSelected: [],
        //     batchId,
        //     query: usedFilter,
        //     phoneNumber
        //   }).unwrap();

        //   toast.loading(`Adding ${total} leads in campaign...`, { duration: 3000 });
        //   setIsCampaign(false);
        //   setSelectedCampaign([]);
        //   setVerifyLeads(false);
        //   setLeadDuplicate(true);

        //   if (campaignId) {
        //     navigate(`/campaigns/${campaignId}`);
        //   }
        //   window.Intercom('trackEvent', 'Leads added to Campaign');
        //   setCurrentResults(true);
        // }
        await addLeadsToCampaign({
          campaignId: campaignId || selectedCampaign?.id,
          leads: selectedRows,
          checkDuplicates: leadDuplicate,
          useBounce: verifyLeads,
          workspaceId,
          dataSelected: [],
          batchId,
          query: usedFilter,
          phoneNumber
        }).unwrap()
        // toast.success(message, { id: toastId, duration: 2000 });
        toast.loading(`Adding ${total} leads in campaign...`, { duration: 3000 })
        setIsCampaign(false)
        setSelectedCampaign(null)
        setVerifyLeads(false)
        setLeadDuplicate(true)
        if (campaignId) {
          navigate(`/campaigns/${campaignId}`)
        }
        window.Intercom('trackEvent', 'Leads added to Campaign')
        setCurrentResults(true)
      } catch (error) {
        // toast.error(error?.data?.error?.message, { id: toastId, duration: 2000 });
        setCurrentResults(false)
        setIsCampaign(false)
        setSelectedCampaign(null)
        setVerifyLeads(false)
        setLeadDuplicate(true)
      }
    }
  }

  const handleAddToCampaignDialogClose = () => {
    setIsAddToCampaignDialogOpen(false)
    setDailogType('')
    setSelectedCampaign(null)
    setIsCampaign(false)
    setVerifyLeads(false)
    setLeadDuplicate(true)
  }

  // const showAlreadyAddedLeads = async () => {
  //   const leadEmails = leads.reduce((emails, lead) => {
  //     if (selectedRows.includes(lead?.id)) {
  //       emails.push(lead?.email)
  //     }
  //     return emails
  //   }, [])
  //   const leadsData = await leadsConfirmation({
  //     campaignId: campaignId || selectedCampaign?.id,
  //     leads: leadEmails,
  //     checkDuplicates: leadDuplicate,
  //     workspaceId
  //   })
  //   setAlreadyExist(leadsData?.data?.length)
  //   if (leadsData?.data?.length == 0) {
  //     handleAddToCampaignDialogSave()
  //     setIsAddToCampaignDialogOpen(false)
  //     setDailogType('')
  //     return
  //   }
  //   setIsAddToCampaignDialogOpen(false)
  //   setDailogType('')
  //   setAddToCampaignConfirmation(true)
  // }

  const handleCloseConfirmationModal = () => {
    setAddToCampaignConfirmation(false)
    setSelectedCampaign(null)
    setIsCampaign(false)
    setVerifyLeads(false)
    setLeadDuplicate(true)
  }

  // download CSV
  const [lookupLeads] = useLookupLeadsMutation()
  const handleDownloadCsvClick = async () => {
    refetchUsage()
    const range = ((selectLastResults ?? 0) - (selectFirstResults ?? 0))
    if (selectedRows.length > dailyLeadLimit) {
      toast.error(
        `Download Leads exceeds the Daily Limit of ${dailyLeadLimit}. You selected ${selectedRows.length}.`
      )
      return
    }
    if (userVerifiedStatus && phoneNumber && selectedRows.length > phoneNumberLimit) {
      toast.error(
        `Download Leads exceeds the Phone Number Limit of ${phoneNumberLimit}. You selected ${selectedRows.length}.`
      )
      return
    }

    if (userVerifiedStatus && !phoneNumber && selectedRows.length > leadsCredits) {
      toast.error('Insufficient Lead Credits: Please add more credits')
      return
    }
    if (verifyLeads && selectedRows.length > bounceCredits) {
      toast.error('Insufficient Lead Verification Credits: Please add more credits')
      return
    }
    if (verifyLeads && range > bounceCredits) {
      toast.error('Insufficient Lead Verification Credits: Please add more credits')
      return
    }

    if (userVerifiedStatus && phoneNumber && range > phoneNumberLimit) {
      toast.error('Insufficient Phone Number Credits: Please add more credits')
      return
    }

    if (range > 10000) {
      const toastId = toast.error(
        'The selected range must not exceed 10000 leads',
        {
          duration: 2000,
          id: lastErrorToast
        }
      )
      setLastErrorToast(toastId)
      return
    }

    if (selectLastResults < selectFirstResults) {
      const toastId = toast.error(
        'The last selection value must be greater than the first selection value.',
        {
          duration: 2000,
          id: lastErrorToast
        }
      )
      setLastErrorToast(toastId)
      return
    }
    const selectedLeads = Array.isArray(selectedRows)
      ? leads.filter((lead) => selectedRows.includes(lead?.id))
      : []
    setLeadCreditDialogOpen(true)
    setLeadCreditTab('download summary')
    setItemCountStatus(true)
    handleRefresh()

    if (selectMenuDropdown?.selectFirst) {
      const range = ((selectLastResults ?? 0) - (selectFirstResults ?? 0) + (selectLastResults === selectFirstResults ? 1 : 0))
      const batchId = uuidv4()
      const searchName = getSearchName()
      setOpenDialog(false)
      const rangeValue = ((selectLastResults ?? 0) - (selectFirstResults ?? 0))
      const loadingToastId = toast.loading(`Downloading ${rangeValue + 1} leads: In progress...`, { duration: 2000 })
      try {
        await lookupLeads({
          leads: selectedRows,
          name: searchName,
          useBounce: verifyLeads,
          phoneNumber,
          workspaceId,
          dataSelected: [],
          batchId,
          all_results: true,
          start: selectFirstResults,
          end: selectLastResults,
          totalSelected: range,
          rangeValue,
          page_size: paginationModel?.pageSize,
          query: usedFilter
        }).unwrap()
        setSelectMenuDropdown({
          selectPage: false,
          selectAll: false,
          selectFirst: false
        })
        setSelectFirstResults(1)
        setSelectLastResults(25)
        toast.dismiss(loadingToastId)
        refetchUsage()
        // toast.success(message, { duration: 3000 });
      } catch (error) {
        toast.dismiss(loadingToastId)
        if (error?.data?.error?.message && error?.data?.error?.message === "Email not verified!") {
          setLeadCreditDialogOpen(false)
          toast.error(error?.data?.error?.message, { duration: 3000 });
          setVerified('unverified');
        } else {
          toast.error('Failed to export leads. Please try again.', { duration: 3000 })
        }
      }
      return
    }
    if (!verifyLeads) {
      const limit = 1000 // Set the chunk size
      const total = selectedLeads?.length // Total selectedLeads to process
      // const toastId = toast.loading(`Downloading ${total} leads: In progress...`, { duration: 1000 })
      const searchName = getSearchName()
      const batchId = uuidv4() // Generate a unique batch ID
      // Process each chunk sequentially
      for (let i = 0; i < total; i += limit) {
        const start = i // Starting index
        const end = i + limit > total ? i + (total % limit) : i + limit // Calculate the end index, ensuring it doesn't exceed total
        // console.log("satrt", start, "end", end);
        const chunk = selectedLeads?.slice(start, end) // Get the current chunk
        // console.log("chunk", chunk);

        // console.log('...........chunk..............', chunk);
        // console.log('selectedRows', selectedRows);
        // console.log('batchId', batchId);
        try {
          await lookupLeads({
            leads: selectedRows, // Send the IDs of the current chunk
            name: searchName,
            useBounce: verifyLeads,
            workspaceId,
            dataSelected: chunk,
            batchId,
            all_results: false,
            phoneNumber
          }).unwrap()
          setOpenDialog(false)
          // console.log("Chunk processed successfully:", message);
          // Optionally, update any progress or success messages here
          // setSnackbarMsg(`${end} / ${total} leads uploading`);
          setOpenDialog(false)
          refetchUsage()
          if (end >= total) {
            setBatchIdForDownload(batchId) // New state to track batch ID
            setOpenDialog(false)
          }
        } catch (error) {
          console.error('Error processing chunk:', error)
          // toast.error(error.data.error.message, { id: toastId, duration: 2000 });
          setOpenDialog(false)
          continue // Stop processing if an error occurs
        }
      }

      // Once all chunks are processed, close the dialog and show success
      // toast.success("All chunks processed successfully!", { id: toastId, duration: 2000 });
    } else {
      try {
        const searchName = getSearchName()
        const batchId = uuidv4()
        const { message } = await lookupLeads({
          leads: selectedRows,
          name: searchName,
          useBounce: verifyLeads,
          workspaceId,
          dataSelected: [],
          batchId,
          all_results: false,
          phoneNumber
        }).unwrap()
        setOpenDialog(false)
        toast.success(message, {
          id: toast.loading(`Processing ${total} contacts...`, { duration: 3000 }),
          duration: 2000
        })
        refetchUsage()
      } catch (error) {
        // toast.error(error.data.error.message, { id: toast.loading(`Processing ${total} contacts...`, { duration: Infinity }), duration: 2000 });
      }
    }
  }

  const user = useSelector((state) => state.user)
  const id = user?._id
  const fetchDownloadedData = async () => {
    await changeDownloadCsvResults(id).unwrap()
    // refetchSavedFiles();
    refetchUsage()
    refetchLeadUsage()
  }

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (leadCreditDialogOpen === true && leadCreditTab === "download summary") {
  //       debounceRefetchSavedFiles();
  //     }
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, [leadCreditDialogOpen, leadCreditTab]);

  // const debounceRefetchSavedFiles = debounce(() => {
  //   // refetchSavedFiles();
  // }, 500);

  useEffect(() => {
    if (leadCreditTab === "download summary") {
      const timer = setTimeout(() => {
        handleRefresh();
      }, 5000);

      return () => clearTimeout(timer); // Cleanup in case component unmounts
    }
  }, [leadCreditTab, handleRefresh]);

  const handelDowloadCsv = async (item) => {
    let file;
    setSelectedFileId(item?._id)
    if (!item?.leadSavedUrl) {
      file = await getDownloadFile(item?._id).unwrap();
      downloadCsv(item.name, file?.data, item?.leadSavedUrl);
      return
    }
    await downloadCsvWithSignedURL(item?.leadSavedUrl)
  }

  const slotPropStyles = {
    panel: {
      sx: {
        '& .MuiDataGrid-filterForm': {
          borderRadius: '8px',
          p: '15px',
          flexDirection: 'column',
          border: `1px solid ${theme.palette.grey[300]}`,
          gap: 2,
          pt: 2
        },
        '& .MuiDataGrid-filterFormColumnInput, .MuiDataGrid-filterFormOperatorInput': {
          width: '100%',

          '& div': {
            p: 1,
            borderRadius: '8px',

            border: `1px solid ${theme.palette.grey[300]}`,
            '&:hover': {
              border: '1px solid #28287B'
            },
            '&:before': { borderBottom: 'none' },
            '&:after': { borderBottom: 'none' },
            '&:hover:before': {
              borderBottom: 'none'
            }
          }
        },

        '& .MuiDataGrid-filterFormValueInput': {
          width: '100%',

          '& div div': {
            p: 1,
            borderRadius: '8px',

            border: `1px solid ${theme.palette.grey[300]}`,
            '&:hover': {
              border: '1px solid #28287B',
              '&:before': {
                borderBottom: 'none'
              }
            },
            '&:before': { borderBottom: 'none' },
            '&:after': { borderBottom: 'none' }
          }
        },
        '& .MuiDataGrid-filterFormDeleteIcon': {
          position: 'absolute',
          top: 0,
          right: 0,
          width: 30
        },
        '& .MuiDataGrid-panelHeader div ': {
          m: 0,
          '& .MuiFormLabel-root.MuiInputLabel-root': { display: 'none' },
          '& .MuiInput-input': {
            padding: '10px',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.grey[300]}`,
            '&:hover:not(.Mui-disabled, .Mui-error)': {
              border: `1px solid ${theme.palette.grey[700]}`
            }
          },
          '&:before': { borderBottom: 'none' },
          '&:after': { borderBottom: 'none' },
          '&:hover:before': {
            borderBottom: 'none'
          }
        },

        '& .MuiDataGrid-columnsPanel': {
          px: 2,
          py: 0,
          '& .MuiDataGrid-columnsPanelRow': {
            '& label': {
              width: '100%',
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              m: 0,
              '& .MuiSwitch-track': {
                backgroundColor: theme.palette.grey[300]
              },
              '& .Mui-checked+.MuiSwitch-track': {
                backgroundColor: '#34C759'
              }
            }
          }
        },
        '& .MuiDataGrid-panelFooter': {
          px: 2
        }
      }
    },

    columnMenu: {
      sx: {
        '& .MuiDivider-root': { margin: 0, display: 'none' },
        '& .MuiTypography-root': {
          fontSize: '13px',
          fontWeight: '700',
          color: alpha(theme.palette.primary.main, 0.8)
        },
        '& .MuiSvgIcon-root': { fill: alpha(theme.palette.primary.main, 0.8) },
        '& .MuiMenuItem-root': {
          my: 1,
          mx: 2,
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
            '& .MuiSvgIcon-root': { fill: theme.palette.primary.main },
            '& .MuiTypography-root': {
              color: theme.palette.primary.main
            }
          }
        }
      }
    },
    footer: {
      sx: {
        '& .MuiDataGrid-selectedRowCount, .MuiTablePagination-selectLabel,.MuiSelect-select.MuiInputBase-input, .MuiTablePagination-displayedRows':
        {
          fontSize: '14px',
          fontWeight: '700',
          color: alpha(theme.palette.primary.main, 0.8)
        }
      }
    }

    // footer: {
    //   sx: {
    //     "& .MuiTablePagination-root": {
    //       display: "flex",
    //       flexDirection: "row",
    //       justifyContent: "space-between", // Space between start and end sections

    //       width: "100%",
    //     },
    //     "& .MuiTablePagination-displayedRows": {
    //       fontSize: "14px",
    //       fontWeight: "700",
    //       color: alpha(theme.palette.primary.main, 0.8),
    //       justifyContent: "start",
    //       textAlign: "start",
    //     },
    //     "& .MuiDataGrid-selectedRowCount, .MuiTablePagination-selectLabel, .MuiSelect-select.MuiInputBase-input":
    //       {
    //         fontSize: "14px",
    //         fontWeight: "700",
    //         color: "red",
    //         justifyContent: "end",
    //         textAlign: "end",
    //       },
    //   },
    // },
  }
  const dataGridStyles = {
    width: '100%',
    '& .MuiDataGrid-columnSeparator': { display: 'none' },
    '& .MuiDataGrid-virtualScroller': { marginTop: '-2px' },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      // background: "#f1f1f1",
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[300]
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.grey[400]
    },
    '& .MuiList-root.MuiMenu-list': {
      width: '100px'
    }
  }

  const [formattedNumber, setFormattedNumber] = useState(0)
  const leadsCredits = usage?.leadsCredits
  const dailyLeadLimit = usage?.dailyLeadLimit
  const phoneNumberLimit = usage?.leadsPhoneNumberExportsPerMonth
  const bounceCredits = usage?.bounceCredit
  const activeLeads = usage?.activeLeads - usage?.usedActiveLeads
  useEffect(() => {
    if (typeof leadsCredits === 'number') {
      const formatted = new Intl.NumberFormat('en-US').format(leadsCredits)
      setFormattedNumber(formatted)
    } else {
      setFormattedNumber(0)
    }
  }, [leadsCredits])

  const { verified, setVerified } = useUserVerifyCheck({ lead: true })
  // select all dropdown
  const [anchorEl, setAnchorEl] = useState(null)
  const [lastErrorToast, setLastErrorToast] = useState(null)
  const [selectFirstResults, setSelectFirstResults] = useState(1)
  const [selectLastResults, setSelectLastResults] = useState(25)
  const [selectMenuDropdown, setSelectMenuDropdown] = useState({
    selectPage: false,
    selectAll: false,
    selectFirst: false
  })
  const handleSelectAllClick = (checked) => {
    if (selectedRows?.length > 0) {
      dispatch(
        setSeachData({
          ...serachDataFromRedux,
          selectedRows: []
        })
      )
      return
    }
    setAnchorEl(checked.currentTarget)

    setSelectMenuDropdown((prev) => ({
      selectPage: false,
      selectAll: false,
      selectFirst: false
    }))
  }

  const handleSelectMenuOption = async (option) => {
    switch (option) {
      case 0:
        if (leads?.length > leadsCredits && leads?.length > phoneNumberLimit) {
          const toastId = toast.error(
            `You don't have enough lead balance to select ${leads.length} leads`,
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }

        if (leads?.length > dailyLeadLimit) {
          const toastId = toast.error(
            `You don't have enough daily lead balance to select ${leads.length} leads`,
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }

        setSelectMenuDropdown((prev) => ({
          ...prev,
          selectPage: true
        }))
        dispatch(
          setSeachData({
            ...serachDataFromRedux,
            selectedRows: usedLeads.map((lead) => lead.id)
          })
        )
        setAnchorEl(false)
        break

      case 1:
        if (leadsCredits < 10000) {
          const toastId = toast.error('You don\'t have enough lead balance to select 10000 leads', {
            duration: 2000,
            id: lastErrorToast
          })
          setLastErrorToast(toastId)
          return
        }
        // eslint-disable-next-line no-case-declarations
        const current = leadsCredits
        if (current > total && usedLeads?.length >= total) {
          dispatch(
            setSeachData({
              ...serachDataFromRedux,
              selectedRows: usedLeads.map((lead) => lead.id)
            })
          )
          setAnchorEl(false)
          setSelectMenuDropdown((prev) => ({
            ...prev,
            selectAll: true
          }))
        } else {
          const toastId = toast.loading(
            `Processing ${total >= current ? current : total} contacts, This may take a while`,
            {
              duration: Infinity
            }
          )
          dispatch(
            setSeachData({
              ...serachDataFromRedux,
              infinityToasterId: toastId
            })
          )

          setAnchorEl(false)
          setSelectMenuDropdown((prev) => ({
            ...prev,
            selectAll: true
          }))

          await findAllLeads(
            {
              all_results: true,
              start: 1,
              page_size: paginationModel?.pageSize,
              totalSelected: total > current ? current : total,
              query: usedFilter
            },
            toastId
          )
          const { search } = store.getState()
          if (toastId === search?.infinityToasterId) {
            toast.success('Processed Successfully', { id: toastId, duration: 2000 })
          } else {
            toast.remove(toastId)
          }
        }
        break

      case 2:
        // eslint-disable-next-line no-case-declarations
        const range = (selectLastResults ?? 0) - (selectFirstResults ?? 0)
        if (range > leadsCredits) {
          const toastId = toast.error(
            `You don't have enough lead balance to select ${range} leads`,
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }
        if (range > dailyLeadLimit) {
          const toastId = toast.error(
            `You don't have enough daily lead balance to select ${range} leads`,
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }

        if (selectFirstResults === 0) {
          const toastId = toast.error(
            'The starting selection value must be greater than 0. Please adjust and try again',
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }
        if (range > total) {
          const toastId = toast.error(
            'The selection range cannot exceed the total available results',
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }

        if (range > 10000) {
          const toastId = toast.error('The selected range must not exceed 10000 leads', {
            duration: 2000,
            id: lastErrorToast
          })
          setLastErrorToast(toastId)
          return
        }

        if (range > usedLeads?.length) {
          const toastId = toast.loading(`Processing ${range} contacts, This may take a while`, {
            duration: Infinity
          })
          dispatch(
            setSeachData({
              ...serachDataFromRedux,
              infinityToasterId: toastId
            })
          )
          setAnchorEl(false)
          // console.log("selectFirstResults", selectFirstResults);
          // console.log("paginationModel?.pageSize", paginationModel?.pageSize);
          // console.log("usedFilter", usedFilter);

          const { people, pagination } = await findLeads({
            all_results: true,
            start: selectFirstResults,
            end: selectLastResults,
            totalSelected: range,
            page_size: paginationModel?.pageSize,
            query: usedFilter
          }).unwrap()
          setSelectFirstResults(1)
          setSelectLastResults(25)
          const { search } = store.getState()
          if (toastId === search?.infinityToasterId) {
            dispatch(
              setSeachData({
                ...serachDataFromRedux,
                total: pagination?.total,
                leads: people,
                selectedRows: people?.map((lead) => lead.id)
              })
            )
            toast.success('Processed Successfully', { id: toastId, duration: 2000 })
          } else {
            toast.remove(toastId)
          }
        } else {
          setAnchorEl(false)
          const startSelection = selectFirstResults - 1
          dispatch(
            setSeachData({
              ...serachDataFromRedux,
              selectedRows: usedLeads
                .slice(startSelection, selectLastResults)
                .map((lead) => lead.id)
            })
          )
        }

        break
      case 3:
        // eslint-disable-next-line no-case-declarations
        const rangeValue = (selectLastResults ?? 0) - (selectFirstResults ?? 0)
        if (selectLastResults < selectFirstResults) {
          const toastId = toast.error(
            'The last selection value must be greater than the first selection value.',
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }
        if (selectLastResults > 10000) {
          const toastId = toast.error(
            'The selected range must not exceed 10000 leads',
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }
        if (selectLastResults > 10000) {
          const toastId = toast.error(
            'The selected range must not exceed 10000 leads',
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }
        if (rangeValue > total) {
          const toastId = toast.error(
            'The selection range cannot exceed the total available results',
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }
        if (rangeValue > leadsCredits && rangeValue > phoneNumberLimit) {
          const toastId = toast.error(
            `You don't have enough lead balance to select ${rangeValue} leads`,
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }
        if (rangeValue > dailyLeadLimit) {
          const toastId = toast.error(
            `You don't have enough daily lead balance to select ${rangeValue} leads`,
            {
              duration: 2000,
              id: lastErrorToast
            }
          )
          setLastErrorToast(toastId)
          return
        }

        // if (rangeValue > 5000) {
        //   console.log(`rangeValue`, rangeValue)
        //   const toastId = toast.error(
        //     `The selected rangeValue must not exceed 5000 leads`,
        //     {
        //       duration: 2000,
        //       id: lastErrorToast,
        //     }
        //   );
        //   setLastErrorToast(toastId);
        //   return;
        // }
        setAnchorEl(false)
        setSelectMenuDropdown((prev) => ({
          ...prev,
          selectFirst: !prev.selectFirst
        }))
        break
      default:
        break
    }
  }

  const findAllLeads = async (params, toastId) => {
    const { pagination, people } = await findLeads(params).unwrap()
    const { search } = store.getState()
    if (search?.infinityToasterId === toastId) {
      let selectedLeads = [...people]
      if (people?.length > leadsCredits) {
        selectedLeads = selectedLeads.slice(0, leadsCredits)
      }
      dispatch(
        setSeachData({
          ...serachDataFromRedux,
          total: pagination.total,
          leads: people,
          selectedRows: selectedLeads?.map((lead) => lead.id)
        })
      )
    }
  }

  const onKeyPressSearch = (event) => {
    if (event?.code === 'Enter') {
      delayedSearch()
    }
  }

  const handleSelectFirstResults = (e) => {
    const enteredValue = Number(e.target.value)
    // add a chaeck in selection as per lead balance
    // if (enteredValue > leadsCredits || enteredValue > dailyLeadLimit) {
    //   if (enteredValue > dailyLeadLimit) {
    //     toast.error(`You don't have enough daily lead balance to select ${enteredValue} leads`, {
    //       duration: 2000,
    //       id: lastErrorToast,
    //     });
    //   } else {
    //     toast.error(`You don't have enough lead balance to select ${enteredValue} leads`, {
    //       duration: 2000,
    //       id: lastErrorToast,
    //     });
    //   }
    // } else {
    setSelectFirstResults(enteredValue)
    // }
  }

  const handleSelectLastResults = (e) => {
    const enteredValue = Number(e.target.value)
    setSelectLastResults(enteredValue)
  }

  const handleSetSelectedRows = (data) => {
    if (leadsCredits < data?.length) {
      const toastId = toast.error(
        `You don't have enough lead balance to select ${data.length} leads`,
        {
          duration: 2000,
          id: lastErrorToast
        }
      )
      setLastErrorToast(toastId)
      return
    }
    dispatch(
      setSeachData({
        ...serachDataFromRedux,
        selectedRows: data
      })
    )
  }

  const groupedByDate = {}
  if (leadUsage && timezone) {
    leadUsage.forEach((item) => {
      const date = moment.utc(item?.data?.createdAt).tz(timezone).format('MM/DD/YYYY')
      if (!groupedByDate[date]) {
        groupedByDate[date] = []
      }
      groupedByDate[date].push(item)
    })
  }

  const transformedGroupedByDate = Object.entries(groupedByDate).map(([date, items]) => {
    const sumAmount = items.reduce(
      (acc, item) => acc + Math.abs(parseFloat(item?.data?.amount)),
      0
    )
    return { date, items, sumAmount }
  })

  const handleEnreachClick = async (newLeads, verifyLeads) => {
    try {
      const { message } = await lookupLeads({
        leads: newLeads,
        name: 'Enriched',
        useBounce: verifyLeads,
        workspaceId,
        batchId: null,
        dataSelected: null
      }).unwrap()
      toast.success(message, { duration: 2000 })
      handleCloseOpenImportLeadsDialog()
    } catch (error) {
      toast.error(error.data.error.message, { duration: 2000 })
    }
  }

  const handleLeadsUpdate = (newLeads, verifyLeads) => {
    handleEnreachClick(newLeads, verifyLeads)
  }
  // eslint-disable-next-line no-unused-vars
  const [workspace, setWorkspace] = useState(null)

  const handleWorkspaceChange = (newWorkspace) => {
    setWorkspace(newWorkspace)
  }
  const { canEdit } = useAuth()

  const { data } = useGetMeAndWorkspaceQuery()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [hoveredScore, setHoveredScore] = useState(null)
  const [intentGraphLoading, setIntentGraphLoading] = useState(false)
  const [hoveredDate, setHoveredDate] = useState(null)
  const [hoveredRow, setHoveredRow] = useState(null)
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    employees: false,
    industry: false,
    keywords: false,
    company_location: false,
    status: false,
    crunchbase_url: false,
    languages: false,
    twitter_url: false,
    website_url: false,
    total_funding: false
  })

  const [lastRequestParams, setLastRequestParams] = useState({
    domain: null,
    topicsArray: [],
    data: null
  });

  const [getCompanyIntent, { isLoading, error }] = useGetCompanyIntentMutation();

  const handleMouseEnter = async ({ score, date, data, domain }) => {
    setHoveredScore(score);
    setHoveredDate(date);
    setDialogOpen(true);
    const topicsArray = data.map(item => item.topic);

    if (lastRequestParams.domain === domain && JSON.stringify(lastRequestParams.topicsArray) === JSON.stringify(topicsArray)) {
      setHoveredRow(lastRequestParams.data);
      setIntentGraphLoading(false);
      return;
    }

    if (isLoading) {
      setIntentGraphLoading(true);
    }
    try {
      setIntentGraphLoading(true);
      const results = await getCompanyIntent({ domain, topicsArray }).unwrap();

      if (results) {
        setHoveredRow(results?.data);
        setLastRequestParams({
          domain,
          topicsArray,
          data: results?.data
        });
        setIntentGraphLoading(false);
      } else if (error) {
        setIntentGraphLoading(false);
        throw new Error({ data: { error: { message: error } } })
      }
    } catch (err) {
      setIntentGraphLoading(false);
      throw new Error({ data: { error: { message: err } } })
    }
  };
  const handleMouseLeave = () => {
    setDialogOpen(false)
  }
  const scoreStyle = {
    color: alpha(theme.palette.primary.main, 0.8),
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0em',
    marginRight: '4px'
  }
  const handleRelevanceFilter = () => {
    setRelevanceToggle(false)
  }
  const handleColumnVisibilityChange = (newVisibilityModel) => {
    setColumnVisibilityModel(newVisibilityModel)
  }
  const apiRef = useGridApiRef();

  const handleOpenFilterMenu = (columnField) => {
    if (apiRef.current.showFilterPanel) {
      apiRef.current.showFilterPanel(columnField);
    }
  };

  const handleOpenFilter = () => {
    handleOpenFilterMenu("name");
  };

  const handleOpenColumnMenuForManagement = () => {
    if (apiRef.current && typeof apiRef.current.showColumnMenu === 'function') {
      apiRef.current.showColumnMenu('name');

      const observer = new MutationObserver((mutations) => {
        const manageColumnsButton = Array.from(document.querySelectorAll('.MuiTypography-root')).find(el => el.textContent.trim() === 'Manage columns');
        if (manageColumnsButton) {
          manageColumnsButton.click();
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    }
  };

  const calculateEstimatedTime = (totalLeads, totalLeadsAdded) => {
    if (!totalLeads || totalLeads <= 0) return "N/A";
    if (totalLeadsAdded >= totalLeads) return "";

    const processingRatePerLead = 5 / 100;
    const remainingLeads = totalLeads - totalLeadsAdded;

    if (totalLeadsAdded === 0) return "30 min";

    let estimatedTime = remainingLeads * processingRatePerLead;

    const minTime = Math.max(30, 60 - Math.floor(Math.max(0, (35000 - totalLeads) / 1000)));
    estimatedTime = Math.max(estimatedTime, minTime);

    if (estimatedTime >= 60) {
      const hours = Math.floor(estimatedTime / 60);
      const minutes = Math.ceil(estimatedTime % 60);
      return minutes > 0 ? `${hours}h ${minutes} min` : `${hours}h`;
    }
    return `${Math.ceil(estimatedTime)} min`;
  };


  return (
    <>
      {
        {
          verified: (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  width: '100%',
                  height: '100%'
                }}
              >
                <Box
                  sx={{
                    width: '90%',
                    // height: "calc(100vh - 134px)",
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <HeaderWithPopover
                    title='Lead Finder'
                    onWorkspaceChange={handleWorkspaceChange}
                    selectedTimeline={data?.workspace}
                    usage={usage}
                  />

                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: alpha(theme.palette.primary.main, 0.8),
                          fontSize: '32px',
                          fontWeight: 700,
                          lineHeight: '40px',
                          letterSpacing: '0px'
                        }}
                      />

                      {isMobile && <ToggleComponent alignment={alignment} handleChange={handleChange} isMobile={isMobile} />}

                      {isMobile &&
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                            // position: 'sticky',
                            // top: 0,
                            // zIndex: 999,
                            backgroundColor: "white",
                            py: 1,
                            // height: "100%",
                            // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            borderRadius: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",

                              justifyContent: "flex-start",
                              alignItems: "center",
                              width: "100%",
                              px: 2,

                              backgroundColor: "white",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "0.9em",
                                fontWeight: 700,
                                lineHeight: "18px",
                                letterSpacing: "0px",
                                color: "#28287B",
                              }}
                            >
                              Filters{" "}
                              {filterCount !== 0 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    background: `${theme.palette.primary.main}`,
                                    fontWeight: 700,
                                    color: "white",
                                    borderRadius: "6px",
                                    padding: "4px 8px",
                                    fontSize: "12px",
                                    ml: 1,
                                  }}
                                >
                                  {filterCount}
                                  <IconButton
                                    size="small"
                                    onClick={handleClear}
                                    sx={{
                                      color: "white",
                                      p: 0,
                                      ml: 0.5,
                                      "&:hover": { color: "#90caf9" },
                                    }}
                                  >
                                    <Close sx={{ fontSize: 14 }} />
                                  </IconButton>
                                </Box>
                              )}
                            </Typography>
                            <Button
                              variant="text"
                              onClick={handleClickButton}
                              sx={{
                                textTransform: "none",
                                minWidth: "40px",
                                ml: "auto",

                                border: "1px solid  #D7D9E6",
                                borderRadius: "10px",
                              }}
                            >
                              <MoreVertIcon sx={{ color: "black" }} />
                            </Button>
                            <Menu
                              anchorEl={toggle}
                              open={Boolean(toggle)}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              sx={{
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1,
                                "& .MuiListItemIcon-root": {
                                  mr: 0,
                                },
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "& .MuiPaper-root": {
                                  width: "200px",
                                  padding: "8px",
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              }}
                            >
                              <MenuItem disableRipple sx={{ p: 0, "&:hover": { backgroundColor: "transparent" } }}>
                                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
                                  <Button
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 700,
                                      lineHeight: "20px",
                                    }}
                                    onClick={handleShowAllSavedSearchClick}
                                  >
                                    Saved Searches
                                  </Button>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    size="small"
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 700,
                                      lineHeight: "20px",
                                    }}
                                    disabled={filterCount === 0 || isSavingSearch}
                                    onClick={handleSaveSearchClick}
                                  >
                                    Save Current Search
                                  </Button>
                                </Box>
                              </MenuItem>
                            </Menu>
                          </Box>

                        </Box>}
                      {/* <Box
                        sx={{
                          display: { md: 'none', xs: 'flex' },
                          justifyContent: 'flex-end',
                          width: { xs: '100%' }
                        }}
                      >
                        <Button
                          onClick={handleFilterToggle}
                          sx={{ py: 1, px: 1, width: { xs: '100%' } }}
                          variant='contained'
                        >
                          <Typography>Filter</Typography>
                        </Button>
                      </Box> */}
                    </Grid>
                  </Grid>

                  <Grid container columnSpacing={2} sx={{ height: '100%', mt: 2, width: { xs: "100%", sm: "100%", md: "calc(100% + 100px)" } }}>
                    <Drawer
                      open={isMobile ? filterOpen : false}
                      variant='temporary'
                      anchor="bottom"
                      onClose={() => setFilterOpen(false)}
                      sx={{
                        mt: 5, // Adds margin-top
                        "& .MuiDrawer-paper": {
                          boxSizing: "border-box",
                          width: { sm: "300px", xs: "100%" },
                          height: "83vh",
                          borderTopLeftRadius: "16px", // Optional: Adds rounded corners at the top
                          borderTopRightRadius: "16px", // Optional: Adds rounded corners at the top
                          // Ensures margin-top applies properly
                        },
                        mt: 2
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          zIndex: 1
                        }}
                      >
                        <IconButton onClick={() => setFilterOpen(false)}>
                          <CloseOutlined />
                        </IconButton>
                      </Box>
                      {/* <ToggleComponent
                        alignment={alignment}
                        handleChange={handleChange}
                        isMobile={isMobile}
                      /> */}

                      <FilterColumn
                        handleChange={handleChange}
                        isMobile={isMobile}
                        filterCount={filterCount}
                        handleClear={handleClear}
                        clearLocation={clearLocation}
                        setClearLocation={setClearLocation}
                        isSavingSearch={isSavingSearch}
                        handleSaveSearchClick={handleSaveSearchClick}
                        handleShowAllSavedSearchClick={handleShowAllSavedSearchClick}
                        filter={usedFilter}
                        handleFilterChange={handleFilterChange}
                        height='68vh'
                        alignment={alignment}
                        delayedSearch={delayedSearch}
                        prevFilter={prevFilter}
                        onKeyPressSearch={onKeyPressSearch}
                        // handleClick={handleClick}
                        handleClose={handleClose}
                        toggle={toggle}
                        // setAnchorEl={setAnchorEl}
                        handleClickButton={handleClickButton}
                        summarytoggle={summarytoggle}
                        handleClickSummaryButton={handleClickSummaryButton}
                        handleSummaryClose={handleSummaryClose}
                        theme={theme}
                        handleReturnPlan={handleReturnPlan}
                      />
                    </Drawer>
                    {!isMobile && (
                      <Grid item xs={3} sx={{ height: '100%', display: { xs: 'none', md: 'block', pl: '0 !important' } }}>
                        {/* <ToggleComponent
                        alignment={alignment}
                        handleChange={handleChange}
                        isMobile={isMobile}
                      /> */}

                        <FilterColumn
                          handleChange={handleChange}
                          isMobile={isMobile}
                          filterCount={filterCount}
                          handleClear={handleClear}
                          clearLocation={clearLocation}
                          setClearLocation={setClearLocation}
                          isSavingSearch={isSavingSearch}
                          handleSaveSearchClick={handleSaveSearchClick}
                          handleShowAllSavedSearchClick={handleShowAllSavedSearchClick}
                          filter={usedFilter}
                          handleFilterChange={handleFilterChange}
                          height='78vh'
                          // height={"calc(100vh - 170px)"}
                          alignment={alignment}
                          delayedSearch={delayedSearch}
                          prevFilter={prevFilter}
                          onKeyPressSearch={onKeyPressSearch}
                          handleClose={handleClose}
                          toggle={toggle}
                          // setAnchorEl={setAnchorEl}
                          handleClickButton={handleClickButton}
                          summarytoggle={summarytoggle}
                          handleClickSummaryButton={handleClickSummaryButton}
                          handleSummaryClose={handleSummaryClose}
                          theme={theme}
                          handleReturnPlan={handleReturnPlan}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={9} sx={{ height: '100%', paddingBottom: '0px' }}>
                      {/* <LeadInfo
                        isLoadingUsage={isLoadingUsage}
                        formattedNumber={formattedNumber}
                        usage={usage}
                        setLeadCreditDialogOpen={setLeadCreditDialogOpen}
                        setLeadCreditTab={setLeadCreditTab}
                        fetchDownloadedData={fetchDownloadedData}
                        isMobile={isMobile}
                        theme={theme}
                        closedCount={closedCount}
                        handleClickOpenImportLeadsDialog={handleClickOpenImportLeadsDialog}
                        relevanceToggle={relevanceToggle}
                        handleClickRelevanceButton={handleClickRelevanceButton}
                        handleCloseRelevance={handleCloseRelevance}
                        isPanelOpen={isPanelOpen}
                        setIsPanelOpen={setIsPanelOpen}
                        descendingOpen={descendingOpen}
                        setDescendingOpen={setDescendingOpen}
                      /> */}
                      <Box
                        sx={{
                          width: '100%',
                          height: '92vh',
                          backgroundColor: 'white',
                          pl: 2,
                          pr: 2,
                          pb: 2,
                          pt: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'space-between',
                          boxShadow: '0px 12px 15px 0px #4B71970D',
                          borderRadius: '12px',
                          gap: '10px'
                        }}
                      >
                        <LeadInfo
                          isLoadingUsage={isLoadingUsage}
                          formattedNumber={formattedNumber}
                          usage={usage}
                          setLeadCreditDialogOpen={setLeadCreditDialogOpen}
                          setLeadCreditTab={setLeadCreditTab}
                          fetchDownloadedData={fetchDownloadedData}
                          isMobile={isMobile}
                          theme={theme}
                          closedCount={closedCount}
                          handleClickOpenImportLeadsDialog={handleClickOpenImportLeadsDialog}
                          relevanceToggle={relevanceToggle}
                          handleClickRelevanceButton={handleClickRelevanceButton}
                          handleCloseRelevance={handleCloseRelevance}
                          isPanelOpen={isPanelOpen}
                          setIsPanelOpen={setIsPanelOpen}
                          descendingOpen={descendingOpen}
                          setDescendingOpen={setDescendingOpen}
                          handleRelevanceFilter={handleRelevanceFilter}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexGrow: 1,
                            width: '100%',
                            height: '90%'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              // height: '98%',
                              overflowY: 'hidden'
                            }}
                          >
                            {!prevCOunt && !usedLeads?.length ? (
                              <>
                                <Box
                                  sx={{
                                    backgroundColor: "#F2F4F6",
                                    borderRadius: "8px",
                                    width: "100%",
                                    height: { xs: "auto", sm: "100%", md: '100%' },
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    p: { xs: 2, md: 3 },
                                    boxSizing: "border-box",
                                    marginBottom: { xs: 6, sm: 2 },
                                  }}
                                >
                                  {/* Scrollable Content Container */}
                                  <Box
                                    sx={{
                                      width: "100%",
                                      maxWidth: { xs: "100%", sm: "600px" },
                                      flex: 1,
                                      overflowY: "auto",
                                      pr: { xs: 0, sm: 1 },// Add scrollbar gutter
                                    }}
                                  >
                                    {/* Filters Section */}
                                    <Box
                                      sx={{
                                        backgroundColor: "white",
                                        borderRadius: "8px",
                                        p: { xs: 2, sm: 3 },
                                        boxShadow: "0px 8px 34px 0px rgba(40, 40, 123, 0.01)",
                                        mb: 2,
                                        width: "100%",
                                        maxWidth: { xs: "100%", sm: "520px" },
                                        mx: "auto"
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          flexDirection: { xs: "column", sm: "row" },
                                          gap: { xs: 1, sm: 2 },
                                          textAlign: { xs: "center", sm: "left" }
                                        }}
                                      >
                                        <FilterFunnel sx={{ fontSize: { xs: "14px", sm: "16px" } }} />
                                        <Typography
                                          variant="body1"
                                          sx={{
                                            fontWeight: 700,
                                            fontSize: { xs: "14px", sm: "16px" },
                                            color: theme.palette.primary.primaryText,
                                            lineHeight: 1.3
                                          }}
                                        >
                                          Apply filters on the left to start your search
                                        </Typography>
                                      </Box>
                                    </Box>

                                    {/* OR Divider */}
                                    <Divider
                                      sx={{
                                        my: 2,
                                        borderColor: "#D7D9E6",
                                        "&::before, &::after": { borderColor: "#D7D9E6" },
                                        fontSize: { xs: 12, sm: 14 },
                                        fontWeight: 500,
                                        width: { xs: '100%', sm: '90%' },
                                        mx: 'auto'
                                      }}
                                    >
                                      or
                                    </Divider>

                                    {/* Recent Searches Block */}
                                    <RecentSearchBlock
                                      searches={searches}
                                      isLoading={isGetSearchesLoading}
                                      onSearchClick={handleSearchClick}
                                    />

                                    {/* OR Divider */}
                                    <Divider
                                      sx={{
                                        my: 2,
                                        borderColor: "#D7D9E6",
                                        "&::before, &::after": { borderColor: "#D7D9E6" },
                                        fontSize: { xs: 12, sm: 14 },
                                        fontWeight: 500,
                                        width: { xs: '100%', sm: '90%' },
                                        mx: 'auto'
                                      }}
                                    >
                                      or
                                    </Divider>

                                    {/* Saved Searches Block */}
                                    <SavedSearchBlock
                                      searches={searches}
                                      isLoading={isGetSearchesLoading}
                                      onSearchClick={handleSearchClick}
                                      onEditSearchClick={handleEditSearchClick}
                                      onAllSavedClick={handleShowAllSavedSearchClick}
                                      showLess
                                    />

                                  </Box>
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box
                                  sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  {alignment === 'People' ? (
                                    <Box
                                      sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: {
                                          xs: 'column',
                                          sm: 'row'
                                        },
                                        justifyContent: 'space-between',
                                        alignItems: {
                                          xs: 'flex-start',
                                          sm: 'center'
                                        },
                                        gap: 2,
                                        flexWrap: { xs: 'wrap', sm: 'nowrap' }
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: '100%',
                                          display: 'flex',
                                          flexDirection: {
                                            xs: 'column',
                                            sm: 'row'
                                          },
                                          // justifyContent: 'start',
                                          alignItems: {
                                            xs: 'flex-start',
                                            sm: 'center'
                                          },
                                          mb: 2,
                                          gap: 2,
                                          justifyContent: 'space-between',
                                          flexWrap: {
                                            xs: 'wrap',
                                            sm: 'nowrap'
                                          }
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            background: '#F2F4F6',
                                            borderRadius: '6px',
                                            // fontSize: "14px",
                                            // fontWeight: 600,
                                            // lineHeight: '17.64px',
                                            padding: '10px 10px 10px 0px',
                                            gap: '2px',
                                            // pr: 1,
                                            // py: "4px",
                                            maxHeight: '39px',
                                            flexWrap: { xs: 'wrap', sm: 'nowrap' }
                                          }}
                                        >
                                          <Checkbox
                                            icon={checkboxOfIcon}
                                            checkedIcon={CheckboxAllIcon}
                                            onChange={handleSelectAllClick}
                                            checked={selectedRows.length > 0}
                                          />
                                          <Box>
                                            <Menu
                                              component='div'
                                              sx={{
                                                marginRight: '1rem',
                                                '& .MuiPaper-root': {
                                                  borderRadius: '8px',
                                                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                  background: '#F2F4F6'
                                                }
                                              }}
                                              anchorEl={anchorEl}
                                              open={Boolean(anchorEl)}
                                              onClose={() => setAnchorEl(false)}
                                              MenuListProps={{
                                                'aria-labelledby': 'basic-button'
                                              }}
                                            >
                                              {/* <MenuItem>
                                                <Checkbox
                                                  icon={checkboxOfIcon}
                                                  checkedIcon={checkboxOnIcon}
                                                  onClick={() => handleSelectMenuOption(0)}
                                                  checked={selectMenuDropdown.selectPage}
                                                />
                                                Select this page
                                              </MenuItem>
                                              <MenuItem>
                                                <Checkbox
                                                  icon={checkboxOfIcon}
                                                  checkedIcon={checkboxOnIcon}
                                                  onClick={() => handleSelectMenuOption(3)}
                                                  checked={selectMenuDropdown.selectFirst}
                                                />{' '}
                                                Select the rows
                                              </MenuItem> */}
                                              <MenuItem>
                                                {/* <TextField
                                                  min={0}
                                                  type='number'
                                                  value={selectFirstResults.toString()}
                                                  onChange={handleSelectFirstResults}
                                                  variant='outlined'
                                                  sx={{
                                                    width: '100px',
                                                    marginX: '0.5rem',
                                                    backgroundColor: 'white',
                                                    borderRadius: '8px',
                                                    '& div fieldset': {
                                                      borderRadius: '8px'
                                                    },
                                                    '& div input': {
                                                      // borderRadius: "8px",
                                                      py: 1,
                                                      fontSize: '13px',
                                                      fontWeight: 400
                                                    }
                                                  }}
                                                /> */}
                                                Select leads
                                                <TextField
                                                  min={0}
                                                  type='number'
                                                  value={selectLastResults.toString()}
                                                  onChange={handleSelectLastResults}
                                                  variant='outlined'
                                                  sx={{
                                                    width: '100px',
                                                    marginX: '0.5rem',
                                                    backgroundColor: 'white',
                                                    borderRadius: '8px',
                                                    '& div fieldset': {
                                                      borderRadius: '8px'
                                                    },
                                                    '& div input': {
                                                      // borderRadius: "8px",
                                                      py: 1,
                                                      fontSize: '13px',
                                                      fontWeight: 400
                                                    }
                                                  }}
                                                />
                                                <Button
                                                  variant="contained" // or any variant you want
                                                  onClick={() => handleSelectMenuOption(3)}
                                                >
                                                  OK
                                                </Button>

                                              </MenuItem>
                                              {/* <MenuItem>
                                                <Checkbox
                                                  onClick={() => handleSelectMenuOption(2)}
                                                  checked={selectMenuDropdown.selectFirst}
                                                />
                                                Select first
                                                <TextField
                                                  min={0}
                                                  type="number"
                                                  value={selectFirstResults.toString()}
                                                  onChange={handleSelectFirstResults}
                                                  variant="outlined"
                                                  sx={{
                                                    width: "100px",
                                                    marginX: "0.5rem",
                                                    backgroundColor: "white",
                                                    borderRadius: "8px",
                                                    "& div fieldset": {
                                                      borderRadius: "8px",
                                                    },
                                                    "& div input": {
                                                      py: 1,
                                                      fontSize: "13px",
                                                      fontWeight: 400,
                                                    },
                                                  }}
                                                />
                                                results
                                              </MenuItem> */}
                                            </Menu>
                                          </Box>
                                          <Typography
                                            sx={{
                                              fontSize: '14px',
                                              fontWeight: 600,
                                              lineHeight: '17.64px',
                                              letterSpacing: '0px',
                                              color: alpha(theme.palette.primary.primaryText, 0.8),
                                              // color: "#28287B",
                                              whiteSpace: 'nowrap' // Prevents text wrapping
                                            }}
                                          >
                                            {new Intl.NumberFormat('en-US').format(
                                              usedTotal || 0
                                            )}{' '}
                                            Results
                                          </Typography>
                                          {selectMenuDropdown?.selectFirst && (
                                            <>
                                              <Typography
                                                sx={{
                                                  fontSize: '14px',
                                                  fontWeight: 600,
                                                  lineHeight: '17.64px',
                                                  letterSpacing: '0px',
                                                  color: theme.palette.primary.primaryText,
                                                  marginLeft: '5px'
                                                }}
                                              >
                                                |
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: '14px',
                                                  fontWeight: 600,
                                                  lineHeight: '1.2',
                                                  letterSpacing: '0px',
                                                  color: theme.palette.primary.primaryText,
                                                  marginLeft: '5px',
                                                  maxWidth: 'fit-content',
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  display: 'inline-block'
                                                }}
                                              >
                                                {selectLastResults ?? 0} selected
                                              </Typography >

                                              <Typography
                                                onClick={handleClearRange}
                                                sx={{
                                                  fontSize: '12px',
                                                  fontWeight: 500,
                                                  // lineHeight: "17.64px",
                                                  letterSpacing: '0em',
                                                  cursor: 'pointer',
                                                  color: `${theme.palette.primary.main}`,
                                                  marginLeft: '5px'
                                                }}
                                              >
                                                Clear
                                              </Typography>

                                            </>
                                          )}
                                        </Box >
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: { sm: 'row' },
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                            gap: 2,
                                            flexWrap: {
                                              xs: 'wrap',
                                              sm: 'nowrap'
                                            }
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              flexDirection: { sm: 'row' },
                                              // justifyContent: "space-between",
                                              alignItems: 'center',
                                              width: '100%',
                                              gap: 2,
                                              flexWrap: {
                                                xs: 'wrap',
                                                sm: 'nowrap'
                                              }
                                            }}
                                          >
                                            {/* No yet implemented
                                            <Tooltip
                                              title='Coming Soon'
                                              arrow
                                              PopperProps={{
                                                sx: {
                                                  '& .MuiTooltip-tooltip': {
                                                    backgroundColor: theme?.palette.primary.main || '#4B71F7',
                                                    color: '#fff',
                                                    fontSize: '12px',
                                                    padding: '6px 12px',
                                                    borderRadius: '6px'
                                                  },
                                                  '& .MuiTooltip-arrow': {
                                                    color: theme?.palette.primary.main || '#4B71F7'
                                                  }
                                                }
                                              }}
                                            >
                                              <span>
                                                <Button
                                                  sx={{
                                                    p: '10px 14px 10px 14px',
                                                    gap: '6px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: `${theme.palette.primary.main}`,
                                                    '&:hover': {
                                                      backgroundColor: `${theme.palette.primary.dark}`
                                                    }
                                                  }}
                                                  variant='contained'
                                                  disabled // Disable the button
                                                >
                                                  <AddIcon sx={{ height: '18px', width: '18px' }} />
                                                  <Typography
                                                    sx={{
                                                      fontSize: '14px',
                                                      fontWeight: 600,
                                                      lineHeight: '17.64px',
                                                      letterSpacing: '0em'
                                                    }}
                                                  >
                                                    Save People
                                                  </Typography>
                                                </Button>
                                              </span>
                                            </Tooltip> */}

                                            <Button
                                              // startIcon={<AddIcon sx={{ ml: 1 }} />}
                                              sx={{
                                                p: '10px 10px 10px 14px',
                                                gap: '6px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: selectedRows.length > 0 || selectMenuDropdown?.selectFirst ? 'none' : '1px solid #E4E4E5',
                                                backgroundColor: selectedRows.length > 0 || selectMenuDropdown?.selectFirst ? `${theme.palette.primary.main}` : '#fff',
                                                '&:hover': {
                                                  backgroundColor: `${theme.palette.primary.dark}`
                                                }
                                              }}
                                              // variant="contained"
                                              disabled={selectMenuDropdown?.selectFirst ? null : (
                                                // selectMenuDropdown?.selectFirst ||
                                                selectedRows?.length === 0 ||
                                                isFindLeadsLoading ||
                                                isfindCompaniesLoading ||
                                                !canEdit
                                              )
                                              }
                                              onClick={handleAddToCampaignClick}
                                            >
                                              <Send sx={{
                                                height: '16px',
                                                width: '16px',
                                                stroke: selectedRows.length > 0 || selectMenuDropdown?.selectFirst ? '#fff' : '#8181B0'
                                              }}
                                              />
                                              <Typography
                                                sx={{
                                                  fontSize: '14px',
                                                  fontWeight: 600,
                                                  lineHeight: '17.64px',
                                                  letterSpacing: '0em',
                                                  color: selectedRows.length > 0 || selectMenuDropdown?.selectFirst ? '#fff' : `${theme.palette.primary.primaryText}`
                                                }}
                                              >
                                                Add to Campaign
                                              </Typography>
                                            </Button>


                                            {/* <Button
                                              // startIcon={<ListIcon />}
                                              sx={{
                                                p: '10px 10px 10px 14px',
                                                gap: '6px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #E4E4E5',
                                                background: theme.palette.primary.contrastText,
                                                '&:hover': {
                                                  background: ' #E1E3EC'
                                                }
                                              }}
                                              disabled={
                                                selectedRows.length === 0 ||
                                                isFindLeadsLoading ||
                                                isfindCompaniesLoading ||
                                                !canEdit
                                              }
                                              onClick={handleAddToListClick}
                                            >
                                              <AddToList sx={{ height: '16px', width: '16px' }} />
                                              <Typography
                                                sx={{
                                                  fontSize: '14px',
                                                  fontWeight: 600,
                                                  lineHeight: '17.64px',
                                                  letterSpacing: '0em',
                                                  color: `${theme.palette.primary.primaryText}`
                                                }}
                                              >
                                                Add to List
                                              </Typography>
                                            </Button> */}
                                          </Box>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'end',
                                              textAlign: 'end',
                                              gap: 2
                                            }}
                                          >
                                            <Button
                                              sx={{
                                                p: '10px 10px 10px 14px',
                                                gap: '6px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                minWidth: '100px',
                                                alignItems: 'center',
                                                border: '1px solid #E4E4E5',
                                                background: theme.palette.primary.contrastText,
                                                '&:hover': {
                                                  background: ' #E1E3EC'
                                                }
                                              }}
                                              onClick={handleOpenFilter}>
                                              <Box
                                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}
                                              >
                                                <FilterIcon />
                                              </Box>
                                              <Typography
                                                sx={{
                                                  fontSize: '14px',
                                                  fontWeight: 600,
                                                  lineHeight: '17.64px',
                                                  letterSpacing: '0em',
                                                  color: `${theme.palette.primary.primaryText}`
                                                }}
                                              >
                                                Filter
                                              </Typography>
                                            </Button>
                                            <Button
                                              // startIcon={<FileDownloadIcon />}
                                              sx={{
                                                p: '10px 16px 10px 14px',
                                                gap: '6px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: 'black',
                                                border: '1px solid #E4E4E5',
                                                background: theme.palette.primary.contrastText,
                                                '&:hover': {
                                                  background: ' #E1E3EC'
                                                }
                                              }}
                                              disabled={selectMenuDropdown?.selectFirst ? null : (
                                                // selectMenuDropdown?.selectFirst ||
                                                selectedRows?.length === 0 ||
                                                isFindLeadsLoading ||
                                                isfindCompaniesLoading ||
                                                !canEdit
                                              )}
                                              onClick={handleConfirmDownload}
                                            >
                                              <ExportIcon sx={{ height: '16px', width: '16px' }} />
                                              <Typography
                                                sx={{
                                                  fontSize: '14px',
                                                  fontWeight: 600,
                                                  lineHeight: '17.64px',
                                                  letterSpacing: '0em',
                                                  color: `${theme.palette.primary.primaryText}`
                                                }}
                                              >
                                                Export
                                              </Typography>
                                            </Button>
                                            {/* <Button
                                              sx={{
                                                p: '10px 10px 10px 14px',
                                                gap: '6px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                minWidth: '180px',
                                                alignItems: 'center',
                                                border: '1px solid #E4E4E5',
                                                background: theme.palette.primary.contrastText,
                                                '&:hover': {
                                                  background: ' #E1E3EC'
                                                }
                                              }}
                                              onClick={handleClickOpenImportLeadsDialog}
                                            >
                                              {!isMobile && <Workspaces sx={{ color:'gray', width:'20px', height:'20px' }} />}
                                              <Typography
                                                sx={{
                                                  fontSize: '14px',
                                                  fontWeight: 600,
                                                  lineHeight: '17.64px',
                                                  letterSpacing: '0em',
                                                  color: `${theme.palette.primary.primaryText}`
                                                }}
                                              >
                                                Lead Enrichment
                                              </Typography>
                                            </Button> */}
                                            <Button
                                              variant='text'
                                              sx={{
                                                textTransform: 'none',
                                                minWidth: '40px',
                                                ml: 'auto',
                                                border: '1px solid  #D7D9E6',
                                                borderRadius: '10px'

                                                // mb: 2,
                                              }}
                                              onClick={handleClickToggleButton}
                                            >
                                              <MoreVertIcon sx={{ color: 'black' }} />
                                            </Button>
                                            <Menu
                                              anchorEl={toggleButton}
                                              open={Boolean(toggleButton)}
                                              onClose={handleCloseToggleButton}
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                              }}
                                              transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                              }}
                                              sx={{
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                mt: 1,
                                                '& .MuiListItemIcon-root': {
                                                  mr: 0
                                                },
                                                '& .MuiAvatar-root': {
                                                  width: 32,
                                                  height: 32,
                                                  ml: -0.5,
                                                  mr: 1
                                                },
                                                '& .MuiPaper-root': {
                                                  width: '200px',
                                                  padding: '8px'
                                                },
                                                '&:before': {
                                                  content: '""',
                                                  display: 'block',
                                                  position: 'absolute',
                                                  top: 0,
                                                  right: 14,
                                                  width: 10,
                                                  height: 10,
                                                  bgcolor: 'background.paper',
                                                  transform: 'translateY(-50%) rotate(45deg)',
                                                  zIndex: 0
                                                }
                                              }}
                                            >
                                              <MenuItem
                                                disableRipple
                                                sx={{
                                                  p: 0,
                                                  '&:hover': { backgroundColor: 'transparent' }
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    gap: 1
                                                  }}
                                                >
                                                  <Button
                                                    variant="text"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                      fontSize: "13px",
                                                      fontWeight: 700,
                                                      lineHeight: "20px",
                                                    }}
                                                    onClick={handleOpenColumnMenuForManagement}
                                                  >
                                                    Manage Column
                                                  </Button>
                                                  <Button
                                                    variant='text'
                                                    fullWidth
                                                    size='small'
                                                    sx={{
                                                      fontSize: '13px',
                                                      fontWeight: 700,
                                                      lineHeight: '20px'
                                                    }}
                                                    onClick={() => {
                                                      setLeadCreditDialogOpen(true)
                                                      setLeadCreditTab('usage')
                                                    }}
                                                  >
                                                    View Leads Usage
                                                  </Button>
                                                  {/* <Button
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                      fontSize: "13px",
                                                      fontWeight: 700,
                                                      lineHeight: "20px",
                                                    }}
                                                  // onClick={handleShowAllSavedSearchClick}
                                                  >
                                                    Edit
                                                  </Button>
                                                  <Button
                                                    variant="contained"
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                      fontSize: "13px",
                                                      fontWeight: 700,
                                                      lineHeight: "20px",
                                                    }}
                                                  // disabled={filterCount === 0 || isSavingSearch}
                                                  // onClick={handleSaveSearchClick}
                                                  >
                                                    Delete
                                                  </Button> */}
                                                </Box>
                                              </MenuItem>
                                            </Menu>
                                          </Box>
                                        </Box>
                                      </Box >
                                    </Box >
                                  ) : (
                                    <></>
                                  )}
                                  <DataGrid
                                    sx={{
                                      ...dataGridStyles,
                                      filter: selectMenuDropdown?.selectFirst
                                        ? 'blur(5px)'
                                        : 'none',
                                      pointerEvents: selectMenuDropdown?.selectFirst
                                        ? 'none'
                                        : 'auto'
                                      // bgcolor: 'red'
                                    }}
                                    slotProps={slotPropStyles}
                                    paginationMode='server'
                                    className={classes.customDataGrid}
                                    columns={
                                      alignment === 'Companies'
                                        ? companyColumns.map((column) => {
                                          if (column.field === 'intent.composite_score') {
                                            return {
                                              ...column,
                                              renderCell: ({ row }) => {
                                                const { intentScore } = usedFilter
                                                let relevantIntent = null

                                                if (!intentScore) {
                                                  relevantIntent = row.intent?.[0]
                                                } else {
                                                  relevantIntent = getRelevantIntentByScore(
                                                    row,
                                                    intentScore
                                                  )
                                                }

                                                if (!relevantIntent) return 'N/A'

                                                const { composite_score: score } =
                                                  relevantIntent

                                                const scoreStyle = {
                                                  fontWeight: 'bold',
                                                  marginRight: '8px'
                                                }

                                                return (
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center'
                                                    }}
                                                  >
                                                    <span style={scoreStyle}>
                                                      {score >= 1 && score <= 35
                                                        ? 'Low'
                                                        : score >= 36 && score <= 65
                                                          ? 'Medium'
                                                          : 'High'}
                                                    </span>
                                                    {score >= 1 && score <= 35 && (
                                                      <SignalCellularAlt1BarRoundedIcon
                                                        // onMouseEnter={() => handleMouseEnter({ score, date })}
                                                        style={{ color: '#5feda6' }}
                                                      />
                                                    )}
                                                    {score >= 36 && score <= 65 && (
                                                      <SignalCellularAlt2BarRoundedIcon
                                                        // onMouseEnter={() => handleMouseEnter({ score, date })}
                                                        style={{ color: '#5feda6' }}
                                                      />
                                                    )}
                                                    {score >= 66 && score <= 100 && (
                                                      <SignalCellularAltRoundedIcon
                                                        // onMouseEnter={() => handleMouseEnter({ score, date })}
                                                        style={{ color: '#5feda6' }}
                                                      />
                                                    )}
                                                  </div>
                                                )
                                              }
                                            }
                                          }

                                          if (column.field === 'intent.topic') {
                                            return {
                                              ...column,
                                              valueGetter: ({ row }) => {
                                                const { intentScore, intentTopic } = usedFilter
                                                let relevantIntent = null

                                                if (intentTopic && intentTopic.length > 0) {
                                                  relevantIntent = getRelevantIntentByTopic(
                                                    row,
                                                    intentTopic
                                                  )
                                                }

                                                if (!relevantIntent && intentScore) {
                                                  relevantIntent = getRelevantIntentByScore(
                                                    row,
                                                    intentScore
                                                  )
                                                }

                                                return relevantIntent
                                                  ? relevantIntent.topic
                                                  : 'N/A'
                                              }
                                            }
                                          }

                                          if (column.field === 'intent.category') {
                                            return {
                                              ...column,
                                              valueGetter: ({ row }) => {
                                                const { intentScore, intentTopic } = usedFilter
                                                let relevantIntent = null

                                                if (intentTopic && intentTopic.length > 0) {
                                                  relevantIntent = getRelevantIntentByTopic(
                                                    row,
                                                    intentTopic
                                                  )
                                                }

                                                if (!relevantIntent && intentScore) {
                                                  relevantIntent = getRelevantIntentByScore(
                                                    row,
                                                    intentScore
                                                  )
                                                }

                                                return relevantIntent
                                                  ? relevantIntent.category
                                                  : 'N/A'
                                              }
                                            }
                                          }
                                          return {
                                            ...column,
                                            valueGetter: ({ row }) => {
                                              const fieldValue = row[column.field]
                                              return fieldValue === '' || fieldValue == null
                                                ? '-'
                                                : fieldValue
                                            }
                                          }
                                        })
                                        : columns(theme, planName).map((column) => {
                                          if (column.field === 'email') {
                                            return {
                                              ...column,
                                              valueGetter: ({ row }) => '****@email.com'
                                            }
                                          }
                                          if (column.field === 'intent.topic') {
                                            return {
                                              ...column,
                                              valueGetter: ({ row }) => {
                                                const fieldValue = row.intent?.[0]?.topic
                                                return fieldValue === '' || fieldValue == null ? 'N/A' : fieldValue // Safely get intent topic
                                              }
                                            }
                                          }
                                          if (column.field === 'intent.category') {
                                            return {
                                              ...column,
                                              valueGetter: ({ row }) => {
                                                const fieldValue = row.intent?.[0]?.category
                                                return fieldValue === '' || fieldValue == null ? 'N/A' : fieldValue // Safely get intent category
                                              }
                                            }
                                          }
                                          // if (column.field === "intent.composite_score") {
                                          //   return {
                                          //     ...column,
                                          //     renderCell: ({ row }) => {
                                          //       const score = row.intent?.[0]?.composite_score;
                                          //       if (score === undefined || score === null || score === "" || score > 100 || score < 1) return "N/A";
                                          //       return (
                                          //         <div style={{ display: "flex", alignItems: "center" }}>

                                          //           {score >= 1 && score <= 35 && (
                                          //             <>
                                          //               <span style={{
                                          //                 color: alpha(theme.palette.primary.main, 0.8),
                                          //                 fontSize: "13px",
                                          //                 fontWeight: 500,
                                          //                 lineHeight: "16px",
                                          //                 letterSpacing: "0em",
                                          //                 marginRight: '4px'
                                          //               }}>Low</span>
                                          //               <SignalCellularAlt1BarRoundedIcon style={{ color: "#5feda6" }} />
                                          //             </>
                                          //           )}
                                          //           {score >= 36 && score <= 65 && (
                                          //             <>
                                          //               <span style={{
                                          //                 color: alpha(theme.palette.primary.main, 0.8),
                                          //                 fontSize: "13px",
                                          //                 fontWeight: 500,
                                          //                 lineHeight: "16px",
                                          //                 letterSpacing: "0em",
                                          //                 marginRight: '4px'
                                          //               }}>Medium</span>
                                          //               <SignalCellularAlt2BarRoundedIcon style={{ color: "#5feda6" }} />
                                          //             </>
                                          //           )}

                                          //           {score >= 66 && score <= 100 && (
                                          //             <>
                                          //               <span style={{
                                          //                 color: alpha(theme.palette.primary.main, 0.8),
                                          //                 fontSize: "13px",
                                          //                 fontWeight: 500,
                                          //                 lineHeight: "16px",
                                          //                 letterSpacing: "0em",
                                          //                 marginRight: '4px'
                                          //               }}>High</span>
                                          //               <SignalCellularAltRoundedIcon style={{ color: "#5feda6" }} />
                                          //             </>
                                          //           )}
                                          //           {(score === undefined || score === null || score === "" || score > 100 || score < 1) && "N/A"}
                                          //         </div>
                                          //       );
                                          //     },
                                          //   };
                                          // }
                                          if (column.field === 'company') {
                                            return {
                                              ...column,
                                              renderCell: ({ row }) => {
                                                const score = row?.organization.intent?.[0]?.composite_score
                                                const date = row?.organization.intent?.[0]?.date
                                                const data = row?.organization?.intent
                                                const domain = row?.organization?.primary_domain
                                                return (
                                                  <Box
                                                    sx={{
                                                      display: 'flex',
                                                      justifyContent: 'space-between',
                                                      alignItems: 'center',
                                                      width: '100%'
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        color: theme?.palette.primary.primaryText,
                                                        fontSize: '13px',
                                                        fontWeight: '500',
                                                        lineHeight: '16px',
                                                        letterSpacing: '0em',
                                                        cursor: 'pointer',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        width: '150px'
                                                      }}
                                                    >
                                                      {row?.organization?.name || 'N/A'}
                                                    </Typography>

                                                    {score && (
                                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {score >= 1 && score <= 35 && (
                                                          <SignalCellularAlt1BarRoundedIcon
                                                            onMouseEnter={() => handleMouseEnter({ score, date, data, domain })}
                                                            style={{ color: '#3F4FF8' }}
                                                          />
                                                        )}
                                                        {score >= 36 && score <= 65 && (
                                                          <SignalCellularAlt2BarRoundedIcon
                                                            onMouseEnter={() => handleMouseEnter({ score, date, data, domain })}
                                                            style={{ color: '#3F4FF8' }}
                                                          />
                                                        )}
                                                        {score >= 66 && score <= 100 && (
                                                          <SignalCellularAltRoundedIcon
                                                            onMouseEnter={() => handleMouseEnter({ score, date, data, domain })}
                                                            style={{ color: '#3F4FF8' }}
                                                          />
                                                        )}
                                                      </div>
                                                    )}
                                                  </Box>
                                                )
                                              }
                                            }
                                          }
                                          if (column.field === 'intent.composite_score') {
                                            return {
                                              ...column,
                                              renderCell: ({ row }) => {
                                                const score = row.intent?.[0]?.composite_score
                                                const date = row.intent?.[0]?.date
                                                if (!score || score < 1 || score > 100) { return 'N/A' }

                                                return (
                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={scoreStyle}>
                                                      {score >= 1 && score <= 35
                                                        ? 'Low'
                                                        : score >= 36 && score <= 65 ? 'Medium' : 'High'}
                                                    </span>
                                                    {score >= 0 && score <= 62 && (
                                                      <SignalCellularAlt1BarRoundedIcon
                                                        onMouseEnter={() => handleMouseEnter({ score, date, row })}
                                                        // onMouseLeave={handleMouseLeave}
                                                        style={{ color: '#5feda6' }}
                                                      />
                                                    )}
                                                    {score >= 63 && score <= 75 && (
                                                      <SignalCellularAlt2BarRoundedIcon
                                                        onMouseEnter={() => handleMouseEnter({ score, date, row })}
                                                        // onMouseLeave={handleMouseLeave}
                                                        style={{ color: '#5feda6' }}
                                                      />
                                                    )}
                                                    {score >= 76 && score <= 100 && (
                                                      <SignalCellularAltRoundedIcon
                                                        onMouseEnter={() => handleMouseEnter({ score, date, row })}
                                                        // onMouseLeave={handleMouseLeave}
                                                        style={{ color: '#5feda6' }}
                                                      />
                                                    )}
                                                  </div>
                                                )
                                              }
                                            }
                                          }
                                          return column
                                        })
                                    }
                                    rows={usedLeads || []}
                                    loading={isFindLeadsLoading || isfindCompaniesLoading}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    checkboxSelection={alignment === 'People'}
                                    keepNonExistentRowsSelected
                                    rowCount={usedTotal}
                                    onRowClick={(e) => {
                                      // if (alignment === 'People') {
                                      setIsLeadDetailDrawerOpen(true)
                                      setRowClickedId(e.id)
                                      // }
                                    }}
                                    rowSelectionModel={alignment === 'People' ? selectedRows : []}
                                    onRowSelectionModelChange={
                                      alignment === 'People' && handleSetSelectedRows
                                    }
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                    rowSelection={alignment === 'People'}
                                    getRowId={(row) => row?.company_id || row?.id}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={handleColumnVisibilityChange}
                                    columnMenuOptions={{
                                      showAllColumns: true,
                                      hideAllColumns: true
                                    }}
                                    apiRef={apiRef}
                                    slots={{
                                      baseCheckbox: CustomCheckbox,
                                      columnMenu: CustomColumnMenu,
                                      columnMenuIcon: () => null,
                                      footer: () => (
                                        <CustomPaginationFooter
                                          paginationModel={paginationModel}
                                          onPaginationModelChange={setPaginationModel}
                                          rowCount={usedTotal}
                                        />
                                      )
                                    }}
                                  />
                                  <SimpleBarChartDialog
                                    open={dialogOpen}
                                    score={hoveredScore}
                                    date={hoveredDate}
                                    row={hoveredRow}
                                    onClose={handleMouseLeave}
                                    intentGraphLoading={intentGraphLoading}
                                  />
                                </Box >
                              </>
                            )}
                          </Box >
                        </Box >
                      </Box >
                    </Grid >
                  </Grid >
                </Box >
              </Box >

              {/* lead detail drawer */}
              {
                usedLeads?.map(
                  (item) =>
                    (alignment === 'Companies' ? item.company_id : item.id) === rowClickedId && (
                      <LeadDetailModal
                        isOpen={isLeadDetailDrawerOpen}
                        onClose={handleLeadDetailDrawerClose}
                        user={item}
                        handleAddToListClick={handleAddToListClick}
                        handleAddToCampaignClick={handleAddToCampaignClick}
                        isConfirmingLeads={isConfirmingLeads}
                        tab={alignment === 'Companies' ? 'companies' : 'person'}
                        handleEmployeesSearchClick={handleEmployeesSearchClick}
                      />
                    )
                )
              }

              {/* all saved search drawer */}
              {/* <Drawer
                anchor="right"
                open={isAllSavedSearchDrawerOpen}
                onClose={handleAllSavedSearchDrawerClose}
                fullScreen={isMobile}
                sx={{
                  backgroundColor: "rgba(4, 4, 30, 0.5)",
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: { sm: "400px", xs: "100%" },
                  },
                }}
              >
                <Box sx={{ p: 2, width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        color: alpha(theme.palette.primary.main, 0.8),
                        textAlign: "center",
                      }}
                    >
                      All Saved Lead Searches
                    </Typography>
                    <IconButton onClick={handleAllSavedSearchDrawerClose}>
                      <EACloseIcon />
                    </IconButton>
                  </Box>
                  <SavedSearchBlock
                    searches={searches}
                    isLoading={isGetSearchesLoading}
                    onSearchClick={handleSearchClick}
                    onEditSearchClick={handleEditSearchClick}
                    showLess={false}
                  />
                </Box>
              </Drawer> */}

              <SavedSearchDrawer
                isOpen={isAllSavedSearchDrawerOpen}
                onClose={handleAllSavedSearchDrawerClose}
                savedSearches={savedSearches}
                isFetching={isFetchingAllSavedSearches}
                onSearchClick={handleSearchClick}
                onEditSearchClick={handleEditSearchClick}
              />

              {/* save search dialog */}
              <Dialog
                open={isSavedSearchDialogOpen}
                onClose={handleSaveSearchDialogClose}
                sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
              >
                <DialogTitle
                  sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '28px',
                    color: alpha(theme.palette.primary.main, 0.8)
                  }}
                >
                  Save Filter
                </DialogTitle>
                <DialogContent>
                  <Typography
                    sx={{
                      width: '100%',
                      textAlign: 'left',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      color: alpha(theme.palette.primary.main, 0.8),
                      mt: 2
                    }}
                  >
                    Filter name
                  </Typography>
                  <TextField
                    autoFocus
                    fullWidth
                    variant='outlined'
                    sx={{
                      mt: 2,
                      width: 480,
                      height: 40,
                      backgroundColor: 'white',
                      '& div': { pl: 0.3 },
                      '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                      '& div input': {
                        py: 1.3,
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        letterSpacing: '0em',
                        '&::placeholder': {
                          color: 'rgba(40, 40, 123, 0.5)'
                        }
                      }
                    }}
                    name='searchName'
                    value={searchName}
                    onChange={(event) => setSearchName(event.target.value)}
                  />
                </DialogContent>
                <DialogActions sx={{ mb: 3, mx: 2 }}>
                  <Button onClick={handleSaveSearchDialogClose} variant='outlined' fullWidth>
                    Cancel
                  </Button>
                  <Button
                    disabled={!searchName.trim()}
                    onClick={handleSaveSearchDialogSave}
                    variant='contained'
                    fullWidth
                  >
                    {isSavingSearch
                      ? (
                        <CircularProgress size={20} sx={{ color: 'white' }} />
                      )
                      : (
                        'Save'
                      )}
                  </Button>
                </DialogActions>
              </Dialog>
              {/* add to list dialog */}

              {/* <Dialog
                // open={isAddToListDialogOpen}
                onClose={handleAddToListDialogClose}
                sx={{
                  backgroundColor: 'rgba(4, 4, 30, 0.5)'
                }}
                fullWidth
                maxWidth='xs'
                >
                <DialogTitle
                  sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '28px',
                    color: alpha(theme.palette.primary.main, 0.8)
                  }}
                >
                  Add to List
                </DialogTitle>
                <DialogContent>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel
                      id='select-list-label'
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '18px',
                        letterSpacing: '0px',
                        color: alpha(theme.palette.primary.main, 0.8)
                      }}
                    >
                      Select List
                    </InputLabel>
                    <Select
                      labelId='select-list-label'
                      value={selectedList || ''}
                      onChange={(e) => setSelectedList(e.target.value)}
                      label='Select List'
                      sx={{
                        backgroundColor: 'white',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '8px',
                          border: '1px solid #E4E4E5'
                        },
                        '& .MuiSelect-select': {
                          py: 2,
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '16px',
                          letterSpacing: '0em'
                        }
                      }}
                    >
                      {isLoadingLists
                        ? (
                          <MenuItem disabled>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <CircularProgress size={20} />
                              Loading lists...
                            </Box>
                          </MenuItem>
                        )
                        : lists?.docs?.length > 0
                          ? (
                            lists.docs.map((list) => (
                              <MenuItem
                                key={list._id}
                                value={list._id}
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  color: alpha(theme.palette.primary.main, 0.8)
                                }}
                              >
                                {list.name}
                              </MenuItem>
                            ))
                          )
                          : (
                            <MenuItem disabled>No lists available</MenuItem>
                          )}
                    </Select>
                  </FormControl> */}
              {/* <FormControlLabel
                    control={
                      <Checkbox
                        icon={<OffCheckboxCustomIcon />}
                        checkedIcon={<OnCheckboxCustomIcon />}
                        checked={verifyLeads}
                        onChange={(e, value) => setVerifyLeads(value)}
                      />
                    }
                    label='Validate Emails'
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '13px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: alpha(theme.palette.primary.main, 0.8)
                      },
                      mt: 2
                    }}
                  />
                  {verifyLeads && (
                    <FormGroup sx={{ pl: 1.5, mt: 1, display: 'flex', flexDirection: { sm: 'column', xs: 'column', md: 'row', lg: 'row' } }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<OffCheckboxCustomIcon />}
                            checkedIcon={<OnCheckboxCustomIcon />}
                            checked={validateEmails.varified}
                            name='varified'
                            onChange={handleEmailValidate}
                          />
                        }
                        label="Verified"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: alpha(theme.palette.primary.main, 0.8),
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<OffCheckboxCustomIcon />}
                            checkedIcon={<OnCheckboxCustomIcon />}
                            checked={validateEmails.unVerified}
                            name='unVerified'
                            onChange={handleEmailValidate}
                          />
                        }
                        label="Un-verified"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: alpha(theme.palette.primary.main, 0.8),
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<OffCheckboxCustomIcon />}
                            checkedIcon={<OnCheckboxCustomIcon />}
                            checked={validateEmails.risky}
                            name='risky'
                            onChange={handleEmailValidate}
                          />
                        }
                        label="Risky"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: alpha(theme.palette.primary.main, 0.8),
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<OffCheckboxCustomIcon />}
                            checkedIcon={<OnCheckboxCustomIcon />}
                            checked={validateEmails.all}
                            name='all'
                            onChange={handleEmailValidate}
                          />
                        }
                        label="Catch all"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: alpha(theme.palette.primary.main, 0.8),
                          },
                        }}
                      />
                    </FormGroup>
                  )} */}
              {/* </DialogContent>
                <DialogActions
                  sx={{
                    mb: 3,
                    mx: 2,
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 2
                  }}
                >
                  <Button onClick={handleAddToListDialogClose} variant='outlined' fullWidth>
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    fullWidth
                    disabled={!selectedList}
                    onClick={handleAddToListDialogSave}
                    sx={{ '&.MuiButton-root': { margin: 0 } }}
                  >
                    Add to List
                  </Button>
                </DialogActions>
              </Dialog> */}

              {/* add to campaign dialog - old */}
              {/* <Dialog
                open={isAddToCampaignDialogOpen}
                onClose={handleAddToCampaignDialogClose}
                sx={{
                  backgroundColor: 'rgba(4, 4, 30, 0.5)'
                }}
                fullWidth
                maxWidth='xs'
              >
                <DialogTitle
                  sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '28px',
                    color: alpha(theme.palette.primary.main, 0.8)
                  }}
                >
                  Add to Campaign
                </DialogTitle>
                <DialogContent>
                  <Autocomplete
                    freeSolo
                    id='checkboxes-tags-demo'
                    options={campaignsList}
                    getOptionLabel={(option) => option?.name}
                    renderOption={(props, option) => (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          px: 0
                        }}
                        {...props}
                      >
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            color: alpha(theme.palette.primary.main, 0.8)
                          }}
                        >
                          {option?.name}
                        </Typography>
                      </li>
                    )}
                    renderTags={(value) => (
                      <Box
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '90%',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '18px',
                          letterSpacing: '0px',
                          color: alpha(theme.palette.primary.main, 0.8)
                        }}
                      >
                        {value}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Select Campaign'
                        variant='outlined'
                        sx={{
                          backgroundColor: 'white',
                          '& div': { pl: 0.3 },
                          '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                          '& div input': {
                            py: 2,
                            fontSize: '13px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            letterSpacing: '0em',
                            '&::placeholder': {
                              color: 'rgba(40, 40, 123, 0.5)'
                            }
                          },
                          '& label': {
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '18px',
                            letterSpacing: '0px',
                            color: alpha(theme.palette.primary.main, 0.8)
                          }
                        }}
                        name='location'
                      />
                    )}
                    sx={{ width: '100%', mt: 2 }}
                    onChange={(e, option) => setSelectedCampaign(option?._id)}
                  />
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 500,
                      lineHeight: '16px',
                      color: alpha(theme.palette.primary.main, 0.8),
                      mt: 2
                    }}
                  >
                    Check the box below if you'd like to validate these leads
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<OffCheckboxCustomIcon />}
                        checkedIcon={<OnCheckboxCustomIcon />}
                        checked={verifyLeads}
                        onChange={(e, value) => setVerifyLeads(value)}
                      />
                    }
                    label='Validate Emails'
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '13px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: alpha(theme.palette.primary.main, 0.8)
                      },
                      mt: 2
                    }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<OffCheckboxCustomIcon />}
                        checkedIcon={<OnCheckboxCustomIcon />}
                        checked={leadDuplicate}
                        onChange={(e, value) => setLeadDuplicate(value)}
                      />
                    }
                    label='Check for duplicates across all campaigns'
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '13px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: alpha(theme.palette.primary.main, 0.8)
                      },
                      mt: 2
                    }}
                  />
                  {userVerifiedStatus
                    ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<OffCheckboxCustomIcon />}
                            checkedIcon={<OnCheckboxCustomIcon />}
                            checked={phoneNumber}
                            onChange={(e, value) => setPhoneNumber(value)}
                          />
                        }
                        label='Phone Number Export'
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: alpha(theme.palette.primary.main, 0.8)
                          },
                          mt: 2
                        }}
                      />
                    )
                    : (
                      <></>
                    )}
                </DialogContent>
                <DialogActions
                  sx={{
                    mb: 3,
                    mx: 2,
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 2
                  }}
                >
                  <Button onClick={handleAddToCampaignDialogClose} variant='outlined' fullWidth>
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    fullWidth
                    disabled={!selectedCampaign || isCampaign}
                    onClick={handleAddToCampaignDialogSave}
                    sx={{ '&.MuiButton-root': { margin: 0 } }}
                  >
                    {isConfirmingLeads
                      ? (
                        <CircularProgress size={20} sx={{ color: 'white' }} />
                      )
                      : (
                        'Add to Campaign'
                      )}
                  </Button>
                </DialogActions>
              </Dialog> */}

              {/* add to campaign dialog - new */}
              <Dialog
                open={isAddToCampaignDialogOpen}
                onClose={handleAddToCampaignDialogClose}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '8px'
                }}
              >
                <AddToCampaignDialog
                  open={isAddToCampaignDialogOpen}
                  dailogType={dailogType}
                  onClose={handleAddToCampaignDialogClose}
                  campaignsList={dailogType === 'list' ? lists?.docs : campaignsList}
                  selectedCampaign={selectedCampaign}
                  setSelectedCampaign={setSelectedCampaign}
                  handleAddToCampaignDialogSave={dailogType === 'list' ? handleAddToListDialogSave : handleAddToCampaignDialogSave}
                  verifyLeads={verifyLeads}
                  setVerifyLeads={setVerifyLeads}
                  leadDuplicate={leadDuplicate}
                  setLeadDuplicate={setLeadDuplicate}
                />
              </Dialog>

              <Dialog
                open={addToCampaignConfirmation}
                onClose={handleCloseConfirmationModal}
                sx={{
                  backgroundColor: 'rgba(4, 4, 30, 0.5)'
                }}
                fullWidth
                maxWidth='xs'
              >
                <DialogTitle
                  sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '28px',
                    color: alpha(theme.palette.primary.main, 0.8)
                  }}
                >
                  Add to Campaign
                </DialogTitle>
                <DialogContent>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '21px',
                      color: alpha(theme.palette.primary.main, 0.8),
                      mt: 1
                    }}
                  >
                    It looks like {alreadyExist ?? 0} of the {selectedRows?.length ?? 0} leads you
                    selected are already part of some campaigns. To avoid sending duplicate emails
                    and saving your credits, please choose the Check for Duplicates option before
                    continuing
                  </Typography>
                  {!verifyLeads && (
                    <Typography
                      sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: alpha(theme.palette.primary.main, 0.8),
                        mt: 2
                      }}
                    >
                      Check the box below if you'd like to validate these leads
                    </Typography>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<OffCheckboxCustomIcon />}
                        checkedIcon={<OnCheckboxCustomIcon />}
                        checked={verifyLeads}
                        onChange={(e, value) => setVerifyLeads(value)}
                      />
                    }
                    label='Validate Emails'
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '13px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: alpha(theme.palette.primary.main, 0.8)
                      },
                      mt: 2
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<OffCheckboxCustomIcon />}
                        checkedIcon={<OnCheckboxCustomIcon />}
                        checked={leadDuplicate}
                        onChange={(e, value) => setLeadDuplicate(value)}
                      />
                    }
                    label='Check for duplicates across all campaigns'
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '13px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: alpha(theme.palette.primary.main, 0.8)
                      },
                      mt: 2
                    }}
                  />
                </DialogContent>
                <DialogActions
                  sx={{
                    mb: 3,
                    mx: 2,
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 2
                  }}
                >
                  <Button onClick={handleCloseConfirmationModal} variant='outlined' fullWidth>
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    fullWidth
                    onClick={handleAddToCampaignDialogSave}
                    sx={{ '&.MuiButton-root': { margin: 0 } }}
                  >
                    Continue
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={leadCreditDialogOpen}
                onClose={closeCreditModal}
                fullScreen={isMobile}
                maxWidth='md' // Adjust this value as needed (e.g., "md", "xl")
                fullWidth
                sx={{
                  backgroundColor: 'rgba(4, 4, 30, 0.5)',
                  '& .MuiDialog-paper': { height: { xs: '100%', sm: '90vh' }, width: '100%' }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                    py: 3,
                    px: 3,
                    overflowX: 'hidden'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 700,
                        lineHeight: '28px',
                        letterSpacing: '0em',
                        color: alpha(theme.palette.primary.main, 0.8)
                      }}
                    >
                      {leadCreditTab === 'usage'
                        ? 'Lead Usage'
                        : leadCreditTab === 'download summary'
                          ? 'Lead finder download summary'
                          : 'Enrichment'}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        width: "50%",
                        justifyContent: "flex-end"
                      }}
                    >
                      {(itemCountStatus && leadCreditTab === 'download summary') && (
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '0em',
                            color: theme?.palette.primary.primaryText,
                            backgroundColor: '#f2f4f6',
                            border: '1px solid #8181B0',
                            borderRadius: '6px',
                            padding: '8px',
                          }}
                        >
                          Your leads will appear shortly
                        </Typography>
                      )}
                      {/* {(leadCreditTab === 'download summary') && (
                        <RefreshButton onClick={handleRefresh} />
                      )} */}
                    </Box>
                    <IconButton onClick={closeCreditModal}>
                      <Close />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      mt: 2,
                      height: { xs: '85vh', sm: '75vh' },
                      overflow: 'hidden',
                      border: `1px solid ${theme.palette.grey[300]}`,
                      borderRadius: 1
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%'
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        sx={{ height: '100%', width: '100%', overflowX: 'auto', ...scrollBarStyle }}
                      >
                        <Table
                          aria-label='simple table'
                          sx={{ borderCollapse: 'revert', width: '100%' }}
                        >
                          {leadCreditTab === 'download summary' && (
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: '13px',
                                      fontWeight: 500,
                                      lineHeight: '16px',
                                      letterSpacing: '0em',
                                      color: '#8181B0'
                                    }}
                                  >
                                    Title
                                  </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                  <Typography
                                    sx={{
                                      fontSize: '13px',
                                      fontWeight: 500,
                                      lineHeight: '16px',
                                      letterSpacing: '0em',
                                      color: '#8181B0'
                                    }}
                                  >
                                    Date
                                  </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                  <Typography
                                    sx={{
                                      fontSize: '13px',
                                      fontWeight: 500,
                                      lineHeight: '16px',
                                      letterSpacing: '0em',
                                      color: '#8181B0'
                                    }}
                                  >
                                    estimated completion time
                                  </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                  <Typography
                                    sx={{
                                      fontSize: '13px',
                                      fontWeight: 500,
                                      lineHeight: '16px',
                                      letterSpacing: '0em',
                                      color: '#8181B0'
                                    }}
                                  >
                                    Status
                                  </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                  <Typography
                                    sx={{
                                      fontSize: '13px',
                                      fontWeight: 500,
                                      lineHeight: '16px',
                                      letterSpacing: '0em',
                                      color: '#8181B0'
                                    }}
                                  >
                                    Download
                                  </Typography>
                                </TableCell>
                                {/* <TableCell align="left">
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: 500,
                                    lineHeight: "16px",
                                    letterSpacing: "0em",
                                    color: "#8181B0",
                                  }}
                                >
                                  Add to Campaign
                                </Typography>
                              </TableCell> */}
                                <TableCell align='left'>
                                  <Typography
                                    sx={{
                                      fontSize: '13px',
                                      fontWeight: 500,
                                      lineHeight: '16px',
                                      letterSpacing: '0em',
                                      color: '#8181B0',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    Verified Leads
                                    {/* <InfoIcon sx={{ marginRight: '4px' }}/> */}
                                    {/* <Tooltip title="We have verified the leads to enhance your delivery rates and safeguard your email accounts from burning out.">
                                  <InfoIcon />
                                </Tooltip> */}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          )}
                          {leadCreditTab === 'usage' ? (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                // width: { xs: "100%", sm: "500px" },
                                // py: 3,
                                px: 3,
                                overflow: 'hidden'
                              }}
                            >
                              <TableContainer
                                component={Paper}
                                sx={{ height: '100%', width: '100%', ...scrollBarStyle }}
                              >
                                <Table aria-label='simple table' sx={{ borderCollapse: 'revert' }}>
                                  <TableBody>
                                    {transformedGroupedByDate.map(({ date, items, sumAmount }) => (
                                      <TableRow key={date}>
                                        <TableCell sx={{ fontWeight: 500, width: '80%' }}>
                                          {date}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 500, width: '20%' }}>
                                          {sumAmount}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          ) : leadCreditTab === 'entrenchment' ? (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                // width: { xs: "100%", sm: "500px" },
                                // py: 3,
                                px: 3,
                                overflow: 'hidden'
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  width: '100%',
                                  mt: 2,
                                  height: { xs: '85vh', sm: '75vh' },
                                  overflow: 'hidden',
                                  borderRadius: 1
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                    width: '100%',
                                    height: '100%'
                                  }}
                                >
                                  <TableContainer
                                    component={Paper}
                                    sx={{ height: '100%', width: '100%', ...scrollBarStyle }}
                                  >
                                    <Table
                                      aria-label='simple table'
                                      sx={{ borderCollapse: 'revert' }}
                                    >
                                      <TableBody />
                                    </Table>
                                  </TableContainer>
                                </Box>
                              </Box>
                            </Box>
                          ) : leadCreditTab === 'download summary' ? (
                            <TableBody>
                              {isDownloadFilesLoading && page > 1 ? (
                                <TableRow>
                                  <TableCell colSpan={5} align='center'>
                                    <CircularProgress />
                                  </TableCell>
                                </TableRow>
                              ) : (
                                downloadFiles?.map((item) => (
                                  <TableRow
                                    key={item._id}
                                    sx={{
                                      '&:last-child td, &:last-child th': { border: 0 },
                                      backgroundColor:
                                        !item.isDownload === false ? '#E6F2FF' : 'inherit' // Conditional background color
                                    }}
                                  >
                                    <TableCell component='th' scope='row'>
                                      <Typography
                                        sx={{
                                          fontSize: '13px',
                                          fontWeight: 500,
                                          lineHeight: '16px',
                                          letterSpacing: '0em',
                                          color: alpha(theme.palette.primary.main, 0.8)
                                        }}
                                      >
                                        {' '}
                                        {item.isDownload}
                                        {item.name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align='left'>
                                      <Typography
                                        sx={{
                                          fontSize: '13px',
                                          fontWeight: 500,
                                          lineHeight: '16px',
                                          letterSpacing: '0em',
                                          color: alpha(theme.palette.primary.main, 0.8)
                                        }}
                                      >
                                        {timezone
                                          ? moment
                                            .utc(item.createdAt)
                                            .tz(timezone)
                                            .format('MM/DD/YYYY')
                                          : moment(item.createdAt).format('MM/DD/YYYY')}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align='left'>
                                      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <Box sx={{ width: "100%", mr: 1 }}>
                                          <LinearProgress
                                            variant="determinate"
                                            value={(item.totalLeadsAdded / item.totalLeads) * 100}
                                            sx={{
                                              height: 8,
                                              borderRadius: 5,
                                              backgroundColor: "#e0e0e0",
                                              "& .MuiLinearProgress-bar": {
                                                backgroundColor:
                                                  item.totalLeadsAdded === item.totalLeads ? "#5be057" : "#1976d2",
                                              },
                                            }}
                                          />
                                        </Box>
                                        <Typography
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            color: item.totalLeadsAdded === item.totalLeads ? "#4CAF50" : theme.palette.primary.primaryText,
                                            minWidth: "50px",
                                          }}
                                        >
                                          {calculateEstimatedTime(item.totalLeads, Math.max(item.totalLeadsAdded ?? 0, item.dataLength ?? 0))}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell align='left'>
                                      <Typography
                                        sx={{
                                          fontSize: '13px',
                                          fontWeight: 500,
                                          lineHeight: '16px',
                                          letterSpacing: '0em',
                                          color:
                                            item?.data?.length === 0
                                              ? '#FFA500'
                                              : item?.status === 'done'
                                                ? '#008000'
                                                : theme?.palette?.primary.primaryText
                                        }}
                                      >
                                        {(item?.dataLength !== 0 && item?.dataLength === item?.totalLeads || (item?.status === 'done' && item?.name === 'Enriched') ||
                                          (item?.status === 'done' && item?.dataLength !== 0 && item?.leadIds?.length && item?.dataLength === item?.leadIds?.length) ||
                                          (item?.status === 'done'))
                                          ? 'Completed'
                                          // : item?.dataLength === 0
                                          : 'Pending...'
                                          // : ''
                                        }
                                      </Typography>
                                    </TableCell>
                                    <TableCell align='left'>
                                      {(item?.dataLength !== 0 || (item?.status === 'done' && item?.name === 'Enriched'))
                                        ? (
                                          <>
                                            <Typography
                                              sx={{
                                                fontSize: '0.9em',
                                                fontWeight: 'bold',
                                                color: '#333'
                                              }}
                                            />
                                            {isDownloadCSVLoading && item?._id === selectedFileId ? (
                                              <CircularProgress size={25} />
                                            ) : (
                                              <IconButton
                                                sx={{ color: theme?.palette?.primary.primaryText }}
                                                onClick={() => handelDowloadCsv(item)}
                                              >
                                                <DownloadOutlined />
                                              </IconButton>
                                            )}
                                          </>
                                        )
                                        : (
                                          <Typography
                                            sx={{
                                              fontSize: '0.9em',
                                              fontWeight: 'bold',
                                              color: '#FFA500'
                                            }}
                                          >
                                            In Progress
                                          </Typography>
                                        )}
                                    </TableCell>
                                    <TableCell align='left'>
                                      {item.status === 'pending'
                                        ? (
                                          <Typography
                                            sx={{
                                              fontSize: '0.9em',
                                              fontWeight: 'bold',
                                              color: '#333'
                                            }}
                                          >
                                            {item.dataLength ?? 0} / {item.totalLeads || 0}
                                          </Typography>
                                        )
                                        : (
                                          <>
                                            <Typography
                                              sx={{
                                                fontSize: '0.9em',
                                                fontWeight: 'bold',
                                                color: '#333'
                                              }}
                                            >
                                              {item.dataLength ?? 0} /{' '}
                                              {item?.totalLeads
                                                ? item?.totalLeads
                                                : item?.leadIds?.length
                                                  ? item?.leadIds?.length
                                                  : item?.dataLength}
                                            </Typography>
                                          </>
                                        )}
                                    </TableCell>
                                  </TableRow>
                                ))
                              )}
                            </TableBody>
                          ) : (
                            <TableBody>
                              {downloadFiles?.map((item) =>
                                item.status === 'done'
                                  ? (
                                    <TableRow
                                      key={item._id}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                      <TableCell component='th' scope='row'>
                                        <Typography
                                          sx={{
                                            fontSize: '13px',
                                            fontWeight: 500,
                                            lineHeight: '16px',
                                            letterSpacing: '0em',
                                            color: alpha(theme.palette.primary.main, 0.8)
                                          }}
                                        >
                                          {item.name}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align='left'>
                                        <Typography
                                          sx={{
                                            fontSize: '13px',
                                            fontWeight: 500,
                                            lineHeight: '16px',
                                            letterSpacing: '0em',
                                            color: alpha(theme.palette.primary.main, 0.8)
                                          }}
                                        >
                                          {moment(item.createdAt).format('MM/DD/YYYY')}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align='left'>
                                        <Typography
                                          sx={{
                                            fontSize: '13px',
                                            fontWeight: 500,
                                            lineHeight: '16px',
                                            letterSpacing: '0em',
                                            color:
                                              item.status === 'pending'
                                                ? '#FFA500'
                                                : item.status === 'done'
                                                  ? '#008000'
                                                  : `${theme?.palette.primary.primaryText}`
                                          }}
                                        >
                                          {item.status === 'pending'
                                            ? 'Pending'
                                            : item.status === 'done'
                                              ? 'Completed'
                                              : ''}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align='left'>
                                        {item.status === 'pending'
                                          ? (
                                            <Typography
                                              sx={{
                                                fontSize: '0.9em',
                                                fontWeight: 'bold',
                                                color: '#333'
                                              }}
                                            >
                                              {item?.dataLength ?? 0} / {item?.leadIds?.length ?? 0}
                                            </Typography>
                                          )
                                          : (
                                            <IconButton
                                              sx={{
                                                color: alpha(theme.palette.primary.main, 0.8)
                                              }}
                                            >
                                              <AddCircleOutlineOutlinedIcon />
                                            </IconButton>
                                          )}
                                      </TableCell>
                                    </TableRow>
                                  )
                                  : (
                                    ''
                                  )
                              )}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      {downloadFiles?.length > 0 && leadCreditTab === 'download summary' && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                          <Pagination
                            page={page}
                            setPage={setPage}
                            total={totalFiles}
                            length={downloadFiles?.length}
                            limit={limit}
                            handleLimitChange={handleLimitChange}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    backgroundColor: '#f0f7ff',
                    borderRadius: '4px',
                    marginTop: '16px',
                    marginBottom: '16px'
                  }}>
                    <InfoIcon sx={{ color: '#1976d2', marginRight: '8px' }} />
                    <Typography>
                      Leads you've already downloaded <span style={{ color: '#d32f2f', fontWeight: 600 }}>won't be included</span> in your new downloads. You'll find them in the "Saved" tab (coming soon).
                    </Typography>
                  </Box>
                </Box>
              </Dialog>

              {/* waleed */}

              <Dialog
                open={isImportLeadsDialogOpen}
                onClose={handleCloseOpenImportLeadsDialog}
                fullWidth
                maxWidth='md'
                sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
                fullScreen={isMobile}
              >
                <DialogTitle>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      width: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        flexDirection: 'column'
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography
                          sx={{
                            fontSize: '20px',
                            fontWeight: 700,
                            lineHeight: '28px',
                            color: alpha(theme.palette.primary.main, 0.8)
                          }}
                        >
                          {listType === 'email' ? 'Import Leads' : 'Import Leads'}
                        </Typography>
                        {activeStep !== 0 && (
                          <>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mr: 0.5
                              }}
                            >
                              <ArrowRight />
                            </Box>
                          </>
                        )}
                        {activeStep === 1
                          ? (
                            <>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 700,
                                  lineHeight: '28px',
                                  color: '#8181B0'
                                }}
                              >
                                Import CSV File
                              </Typography>
                            </>
                          )
                          : activeStep === 2
                            ? (
                              <>
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '28px',
                                    color: '#8181B0'
                                  }}
                                >
                                  {listType === 'email'
                                    ? 'Input Emails Manually'
                                    : 'Input Domains Manually'}
                                </Typography>
                              </>
                            )
                            : activeStep === 3
                              ? (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 700,
                                      lineHeight: '28px',
                                      color: '#8181B0'
                                    }}
                                  >
                                    Utilize Google Sheets
                                  </Typography>
                                </>
                              )
                              : null}
                      </Box>

                      {activeStep === 0
                        ? (
                          <>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 400,
                                lineHeight: '20px',
                                color: '#8181B0',
                                mt: 1
                              }}
                            >
                              <Box display='flex' alignItems='center'>
                                {listType === 'email'
                                  ? 'Choose one of the methods listed below to effortlessly import emails.'
                                  : 'Choose one of the methods listed below to effortlessly import domains.'}
                                <Tooltip title='You can access the Enriched Leads from Download Summary and can download it for offline use.'>
                                  <InfoIcon sx={{ height: '18px', width: '18px', ml: 1 }} />
                                </Tooltip>
                              </Box>
                            </Typography>
                          </>
                        )
                        : (
                          <>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 400,
                                lineHeight: '20px',
                                color: '#8181B0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 1,
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                setActiveStep(0)
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  mr: 1
                                }}
                              >
                                <ArrowLeftIconBlue color='#8181B0' />
                              </Box>
                              Select a Different Method
                            </Typography>
                          </>
                        )}
                    </Box>
                    <IconButton
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      onClick={handleCloseOpenImportLeadsDialog}
                    >
                      <EACloseIcon />
                    </IconButton>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  {activeStep === 0
                    ? (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            rowGap: 2
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                              cursor: 'pointer',
                              width: '100%',
                              p: 3,
                              border: '1px solid #00AA38',
                              flexDirection: 'column',
                              height: '100%',
                              backgroundColor: '#fff',
                              borderRadius: '12px',
                              boxShadow: '0px 12px 15px 0px #4B71970D',
                              marginRight: !isMobile ? '12px' : '0px'
                            }}
                            onClick={() => {
                              setActiveStep(1)
                            }}
                          >
                            <Box
                              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                              <BulkUploadIcon />
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  lineHeight: '26px',
                                  color: alpha(theme.palette.primary.main, 0.8),
                                  mt: 1.5
                                }}
                              >
                                Upload CSV
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                              cursor: 'pointer',
                              width: '100%',
                              p: 3,
                              border: '1px solid #0071F6',
                              flexDirection: 'column',
                              height: '100%',
                              backgroundColor: '#fff',
                              borderRadius: '12px',
                              boxShadow: '0px 12px 15px 0px #4B71970D',
                              marginLeft: !isMobile ? '12px' : '0px'
                            }}
                            onClick={() => {
                              setActiveStep(3)
                            }}
                          >
                            <Google />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  lineHeight: '26px',
                                  color: alpha(theme.palette.primary.main, 0.8),
                                  mt: 1.5
                                }}
                              >
                                Use Google Sheets
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )
                    : activeStep === 1
                      ? (
                        <CsvImport
                          listType={listType}
                          setSnackbarOpen={setSnackbarOpen}
                          setSnackbarMsg={setSnackbarMsg}
                          onLeadsCreate={onLeadsCreate}
                          leadFinder={leadFinder}
                          setLeadsData={handleLeadsUpdate}
                          bounceCredits={bounceCredits}
                        />
                      )
                      : activeStep === 3
                        ? (
                          <GoogleSheetImport
                            listType={listType}
                            setSnackbarOpen={setSnackbarOpen}
                            setSnackbarMsg={setSnackbarMsg}
                            onLeadsCreate={onLeadsCreate}
                            leadFinder={leadFinder}
                            setLeadsData={handleLeadsUpdate}
                            bounceCredits={bounceCredits}
                          />
                        )
                        : null}
                </DialogContent>
              </Dialog>
              {/* <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby='dialog-title'
                aria-describedby='dialog-description'
              >
                <DialogTitle id='dialog-title'>
                  Are you sure you want to download the CSV?
                </DialogTitle>
                <DialogContent>
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 500,
                      lineHeight: '16px',
                      color: alpha(theme.palette.primary.main, 0.8),
                      mt: 2
                    }}
                  >
                    Check the box below if you'd like to validate these leads
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<OffCheckboxCustomIcon />}
                        checkedIcon={<OnCheckboxCustomIcon />}
                        checked={verifyLeads}
                        onChange={(e, value) => setVerifyLeads(value)}
                      />
                    }
                    label='Validate Emails'
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '13px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: alpha(theme.palette.primary.main, 0.8)
                      },
                      mt: 2
                    }}
                  /> */}

              {/* {verifyLeads && (
                    <FormGroup sx={{ pl: 1.5, mt: 1, display: 'flex', flexDirection: { sm: 'column', xs: 'column', md: 'row', lg: 'row' } }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<OffCheckboxCustomIcon />}
                            checkedIcon={<OnCheckboxCustomIcon />}
                            checked={validateEmails.varified}
                            name='varified'
                            onChange={handleEmailValidate}
                          />
                        }
                        label="Verified"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: alpha(theme.palette.primary.main, 0.8),
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<OffCheckboxCustomIcon />}
                            checkedIcon={<OnCheckboxCustomIcon />}
                            checked={validateEmails.unVerified}
                            name='unVerified'
                            onChange={handleEmailValidate}
                          />
                        }
                        label="Un-verified"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: alpha(theme.palette.primary.main, 0.8),
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<OffCheckboxCustomIcon />}
                            checkedIcon={<OnCheckboxCustomIcon />}
                            checked={validateEmails.risky}
                            name='risky'
                            onChange={handleEmailValidate}
                          />
                        }
                        label="Risky"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: alpha(theme.palette.primary.main, 0.8),
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<OffCheckboxCustomIcon />}
                            checkedIcon={<OnCheckboxCustomIcon />}
                            checked={validateEmails.all}
                            name='all'
                            onChange={handleEmailValidate}
                          />
                        }
                        label="Catch all"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: alpha(theme.palette.primary.main, 0.8),
                          },
                        }}
                      />
                    </FormGroup>
                  )} */}

              {/* {userVerifiedStatus
                    ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<OffCheckboxCustomIcon />}
                            checkedIcon={<OnCheckboxCustomIcon />}
                            checked={phoneNumber}
                            onChange={(e, value) => setPhoneNumber(value)}
                          />
                        }
                        label='Phone Number Export'
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: alpha(theme.palette.primary.main, 0.8)
                          },
                          mt: 2
                        }}
                      />
                    )
                    : (
                      <></>
                    )}
                </DialogContent>
                <DialogActions>
                  <Button variant='contained' color='primary' onClick={handleDownloadCsvClick}>
                    Confirm
                  </Button>
                  <Button onClick={handleCloseDialog}>Cancel</Button>
                </DialogActions>
              </Dialog> */}
              <Dialog open={openDialog}>
                <ExportCsvDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} handleDownloadCsvClick={handleDownloadCsvClick} verifyLeads={verifyLeads} setVerifyLeads={setVerifyLeads} />
              </Dialog>
            </>
          ),
          unverified: <VerifyEmailAddressPage secondary />,
          loading: ''
        }[verified]
      }
    </>
  )
}

export default Page
