import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'src/utils/base-query.js'
import { createSlice } from '@reduxjs/toolkit'
export const multichannelSlice = createSlice({
  name: 'linkedin_accounts',
  initialState: [],
  reducers: {
    setAccounts(state, action) {
      return action.payload
    },
    accountsAdded(state, action) {
      state.push(...action.payload)
    },
    accountUpdated(state, action) {
      const updatedAccount = action.payload
      const index = state.findIndex((a) => a._id === updatedAccount._id)
      if (index >= 0) {
        state[index] = { ...state[index], ...updatedAccount }
      }
    },
    accountDeleted(state, action) {
      const id = action.payload
      return state.filter((a) => a._id !== id)
    },
    accountsDeleted(state, action) {
      const { deleteAll, ids } = action.payload
      if (deleteAll) return []
      return state.filter((a) => !ids.includes(a._id))
    }
  }
})

export const {
  setAccounts,
  accountsAdded,
  accountUpdated,
  accountDeleted,
  accountsDeleted
} = multichannelSlice.actions


export const multichannelApi = createApi({
  reducerPath: 'multichannelApi',
  baseQuery,
  endpoints: (builder) => ({
    getAllMultichannelAccounts: builder.mutation({
      query: (data) => {
        return {
          url: 'multichannel/getLinkedAccounts',
          method: 'POST',
          body: data
        }
      },
      providesTags: ['MULTICHANNEL_ACCOUNTS']
    }),
    deleteMultichannelAccount: builder.mutation({
      query: (data) => {
        return {
          url: 'multichannel/deleteLinkedAccount/' + data,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['MULTICHANNEL_ACCOUNTS']
    }),
    storeUserInfoInRedis: builder.mutation({
      query: (data) => {
        return {
          url: 'multichannel/storeWorkSpaceId',
          method: 'POST',
          body: data
        }
      }
    }),
    updateLinkedinLimits: builder.mutation({
      query: (data) => {
        return {
          url: 'multichannel/updateLinkedinLimits',
          method: 'POST',
          body: {
            linkedinLimits: data
          }
        }
      }
    }),
    getLinkedinLimits: builder.query({
      query: () => {
        return {
          url: 'multichannel/getLinkedinLimits',
          method: 'GET'
        }
      }
    }),
    checkLinkedInAccountAlreadyExists: builder.mutation({
      query: (data) => {
        return {
          url: 'multichannel/checkLinkedInAccountAlreadyExists/' + data,
          method: 'GET'
        }
      }
    }),
    getUnipileHostedAuthUrl: builder.mutation({
      query: (data) => {
        return {
          url: 'multichannel/getUnipileHostedAuthUrl',
          method: 'POST',
          body: data
        }
      },
    }),
  })
})

export const {
  useGetAllMultichannelAccountsMutation,
  useDeleteMultichannelAccountMutation,
  useStoreUserInfoInRedisMutation,
  useUpdateLinkedinLimitsMutation,
  useGetLinkedinLimitsQuery,
  useCheckLinkedInAccountAlreadyExistsMutation,
  useGetUnipileHostedAuthUrlMutation
} = multichannelApi
