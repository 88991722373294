import { useEffect, useState } from "react";

const LinkedinAuthMessage = ({ message, success }) => {
    if (!message) return null;
    const [countdown, setCountdown] = useState(5);
    useEffect(() => {
        if (countdown === 0) {
            window.close();
            return;
        }

        const timer = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [countdown]);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
            backgroundColor: "#f4f4f4",
            fontFamily: "Arial, sans-serif",
        }}>
            <h1 style={{ color: "#333" }}>{message || "Authentication Successful!"}</h1>
            <h3 style={{ color: "#555" }}>
                You can close this tab now.<br />
                Automatically closing in <strong>{countdown}</strong> seconds...
            </h3>
            {success &&
                <h5>
                    <strong>Note:</strong> the account will <strong>not</strong> get added if a linkedin account with the same email already exists.
                </h5>}
        </div>
    );
};

export default LinkedinAuthMessage;
