import { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  IconButton,
  InputBase,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Radio,
  Typography,
  Dialog
} from '@mui/material'
import {
  Close,
  KeyboardArrowDown,
  ExpandMore,
  LockOutlined
} from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'
import CommonUpgradeModal from './filters/commonUpgradeModal'

const checkboxOfIcon = <OffCheckboxCustomIcon />
const checkboxOnIcon = <OnCheckboxCustomIcon />

export function ComboBox ({ options, value, placeholder, onChange, freeSolo = false, groupBy = false, isExclude = false, isDisabled = false }) {
  const theme = useTheme()
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={options}
      groupBy={groupBy}
      value={value}
      freeSolo={freeSolo}
      onChange={onChange}
      disabled={isDisabled}
      popupIcon={<KeyboardArrowDown sx={{ color: '#101010' }} />}
      // isOptionEqualToValue={(option, value) => isEqual(value, typeof option === 'object' ? option.label : option)}
      isOptionEqualToValue={(option, value) => {
        if (typeof option === 'string' && typeof value === 'string') {
          return option === value
        } else if (typeof option === 'object' && typeof value === 'string') {
          return option.label === value
        } else if (typeof option === 'object' && typeof value === 'object') {
          return option.label === value.label
        }
        return false
      }}
      renderInput={(props) => (
        <InputBase
          placeholder={placeholder}
          sx={{
            border: '1px solid #E4E4E5',
            borderRadius: '8px',
            p: 1,
            fontSize: { sm: '10px', md: '10px', lg: '14px' },
            width: '100%',
            minWidth: { sm: '10px', md: '10px', lg: '20px' },
            maxWidth: { xs: '50px', sm: '90px', md: '140px', lg: '220px', xl: '280px' },
            overflow: 'hidden',
            whiteSpace: 'normal',
            wordWrap: 'break-word'
          }}
          {...props.InputProps}
          {...props}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props}>
          <Checkbox
            icon={checkboxOfIcon}
            checkedIcon={checkboxOnIcon}
            sx={{
              marginRight: { xs: '8px', sm: '4px', md: '4px', lg: '8px' },
              '& .MuiSvgIcon-root': {
                fontSize: { xs: '6px', sm: '6px', md: '6px', lg: '12px' } // Controls the icon size
              }
            }}
            checked={selected}
          />

          {option.img && (
            <ListItemAvatar>
              <Avatar>
                <img src={option.img} sx={{ width: '20px', height: '20px', color: '#3F4FF8' }} />
              </Avatar>
            </ListItemAvatar>
          )}
          <ListItemText
            primary={option.label ?? option}
            primaryTypographyProps={{ sx: { textTransform: 'capitalize', fontSize: { xs: '8px', sm: '8px', md: '8px', lg: '13px' }, fontWeight: '500', color: `${theme.palette.primary.primaryText}` } }}
            secondary={option.secondaryLabel ? option.secondaryLabel : ''}
          />
        </ListItem>
      )}
      renderTags={(value, getTagProps) => value.map((option, index) => <Tag key={index} label={option.label ?? option} {...getTagProps({ index })} isExclude={isExclude} />)}
    />
  )
}

export function Tag ({ label, isExclude, ...props }) {
  return (
    <Chip
      variant='outlined'
      label={label}
      size='small'
      deleteIcon={<Close />}
      sx={{
        borderRadius: 2,
        background: isExclude ? '#FF494921' : '#E6F0FF',
        fontWeight: 500,
        fontSize: '14px',
        border: isExclude ? '1px solid #FF454540' : '1px solid #3F4FF836',
        color: 'black',
        textTransform: 'capitalize',
        textDecoration: isExclude ? 'line-through' : 'none',
        '& .MuiChip-deleteIcon': { color: 'black' }
      }}
      {...props}
    />
  )
}

export function FilterAccordion ({ label, children }) {
  const theme = useTheme()
  return (
    <Accordion disableGutters sx={{ boxShadow: 'none' }}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: '#101010' }} />}
        sx={{
          color: `${theme?.palette.primary.main}`,
          padding: '0',
          margin: '0',
          fontSize: '13px',
          fontWeight: '700'
        }}
      >
        {label}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '0',
          margin: '0'
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export function FilterDropdown ({ title, filterCount, icon, clearAll, children, preview = <></>, isOpen, onToggle }) {
  const theme = useTheme()
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <Paper
      sx={{
        width: '100%',
        p: 1,
        boxShadow: '0 1px 2px rgba(0,0,0,0.08)',
        '&:hover': {
          boxShadow: '0 1px 2px rgba(0,0,0,0.10)'
        },
        mb: '13px',
        border: '1px solid var(--Border-Light, #E4E4E5)',
        borderRadius: 2
      }}
    >
      <Box
        onClick={onToggle}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          mx: { sm: '0px', md: '0', lg: '16px' }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {icon}
          <Typography variant='h6' sx={{ fontWeight: 700, fontSize: { sm: '10px', md: '12px', lg: '14px' } }}>
            {title}
          </Typography>
          {filterCount > 0 && (
            <Box
              sx={{
                background: `${theme.palette.primary.main}`,
                display: 'flex',
                alignItems: 'center',
                color: 'white',
                borderRadius: '6px',
                padding: { sm: '2px 4px', md: '2px 4px', lg: '4px 8px' },
                fontSize: { sm: '10px', md: '12px', lg: '14px' },
                fontWeight: 700
              }}
            >
              {filterCount}
              <IconButton
                size='small'
                onClick={(e) => {
                  e.stopPropagation()
                  clearAll()
                }}
                sx={{
                  color: 'white',
                  p: 0,
                  ml: 0.5,
                  '&:hover': { color: '#90caf9' }
                }}
              >
                <Close sx={{ fontSize: 14 }} />
              </IconButton>
            </Box>
          )}
        </Box>
        <IconButton size='small'>
          <KeyboardArrowDown
            sx={{
              color: isOpen ? `${theme.palette.primary.main}` : '#101010',
              rotate: isOpen ? '180deg' : '0deg',
              transition: '0.2s linear rotate'
            }}
          />
        </IconButton>
      </Box>

      <Collapse in={isOpen}>
        <Box sx={{ margin: '1rem' }}>
          {children}
        </Box>
      </Collapse>

      <Collapse in={!isOpen}>
        {preview}
      </Collapse>
    </Paper>
  )
}

export function RadioBtnCollapse ({ selected, value, label, children }) {
  return (
    <Box py={2} px={2} borderRadius={1.5} border='1px solid #3F4FF836' bgcolor={selected === value ? '#FFF' : '#F2F4F6'}>
      <FormControlLabel value={value} control={<Radio />} label={label} sx={{ '& .Mui-checked': { color: '#3F4FF8' }, '& .MuiFormControlLabel-label': { fontSize: '14px', fontWeight: '700' } }} />
      {children &&
        <Collapse in={selected === value}>
          {children}
        </Collapse>}
    </Box>
  )
}

export function LockedFilter ({
  title, icon, plan
  // isPlanCommonUpgradeModalOpen, closeModal, openModal
}) {
  const theme = useTheme()
  const [isPlanCommonUpgradeModalOpen, setIsPlanCommonUpgradeModalOpen] = useState(false)
  // const [setClose] = useState(false)
  const closeModal = (event) => {
    event.stopPropagation()
    setIsPlanCommonUpgradeModalOpen(false)
    // setClose(false)
  }
  const openModal = (event) => {
    event.stopPropagation()
    setIsPlanCommonUpgradeModalOpen(true)
    // setClose(false)
  }
  return (
    <Paper
      sx={{
        width: '100%',
        p: 1,
        boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
        mb: '13px',
        border: '1px solid var(--Border-Light, #E4E4E5)',
        borderRadius: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.09)', // Light grey background
        opacity: 0.9
      }}
      onClick={openModal}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          mx: '16px'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {icon}
          <Typography variant='h6' sx={{ fontWeight: 700, fontSize: '14px' }}>
            {title}
          </Typography>
        </Box>
        <IconButton size='small'>
          <LockOutlined sx={{ color: `${theme.palette.primary.main}` }} />
        </IconButton>
      </Box>
      <Dialog
        open={isPlanCommonUpgradeModalOpen}
        onClose={closeModal}
        maxWidth='lg'
        fullWidth
      >
        <CommonUpgradeModal currentPlan={plan} closeModal={closeModal} title={title} icon={icon} />
      </Dialog>
    </Paper>
  )
}
