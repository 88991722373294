import React, { useEffect, useState } from 'react'
import { Box, Stack, Tooltip, Avatar, Typography, IconButton, useTheme } from '@mui/material'

import { useLocation, useNavigate } from 'react-router-dom'
import PopoverMenu from './settings/PopoverMenu'
import {
  setWorkspace,
  useGetWorkSpacesMutation,
  useUpdateWorkSpaceStatusMutation
} from 'src/services/workSpace-service'
import { useDispatch, useSelector } from 'react-redux'
import PremiumFeatureDialog from 'src/utils/premiumFeatureDialog'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import WorkspaceSelctorIcon from '../icons/WorkspaceSelctorIcon.png'
import { useGetCurrentPlanQuery } from 'src/services/billing-service'
import { config } from 'src/config'
import usePlan from 'src/hooks/usePlan'

const WorkspaceSelector = ({ isFreeUser, navMenuOpen, isMobile }) => {
  const theme = useTheme()
  const [getWorkSpaces] = useGetWorkSpacesMutation()
  const { data: currentPlan } = useGetCurrentPlanQuery()
  const [updateWorkSpaceStatus] = useUpdateWorkSpaceStatusMutation()
  const [openModal, setOpenModal] = useState(false)
  const [timelineButtons, setTimeLineButtons] = useState([])
  const [activeWorkspace, setActiveWorkspace] = useState({})
  const [timeline, setTimeline] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const plan = usePlan()
  const planInfo = plan?.planDetails?.planDetails

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const workspace = useSelector((state) => state.workspace)

  const checkFreeWorkspace = async (docs) => {
    const currentWorkspace = docs?.currentWorkspace
    const currentPlan = docs?.currentPlan
    const uniqueWorkspaces = docs?.uniqueWorkspaces?.length
    const isEarlyBird = currentWorkspace?.isEarlyBirdAccessUser
    const isAppSumo = currentWorkspace?.assignedPlan?.length > 0
    const isPaid =
      currentPlan?.subscription?.sendingWarmup?.expiresAt ||
      currentPlan?.subscription?.leads?.expiresAt

    if (
      (!currentPlan && !isAppSumo) ||
      (uniqueWorkspaces > 1 && !isPaid && !isEarlyBird && !isAppSumo)
    ) {
      navigate('/workSpace/billing', { state: { fromCreateWorkspace: true }, replace: true })
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigateToCreateWorkspace = () => {
    if (isFreeUser) {
      setOpenModal(true)
      return
    }
    navigate('/new/workSpace/create', { state: { from: location.pathname } })
  }

  const handleWorkspaceChange = async (newTimeline) => {
    const { data } = await updateWorkSpaceStatus({ workspaceId: newTimeline?._id })
    const updatedWorkspace = data?.updatedWorkspace
    const currentPlan = data?.currentPlan

    if (currentPlan || updatedWorkspace?.appSumoCode) {
      navigate('/accounts')
      navigate(0)
    } else {
      navigate('/workSpace/billing', { state: { fromCreateWorkspace: true }, replace: true })
    }

    setTimeline(updatedWorkspace)
    dispatch(setWorkspace(updatedWorkspace))
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      const docs = await getWorkSpaces().unwrap()
      setTimeLineButtons(docs?.uniqueWorkspaces)
      setActiveWorkspace(docs?.currentWorkspace)
      await checkFreeWorkspace(docs)
    }, 500)
    return () => clearTimeout(timer)
  }, [getWorkSpaces])

  useEffect(() => {
    setTimeline(activeWorkspace)
    dispatch(setWorkspace(activeWorkspace))
    if (activeWorkspace) {
      if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
        window.localStorage.setItem('workspace', JSON.stringify(activeWorkspace))
      }
    }
  }, [timelineButtons])

  return (
    !(user?.isPartner || user?.partnerAccountId) && (
      <>
        <Stack
          direction='row'
          justifyContent={navMenuOpen || isMobile ? 'space-between' : 'flex-start'}
          sx={{ mb: isMobile ? 2 : 0 }}
        >
          {navMenuOpen || isMobile
            ? (
              <Stack alignItems='center' direction='row' spacing={2}>
                <Avatar
                  src={WorkspaceSelctorIcon}
                  sx={{
                    backgroundColor: theme?.palette.primary.light,
                    width: 40,
                    height: 40,
                    color: theme?.palette.primary.dark

                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    mx: 1,
                    width: 130,
                    overflow: 'hidden',
                    gap: 1

                  }}
                >
                  <Tooltip title={timeline?.name} arrow placement='top'>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '18px',
                        letterSpacing: '0em',
                        color: theme.palette.primary.contrastText,
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                      disabled={user?.isPartner || user?.partnerAccountId}
                    >
                      {timeline?.name}
                    </Typography>
                  </Tooltip>
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 400,
                      lineHeight: '16px',
                      letterSpacing: '0em',
                      color: theme.palette.primary.contrastText,
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {getLabel(currentPlan?.subscription?.sendingWarmup?.planId, workspace, planInfo)}
                  </Typography>
                </Box>
                <IconButton sx={{ color: '#fff', padding: 0 }} onClick={handleClick}>

                  <ArrowDropDownIcon />
                </IconButton>
              </Stack>
              )
            : (
              <Tooltip title={timeline?.name} arrow placement='right'>
                <Avatar
                  onClick={handleClick}
                  src={WorkspaceSelctorIcon}
                  sx={{
                    backgroundColor: theme?.palette.primary.light,
                    width: 40,
                    height: 40,
                    cursor: 'pointer',
                    color: 'white',
                    ml: 0.5
                  }}
                />
              </Tooltip>
              )}
        </Stack>

        <PopoverMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          timelineButtons={timelineButtons}
          setTimeline={handleWorkspaceChange}
          navigateToCreateWorkspace={navigateToCreateWorkspace}
          timeline={timeline}
        />
        <PremiumFeatureDialog open={openModal} onClose={() => setOpenModal(false)} />
      </>
    )
  )
}

function getLabel (planId, workSpace, plan) {
  // Check if user has an EarlyBirdPlan but no planId
  if (!planId && workSpace?.EarlyBirdPlan) {
    const plan = workSpace.EarlyBirdPlan.replace('EarlyBird', '') || ''
    return `${plan} Plan`
  }

  const planMap = {
    [config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID]: '7 days free trial',
    [config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID]: 'Growth Plan',
    [config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID]: 'Growth Plan',
    [config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID]: 'Infinity Plan',
    [config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID]: 'Infinity Plan',
    [config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID]: 'Starter Plan',
    [config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID]: 'Starter Plan'
  }

  if (planId && planMap[planId]) {
    return planMap[planId]
  }

  // check if user belongs to an old plans
  const { subscriptionType, billingPeriod } = plan
  if (subscriptionType && billingPeriod) {
    return `${billingPeriod} ${subscriptionType} Plan`
      .trim()
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }
  

  return 'Free Plan'
}

export default WorkspaceSelector
