import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack, Typography, RadioGroup } from '@mui/material'
import { ComboBox, Tag, FilterDropdown } from '../filterComponents'
import { titleRole } from 'src/assets/newFiltersData'
import { FolderIcon } from 'src/icons/Folder'
import { useTheme } from '@emotion/react'

export default function TitleRoleFilter ({ onChange, type, isOpen, onToggle }) {
  const theme = useTheme()
  const [selectedMethod, setSelectedMethod] = useState('')
  const [includedJobTitles, setIncludedJobTitles] = useState([])
  const [excludedJobTitles, setExcludedJobTitles] = useState([])
  const [managementTitles, setManagementTitles] = useState([])
  const [departmentTitles, setDepartmentTitles] = useState([])
  const ref = useRef({ internalStateChange: false })
  const jobTitleData = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].current_title)
  const departmentsTitle = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].departments)
  const excludeCurrTitle = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].excludeCurrentTitle)
  const managementLevel = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].management_levels)
  const handleIncludedJobTitle = useCallback((newValue, updateRedux = true) => {
    setIncludedJobTitles(newValue)
    if (updateRedux) onChange('current_title', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const handleExcludedJobTitle = useCallback((newValue, updateRedux = true) => {
    setExcludedJobTitles(newValue)
    if (updateRedux) onChange('excludeCurrentTitle', newValue)
    ref.current.internalStateChange = true
  }, [onChange])

  const handleManagementTitle = useCallback((newValue, updateRedux = true) => {
    if (selectedMethod === 'isUnKnown') return // Prevent selection when isUnknown is selected
    setManagementTitles(newValue)
    if (updateRedux) onChange('management_levels', newValue)
    ref.current.internalStateChange = true
  }, [onChange, selectedMethod])

  const handleDepartmentTitle = useCallback((newValue, updateRedux = true) => {
    if (selectedMethod === 'isUnKnown') return // Prevent selection when isUnknown is selected
    const transformedValues = newValue.map(item => {
      // If it's already a string (from existing selection)
      if (typeof item === 'string') return item
      // If it's object format, using the label
      return item.label
    })
    setDepartmentTitles(transformedValues)
    if (updateRedux) onChange('departments', transformedValues)
    ref.current.internalStateChange = true
  }, [onChange, selectedMethod])

  const clearAll = useCallback(() => {
    setSelectedMethod('')
    handleIncludedJobTitle([])
    handleExcludedJobTitle([])
    handleManagementTitle([])
    handleDepartmentTitle([])
  }, [handleIncludedJobTitle, handleExcludedJobTitle, handleManagementTitle, handleDepartmentTitle])

  const handleRadioBtn = useCallback((e) => {
    setSelectedMethod(e.target.value)
    handleIncludedJobTitle([])
    handleExcludedJobTitle([])
    if (e.target.value === 'isUnKnown') {
      handleManagementTitle([])
      handleDepartmentTitle([])
    }
  }, [handleIncludedJobTitle, handleExcludedJobTitle, handleManagementTitle, handleDepartmentTitle])

  useEffect(() => {
    if (ref.current.internalStateChange) {
      ref.current.internalStateChange = false
      return
    }

    setSelectedMethod(jobTitleData ? 'isAnyOf' : '')
    setIncludedJobTitles(jobTitleData ?? [])
    // setExcludedJobTitles([])
    // setManagementTitles([])
    // setDepartmentTitles([])
    setExcludedJobTitles(excludeCurrTitle ?? [])
    setManagementTitles(managementLevel ?? [])
    setDepartmentTitles(departmentsTitle ?? [])
  }, [jobTitleData, departmentsTitle, managementLevel, excludeCurrTitle])
  // Calculate total filter count
  // Calculate separate counts
  const includeCount = includedJobTitles?.length
  const excludeCount = excludedJobTitles?.length
  const managementCount = managementTitles?.length
  const departmentCount = departmentTitles?.length
  const methodCount = (selectedMethod === 'isKnown' || selectedMethod === 'isUnKnown') ? 1 : 0

  // Total filter count
  const totalFilterCount = includeCount + excludeCount + managementCount + departmentCount + methodCount
  const preview = (
    <>
      {((selectedMethod && selectedMethod !== 'isAnyOf') ||
        includedJobTitles?.length > 0 ||
        excludedJobTitles?.length > 0 ||
        managementTitles?.length > 0 ||
        departmentTitles?.length > 0) &&
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
            {includedJobTitles?.length > 0 &&
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
                <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Include</Typography>
                {includedJobTitles?.map((lang) => (
                  <Tag key={lang} label={lang} onDelete={() => handleIncludedJobTitle(includedJobTitles?.filter(l => l !== lang))} />
                ))}
              </Box>}
            {excludedJobTitles?.length > 0 &&
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
                <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Exclude</Typography>
                {excludedJobTitles?.map((lang) => (
                  <Tag key={lang} label={lang} onDelete={() => handleExcludedJobTitle(excludedJobTitles?.filter(l => l !== lang))} isExclude />
                ))}
              </Box>}
            {managementTitles?.length > 0 &&
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
                <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Management</Typography>
                {managementTitles?.map((lang) => (
                  <Tag key={lang} label={lang} onDelete={() => handleManagementTitle(managementTitles?.filter(l => l !== lang))} />
                ))}
              </Box>}
            {departmentTitles?.length > 0 &&
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
                <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Department</Typography>
                {departmentTitles?.map((lang) => (
                  <Tag key={lang} label={lang} onDelete={() => handleDepartmentTitle(departmentTitles?.filter(l => l !== lang))} />
                ))}
              </Box>}
            {selectedMethod === 'isKnown' &&
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: '280px', height: '0px', border: '1px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
                <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600 }}>Is known</Typography>
              </Box>}
            {selectedMethod === 'isUnKnown' &&
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                <Box sx={{ width: '280px', height: '0px', border: '1px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
                <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600 }}>Is unknown</Typography>
              </Box>}
          </Box>}
    </>
  )

  return (
    <FilterDropdown
      title='Title / Role'
      filterCount={totalFilterCount}
      excludeCount={excludeCount} // Add exclude count separately
      clearAll={clearAll}
      icon={<FolderIcon sx={{ stroke: `${theme.palette.primary.main}` }} />}
      preview={preview}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Stack direction='column' gap={2} mt={2}>
        <RadioGroup
          value={selectedMethod}
          onChange={handleRadioBtn}
        >
          <Stack direction='column' gap={1} sx={{ paddingLeft: 0, marginLeft: 0 }}>
            {/* <RadioBtnCollapse selected={selectedMethod} value='isAnyOf' label='Include Job Title(s)'> */}
            <Box sx={{
              // position: 'relative',
              display: 'flex', flexDirection: 'column'
            }}
            >
              <Box sx={{ display: 'flex', flexDirection: { sm: 'column', md: 'row', lg: 'row' }, justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>Include Job Title(s)</Typography>
                {includedJobTitles?.length > 0 && (
                  <Typography
                    onClick={() => handleIncludedJobTitle([])}
                    sx={{
                      // position: 'absolute',
                      // right: 8,
                      // top: -26,
                      color: 'primary.main',
                      fontSize: '12px',
                      fontWeight: 600,
                      cursor: 'pointer'
                    }}
                  >
                    Clear ({includedJobTitles?.length})
                  </Typography>
                )}
              </Box>
              <ComboBox
                placeholder='Search job titles'
                options={[...titleRole.jobTitles].sort((a, b) => a.localeCompare(b))}
                value={includedJobTitles}
                onChange={(e, newValue) => { handleIncludedJobTitle(newValue) }}
                freeSolo
                multiple
                handleHomeEndKeys
              />

            </Box>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography variant='button'>Exclude job titles</Typography>
            </Stack>
            {/* <FilterAccordion label='Exclude job titles'> */}
            <Box sx={{ position: 'relative' }}>
              <ComboBox
                placeholder='Search job titles'
                options={titleRole.jobTitles}
                value={excludedJobTitles}
                onChange={(e, newValue) => { handleExcludedJobTitle(newValue) }}
                isExclude
                freeSolo
                multiple
                handleHomeEndKeys
              />
            </Box>
            {/* </FilterAccordion> */}
            {/* </RadioBtnCollapse> */}
            {/* <RadioBtnCollapse selected={selectedMethod} value='isKnown' label='Is known' />
            <RadioBtnCollapse selected={selectedMethod} value='isUnKnown' label='Is unknown' /> */}
          </Stack>
        </RadioGroup>

        {/* Management Titles */}
        <Stack direction='column' gap={1}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='button'>Management titles</Typography>
          </Stack>
          <ComboBox
            placeholder='Enter titles'
            options={[...titleRole.managementLevel].sort((a, b) => a.localeCompare(b))}
            value={managementTitles}
            onChange={(e, newValue) => { handleManagementTitle(newValue) }}
            disabled={selectedMethod === 'isUnKnown'}
            freeSolo
            multiple
            handleHomeEndKeys
          />
        </Stack>

        {/* Department & Job Functions */}
        <Stack direction='column' gap={1}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='button'>Department and Job Function</Typography>
          </Stack>
          <ComboBox
            placeholder='Enter titles'
            // groupBy={(option) => option.key}
            groupBy={(option) => option.group} // Changed from key to group
            options={Object.entries(titleRole.department).flatMap(([group, values]) =>
              // Transform each value into the expected format
              values.map(value => ({
                label: value, // This matches what ComboBox expects
                value, // Keep original value
                group // For grouping
              }))
            )} value={departmentTitles}
            onChange={(e, newValue) => { handleDepartmentTitle(newValue) }}
            disabled={selectedMethod === 'isUnKnown'}
            freeSolo
            multiple
            handleHomeEndKeys
          />
        </Stack>
      </Stack>
    </FilterDropdown>
  )
}
