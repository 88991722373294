import { useState, useCallback } from 'react'
import {
  Typography,
  Box,
  DialogTitle,
  DialogContent,
  Stack,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
  ButtonBase,
  Fade,
  useMediaQuery,
  DialogActions, ToggleButton, ToggleButtonGroup
} from '@mui/material'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useBillingUsageQuery, useGetCurrentPlanQuery, useUpdatePlanMutation } from 'src/services/billing-service'
import { config } from 'src/config'
import UpgradePlanIcon from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/upgrade-plan.svg'
import UpgradePlanIconWhite from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/upgrade-plan-white.svg'
import UpgradePlanIconGrey from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/upgrade-plan-grey.svg'
import StarterPlanIcon from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/starter-plan-icon.svg'
import GrowthPlanIcon from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/growth-plan-icon.svg'
import InfinityPlanIcon from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/infinity-plan-icon.svg'
import { Close } from '@mui/icons-material'
import CurveLineIcon from 'src/assets/leadFinder/LFFilterNew/buyingIntentIcons/curve-line.svg'

const plusSignPositions = [
  { top: '10%', left: '5%', fontSize: '8px' },
  { top: '25%', left: '15%', fontSize: '10px' },
  { top: '40%', left: '30%', fontSize: '7px' },
  { top: '65%', left: '50%', fontSize: '6px' },
  { top: '60%', left: '70%', fontSize: '2px' },
  { top: '40%', left: '80%', fontSize: '3px' },
  { top: '20%', left: '90%', fontSize: '6px' },
  { top: '10%', left: '60%', fontSize: '8px' },
  { top: '10%', left: '60%', fontSize: '8px' },
  { top: '10%', left: '60%', fontSize: '8px' }
]

export default function CommonUpgradeModal ({ currentPlan, closeModal, title, icon }) {
  const [selectedPlan, setSelectedPlan] = useState(() => {
    const planOrder = ['free', 'starter', 'growth', 'infinity']
    let currentBasePlan = currentPlan
    if (currentPlan && (
      currentPlan.includes('yearly') ||
      currentPlan.includes('annual')
    )) {
      currentBasePlan = currentPlan.replace('-yearly', '').replace('-annual', '')
    }
    const currentIndex = planOrder.indexOf(currentBasePlan)
    const nextPlanIndex = Math.min(currentIndex + 1, planOrder.length - 1)

    return planOrder[nextPlanIndex]
  })
  const workspace = useSelector((state) => state.workspace)
  const { refetch: refetchCurrentPlan } = useGetCurrentPlanQuery()
  const { refetch: refetchUsage } = useBillingUsageQuery()
  const [updatePlan] = useUpdatePlanMutation()
  const [pricing, setPricing] = useState(() => {
    if (currentPlan && (
      currentPlan.includes('yearly') ||
      currentPlan.includes('annual')
    )) {
      return 'annual'
    }
    return 'monthly'
  })

  const handlePricingChange = (event, newPricing) => {
    if (newPricing !== null) {
      setPricing(newPricing)
    }
  }
  const getPlanValue = (basePlan) => {
    if (pricing === 'annual') {
      return `${basePlan}${basePlan !== 'free' ? '-yearly' : ''}`
    }
    return basePlan
  }
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const handleRadioBtn = useCallback((e) => {
    setSelectedPlan(e.target.value)
  }, [])

  const handleUpdatePlanClick = async () => {
    const actualSelectedPlan = getPlanValue(selectedPlan)
    if (actualSelectedPlan === 'free' || actualSelectedPlan === currentPlan) return

    let priceId
    const isYearly = pricing === 'annual'
    switch (selectedPlan) {
      case 'starter':
        priceId = isYearly
          ? config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID
          : config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
        break
      case 'growth':
        priceId = isYearly
          ? config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID
          : config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
        break
      case 'infinity':
        priceId = isYearly
          ? config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID
          : config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
        break
      default:
        break
    }

    const toastId = toast.loading('Updating...', { duration: Infinity })
    const session = await updatePlan({ priceId, workspaceId: workspace?._id }).unwrap()
    if (session.url) {
      window.location.href = session.url
    } else if (session.errorMsg) {
      toast.error(session.errorMsg, { id: toastId, duration: 5000 })
    } else {
      toast.success('Plan Updated', { id: toastId, duration: 2000 })
      await refetchCurrentPlan()
      refetchUsage()
    }
  }

  const isUpgradeBtnDisabled = getPlanValue(selectedPlan) === currentPlan || selectedPlan === 'free'

  return (
    <Grid container position='relative'>
      {isMdUp &&
        <DialogActions sx={{ position: 'absolute', top: '24px', right: '24px', zIndex: '10000' }}>
          <ButtonBase onClick={closeModal} sx={{ padding: '6px', backgroundColor: '#FFFFFF20', borderRadius: '6px', boxShadow: '0px 0px 1px 1px #ffffff75' }}>
            <Close fontSize='medium' sx={{ color: 'white' }} />
          </ButtonBase>
        </DialogActions>}
      <Grid item xs={isMdUp ? 6 : 12} p={isMdUp ? 4 : 2}>
        <DialogTitle>
          <Stack direction='row' gap={2} alignItems='center'>
            <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
            <Typography variant='h4' fontWeight='700'>Your {currentPlan} plan doesn't support {title}.</Typography>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
            <ToggleButtonGroup
              value={pricing}
              exclusive
              onChange={handlePricingChange}
              aria-label='pricing options'
            >
              <ToggleButton
                value='monthly'
                aria-label='monthly'
                sx={{
                  bgcolor: pricing === 'monthly' ? '#3F4FF8' : 'transparent',
                  color: pricing === 'monthly' ? '#FFF' : '#3F4FF8',
                  '&:hover': {
                    bgcolor: pricing === 'monthly' ? '#3F4FF8' : '#transparent'
                  }
                }}
              >
                Monthly
              </ToggleButton>
              <ToggleButton
                value='annual'
                aria-label='annual'
                sx={{
                  bgcolor: pricing === 'annual' ? '#3F4FF8' : 'transparent',
                  color: pricing === 'annual' ? '#FFF' : '#3F4FF8',
                  '&:hover': {
                    bgcolor: pricing === 'annual' ? '#3F4FF8' : 'transparent'
                  }
                }}
              >
                Annually
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <RadioGroup
            value={selectedPlan}
            onChange={handleRadioBtn}
          >
            <Stack direction='column' gap='9px' paddingBlock='38px'>
              <PlanRadioBtn
                title='Free Plan'
                subtitle='A quick and free way to discover Success.ai and its various features.'
                value='free'
                priceTag='Free'
                currentPlan={currentPlan}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                pricing={pricing}
              />
              <PlanRadioBtn
                title='Starter Plan'
                subtitle='Adapted for small businesses that want to explore tools and optimize their tasks.'
                value='starter'
                priceTag={pricing === 'annual' ? '$35/Month' : '$59/Month'}
                currentPlan={currentPlan}
                selectedPlan={selectedPlan}
                icon={StarterPlanIcon}
                setSelectedPlan={setSelectedPlan}
                pricing={pricing}
              />
              <PlanRadioBtn
                title='Growth Plan'
                subtitle="Maximize teamwork. Elevate your team's productivity."
                value='growth'
                priceTag={pricing === 'annual' ? '$59/Month' : '$99/Month'}
                currentPlan={currentPlan}
                selectedPlan={selectedPlan}
                icon={GrowthPlanIcon}
                setSelectedPlan={setSelectedPlan}
                pricing={pricing}
              />
              <PlanRadioBtn
                title='Infinity Plan'
                subtitle='Unlock the full power of Success.ai to boost your productivity and team collaboration.'
                value='infinity'
                priceTag={pricing === 'annual' ? '$89/Month' : '$149/Month'}
                currentPlan={currentPlan}
                selectedPlan={selectedPlan}
                icon={InfinityPlanIcon}
                setSelectedPlan={setSelectedPlan}
                pricing={pricing}
              />
            </Stack>
          </RadioGroup>

          <ButtonBase
            onClick={handleUpdatePlanClick}
            disabled={isUpgradeBtnDisabled}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              backgroundColor: isUpgradeBtnDisabled ? '#FFF' : '#3F4FF8',
              padding: '12px',
              borderRadius: '10px',
              border: isUpgradeBtnDisabled ? '1px solid #3F4FF836' : '1px solid transparent',
              position: 'relative',
              overflow: 'hidden',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: isUpgradeBtnDisabled ? '#FFF' : '#2D44C1'
              },
              '@keyframes shine': {
                '0%': { transform: 'scale(1)', opacity: 0.3 },
                '100%': { transform: 'scale(1.5)', opacity: 1 }
              }
            }}
          >
            {/* Background plus signs */}
            {plusSignPositions.map((position, index) => (
              <Box
                key={index}
                sx={{
                  position: 'absolute',
                  top: position.top,
                  left: position.left,
                  fontSize: position.fontSize,
                  color: '#FFF',
                  // animation: 'shine 1.5s infinite alternate',
                  opacity: 0.5
                }}
              >
                +
              </Box>
            ))}
            <Box>
              <img src={isUpgradeBtnDisabled ? UpgradePlanIconGrey : UpgradePlanIconWhite} style={{ width: '32px', height: '32px' }} />
            </Box>
            <Box>
              <Typography variant='body2' color={isUpgradeBtnDisabled ? '#8181B0' : '#FFF'} fontWeight='600'>
                Upgrade & Pay
              </Typography>
            </Box>
          </ButtonBase>

          <Link to='/settingsUpdated'>
            <Typography variant='subtitle2' fontWeight='600' color='GrayText' textAlign='center' mt={2}>
              View Plan Comparison
            </Typography>
          </Link>
        </DialogContent>
      </Grid>

      {isMdUp &&
        <Grid item xs={6} p={1}>
          <Box
            sx={{
              background: 'radial-gradient(260% 245% at -140% -95%, #81DDFA 6%, #3F5DFA 60%, #2C316E 100%)',
              width: '100%',
              height: '100%',
              borderRadius: '6px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <img src={CurveLineIcon} style={{ width: '570px', height: '780px' }} />
            <Fade in={selectedPlan === 'free'} mountOnEnter unmountOnExit timeout={400}>{freePlanFeatureBox}</Fade>
            <Fade in={selectedPlan === 'starter'} mountOnEnter unmountOnExit timeout={400}>{starterPlanFeatureBox}</Fade>
            <Fade in={selectedPlan === 'growth'} mountOnEnter unmountOnExit timeout={400}>{growthPlanFeatureBox}</Fade>
            <Fade in={selectedPlan === 'infinity'} mountOnEnter unmountOnExit timeout={400}>{infinityPlanFeatureBox}</Fade>
          </Box>
        </Grid>}
    </Grid>
  )
}

function PlanRadioBtn ({ currentPlan, value, selectedPlan, setSelectedPlan, title, subtitle, icon, priceTag, pricing }) {
  const isCurrentPlan = value === 'free'
    ? currentPlan === 'free'
    : (pricing === 'monthly' && currentPlan === value) ||
                       (pricing === 'annual' && currentPlan === `${value}-yearly`)
  return (
    <Box
      onClick={() => setSelectedPlan(value)}
      sx={{
        cursor: 'pointer',
        border: '1px solid #3F4FF836',
        padding: '12px',
        borderRadius: '6px',
        boxShadow: (selectedPlan === value && !isCurrentPlan) ? '0px 0px 2px 2px #3F4FF8' : 'none',
        backgroundColor: (selectedPlan === value && !isCurrentPlan) ? '#E7F0FF' : isCurrentPlan ? '#F2F4F6' : 'transparent',
        transition: 'all .3s'
      }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' gap={1} alignItems='center'>
          {icon && <img src={icon} style={{ width: '16px', height: '16px', color: '#3F4FF8' }} />}
          <Typography variant='h5'>{title}</Typography>
          {isCurrentPlan
            ? <Chip label='Current' size='small' variant='filled' sx={{ backgroundColor: '#3F4FF8', color: 'white', fontWeight: '600' }} />
            : <Typography variant='subtitle2' color='GrayText'>{priceTag}</Typography>}
        </Stack>
        <FormControlLabel value={value} control={<Radio />} sx={{ '& .Mui-checked': { color: '#3F4FF8' }, '& .MuiFormControlLabel-label': { fontSize: '14px', fontWeight: '600' } }} />
      </Stack>
      <Typography variant='body2' color='GrayText' maxWidth='380px'>{subtitle}</Typography>
    </Box>
  )
}

function FeatureBox ({ backgroundColor, children }) {
  return (
    <Box
      sx={{
        backgroundColor,
        padding: '12px',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        width: 1,
        boxShadow: '0px 0px 1px 1px #ffffff75',
        minHeight: '100px'
      }}
    >
      {children}
    </Box>
  )
}

const freePlanFeatureBox = (
  <Stack
    direction='column'
    gap='16px'
    alignItems='center'
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: 1,
      paddingInline: '4px',
      paddingBlock: '2px',
      mask: 'linear-gradient(to bottom, transparent, white 30%, white 70%, transparent)',
      maxWidth: '400px'
    }}
  >
    <FeatureBox backgroundColor='transparent' />

    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Doesn't support </Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Upgrade your plan to access this feature</Typography>
      </Stack>
    </FeatureBox>

    <FeatureBox backgroundColor='transparent' />
  </Stack>
)

const starterPlanFeatureBox = (
  <Stack
    direction='column'
    gap='16px'
    alignItems='center'
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: 1,
      paddingInline: '4px',
      paddingBlock: '2px',
      maxWidth: '400px'
    }}
  >
    <Typography color='white' fontWeight='600'>Starter Plan</Typography>

    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Keywords</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture keywords when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>

    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Revenue</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture revenue when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>
  </Stack>
)
const growthPlanFeatureBox = (
  <Stack
    direction='column'
    gap='16px'
    alignItems='center'
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: 1,
      paddingInline: '4px',
      paddingBlock: '2px',
      maxWidth: '400px'
    }}
  >
    <Typography color='white' fontWeight='600'>Growth Plan</Typography>

    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Keywords</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture keywords when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>

    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Revenue</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture revenue when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>
    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Funding</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture funding when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>
    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Technology</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture technology when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>
  </Stack>
)
const infinityPlanFeatureBox = (
  <Stack
    direction='column'
    gap='16px'
    alignItems='center'
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: 1,
      paddingInline: '4px',
      paddingBlock: '2px',
      maxWidth: '400px'
    }}
  >
    <Typography color='white' fontWeight='600'>Infinity Plan</Typography>

    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Keywords</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture keywords when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>

    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Revenue</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture revenue when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>
    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Funding</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture funding when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>
    <FeatureBox backgroundColor='#FFFFFF20'>
      <Box width='48px' height='48px' flexShrink={0}>
        <img src={UpgradePlanIcon} style={{ width: '48px', height: '48px' }} />
      </Box>
      <Stack>
        <Typography variant='h6' fontWeight='600' color='#FFF'>Technology</Typography>
        <Typography variant='subtitle2' color='#FFFFFF80'>Capture technology when a company engages with these topics</Typography>
      </Stack>
    </FeatureBox>
  </Stack>
)
