import { Add, Logout } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SBMenu from 'src/assets/sidebar/SBMenu'
import { removeAuthToken, useLogoutMutation } from 'src/services/auth-service.js'
import { setUser, useGetMeQuery } from 'src/services/user-service.js'
import { allowedDomains, config } from 'src/config'
import SubscriptionChecker from './SubscriptionChecker'

const SideNavFooter = ({ navMenuOpen, isMobile }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [logout] = useLogoutMutation()
  const [isLogoutLoading, setIsLogoutLoading] = useState(false)

  const { data: user } = useGetMeQuery()
  const dispatch = useDispatch()
  // const { data: currentPlan } = useGetCurrentPlanQuery()

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const [anchorEl3, setAnchorEl3] = useState(null)
  const [anchorEl4, setAnchorEl4] = useState(null)
  const [buttonClicked, setButtonClicked] = useState(false)

  useEffect(() => {
    if (user) {
      if (!(user.isPartner || user.partnerAccountId)) {
        window.intercomSettings = {
          app_id: config.INTERCOM_APP_ID,
          name: user.name.first + ' ' + user.name.last,
          email: user.email
        }
      }
      dispatch(setUser(user))
    }
  }, [user])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setButtonClicked(!buttonClicked)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setButtonClicked(!buttonClicked)
  }
  const handleClose2 = () => {
    setAnchorEl2(null)
    setButtonClicked(!buttonClicked)
  }
  const handleClose3 = () => {
    setAnchorEl3(null)
    setButtonClicked(!buttonClicked)
  }
  const handleClose4 = () => {
    setAnchorEl4(null)
    setButtonClicked(!buttonClicked)
  }

  const open = Boolean(anchorEl)
  const open2 = Boolean(anchorEl2)
  const open3 = Boolean(anchorEl3)
  const open4 = Boolean(anchorEl4)
  const id = open ? 'simple-popover' : undefined

  const handleLogout = async () => {
    let currentDomain
    setIsLogoutLoading(true)
    await logout().unwrap()
    removeAuthToken()
    if (typeof window !== 'undefined' && typeof window.sessionStorage !== 'undefined') {
      currentDomain = window.location.hostname
      window.sessionStorage.clear()
    }
    window.location.href = allowedDomains.includes(currentDomain) ? '/login' : '/w-login'
  }

  return (
    <>
      <SubscriptionChecker navigate={navigate} />
      <Box
        component='header'
        sx={{
          color: 'common.white',

          zIndex: (theme) => theme.zIndex.appBar,
          pr: buttonClicked && 1.9
        }}
      >
        <Stack
          direction='row'
          justifyContent={navMenuOpen || isMobile ? 'space-between' : 'flex-start'}
          sx={{ mb: isMobile ? 2 : 0 }}
        >
          {navMenuOpen || isMobile
            ? (
              <Stack alignItems='center' direction='row' spacing={2}>
                <Avatar
                  sx={{
                    backgroundColor: theme?.palette.primary.light,
                    width: 40,
                    height: 40,
                    color: theme?.palette.primary.dark
                  }}
                >
                  {user?.name?.first.charAt(0).toUpperCase()}
                  {user?.name?.last.charAt(0).toUpperCase()}
                </Avatar>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    mx: 1,
                    width: 130,
                    overflow: 'hidden',
                    gap: 1
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '18px',
                      letterSpacing: '0em',
                      color: theme.palette.primary.contrastText,
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {' '}
                    {user?.name?.first} {user?.name?.last}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 400,
                      lineHeight: '16px',
                      letterSpacing: '0em',
                      color: theme.palette.primary.contrastText,
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {user?.email}
                  </Typography>
                </Box>
                <IconButton onClick={handleClick}>
                  <SBMenu color={theme.palette.primary.contrastText} />
                </IconButton>
              </Stack>
              )
            : (
              <Avatar
                onClick={handleClick}
                sx={{
                  backgroundColor: theme?.palette.primary.light,
                  width: 40,
                  height: 40,
                  color: theme.palette.primary.dark,
                  ml: 0.5
                }}
              >
                {user?.name?.first?.charAt(0).toUpperCase()}
                {user?.name?.last?.charAt(0).toUpperCase()}
              </Avatar>
              )}
        </Stack>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 200
          }}
        >
          <Button fullWidth sx={{ py: 2 }} onClick={() => navigate('/settingsUpdated')}>
            Settings
          </Button>
          <Button fullWidth sx={{ py: 2 }} onClick={handleLogout}>
            Logout
            {isLogoutLoading
              ? (
                <CircularProgress color='inherit' size={20} sx={{ ml: 1 }} />
                )
              : (
                <Logout fontSize='small' sx={{ ml: 1 }} />
                )}
          </Button>
        </Box>
      </Popover>
      <Popover
        id={id}
        open={open4}
        anchorEl={anchorEl4}
        onClose={handleClose4}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 200
          }}
        >
          <Button fullWidth sx={{ py: 2 }}>
            Help
          </Button>
        </Box>
      </Popover>
      <Popover
        id={id}
        open={open3}
        anchorEl={anchorEl3}
        onClose={handleClose3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 200
          }}
        >
          <Typography sx={{ p: 2, fontSize: '14px', fontWeight: 600 }}>No notifications</Typography>
        </Box>
      </Popover>
      <Popover
        id={id}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 200
          }}
        >
          <TextField
            fullWidth
            sx={{
              '& div': { borderRadius: 0, p: 1.5, px: 5 },
              '& div fieldset': { borderRadius: 0 }
            }}
            placeholder='Search'
            variant='filled'
          />
          <Button
            fullWidth
            sx={{ py: 2, backgroundColor: '#BCEBDA', borderRadius: 0, color: '#101828' }}
          >
            My Organization
          </Button>
          <Button fullWidth sx={{ py: 2, color: '#101828' }}>
            <Add fontSize='small' sx={{ mr: 1, color: '#101828' }} /> Create Workspace
          </Button>
        </Box>
      </Popover>
    </>
  )
}
export default SideNavFooter
