import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Switch,
  IconButton,
  Tabs,
  Tab,
  CircularProgress,
  FormControlLabel,
  useTheme
} from '@mui/material'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import CloseIcon from '@mui/icons-material/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-hot-toast'
import HeaderWithPopover from 'src/components/HeaderWithPopover'
import { HubSpotLogo } from 'src/assets/integrations/HubSpotLogo'
import { useGetCampaignEmailLabelsQuery, useGetDealPipelinesQuery, useSaveHubspotSettingMutation, useGetHubspotSettingQuery } from 'src/services/hubspot-service'

const Page = () => {
  const theme = useTheme()
  const { data: leadStatuses } = useGetCampaignEmailLabelsQuery()
  const { data: pipelines } = useGetDealPipelinesQuery()
  const { data: settings } = useGetHubspotSettingQuery()
  const [saveHubspotSetting, { isLoading: isCreating }] = useSaveHubspotSettingMutation()

  const [tabValue, setTabValue] = useState(0)
  const [validationErrors, setValidationErrors] = useState({})
  const [touched, setTouched] = useState({})
  const [mappings, setMappings] = useState([
    { id: 1, sourceField: 'firstName', sourceFieldlabel: 'First Name', targetField: 'firstname', targetFieldLabel: 'First Name', defaultField: true },
    { id: 2, sourceField: 'lastName', sourceFieldlabel: 'Last Name', targetField: 'lastname', targetFieldLabel: 'Last Name', defaultField: true },
    { id: 3, sourceField: 'email', sourceFieldlabel: 'Email', targetField: 'email', targetFieldLabel: 'Email', defaultField: true },
    { id: 4, sourceField: 'phone_number', sourceFieldlabel: 'Phone Number', targetField: 'phone', targetFieldLabel: 'Phone Number', defaultField: true },
    { id: 5, sourceField: 'company_name', sourceFieldlabel: 'Company Name', targetField: 'company', targetFieldLabel: 'Company Name', defaultField: true }
  ])

  const availableSourceFields = [
    { sourceField: 'firstName', sourceFieldlabel: 'First Name' },
    { sourceField: 'lastName', sourceFieldlabel: 'Last Name' },
    { sourceField: 'title', sourceFieldlabel: 'Title' },
    { sourceField: 'email', sourceFieldlabel: 'Email' },
    { sourceField: 'phone_number', sourceFieldlabel: 'Phone Number' },
    { sourceField: 'city', sourceFieldlabel: 'City' },
    { sourceField: 'state', sourceFieldlabel: 'State' },
    { sourceField: 'company_name', sourceFieldlabel: 'Company Name' },
    { sourceField: 'country', sourceFieldlabel: 'Country' },
    { sourceField: 'industry', sourceFieldlabel: 'Industry' },
    { sourceField: 'linkedin_url', sourceFieldlabel: 'LinkedIn Url' },
    { sourceField: 'website_domain', sourceFieldlabel: 'Website Domain' }
  ]

  const availableTargetFields = [
    { targetField: 'firstname', targetFieldLabel: 'First Name' },
    { targetField: 'lastname', targetFieldLabel: 'Last Name' },
    { targetField: 'jobtitle', targetFieldLabel: 'Job Title' },
    { targetField: 'company', targetFieldLabel: 'Company Name' },
    { targetField: 'email', targetFieldLabel: 'Email' },
    { targetField: 'phone', targetFieldLabel: 'Phone Number' },
    { targetField: 'city', targetFieldLabel: 'City' },
    { targetField: 'state', targetFieldLabel: 'State' },
    { targetField: 'country', targetFieldLabel: 'Country' },
    { targetField: 'industry', targetFieldLabel: 'Industry' },
    { targetField: 'hs_linkedin_url', targetFieldLabel: 'LinkedIn Url' },
    { targetField: 'website', targetFieldLabel: 'Website Domain' }
  ]

  // Add state for tracking available fields
  const [availableSourceFieldsState, setAvailableSourceFieldsState] = useState(availableSourceFields)
  const [availableTargetFieldsState, setAvailableTargetFieldsState] = useState(availableTargetFields)

  const handleAddMapping = () => {
    const newMapping = {
      id: mappings.length + 1,
      sourceField: null,
      targetField: null
    }
    setMappings([...mappings, newMapping])
  }

  // Modified handleRemoveMapping to handle available fields
  const handleRemoveMapping = (id, defaultValue = false) => {
    if (defaultValue === false) {
      const mappingToRemove = mappings.find(m => m.id === id)

      // Add removed fields back to available options
      if (mappingToRemove.sourceField) {
        const sourceField = availableSourceFields.find(
          f => f.sourceField === mappingToRemove.sourceField
        )
        setAvailableSourceFieldsState(prev =>
          [...prev, sourceField].sort((a, b) =>
            a.sourceFieldlabel.localeCompare(b.sourceFieldlabel)
          )
        )
      }

      if (mappingToRemove.targetField) {
        const targetField = availableTargetFields.find(
          f => f.targetField === mappingToRemove.targetField
        )
        setAvailableTargetFieldsState(prev =>
          [...prev, targetField].sort((a, b) =>
            a.targetFieldLabel.localeCompare(b.targetFieldLabel)
          )
        )
      }

      setMappings(mappings.filter(mapping => mapping.id !== id))
    }
  }

  const handleTabChange = (newValue) => {
    setTabValue(newValue)
  }

  const defaultValues = {
    dealTriggerPoint: settings?.data?.dealTriggerPoint || '',
    dealStageWithPipeline: settings?.data?.dealStageWithPipeline || '',
    activityStatus: settings?.data?.activityStatus || false
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...defaultValues
    },
    validationSchema: Yup.object({
      dealTriggerPoint: Yup.string().trim().required('This field is required'),
      dealStageWithPipeline: Yup.string().trim().required('This field is required'),
      activityStatus: Yup.string().trim().required('This field is required')
    }),
    onSubmit: async (values) => {
      try {
        await saveHubspotSetting(values).unwrap()
        toast.success('Hubspot Setting Updated Successfully.')
      } catch (error) {
        toast.error(error.data?.error?.message || 'Failed to update Hubspot Setting.')
      }
    }
  })

  // Validate a single mapping
  const validateMapping = (mapping) => {
    const errors = {}

    if (!mapping.sourceField) {
      errors.sourceField = 'Source field is required'
    }

    if (!mapping.targetField) {
      errors.targetField = 'Target field is required'
    }

    return errors
  }

  // Validate all mappings
  const validateAllMappings = () => {
    const newErrors = {}
    mappings.forEach((mapping) => {
      const mappingErrors = validateMapping(mapping)
      if (Object.keys(mappingErrors).length > 0) {
        newErrors[mapping.id] = mappingErrors
      }
    })
    return newErrors
  }

  // Modified handleFieldChange to handle available fields
  const handleFieldChange = (id, field, value) => {
    const oldMapping = mappings.find(m => m.id === id)

    setMappings(mappings.map(mapping => {
      if (mapping.id === id) {
        return { ...mapping, [field]: value }
      }
      return mapping
    }))

    // Add old value back to available fields if it exists
    if (oldMapping[field]) {
      if (field === 'sourceField') {
        const oldField = availableSourceFields.find(f => f.sourceField === oldMapping[field])
        setAvailableSourceFieldsState(prev => [...prev, oldField].sort((a, b) =>
          a.sourceFieldlabel.localeCompare(b.sourceFieldlabel)
        ))
      } else {
        const oldField = availableTargetFields.find(f => f.targetField === oldMapping[field])
        setAvailableTargetFieldsState(prev => [...prev, oldField].sort((a, b) =>
          a.targetFieldLabel.localeCompare(b.targetFieldLabel)
        ))
      }
    }

    // Remove new value from available fields
    if (value) {
      if (field === 'sourceField') {
        setAvailableSourceFieldsState(prev =>
          prev.filter(f => f.sourceField !== value)
        )
      } else {
        setAvailableTargetFieldsState(prev =>
          prev.filter(f => f.targetField !== value)
        )
      }
    }

    // Handle validation
    setTouched(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: true
      }
    }))

    const updatedMapping = { ...oldMapping, [field]: value }
    const mappingErrors = validateMapping(updatedMapping)

    setValidationErrors(prev => ({
      ...prev,
      [id]: mappingErrors
    }))
  }

  // Modified handleBlur for validation
  const handleBlur = (id, field) => {
    setTouched(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: true
      }
    }))

    const mapping = mappings.find(m => m.id === id)
    const mappingErrors = validateMapping(mapping)

    setValidationErrors(prev => ({
      ...prev,
      [id]: mappingErrors
    }))
  }

  const saveMappings = async () => {
    const errors = validateAllMappings()

    // Mark all fields as touched
    const newTouched = {}
    mappings.forEach(mapping => {
      newTouched[mapping.id] = {
        sourceField: true,
        targetField: true
      }
    })
    setTouched(newTouched)

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors)
      toast.error('Please fix validation errors before saving')
      return
    }

    try {
      const Data = mappings.map(({ id, ...rest }) => rest)
      await saveHubspotSetting({ mappings: Data }).unwrap()
      toast.success('Hubspot Setting Updated Successfully.')
    } catch (error) {
      toast.error(error.data?.error?.message || 'Failed to update Hubspot Setting.')
    }
  }

  // Helper function to get error message
  const getErrorMessage = (id, field) => {
    if (touched[id]?.[field] && validationErrors[id]?.[field]) {
      return validationErrors[id][field]
    }
    return ''
  }

  useEffect(() => {
    if (settings?.data) {
      formik.setValues({
        dealTriggerPoint: settings.data.dealTriggerPoint || '',
        dealStageWithPipeline: settings.data.dealStageWithPipeline || '',
        activityStatus: settings.data.activityStatus ?? false
      })

      const updatedMappings = settings.data.mappings.map((mapping, index) => ({
        ...mapping,
        id: index + 1
      }))

      setMappings(updatedMappings)
    }
  }, [settings?.data])

  // Update available fields when mappings change
  useEffect(() => {
    // Get all selected source fields
    const selectedSourceFields = mappings
      .map(mapping => mapping.sourceField)
      .filter(field => field !== null)

    // Get all selected target fields
    const selectedTargetFields = mappings
      .map(mapping => mapping.targetField)
      .filter(field => field !== null)

    // Filter available fields to exclude selected ones
    const updatedSourceFields = availableSourceFields.filter(
      field => !selectedSourceFields.includes(field.sourceField)
    )

    const updatedTargetFields = availableTargetFields.filter(
      field => !selectedTargetFields.includes(field.targetField)
    )

    setAvailableSourceFieldsState(updatedSourceFields)
    setAvailableTargetFieldsState(updatedTargetFields)
  }, [mappings])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%'
        }}
      >
        <Box
          sx={{
            width: '90%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <HeaderWithPopover
            title='HubSpot'
          />
          <Box sx={{ mt: 3, width: '100%' }}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => handleTabChange(newValue)}
              sx={{ borderBottom: '1px solid #E4E4E5' }}
            >
              <Tab label='Settings' />
              <Tab label='Field Mapping' />
            </Tabs>
            {tabValue === 0
              ? (
            // Settings Tab
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Container maxWidth='md' sx={{ py: 6 }}>
                    <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
                      <Box sx={{ bgcolor: 'warning.main', borderRadius: 2, p: 2, width: 64, height: 64, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <HubSpotLogo />
                      </Box>
                      <Box>
                        <Typography variant='h5' sx={{ mb: 1, fontWeight: 600, color: theme.palette.primary.primaryText }}> About Hubspot configuration </Typography>
                        <Typography color='text.secondary'> Marketing, sales, and service software that helps your business grow without compromise.</Typography>
                      </Box>
                    </Box>

                    {/* Settings Form */}
                    <Card>
                      <CardContent sx={{ p: 3 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                          <FormControl fullWidth error={formik.touched.dealTriggerPoint && Boolean(formik.errors.dealTriggerPoint)}>
                            <InputLabel>Create deals when lead is marked as</InputLabel>
                            <Select
                              name='dealTriggerPoint'
                              value={formik.values.dealTriggerPoint}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              label='Create deals when lead is marked as'
                            >
                              {leadStatuses?.labels?.length > 0
                                ? (
                                    leadStatuses.labels.map((status) => (
                                      <MenuItem key={status._id} value={status._id}>
                                        {status.name}
                                      </MenuItem>
                                    ))
                                  )
                                : (
                                  <MenuItem>Loading...</MenuItem>
                                  )}
                            </Select>
                            {formik.touched.dealTriggerPoint && formik.errors.dealTriggerPoint && (
                              <Typography variant='caption' color='error'>
                                {formik.errors.dealTriggerPoint}
                              </Typography>
                            )}
                          </FormControl>

                          <FormControl fullWidth error={formik.touched.dealStageWithPipeline && Boolean(formik.errors.dealStageWithPipeline)}>
                            <InputLabel>Select Hubspot Stage for Deal Creation</InputLabel>
                            <Select
                              name='dealStageWithPipeline'
                              value={formik.values.dealStageWithPipeline}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              label='Select Hubspot Stage for Deal Creation'
                            >
                              {pipelines?.data?.results?.length > 0
                                ? (
                                    pipelines.data.results.map((pipeline) =>
                                      pipeline.stages.map((stage) => (
                                        <MenuItem key={pipeline.stages.displayOrder} value={`${pipeline.id}|${stage.id}`}>
                                          {`${stage.label} (${pipeline.label})`}
                                        </MenuItem>
                                      ))
                                    )
                                  )
                                : (
                                  <MenuItem>Loading...</MenuItem>
                                  )}
                            </Select>
                            {formik.touched.dealStageWithPipeline && formik.errors.dealStageWithPipeline && (
                              <Typography variant='caption' color='error'>
                                {formik.errors.dealStageWithPipeline}
                              </Typography>
                            )}
                          </FormControl>

                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                              control={
                                <Switch
                                  name='activityStatus'
                                  checked={formik.values.activityStatus}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              }
                              label='Add activity to Hubspot'
                              labelPlacement='start'
                            />
                            {formik.touched.activityStatus && formik.errors.activityStatus && (
                              <Typography variant='caption' color='error'>
                                {formik.errors.activityStatus}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                    {/* Save Button */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                      <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={isCreating}
                      >
                        {isCreating ? <CircularProgress size={24} /> : 'Save'}
                      </Button>
                    </Box>
                  </Container>
                </form>
                )
              : tabValue === 1 && (
              // Field Mapping Tab
                <Container maxWidth='md' sx={{ py: 6 }}>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant='h6' sx={{ mb: 2, color: theme.palette.primary.primaryText }}>
                      Customize Mapping
                    </Typography>
                    <Typography color='text.secondary'>
                      Mappings connect individual fields with matching information from each app.
                    </Typography>
                  </Box>

                  {/* App Icons */}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '32px',
                      height: '32px',
                      bgcolor: 'warning.main',
                      borderRadius: 1,
                      justifyContent: 'center'
                    }}
                    >
                      <HubSpotLogo sx={{ width: '25px', height: '25px' }} />
                    </Box>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '32px',
                      height: '32px',
                      borderRadius: 1,
                      justifyContent: 'center',
                      marginRight: '45.7%'
                    }}
                    >
                      <img
                        src='/apple-touch-icon.png'
                        alt='App Icon'
                        style={{ width: '30px', height: '30px' }}
                      />
                    </Box>
                  </Box>

                  <Box sx={{ mb: 3 }}>
                    {mappings.map((mapping) => (
                      <Box
                        key={mapping.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          mb: 2
                        }}
                      >
                        <FormControl sx={{ flex: 1 }} error={!!getErrorMessage(mapping.id, 'sourceField')}>
                          <Select
                            value={mapping.sourceField}
                            onChange={(e) => handleFieldChange(mapping.id, 'sourceField', e.target.value)}
                            onBlur={() => handleBlur(mapping.id, 'sourceField')}
                            displayEmpty
                            sx={{ width: '100%' }}
                          >
                            <MenuItem value='' disabled>Select Field</MenuItem>
                            {/* Show currently selected value if it exists */}
                            {mapping.sourceField && !availableSourceFieldsState.find(f => f.sourceField === mapping.sourceField) && (
                              <MenuItem value={mapping.sourceField}>
                                {availableSourceFields.find(f => f.sourceField === mapping.sourceField)?.sourceFieldlabel}
                              </MenuItem>
                            )}
                            {/* Show available options */}
                            {availableSourceFieldsState.map((field) => (
                              <MenuItem key={field.sourceField} value={field.sourceField}>
                                {field.sourceFieldlabel}
                              </MenuItem>
                            ))}
                          </Select>
                          {getErrorMessage(mapping.id, 'sourceField') && (
                            <Typography variant='caption' color='error' sx={{ mt: 0.5 }}>
                              {getErrorMessage(mapping.id, 'sourceField')}
                            </Typography>
                          )}
                        </FormControl>

                        <CompareArrowsIcon sx={{ color: 'text.secondary' }} />

                        <FormControl sx={{ flex: 1 }} error={!!getErrorMessage(mapping.id, 'targetField')}>
                          <Select
                            value={mapping.targetField}
                            onChange={(e) => handleFieldChange(mapping.id, 'targetField', e.target.value)}
                            onBlur={() => handleBlur(mapping.id, 'targetField')}
                            displayEmpty
                            sx={{ width: '100%' }}
                          >
                            <MenuItem value='' disabled>Select Field</MenuItem>
                            {/* Show currently selected value if it exists */}
                            {mapping.targetField && !availableTargetFieldsState.find(f => f.targetField === mapping.targetField) && (
                              <MenuItem value={mapping.targetField}>
                                {availableTargetFields.find(f => f.targetField === mapping.targetField)?.targetFieldLabel}
                              </MenuItem>
                            )}
                            {/* Show available options */}
                            {availableTargetFieldsState.map((field) => (
                              <MenuItem key={field.targetField} value={field.targetField}>
                                {field.targetFieldLabel}
                              </MenuItem>
                            ))}
                          </Select>
                          {getErrorMessage(mapping.id, 'targetField') && (
                            <Typography variant='caption' color='error' sx={{ mt: 0.5 }}>
                              {getErrorMessage(mapping.id, 'targetField')}
                            </Typography>
                          )}
                        </FormControl>

                        <IconButton
                          onClick={() => handleRemoveMapping(mapping.id, mapping.defaultField)}
                          size='small'
                          sx={{
                            color: mapping.defaultField
                              ? '#e7e8e9'
                              : 'text.secondary',
                            pointerEvents: mapping.defaultField
                              ? 'none'
                              : 'auto'
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      variant='outlined'
                      onClick={handleAddMapping}
                    >
                      New Mapping
                    </Button>

                    <Button
                      variant='contained'
                      color='primary'
                      onClick={saveMappings}
                      disabled={isCreating}
                    >
                      {isCreating ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                  </Box>
                </Container>
              )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Page
