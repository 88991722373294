import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import InboxPagination from 'src/components/InboxPagination'
import { useGetCampaignLogsMutation } from 'src/services/campaign-service'

const formatTimestamp = (timestamp) => moment(timestamp).fromNow()

export function CampaignLogsTab ({ campaign }) {
  const [logs, setLogs] = useState([])
  const [totalLogs, setTotalLogs] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [fetchLogs, { isLoading }] = useGetCampaignLogsMutation()

  const handleLimitChange = (event) => {
    setLimit(Number(event.target.value))
    setPage(1)
  }

  useEffect(() => {
    (async () => {
      try {
        const { data } = await fetchLogs({
          id: campaign?._id,
          limit: String(limit),
          offset: String(logs.length * (page - 1))
        })

        setLogs(data?.docs || [])
        setTotalLogs(data?.totalDocs || 0)
      } catch {
        toast.error('error while fetching logs')
        setLogs([])
      }
    })()
  }, [limit, page])

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <CircularProgress size={24} />
      </Box>
    )
  }

  if (!logs.length) {
    return (
      <Typography variant='body1' align='center'>
        'No Data Found'
      </Typography>
    )
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 520 }}>
        <Table stickyHeader aria-label='campaign logs'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '200px' }}>Time</TableCell>
              <TableCell>Activity Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log, index) => (
              <TableRow key={index}>
                <TableCell>
                  <div>{formatTimestamp(log.createdAt)}</div>
                  <span style={{ fontSize: 'small' }}>{log.timestamp}</span>
                </TableCell>
                <TableCell>{log.logMessage}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <InboxPagination
          page={page}
          setPage={setPage}
          total={totalLogs}
          length={logs.length}
          limit={limit}
          handleLimitChange={handleLimitChange}
        />
      </Box>
    </>
  )
}
